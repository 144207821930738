import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Button,
} from "@mui/material";
import { IMG, TablePopup } from "../../users/userTable/Components";
import useStyles from "../styles/useStyles";
import incrementIcon from "../../../assets/images/common/incrementIcon.svg";
import other from "../../../assets/images/clients/medication/other.png";
import novisit from "../../../assets/images/clients/medication/novisit.png";
import refuced from "../../../assets/images/clients/medication/refuced.png";
import partialyTaken from "../../../assets/images/clients/medication/partialyTaken.png";
import sheduled from "../../../assets/images/clients/medication/sheduled.png";
import Marchat from "../../../assets/images/clients/medication/Marchat.png";
import notGiven from "../../../assets/images/clients/medication/notGiven.png";
import notTaken from "../../../assets/images/clients/medication/notTaken.png";  
import missingMedication from "../../../assets/images/clients/medication/missingMedication.png";
import preparedLeftOut from "../../../assets/images/clients/medication/preparedLeftOut.png";
import selfAdministrated from "../../../assets/images/clients/medication/selfAdministrated.png";
import FullyTaken from "../../../assets/images/clients/medication/fullytaken.png";
import MarChartModal from "./MarChartModal";
import { TabButton } from "../clientReusableComponents/components";
const data = [{id:1,status:"FullyTaken",image:FullyTaken},{id:2,status:'Prepared & Left Out',image:preparedLeftOut},
  {id:3,status:'Not Taken',image:notTaken},{id:4,status:'Missing Medication',image:missingMedication},
  {id:5,status:'Destroyed',image:preparedLeftOut},{id:6,status:'Self Administered',image:selfAdministrated},
  {id:7,status:'Not Observed',image:preparedLeftOut},{id:8,status:'Refused',image:refuced},
  {id:9,status:'Not Given',image:notGiven},{id:10,status:'No Visit',image:novisit},
  {id:11,status:'Other',image:other},
  {id:12,status:'SPartially Taken',image:partialyTaken},{id:13,status:'Scheduled',image:Marchat},
  {id:14,status:'Not Scheduled',image:sheduled},,

]
const statusMap = {
  FullyTaken: { img: other, label: "Fully Taken" },
  NotTaken: { img: Marchat, label: "Not Taken" },
  Refused: { img: sheduled, label: "Refused" },
  PartiallyTaken: { img: partialyTaken, label: "Partially Taken" },
  MissingMedication: { img: refuced, label: "Missing Medication" },
  Destroyed: { img: novisit, label: "Destroyed" },
  NotGiven: { img: Marchat, label: "Not Given" },
  SelfAdministered: { img: other, label: "Self-Administered" },
  Scheduled: { img: novisit, label: "Scheduled" },
  NotScheduled: { img: sheduled, label: "Not Scheduled" },
};

const sampleData = [
  {
    medication: "Citalopram",
    type: "Blister Pack",
    sessions: [
      { session: "Morning", time: "08:00", days: Array(30).fill("FullyTaken") },
      { session: "Afternoon", time: "13:00", days: Array(30).fill("NotTaken") },
      { session: "Dinner", time: "08:00", days: Array(30).fill("FullyTaken") },
      { session: "Night", time: "13:00", days: Array(30).fill("NotTaken") },
    ],
  },
  {
    medication: "Dihydrocodeine",
    type: "Scheduled",
    sessions: [
      { session: "Morning", time: "09:00", days: Array(30).fill("Refused") },
      {
        session: "Evening",
        time: "18:00",
        days: Array(30).fill("PartiallyTaken"),
      },
    ],
  },
  {
    medication: "Paracetamol",
    type: "PRN",
    sessions: [
      {
        session: "Night",
        time: "22:00",
        days: Array(30).fill("MissingMedication"),
      },
    ],
  },
  {
    medication: "Ibuprofen",
    type: "Scheduled",
    sessions: [
      { session: "Morning", time: "07:00", days: Array(30).fill("Destroyed") },
      { session: "Afternoon", time: "12:30", days: Array(30).fill("NotGiven") },
    ],
  },
  {
    medication: "Aspirin",
    type: "Blister Pack",
    sessions: [
      {
        session: "Evening",
        time: "20:00",
        days: Array(30).fill("SelfAdministered"),
      },
    ],
  },
  {
    medication: "Omeprazole",
    type: "PRN",
    sessions: [
      { session: "Morning", time: "08:30", days: Array(30).fill("Scheduled") },
      { session: "Night", time: "21:00", days: Array(30).fill("NotScheduled") },
    ],
  },
  {
    medication: "Aspirin",
    type: "Blister Pack",
    sessions: [
      {
        session: "Evening",
        time: "20:00",
        days: Array(30).fill("SelfAdministered"),
      },
    ],
  },
  {
    medication: "Omeprazole",
    type: "PRN",
    sessions: [
      { session: "Morning", time: "08:30", days: Array(30).fill("Scheduled") },
      { session: "Night", time: "21:00", days: Array(30).fill("NotScheduled") },
    ],
  },
];
const MARChartTable = () => {
  const [medicationOpen, setMedicationOpen] = useState(false);
  const [selectedMedicationType, setSelectedMedicationType] = useState([]);
  const [popupData, setPopupData] = useState(null);
  const [editOpen, setEditOpen] = useState(false);
  const classes = useStyles();

  const adminFiltList = [
    {
      id: 1,
      text: "Select All",
      onChange: () => {
        setSelectedMedicationType(
          selectedMedicationType.length > 0
            ? []
            : ["Blister Pack", "Scheduled", "PRN"]
        );
        setMedicationOpen(false); // Close the popup after selection
      },
      checked:
        selectedMedicationType.length === 3 ||
        (selectedMedicationType.includes("Blister Pack") &&
          selectedMedicationType.includes("Scheduled") &&
          selectedMedicationType.includes("PRN")),
    },
    {
      id: 2,
      text: "Blister Pack",
      onChange: () => {
        if (selectedMedicationType.includes("Blister Pack")) {
          setSelectedMedicationType(
            selectedMedicationType.filter((type) => type !== "Blister Pack")
          );
        } else {
          setSelectedMedicationType([
            ...selectedMedicationType,
            "Blister Pack",
          ]);
        }
        setMedicationOpen(false); // Close the popup after selection
      },
      checked: selectedMedicationType.includes("Blister Pack"),
    },
    {
      id: 3,
      text: "Scheduled",
      onChange: () => {
        if (selectedMedicationType.includes("Scheduled")) {
          setSelectedMedicationType(
            selectedMedicationType.filter((type) => type !== "Scheduled")
          );
        } else {
          setSelectedMedicationType([...selectedMedicationType, "Scheduled"]);
        }
        setMedicationOpen(false); // Close the popup after selection
      },
      checked: selectedMedicationType.includes("Scheduled"),
    },
    {
      id: 4,
      text: "PRN",
      onChange: () => {
        if (selectedMedicationType.includes("PRN")) {
          setSelectedMedicationType(
            selectedMedicationType.filter((type) => type !== "PRN")
          );
        } else {
          setSelectedMedicationType([...selectedMedicationType, "PRN"]);
        }
        setMedicationOpen(false); // Close the popup after selection
      },
      checked: selectedMedicationType.includes("PRN"),
    },
  ];
  const filteredData = sampleData.filter((data) =>selectedMedicationType.length === 0 ||selectedMedicationType.includes(data.type)
  );
  return (
    <Box className={classes.MainContainer}>
      <div className={classes.tableContainer}  >
        <Table sx={{ width: " maxContent",height:"100%",overflow:"auto" }} className={classes.table}>
          <TableHead className={classes.MarChartTableHead}>
            <TableRow>
              <TableCell sx={{ left: 0 }} className={classes.tableCellHeader}>
                <Box className={classes.medicationTextHeader}>
                  <p style={{ margin: "0px", color: "white" }}>Medication</p>
                  <IMG
                    imgUrl={incrementIcon}
                    onClick={() => setMedicationOpen(!medicationOpen)}
                  />
                  {medicationOpen && (
                    <TablePopup
                      items={adminFiltList.map((item) => ({
                        id: item.id,
                        text: item.text,
                        checked: item.checked,
                        onChange: item.onChange,
                      }))}
                      onClose={() => setMedicationOpen(false)}
                    />
                  )}
                </Box>
              </TableCell>
              <TableCell sx={{ left: 181 }} className={classes.tableCellHeader}>
                Session
              </TableCell>
              <TableCell sx={{ left: 263 }} className={classes.tableCellHeader}>
                Time
              </TableCell>
              <TableCell colSpan={30}
                sx={{backgroundColor: "#D0F4EE",textAlign: "center",p: 0,border: "none"}}>
                {Array.from({ length: 30 }, (_, idx) => (
                  <TableCell key={idx} sx={{ px: 1 }}
                    className={classes.tableCellHeaderDays}>
                    <Box sx={{ width: "30px" }}>{idx + 1}</Box>
                  </TableCell>
                ))}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{}}>
            {filteredData.map((data, medIndex) =>
              data.sessions.map((session, index) => (
                <TableRow
                  key={`${data.medication}-${session.session}-${index}`}>
                  {index === 0 && (
                    <TableCell rowSpan={data.sessions.length}
                      sx={{left: 0,width: 150,}}
                      className={classes.tablecellBody}>
                      <Box className={classes.medicationText}>
                        {data.medication}
                        <br />
                        <small style={{ fontSize: "0.8em", color: "#666" }}>
                          {data.type}
                        </small>
                      </Box>
                    </TableCell>
                  )}
                  <TableCell sx={{ left: 181, width: 150 }}
                    className={classes.tablecellBody}
                  >
                    {session.session}
                  </TableCell>
                  <TableCell sx={{ left: 263 }}
                    className={classes.tablecellBody}
                  >
                    {session.time}
                  </TableCell>
                  {session.days.map((status, dayIndex) => (
                    <TableCell key={dayIndex}
                      sx={{ px: 1 }}
                      className={classes.tablecellBodyDays}
                    >
                      <Box className={classes.hoverButtonMainBox}>
                        <img
                          src={statusMap[status]?.img}
                          alt={statusMap[status]?.label}
                          className={classes.statusimage}
                        />
                        <Button
                          className={classes.hoverButton}
                          onClick={(event) => {
                            event.stopPropagation(); // Prevent table row click
                            const rect =
                              event.currentTarget.getBoundingClientRect();
                            setPopupData({
                              status: statusMap[status],
                              day: dayIndex + 1,
                              medication: data.medication,
                              type: data.type,
                              session: session.session,
                              time: session.time,
                              img: statusMap[status].img,
                              position: { top: rect.top, left: rect.left },
                            });
                            setMedicationOpen(true);
                          }}
                        >
                         { statusMap[status]?.label}
                         <span
        style={{
          position: "absolute",
          left: "45%",
          top: "-16%",
          width: 0,
          height: 0,
          paddingTop: "px",
          borderLeft: "6px solid transparent",
          borderRight: "6px solid transparent",
          borderBottom: true ? "6px solid #279989" : "6px solid transparent",
        }}
      ></span>
                        </Button>
                      </Box>
                    </TableCell>
                  ))}
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
        {/* Modal for Status Details */}
        <MarChartModal popupData={popupData} setPopupData={setPopupData} editOpen={editOpen} setEditOpen={setEditOpen} statusData={data} />
       

      </div>
      <Box
      sx={{
        position:'sticky',
        left: 0,
        zIndex: 1,
        display: "flex",
       alignItems: "center",
       flexWrap: "wrap",
       flexDirection: "row",
       mt: 3,
    // gap: "10px", // Adjust gap between the items as needed   
  }}
>
  {data?.map((item, index) => (
    <Box
      key={index}
      sx={{
        display: "flex",
        alignItems: "center",
        mb:1
      }}
    >
      {/* Status text */}
      <p
        style={{
          color: "#969999",
          fontSize: "13px",
          fontWeight: "400",
          margin: "0",
          whiteSpace: "nowrap", // Prevent text wrapping
        }}
      >
        {item?.status}
      </p>

      {/* Status image */}
      <img
        src={item?.image}
        alt="status"
        style={{
          width: "16px", // Adjust size as needed
          height: "16px",
          marginLeft: "5px", // Spacing between text and image
        }}
      />

      {/* Divider */}
      {index !== data.length - 1 && (
        <span
          style={{
            margin: "0 10px", // Spacing for the divider
            color: "#D0F4EE", // Divider color
            fontSize: "20px", // Adjust divider size
            p:2
          }}
        >
          |
        </span>
      )}
    </Box>
  ))}
     </Box>

    </Box>
  );
};
export default MARChartTable;
