import { Box, Typography } from "@mui/material";
export const StrikeBar = ({ riskLevel, showText = false }) => {
  const riskText = riskLevel.toLowerCase();

  let lowColor = "#0FB583";
  let mediumColor = "#F0CA44";
  let highColor = "#F73636";

  if (riskText === "high") {
    lowColor = "#0FB583";
    mediumColor = "#F0CA44";
    highColor = "#F73636";
  } else if (riskText === "low") {
    lowColor = "#0FB583";
    mediumColor = "#CFE3DC";
    highColor = "trasparent";
  } else if (riskText === "moderate") {
    lowColor = "#0FB583";
    mediumColor = "#F0CA44";
    highColor = "trasparent";
  }

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        borderBottom: "0px",
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          borderRadius: "10px",
          background: "#DCEBE6",
        }}
      >
        <Box
          sx={{
            background: lowColor,
            height: "12px",
            borderRadius: "10px 0px 0px 10px",
            flex: 1,
          }}
        ></Box>
        <Box
          sx={{
            background: mediumColor,
            height: "12px",
            flex: 1,
          }}
        ></Box>
        <Box
          sx={{
            background: highColor,
            height: "12px",
            flex: 1,
            borderRadius: "0px 10px 10px 0px",
          }}
        ></Box>
      </Box>


      {showText && (
        <p
          // variant="body2"
          style={{
            fontSize: "12px",
            textTransform: "capitalize",
            padding:0,
            margin:0
          }}
        >
          {riskText}
        </p>
      )}
    </Box>
  );
};
