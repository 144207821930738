import { Button } from "@mui/material";

export default function CustomButtom({
  text = "",
  variant = "outlinedSecondary",
  disabled = false,
  ...params
}) {
  return (
    <Button
      variant={variant}
      sx={{
        "&:hover": {
          backgroundColor: "#279989", // Change background color on hover
          color: "#FFF", // Change text color on hover,
          borderColor: "#279989",
        },
        "&:focus, &:focus-visible": {
          outline: "none", // Remove default outline
          borderColor: "#279989", // Keep the custom border color
          backgroundColor: "#279989", // Maintain the background color
          color: "#FFFFFF",
        },
        background: disabled === true ? "rgba(232, 232, 232, 1)" : "#FFFFFF",
        cursor: disabled === true ? "not-allowed" : "pointer",
        pointerEvents: disabled === true ? "none" : "auto",
        height: "33px",
        fontSize: "13px",
      }}
      {...params}
    >
      {text}
    </Button>
  );
}
