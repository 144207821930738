import * as React from "react";
import { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ActionIcon from "../../../../assets/images/userTableIcons/UserTableEdit.svg";
import HoverActionIcon from "../../../../assets/images/userTableIcons/UserTableEditHover.svg";
import { Typography } from "@mui/material";
import { getCookie } from "../../../../utils/utils";
import EditToDoDialog from "./VisitToDoEdit";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#279989",
    padding: "8px 16px",
    border: "none",
    color: theme.palette.common.white,
    position: "sticky",
    top: 0,
    zIndex: 1,
  },
  [`&.${tableCellClasses.body}`]: {
    borderBottom: "2px solid #eefefa",
    fontSize: 14,
    padding: "8px 16px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function VisitToDoTable({ visitId }) {
  const [toDoList, setToDoList] = useState([]);
  const [openEditTodo, setOpenEditTodo] = useState(false);
  const [selectedTodo, setSelectedTodo] = useState(null);
  const [refresh, setRefresh] = useState(false);

  const formatDate = (dateString) => {
    const [year, month, day] = dateString.split("-");
    return `${day}-${month}-${year}`;
  };

  useEffect(() => {
    const fetchToDo = async () => {
      try {
        const token = getCookie();
        const URL = `${process.env.REACT_APP_BASE_URL}/todo-get-todo-details?visit_details_id=${visitId}`;
        const options = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        };

        const response = await fetch(URL, options);
        const data = await response.json();

        if (response.ok) {
          setToDoList(data.data);
        } else {
          console.log("API request failed with status:", response.status);
        }
      } catch (e) {
        console.log("Error fetching data:", e.message);
      }
    };

    fetchToDo();
  }, [openEditTodo]);

  // useEffect(() => {
  //   console.log(toDoList);
  // }, [toDoList]);

  const toggleEditTodoPopup = () => setOpenEditTodo((prev) => !prev);

  const handleEditClick = (todo) => {
    setSelectedTodo(todo);
    setOpenEditTodo(true); // Open the edit dialog
  };

  const handleCloseDialog = () => {
    setOpenEditTodo(false);
    setSelectedTodo(null); // Clear the selected todo when closing
  };

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{ maxHeight: "370px", overflow: "auto" }}
      >
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Todo Name</StyledTableCell>
              <StyledTableCell align="center">Visit Date</StyledTableCell>
              <StyledTableCell align="center">Essential</StyledTableCell>
              <StyledTableCell align="center">Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {toDoList.length > 0 ? (
              toDoList.map((item) => (
                <StyledTableRow key={item.todo_description}>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    sx={{
                      textAlign: "left",
                      maxWidth: "300px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {item.todo_description}
                  </StyledTableCell>
                  <StyledTableCell sx={{ textAlign: "center" }}>
                    {formatDate(item.todo_date)}
                  </StyledTableCell>
                  <StyledTableCell sx={{ textAlign: "center" }}>
                    <Typography>
                      {item.essential === 1 ? "Yes" : "No"}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell sx={{ textAlign: "center" }}>
                    <img
                      src={ActionIcon}
                      alt="Action Icon"
                      style={{ width: "24px", height: "24px" }}
                      onMouseOver={(e) => {
                        e.currentTarget.src = HoverActionIcon;
                        e.currentTarget.style.cursor = "pointer";
                      }}
                      onMouseOut={(e) => (e.currentTarget.src = ActionIcon)}
                      onClick={() => handleEditClick(item)}
                    />
                  </StyledTableCell>
                </StyledTableRow>
              ))
            ) : (
              <StyledTableRow>
                <StyledTableCell colSpan={4} align="center">
                  No ToDo's added for this visit
                </StyledTableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {openEditTodo && (
        <EditToDoDialog
          openDialog={openEditTodo}
          handleCloseDialog={handleCloseDialog}
          selectedTodo={selectedTodo} // Pass selected todo if needed
        />
      )}
    </>
  );
}
