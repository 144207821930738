import React, { useEffect, useState } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  TextField,
  FormControlLabel,
  Checkbox,
  Button,
  IconButton,
  Grid,
} from "@mui/material";
import { Name, Calendar } from "../../../reusableComponents";
import CloseIcon from "../../../../assets/icons/close.svg";
import CloseHoverIcon from "../../../../assets/icons/closehover.svg";
import ToDo_List_1 from "../../../../assets/images/clients/todoList_Icon/ToDo_List_1.gif";
import ConfirmationDialog from "../../../reusableComponents/ConfirmationDialog";
import { getCookie } from "../../../../utils/utils";
import Admin from "../../../../assets/images/users/Admin.svg";

export default function EditToDoDialog({
  openDialog,
  selectedTodo,
  handleCloseDialog = () => {},
}) {
  const [form, setForm] = useState({
    additional_notes: selectedTodo?.additional_notes || "",
    essential: selectedTodo?.essential || 0,
  }); // Initialize with selectedTodo or empty object
  const [intialForm, setIntialForm] = useState(form); // State for error messages
  const [showConfirmation, setShowConfirmation] = useState(false);

  const areObjectsEqual = (obj1, obj2) => {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  };

  const PutEdit_Todo = async (updatedData) => {
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/todo/${selectedTodo.todo_details_id}`;
      const token = getCookie();
      const options = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(updatedData),
      };
      const response = await fetch(url, options);
      const data = await response.json();
      if (response.ok && data.statusCode === 200) {
        const msg = "Updated todo Details";
        handleCloseDialog();
      } else {
        const msg2 = "Failed to update todo details.";
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "backdropClick" || reason === "escapeKeyDown") {
      return;
    }
    handleCloseDialog();
  };

  const handleDateChange = (date, name) => {
    setForm((prevFormData) => {
      const updatedForm = { ...prevFormData, [name]: date };
      return updatedForm;
    });
  };

  const handleChange = (name, value) => {
    setForm((prevFormData) => ({
      ...prevFormData,
      [name]: value, // Dynamically set the value for the input that changed
    }));
  };

  const handleCloseConfirmation = () => {
    setShowConfirmation(false);
  };

  const handleCheckboxChange = (name, checked) => {
    setForm((prevFormData) => ({
      ...prevFormData,
      [name]: checked ? 1 : 0, // Dynamically set the value for the input that changed
    }));
  };

  const onConfirmFun = () => {
    console.log("Payload to be sent:", form); // Log to see what you are sending
    PutEdit_Todo(form);
  };
  const onCancelFun = () => {
    if (!areObjectsEqual(form, intialForm)) {
      setShowConfirmation(true);
    } else {
      handleCloseDialog();
    }
    console.log(showConfirmation);
  };

  const onConfirmationConfirmFun = () => {
    setShowConfirmation(false);
    setForm(intialForm);
    handleCloseDialog();
  };

  return (
    <Dialog
      open={openDialog}
      onClose={handleClose}
      PaperProps={{
        sx: {
          width: "50%",
          maxWidth: "700px",
        },
      }}
    >
      <Box textAlign="center" justifyContent="Center">
        <IconButton
          aria-label="close"
          onClick={onCancelFun}
          sx={{
            position: "absolute",
            right: 1,
            top: 10,
            width: "20px !important",
            height: "20px !important",
            backgroundImage: `url(${CloseIcon})`,
            backgroundSize: "cover",
            "&:hover": {
              backgroundImage: `url(${CloseHoverIcon})`,
            },
          }}
        />
        <DialogContent sx={{ margin: "0px 30px" }}>
          <Box sx={{ marginTop: "10px" }}>
            <img
              style={{
                width: "10%",
                height: "auto",
              }}
              src={ToDo_List_1}
              alt="Edit To-Do"
            />
            <Typography variant="h3">Edit To-Do</Typography>
          </Box>

          <Box>
            <Grid container direction="row" alignItems="Top">
              <Grid item xs={12}>
                <Name
                  label="To-Do Name"
                  name="additional_notes"
                  placeholder="Selected To-Do"
                  preFilledvalue={selectedTodo?.todo_description}
                  disabled
                ></Name>
              </Grid>
              <Grid item xs={12}>
                <Calendar
                  label="Visit Date"
                  onDateChange={handleDateChange}
                  preFilledvalue={selectedTodo?.visit_date}
                  disabled
                ></Calendar>
              </Grid>
              <Grid item xs={12}>
                <Name
                  label="Additional Notes"
                  name="additional_notes"
                  className="multiline-textfield"
                  placeholder="Additional Notes"
                  multiline="true"
                  rows="4"
                  preFilledvalue={selectedTodo?.additional_notes}
                  onValueChange={(value) =>
                    handleChange("additional_notes", value)
                  }
                ></Name>
              </Grid>
              {/* Alert Checkbox */}
              <Grid item xs={12} sx={{ textAlign: "left" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={form.essential === 1} // Bind to the state
                      onChange={
                        (e) =>
                          handleCheckboxChange("essential", e.target.checked) // Update the state based on checkbox
                      }
                    />
                  }
                  label="Check this to get an alert if the Todo is not completed."
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="customcancel" onClick={onCancelFun} color="primary">
            Cancel
          </Button>
          <Button variant="customsave" onClick={onConfirmFun} color="primary">
            Save
          </Button>
        </DialogActions>
        {showConfirmation && (
          <ConfirmationDialog
            openDialog={showConfirmation}
            onConfirmFun={onConfirmationConfirmFun}
            onCancelFun={handleCloseConfirmation}
            TitleText="Do you want to leave without saving changes?"
            paraText="Any unsaved changes will be lost."
            IconUrl={Admin}
            cancelText="Cancel"
            confirmText="Confirm"
          />
        )}
      </Box>
    </Dialog>
  );
}
