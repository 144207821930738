import { useState, useEffect } from "react";

import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import { Box, Button } from "@mui/material";

import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import React from "react";
import PaginationCust from "./PaginationCust";
import { Link } from "react-router-dom";
import EnhancedTableHead from "./TodoTableHeader";
import { Typography } from "@mui/material";
import { PAGINATION_LIST } from "../../../../constants/constants";
import { ScreenHeights } from "../../../../themes/Theme";
import { useParams } from "react-router-dom";

import { Status } from "./Components";
import { slicedText } from "../../../../utils/utils";
import UserTableEdit from "../../../../assets/images/userTableIcons/UserTableEdit.svg";
import UserTableEditHover from "../../../../assets/images/userTableIcons/UserTableEditHover.svg";
import UserTableView from "../../../../assets/images/userTableIcons/UserTableView.svg";
import UserTableViewHover from "../../../../assets/images/userTableIcons/UserTableViewHover.svg";
import UserTableDriveImage from "../../../../assets/images/userTableIcons/UserTableCar.svg";
import { StrikeBar } from "../../clientReusableComponents/StrikeBar";
import { getCookie } from "../../../../utils/utils";
import EditTodoForm from "../EditTodoForm";

const useStyles = (theme) => ({
  root: {
    width: "100%",
    // maxWidth: 800,
    background: "blue",
  },
  container: {
    maxHeight: 440,
  },
  paper: {
    width: "100%",
    // marginBottom: theme.spacing(2),
    marginBottom: 2,
  },
  table: {
    // minWidth: 400,
    // maxWidth:500,
    maxHeight: 200,
    overflowY: "auto",
    width: "100%",

    border: "none",
    // boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Add box shadow
  },
  tablebody: {
    // minWidth: 400,
    // maxWidth:500,
    //maxHeight: 200,
    overflowY: "auto",
    //width: "100%",
    //border: "none",
    //boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Add box shadow
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
});

const HoverImg = ({ imgBeforeHover, imgAfterHover }) => {
  const [hover, setHover] = useState(false);
  return (
    <img
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{
        width: "26px",
        height: "26px",
      }}
      alt="table icons"
      src={hover ? imgAfterHover : imgBeforeHover}
    />
  );
};

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  maxHeight: ScreenHeights.userTableHeight,
  minWidth: 200,
  border: "none",
  // boxShadow: "none",
  borderRadius: "5px", // Add border radius here
  background: "#FFFFFF",
  boxShadow: "0px 0px 10px 0px rgba(39, 153, 137, 0.46)",
  overflowY: "auto", // Ensure content inside respects border-radius
}));

const StyledTable = styled(Table)(({ theme }) => ({
  borderRadius: "5px", // Add border radius here
  overflow: "hidden", // Ensure content inside respects border-radius
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#EEFEFA",
    // border:"0px"
  },
  "&.MuiTableRow-root": {
    borderBottom: "none",
  },
}));

const styles = {
  tableCell: {
    borderBottom: "none", // This removes the border from the cells,
    paddingTop: "8px",
    paddingBottom: "8px",
  },
  pagragraph: { paddingLeft: "0px", fontSize: "13px", padding: "0px" },
};

// const sessionsConstants = ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"];
const sessionsConstants = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

const sessionsListData = [
  {
    id: 1,
    session_type: "Morning",
    session_time: "7:00-11:00",
  },
  {
    id: 2,
    session_type: "Afternoon",
    session_time: "11:00-15:00",
  },
  {
    id: 3,
    session_type: "Tea",
    session_time: "15:00-18:00",
  },
  {
    id: 4,
    session_type: "Dinner",
    session_time: "18:00-22:00",
  },
  {
    id: 5,
    session_type: "Night",
    session_time: "22:00-07:00",
  },
];

export default function TodoTable({
  togoleCreateTodoPopup,
  clientId,
  usersList,
  toggleIDsInSelectedIDS,
  toggleAllIDs,
  selectedIDS,
}) {
  // console.log(usersList, "client list")
  const classes = useStyles();
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationList, setPaginationList] = useState([]);
  const [itemsRange, setItemsRange] = useState(PAGINATION_LIST[1]);
  const { userID } = useParams();

  const [originalTodoList, setOriginalTodoList] = useState([]);
  const [filteredTodoList, setFilteredTodoList] = useState([]);

  const [sessionsList, setSessionsList] = useState(sessionsListData);

  const [dense, setDense] = React.useState(true);
  const [orderByName, setOrderByName] = useState(null);
  const [order, setOrder] = useState("asc");

  const [orderByAdminList, setOrderByAdminList] = useState([]);
  const [isAdminOpen, setIAdminOpen] = useState(false);

  const [orderByStatusList, setOrderByStatusList] = useState([]);
  const [isStatusOpen, setIsStatusOpen] = useState(false);

  const [orderBySessionList, setOrderBySessionList] = useState([]);
  const [isSessionOpen, setIsSessionOpen] = useState(false);

  const [orderBy, setOrderBy] = useState("name");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const [openEditTodo, setOpenEditTodo] = useState(false);
  const toggleEditTodoPopup = () => setOpenEditTodo((prev) => !prev);
  const [selectedTodo, setSelectedTodo] = useState(null);

  const [reload, setReload] = useState(false);

  const reloadData = () => {
    setReload(!reload);
  };

  useEffect(() => {
    const fetchTodosAPI = async () => {
      try {
        // setTodosList();
        const token = getCookie();

        const BASE_URL = "https://3.8.158.142:8443/";
        const URL = `${BASE_URL}api/todo-get-all?client_id=${userID}`;
        const options = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        };

        const response = await fetch(URL, options);
        const data = await response.json();

        if (response.ok) {
          setOriginalTodoList(data.data);
          setFilteredTodoList([...data.data]);
        } else {
          console.log("get all failed");
        }
      } catch (error) {
        console.error("Error fetching ToDos:", error);
      }
    };

    fetchTodosAPI();
  }, [togoleCreateTodoPopup, reload]);

  useEffect(() => {
    // let total = Math.ceil(usersList.length / itemsRange);

    const length = getSlicedList(true);
    let total = Math.ceil(length / itemsRange);

    let numbList = [];
    for (let i = 1; i <= total; i++) {
      numbList.push(i);
    }

    setPaginationList(numbList);
    setCurrentPage(1);
  }, [usersList, itemsRange, orderByName, orderByAdminList, orderByStatusList]);
  // [mainUserList , itemsRange]

  const incPagination = () => {
    if (currentPage < paginationList.length) {
      setCurrentPage(currentPage + 1);
    }
  };

  const decPagination = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const getFilters = (users) => {
    let filtUsers = users;

    if (orderByAdminList.includes("Yes") && !orderByAdminList.includes("No")) {
      filtUsers = filtUsers.filter((each) => each.essential === 1);
    }
    if (orderByAdminList.includes("No") && !orderByAdminList.includes("Yes")) {
      filtUsers = filtUsers.filter((each) => each.essential === 0);
    }

    if (orderBySessionList.length > 0) {
      filtUsers = filtUsers.filter((each) => {
        let isContent = false;
        each.session_type.forEach((element) => {
          if (orderBySessionList.includes(element)) {
            isContent = true;
          }
        });

        return isContent;
      });
    }

    // orderBySessionList

    return filtUsers;
  };

  // Slicing and showing the list based on current page and pagination length
  const getSlicedList = (isGetLength = false) => {
    let start = currentPage === 1 ? 0 : (currentPage - 1) * itemsRange;
    let end = start + itemsRange;

    // return getFilters(usersList.slice(start, end));
    const filtedUserList = getFilters(originalTodoList);

    // setMainUserList(filtedUserList)

    if (isGetLength) {
      return filtedUserList.length;
    }
    return filtedUserList.slice(start, end);
  };

  const getDayNameList = (frequencyList) => {
    return (
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {sessionsConstants.map((day, index) => {
          const isSelected = frequencyList.includes(day);
          return (
            <Box
              key={index}
              style={{
                borderRadius: "5px",
                border: "2px solid rgba(39, 153, 137, 0.30)",
                background: isSelected ? "#279989" : "#FFF",
                color: isSelected ? "#FFF" : "#1E3037",
                fontWeight: isSelected ? 700 : 400,
                height: 35,
                width: 38,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                margin: "0px 3px",
              }}
            >
              {day.slice(0, 2)}
            </Box>
          );
        })}
      </Box>
    );
  };

  const getDataNotFound = () => {
    return (
      <StyledTableRow
        sx={{
          width: "100%",
          height: "100px",
          borderRadius: "10px",
          position: "relative",
        }}
      >
        <Typography
          variant="body0"
          sx={{
            ...styles.pagragraph,
            width: "100%",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          No Todos Found
        </Typography>
      </StyledTableRow>
    );
  };

  return (
    <div className={classes.root}>
      <StyledTableContainer
        className={classes.container}
        style={{
          height: "calc(100vh - 365px)",
          overflowY: "auto",
        }}
      >
        <StyledTable
          sx={classes.table}
          aria-labelledby="tableTitle"
          size={dense ? "small" : "medium"}
          aria-label="enhanced table"
          stickyHeader
        >
          <EnhancedTableHead
            setOrderByName={setOrderByName}
            orderByName={orderByName}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            toggleAllIDs={toggleAllIDs}
            orderByAdminList={orderByAdminList}
            setOrderByAdminList={setOrderByAdminList}
            isAdminOpen={isAdminOpen}
            setIAdminOpen={setIAdminOpen}
            orderByStatusList={orderByStatusList}
            setOrderByStatusList={setOrderByStatusList}
            isStatusOpen={isStatusOpen}
            setIsStatusOpen={setIsStatusOpen}
            sessionsList={sessionsList}
            orderBySessionList={orderBySessionList}
            setOrderBySessionList={setOrderBySessionList}
            isSessionOpen={isSessionOpen}
            setIsSessionOpen={setIsSessionOpen}
          />
          <TableBody>
            {getSlicedList().length > 0
              ? getSlicedList().map((eachTodo, index) => (
                  <StyledTableRow key={index}>
                    <TableCell sx={{ ...styles.tableCell, width: "20%" }}>
                      <Typography
                        variant="body0"
                        sx={{
                          ...styles.pagragraph,
                          textTransform: "capitalize",
                        }}
                      >
                        {eachTodo.todo_description || "-"}
                      </Typography>
                    </TableCell>

                    <TableCell
                      sx={{
                        ...styles.tableCell,
                        textAlign: "center",
                        width: "20%",
                      }}
                    >
                      <Typography variant="body0" sx={styles.pagragraph}>
                        {/* {each.frequency} */}

                        {getDayNameList(eachTodo.day_name)}
                      </Typography>
                    </TableCell>

                    <TableCell
                      sx={{
                        ...styles.tableCell,
                        textAlign: "center",
                        width: "15%",
                      }}
                    >
                      <Typography variant="body0" sx={styles.pagragraph}>
                        {/* {eachTodo?.session_type || "-"} */}
                        {eachTodo?.session_type.map((each, index) => {
                          return <Box key={index}>{each}</Box>;
                        })}
                      </Typography>
                    </TableCell>

                    <TableCell
                      sx={{
                        ...styles.tableCell,
                        textAlign: "center",
                        width: "10%",
                      }}
                    >
                      <Typography variant="body0" sx={styles.pagragraph}>
                        {eachTodo?.start_date || "-"}
                      </Typography>
                    </TableCell>

                    <TableCell
                      sx={{
                        ...styles.tableCell,
                        textAlign: "center",
                        width: "10%",
                      }}
                    >
                      <Typography variant="body0" sx={styles.pagragraph}>
                        {eachTodo?.end_date || "-"}
                      </Typography>
                    </TableCell>

                    <TableCell
                      sx={{
                        ...styles.tableCell,
                        textAlign: "center",
                        width: "15%",
                      }}
                    >
                      <Typography variant="body0" sx={styles.pagragraph}>
                        {eachTodo?.essential ? "Yes" : "No"}
                      </Typography>
                    </TableCell>

                    <TableCell
                      sx={{ ...styles.tableCell, textAlign: "center" }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          flexShrink: "0",
                          cursor: "pointer",
                        }}
                        // onClick={() => togoleEditTodoPopup()}
                        onClick={() => {
                          setSelectedTodo(eachTodo);
                          toggleEditTodoPopup();
                        }}
                      >
                        <HoverImg
                          imgBeforeHover={UserTableEdit}
                          imgAfterHover={UserTableEditHover}
                        />
                      </Box>
                    </TableCell>
                  </StyledTableRow>
                ))
              : getDataNotFound()}
          </TableBody>
          {/* Popup Component */}
          {openEditTodo && (
            <EditTodoForm
              clientId={clientId}
              open={openEditTodo}
              handleClose={toggleEditTodoPopup}
              content="Edit your details here"
              selectedTodo={selectedTodo}
              onSuccess={() => {
                reloadData();
              }}
            />
          )}
        </StyledTable>
      </StyledTableContainer>

      <PaginationCust
        total={getSlicedList(true)}
        tableName="To-Dos"
        paginationList={paginationList}
        currentPage={currentPage}
        incPagination={incPagination}
        decPagination={decPagination}
        itemsRange={itemsRange}
        setItemsRange={setItemsRange}
        isDisabled={getSlicedList().length > 0}
      />
    </div>
  );
}
