import React from 'react'
import Header from '../components/common/header/Header'

const BillingPage = () => {
  return (
    <div>
      <Header/>
      <h1>BillingPage</h1>
    </div>
  )
}

export default BillingPage