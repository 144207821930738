import React, { useState, useEffect, useMemo } from "react";
import { Container, Grid, FormLabel, Typography, Box } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { ViewTheme } from "../../../../src/components/users/Theme";
import { getCookie } from "../../../utils/utils";

export default function MyCareNetworkView({ clientId, sideTabId }) {
  const initialNetwork = useMemo(
    () => ({
      name_organization: "",
      occupation_type: "",
      email: "",
      telephone_codes: "",
      contact_number: "",
      emergency_telephone_codes: "",
      emergency_contact_number: "",
      address: "",
      city: "",
      postcode: "",
      country: "",
      org_lpa_behalf: "",
      country_code: "",
      country_name: "",
      emergency_country_code: "",
      emergency_country_name: "",
    }),
    []
  );
  const [network, setNetwork] = useState(initialNetwork);

  useEffect(() => {
    const getNetwork = async () => {
      if (!clientId) return;
      try {
        const token = getCookie();
        const URL = `${process.env.REACT_APP_BASE_URL}/care-network?client_id=${clientId}&id=${sideTabId}`;
        const options = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        };
        const response = await fetch(URL, options);
        const data = await response.json();
        if (response.ok) {
          setNetwork(data.data);
        } else {
          setNetwork(initialNetwork);
        }
      } catch (e) {
        console.error("Error fetching My care Network", e.message);
        setNetwork(initialNetwork);
      }
    };
    getNetwork();
  }, [clientId, initialNetwork, sideTabId]);

  return (
    <ThemeProvider theme={ViewTheme}>
      <Container
        sx={{ display: "flex", flexDirection: "column", width: "100%" }}
        maxWidth={false}
      >
        <Box
          sx={{
            padding: 0,
            width: { xxl: "50%", lg: "70%", xs: "98%" },
            margin: "15px 20px",
            textAlign: "left",
          }}
        >
          <Grid item xs={6} sx={{ textAlign: "left", marginBottom: "20px" }}>
            <h5>{network.occupation_type}</h5>
          </Grid>
          <Grid container direction="row" alignItems="top" spacing={"10px"}>
            <Grid item xs={3} lg={5}>
              <FormLabel>
                <Typography variant="h7">Name</Typography>
              </FormLabel>
            </Grid>
            <Grid item xs={0.5}>
              <FormLabel sx={{ textAlign: "Center" }}>:</FormLabel>
            </Grid>
            <Grid item xs={8.5} lg={6.5}>
              <Typography variant="body4">
                {network.name_organization}
              </Typography>
            </Grid>
            <Grid item xs={3} lg={5}>
              <FormLabel>
                <Typography variant="h7">Occupation</Typography>
              </FormLabel>
            </Grid>
            <Grid item xs={0.5}>
              <FormLabel sx={{ textAlign: "Center" }}>:</FormLabel>
            </Grid>
            <Grid item xs={8.5} lg={6.5}>
              <Typography variant="body4">{network.occupation_type}</Typography>
            </Grid>
            <Grid item xs={3} lg={5}>
              <FormLabel>
                <Typography variant="h7">Contact Number</Typography>
              </FormLabel>
            </Grid>
            <Grid item xs={0.5}>
              <FormLabel sx={{ textAlign: "Center" }}>:</FormLabel>
            </Grid>
            <Grid item xs={8.5} lg={6.5}>
              <Typography variant="body4">
                {`${network.country_code} ${network.contact_number}`}
              </Typography>
            </Grid>
            <Grid item xs={3} lg={5}>
              <FormLabel>
                <Typography variant="h7">Emergency Contact Number</Typography>
              </FormLabel>
            </Grid>
            <Grid item xs={0.5}>
              <FormLabel sx={{ textAlign: "Center" }}>:</FormLabel>
            </Grid>
            <Grid item xs={8.5} lg={6.5}>
              <Typography variant="body4">
                {`${network.emergency_country_code} ${network.emergency_contact_number}`}
              </Typography>
            </Grid>
            <Grid item xs={3} lg={5}>
              <FormLabel>
                <Typography variant="h7">Email</Typography>
              </FormLabel>
            </Grid>
            <Grid item xs={0.5}>
              <FormLabel sx={{ textAlign: "Center" }}>:</FormLabel>
            </Grid>
            <Grid item xs={8.5} lg={6.5}>
              <Typography variant="body4">{network.email}</Typography>
            </Grid>
            <Grid item xs={3} lg={5}>
              <FormLabel>
                <Typography variant="h7">Address</Typography>
              </FormLabel>
            </Grid>
            <Grid item xs={0.5}>
              <FormLabel sx={{ textAlign: "Center" }}>:</FormLabel>
            </Grid>
            <Grid item xs={8.5} lg={6.5}>
              <Typography variant="body4">{network.address}</Typography>
            </Grid>
            <Grid item xs={3} lg={5}>
              <FormLabel>
                <Typography variant="h7">City</Typography>
              </FormLabel>
            </Grid>
            <Grid item xs={0.5}>
              <FormLabel sx={{ textAlign: "Center" }}>:</FormLabel>
            </Grid>
            <Grid item xs={8.5} lg={6.5}>
              <Typography variant="body4">{network.city}</Typography>
            </Grid>
            <Grid item xs={3} lg={5}>
              <FormLabel>
                <Typography variant="h7">Postcode</Typography>
              </FormLabel>
            </Grid>
            <Grid item xs={0.5}>
              <FormLabel sx={{ textAlign: "Center" }}>:</FormLabel>
            </Grid>
            <Grid item xs={8.5} lg={6.5}>
              <Typography variant="body4">{network.postcode}</Typography>
            </Grid>
            <Grid item xs={3} lg={5}>
              <FormLabel>
                <Typography variant="h7">Country</Typography>
              </FormLabel>
            </Grid>
            <Grid item xs={0.5}>
              <FormLabel sx={{ textAlign: "Center" }}>:</FormLabel>
            </Grid>
            <Grid item xs={8.5} lg={6.5}>
              <Typography variant="body4">{network.country_name}</Typography>
            </Grid>
            <Grid item xs={3} lg={5}>
              <FormLabel>
                <Typography variant="h7">
                  Does the Person/Organization have LPA to act on behalf?
                </Typography>
              </FormLabel>
            </Grid>
            <Grid item xs={0.5}>
              <FormLabel sx={{ textAlign: "Center" }}>:</FormLabel>
            </Grid>
            <Grid item xs={8.5} lg={6.5}>
              <Typography variant="body4">
                {network.org_lpa_behalf === ""
                  ? ""
                  : network.org_lpa_behalf === 0
                  ? "No"
                  : "Yes"}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
