export const PAGENATION_RANGE = 10; //pagination length of tables

export const PAGINATION_LIST =  [10, 20, 50, 100]


// Routing paths
export const routePath =  {
    home:"/",
    login:"/login",
    users:"/users",
    verifyToken :"/api/dashboard/:token",
    addUser:"/users/add-user",
    userDashboard: "users/user-dashboard",
    clients: "/clients",

}