import React, { useState, useRef, useEffect } from "react";
import {
  Name,
  Calendar,
  NumberInput,
  Currency,
} from "../../reusableComponents";
import { Container, Grid, Button, Box } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { EditTheme } from "../Theme";
import { getCookie } from "../../../utils/utils";
import ConfirmationDialog from "../../reusableComponents/ConfirmationDialog";
import Admin from "../../../assets/images/users/Admin.svg";

export default function UserPayrollEdit({
  userId,
  toggleEdit,
  setHasUnsavedChanges,
}) {
  const initialPayrollInfo = {
    bank_name: "",
    branch_name: "",
    account_holder_name: "",
    account_number: "",
    sort_code: "",
    hourly_rate: "",
    pay_rate_per_mile: "",
    nin: "",
    employment_start: "",
    employment_end: "",
    drive: "",
  };
  const [payrollInfo, setPayrollInfo] = useState(initialPayrollInfo);
  const [payrollInfoOriginal, setPayrollInfoOriginal] =
    useState(initialPayrollInfo);

  useEffect(() => {
    const fetchPayroll = async () => {
      if (!userId) return;
      const token = getCookie();
      const URL = `${process.env.REACT_APP_BASE_URL}/payroll/${userId}`;
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await fetch(URL, options);
      const data = await response.json();
      if (response.ok === true) {
        setPayrollInfo(data.data);
        setPayrollInfoOriginal(data.data);
        setHasUnsavedChanges(false); // No unsaved changes on initial load
        // setAllowedMethod("PUT");
      } else {
        setPayrollInfoOriginal(data.data);
        setPayrollInfo(data.data);
      }
    };
    fetchPayroll();
  }, [userId, setHasUnsavedChanges]);

  const [openDialog, setOpenDialog] = useState(false);
  const handleOpen = () => {
    setOpenDialog(true);
  };
  const handleClose = () => {
    setOpenDialog(false);
  };
  const onClickYesDialogBtn = () => {
    toggleEdit();
    setHasUnsavedChanges(false);
  };
  const banknameRef = useRef();
  const branchnameRef = useRef();
  const accholdernameRef = useRef();
  const ninumberRef = useRef();
  const accnumberRef = useRef(null);
  const sortcodeRef = useRef(null);
  const hourlyrateRef = useRef(null);
  const payratepermileRef = useRef(null);
  const empstartdateRef = useRef(null);
  const empenddateRef = useRef(null);

  const handleValueChange = (newValue, name) => {
    setPayrollInfo((prevFormData) => {
      const updatedForm = { ...prevFormData, [name]: newValue };
      setHasUnsavedChanges(!areObjectsEqual(updatedForm, payrollInfoOriginal)); // Detect changes
      return updatedForm;
    });
  };

  const handleNumberChange = (number, name) => {
    setPayrollInfo((prevFormData) => {
      const updatedForm = { ...prevFormData, [name]: number };
      setHasUnsavedChanges(!areObjectsEqual(updatedForm, payrollInfoOriginal)); // Detect changes
      return updatedForm;
    });
  };

  const handleCurrencyChange = (currency, name) => {
    setPayrollInfo((prevFormData) => {
      const updatedForm = { ...prevFormData, [name]: currency };
      setHasUnsavedChanges(!areObjectsEqual(updatedForm, payrollInfoOriginal)); // Detect changes
      return updatedForm;
    });
  };

  const handleDateChange = (date, name) => {
    setPayrollInfo((prevFormData) => {
      const updatedForm = { ...prevFormData, [name]: date };
      setHasUnsavedChanges(!areObjectsEqual(updatedForm, payrollInfoOriginal)); // Detect changes
      return updatedForm;
    });
  };
  const areObjectsEqual = (obj1, obj2) => {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  };
  const handleCancel = () => {
    if (!areObjectsEqual(payrollInfo, payrollInfoOriginal)) {
      handleOpen();
    } else {
      toggleEdit();
      setHasUnsavedChanges(false); // Reset unsaved state
    }
  };
  const handleSubmit = () => {
    let isValid = true;

    if (banknameRef.current && !banknameRef.current.handleValidation()) {
      isValid = false;
    }
    if (branchnameRef.current && !branchnameRef.current.handleValidation()) {
      isValid = false;
    }
    if (
      accholdernameRef.current &&
      !accholdernameRef.current.handleValidation()
    ) {
      isValid = false;
    }
    if (accnumberRef.current && !accnumberRef.current.handleValidation()) {
      isValid = false;
    }
    if (sortcodeRef.current && !sortcodeRef.current.handleValidation()) {
      isValid = false;
    }
    if (hourlyrateRef.current && !hourlyrateRef.current.handleValidation()) {
      isValid = false;
    }
    if (
      payratepermileRef.current &&
      !payratepermileRef.current.handleValidation()
    ) {
      isValid = false;
    }
    if (ninumberRef.current && !ninumberRef.current.handleValidation()) {
      isValid = false;
    }
    if (
      empstartdateRef.current &&
      !empstartdateRef.current.handleValidation()
    ) {
      isValid = false;
    }
    if (empenddateRef.current && !empenddateRef.current.handleValidation()) {
      isValid = false;
    }
    if (isValid) {
      setHasUnsavedChanges(false); // Reset unsaved state
      // Add your form submission logic here
      PostPayroll();
      //toggleEdit();
    } else {
      console.log("Form is invalid. Please correct the errors and try again.");
    }
  };

  const PostPayroll = async () => {
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/payroll/${userId}`;
      const body = payrollInfo;
      const token = getCookie();
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      };
      const response = await fetch(url, options);
      const data = await response.json();
      if (response.ok && data.statusCode === 200) {
        console.log(data);
        toggleEdit();
      } else {
        console.log("Failed to upload user payroll details.");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  return (
    <ThemeProvider theme={EditTheme}>
      <Container
        sx={{ display: "flex", flexDirection: "column", width: "100%" }}
        maxWidth={false}
      >
        <Box
          sx={{
            padding: 0,
            width: { xxl: "50%", lg: "70%", xs: "98%" },
            margin: "15px 20px",
          }}
        >
          <Grid container direction="row" alignItems="Top">
            <Grid item xs={12} md={5.75}>
              <Name
                ref={banknameRef}
                label="Bank Name"
                placeholder="Bank Name"
                onValueChange={handleValueChange}
                preFilledvalue={payrollInfo.bank_name}
                name="bank_name"
                required
                maxLength={35}
              />
            </Grid>
            <Grid item md={0.5} xs={false} />
            <Grid item xs={12} md={5.75}>
              <Name
                ref={branchnameRef}
                label="Branch Name"
                placeholder="Branch Name"
                onValueChange={handleValueChange}
                preFilledvalue={payrollInfo.branch_name}
                name="branch_name"
                maxLength={35}
              />
            </Grid>
            <Grid item xs={12}>
              <Name
                ref={accholdernameRef}
                label="Account Holder Name"
                placeholder="Account Holder Name"
                onValueChange={handleValueChange}
                preFilledvalue={payrollInfo.account_holder_name}
                name="account_holder_name"
                required
                maxLength={35}
              />
            </Grid>
            <Grid item xs={12} md={5.75}>
              <NumberInput
                ref={accnumberRef}
                label="Account Number"
                placeholder="Account Number"
                required
                maxLength={8}
                onValueChange={handleNumberChange}
                preFilledvalue={payrollInfo.account_number}
                name="account_number"
              />
            </Grid>
            <Grid item md={0.5} xs={false} />
            <Grid item xs={12} md={5.75}>
              <NumberInput
                ref={sortcodeRef}
                label="Sort Code"
                placeholder="Sort Code"
                sortcode
                required
                maxLength={9}
                onValueChange={handleNumberChange}
                preFilledvalue={payrollInfo.sort_code}
                name="sort_code"
              />
            </Grid>
            <Grid item xs={12} md={5.75}>
              <Currency
                ref={hourlyrateRef}
                label="Hourly Rate"
                placeholder="Hourly Rate"
                maxLength={5}
                onValueChange={handleCurrencyChange}
                preFilledvalue={payrollInfo.hourly_rate}
                name="hourly_rate"
              />
            </Grid>
            <Grid item md={0.5} xs={false} />
            <Grid item xs={12} md={5.75}>
              <Currency
                ref={payratepermileRef}
                label="Pay Rate Per Mile"
                placeholder="Pay Rate Per Mile"
                disabled={payrollInfo.drive === 0}
                maxLength={4}
                onValueChange={handleCurrencyChange}
                preFilledvalue={payrollInfo.pay_rate_per_mile}
                name="pay_rate_per_mile"
              />
            </Grid>
            <Grid item xs={12}>
              <Name
                ref={ninumberRef}
                AllCapital
                label="NI Number"
                placeholder="NI Number"
                onValueChange={handleValueChange}
                preFilledvalue={payrollInfo.nin}
                name="nin"
                required
                maxLength={10}
              />
            </Grid>
            <Grid item xs={12} md={5.75}>
              <Calendar
                ref={empstartdateRef}
                label="Employee Start Date"
                onDateChange={handleDateChange}
                required
                preFilledvalue={payrollInfo.employment_start}
                name="employment_start"
              />
            </Grid>
            <Grid item md={0.5} xs={false} />
            <Grid item xs={12} md={5.75}>
              <Calendar
                ref={empenddateRef}
                label="Employee End Date"
                onDateChange={handleDateChange}
                preFilledvalue={payrollInfo.employment_end}
                name="employment_end"
                minDate={payrollInfo.employment_start}
              />
            </Grid>
          </Grid>
          <Box sx={{ mt: 4 }}>
            <Grid container direction="row" alignItems="center">
              <Grid item>
                <Button
                  variant="customcancel"
                  color="primary"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="customsave"
                  color="primary"
                  onClick={handleSubmit}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
      {openDialog && (
        <ConfirmationDialog
          openDialog={openDialog}
          TitleText="Do you want to leave without saving changes?"
          paraText="Any unsaved changes will be lost."
          IconUrl={Admin}
          cancelText="Cancel"
          confirmText="Confirm"
          onCancelFun={() => handleClose()}
          onConfirmFun={onClickYesDialogBtn}
          handleCloseDialog={handleClose}
        />
      )}
    </ThemeProvider>
  );
}
