import React, { useEffect, useState } from "react";
import {
  Box,
  TextField,
  ThemeProvider,
  createTheme,
  Typography,
} from "@mui/material";
import Dropdown from "../reusableComponents/Dropdown";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";

export default function CustomCalendar({
  selectedDate,
  setSelectedDate,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
}) {
  const selection = [
    { id: 1, text: "Day" },
    { id: 2, text: "Week" },
    { id: 3, text: "Month" },
    { id: 4, text: "Year" },
    { id: 5, text: "Custom" },
  ];

  const [selectedDateRange, setSelectedDateRange] = useState("Day");

  const shouldDisableDate = (date) => {
    // Disable any date that's not Monday (day() returns 0 for Sunday, 1 for Monday, ..., 6 for Saturday)
    return date.day() !== 1; // 1 represents Monday
  };

  const displayDate = () => {
    if (selectedDateRange === "Day") {
      setStartDate(dayjs(selectedDate).format("YYYY-MM-DD"));
      setEndDate(dayjs(selectedDate).format("YYYY-MM-DD"));
      return dayjs(selectedDate).format("D MMM YYYY");
    } else if (selectedDateRange === "Week") {
      const startOfWeek = dayjs(selectedDate).startOf("isoWeek"); // Starts on Monday
      const endOfWeek = dayjs(selectedDate).endOf("isoWeek"); // Ends on Sunday
      setStartDate(dayjs(startOfWeek).format("YYYY-MM-DD"));
      setEndDate(dayjs(endOfWeek).format("YYYY-MM-DD"));
      return `${startOfWeek.format("D MMM YYYY")} - ${endOfWeek.format(
        "D MMM YYYY"
      )}`;
    } else if (selectedDateRange === "Month") {
      const startOfMonth = dayjs(selectedDate).startOf("month");
      const endOfMonth = dayjs(selectedDate).endOf("month");
      setStartDate(dayjs(startOfMonth).format("YYYY-MM-DD"));
      setEndDate(dayjs(endOfMonth).format("YYYY-MM-DD"));
      return `${startOfMonth.format("D MMM YYYY")} - ${endOfMonth.format(
        "D MMM YYYY"
      )}`;
    } else if (selectedDateRange === "Year") {
      const startOfYear = dayjs(selectedDate).startOf("year");
      const endOfYear = dayjs(selectedDate).endOf("year");
      setStartDate(dayjs(startOfYear).format("YYYY-MM-DD"));
      setEndDate(dayjs(endOfYear).format("YYYY-MM-DD"));
      return `${startOfYear.format("D MMM YYYY")} - ${endOfYear.format(
        "D MMM YYYY"
      )}`;
    } else if (selectedDateRange === "Custom") {
      setSelectedDate(dayjs().format("YYYY-MM-DD"));
      return `${dayjs(startDate).format("D MMM YYYY")} - ${dayjs(
        endDate
      ).format("D MMM YYYY")}`;
    }
  };

  //Print Command to verify Start and End Dates
  // useEffect(() => {
  //   console.log("Selected Date:", dayjs(selectedDate).format("DD/MM/YYYY"));
  //   console.log("Start Date:", dayjs(startDate).format("DD/MM/YYYY"));
  //   console.log("End Date:", dayjs(endDate).format("DD/MM/YYYY"));
  // }, [startDate, endDate]);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
    // If the selected date is before the new start date, update it to the new start date
    if (selectedDate.isBefore(date)) {
      setSelectedDate(date);
    }
  };

  // Handle change for End Date
  const handleEndDateChange = (date) => {
    setEndDate(date);
    // If the selected date is after the new end date, update it to the new end date
    if (selectedDate.isAfter(date)) {
      setSelectedDate(date);
    }
  };

  const handleDropdownChange = (value) => {
    setSelectedDateRange(value.text);
    if (value.text === "Custom") {
      setStartDate(dayjs());
      setEndDate(dayjs().add(1, "month"));
    }
  };

  const handleLeftChevronChange = () => {
    if (selectedDateRange === "Day") {
      setSelectedDate((prevDate) => dayjs(prevDate).subtract(1, "day"));
    }
    if (selectedDateRange === "Week") {
      setSelectedDate((prevDate) => dayjs(prevDate).subtract(1, "week"));
    }
    if (selectedDateRange === "Month") {
      setSelectedDate((prevDate) => dayjs(prevDate).subtract(1, "month"));
    }
    if (selectedDateRange === "Year") {
      setSelectedDate((prevDate) => dayjs(prevDate).subtract(1, "year"));
    }
  };

  const handleRightChevronChange = () => {
    if (selectedDateRange === "Day") {
      setSelectedDate((prevDate) => dayjs(prevDate).add(1, "day"));
    }
    if (selectedDateRange === "Week") {
      setSelectedDate((prevDate) => dayjs(prevDate).add(1, "week"));
    }
    if (selectedDateRange === "Month") {
      setSelectedDate((prevDate) => dayjs(prevDate).add(1, "month"));
    }
    if (selectedDateRange === "Year") {
      setSelectedDate((prevDate) => dayjs(prevDate).add(1, "year"));
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        {/* Dropdown for date range selection */}
        <Box sx={{ width: "30%", marginRight: "10px", marginTop: "10px" }}>
          <Dropdown
            list={selection}
            nolabel={true}
            preFilledvalue="Day"
            onDropdownSelect={handleDropdownChange}
          />
        </Box>
        <Box
          sx={{
            width: "70%",
            display: "flex",
            alignItems: "left",
          }}
        >
          {selectedDateRange === "Custom" ? (
            <Box
              sx={{
                display: "flex",
                width: "100%",
                alignItems: "left",
                gap: "10px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Arvo",
                    fontSize: "12px",
                    margin: "0px",
                    padding: "0px",
                    marginBottom: "-10px",
                    textAlign: "left", // Ensures text aligns to the left
                    color: "#279989",
                  }}
                >
                  Start Date
                </Typography>
                <DatePicker
                  value={selectedDate}
                  onChange={handleStartDateChange}
                  format="D MMM YYYY"
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Arvo",
                    fontSize: "12px",
                    margin: "0px",
                    padding: "0px",
                    marginBottom: "-10px",
                    textAlign: "left", // Ensures text aligns to the left
                    color: "#279989",
                  }}
                >
                  End Date
                </Typography>
                <DatePicker
                  value={endDate}
                  onChange={handleEndDateChange}
                  minDate={startDate} // Disable dates before the startDate
                  format="D MMM YYYY"
                />
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                width: "100%",
                alignItems: "left",
                marginTop: "10px",
              }}
            >
              <ChevronLeftRoundedIcon
                sx={{
                  color: "#279989",
                  marginTop: "13px",
                  cursor: "pointer",
                  borderRadius: "50%", // Makes it circular
                  transition: "background-color 0.3s ease", // Smooth transition
                  ":hover": {
                    backgroundColor: "#e0f7f5", // Light green circle on hover
                  },
                }}
                onClick={handleLeftChevronChange}
              />
              <DatePicker
                value={selectedDate}
                onChange={handleDateChange}
                shouldDisableDate={
                  selectedDateRange === "Week" && shouldDisableDate
                }
                format="DD-MM-YYYY"
                views={
                  selectedDateRange === "Month"
                    ? ["year", "month"]
                    : selectedDateRange === "Year"
                    ? ["year"]
                    : ["year", "month", "day"]
                }
                sx={{
                  "& .MuiInputBase-root": {
                    border: "none", // Remove the border of the input field
                  },
                  "& .MuiInputBase-input": {
                    display: "none", // Hide the input text field, keep calendar icon visible
                  },
                  "& .MuiInputBase-root.Mui-focused": {
                    border: "none", // Remove border when focused
                  },
                }}
                slotProps={{
                  popper: {
                    sx: {
                      "& .MuiPaper-root": {
                        position: "relative", // Move the paper
                        top: "-5px", // Adjust vertical positioning
                        right: "110px", // Adjust horizontal positioning
                        transform: "none", // Prevent centering
                      },
                    },
                  },
                }}
              />
              <ChevronRightRoundedIcon
                sx={{
                  color: "#279989",
                  marginTop: "13px",
                  marginLeft: "9px",
                  cursor: "pointer",
                  borderRadius: "50%", // Makes it circular
                  transition: "background-color 0.3s ease", // Smooth transition
                  ":hover": {
                    backgroundColor: "#e0f7f5", // Light green circle on hover
                  },
                }}
                onClick={handleRightChevronChange}
              />
              {/* Display selected date range */}
              <TextField
                value={displayDate()}
                sx={{
                  width: "100%",
                  marginLeft: "10px",
                  pointerEvents: "none", // Disable pointer interactions
                  cursor: "default", // Set cursor to default
                }}
                readOnly
              />
            </Box>
          )}
        </Box>
      </Box>
    </LocalizationProvider>
  );
}
