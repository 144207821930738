import React, {
  useRef,
  // useState,
  useEffect,
} from "react";

import StyledCheckbox from "../../reusableComponents/StyledCheckbox";
import incrementIcon from "../../../assets/images/common/incrementIcon.svg";
import { Typography, Box } from "@mui/material";

export const IMG = ({ isDec, onClick, imgUrl = incrementIcon }) => {
  return (
    <img
      onClick={onClick}
      style={{
        width: "12px",
        height: "12px",
        marginLeft: "5px",
        //paddingBottom: "3px ! important",
        transform: isDec ? "rotateX(180deg)" : "rotate(0deg)",
        cursor: "pointer",
        position: "relative",
      }}
      alt="increment"
      src={imgUrl}
    />
  );
};

export const TablePopup = ({ items, onClose = () => {} }) => {
  const popupRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!popupRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  return (
    <Box
      ref={popupRef}
      sx={{
        border: "1px solid #27998966",
        background: "white",
        borderRadius: "8px",
        position: "absolute",
        minWidth: "120px",
        zIndex: 99,
        bottom: "0",
        transform: "translate(-50%, 105%)",
        left: "50%",
      }}
    >
      {items.map((each, index) => (
        <Typography
          key={index}
          variant="body0"
          sx={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            fontSize: "14px",
            padding: "0px",
            margin: "0px",

            position: "relative",

            "&:before": {
              content: '""',
              position: "absolute",
              bottom: "0px",
              left: "8px", // Add left space to the border
              right: "8px", // Add right space to the border
              height: "0.5px",
              backgroundColor:
                items.length - 1 !== index
                  ? "rgba(39, 153, 137, 0.2)"
                  : "transparent", // Customize border color
            },
          }}
        >
          <StyledCheckbox checked={each.checked} onChange={each.onChange} />
          {each.text}
        </Typography>
      ))}
    </Box>
  );
};

export const Status = ({ status }) => {
  const activeClass = {
    backgroundColor: "#F1FFF3",
    border: "1.5px solid #03AE71",
    color: "#03AE71",
  };

  const inActiveClass = {
    backgroundColor: "#FFF0E3",
    border: "1.5px solid #F5891D",
    color: "#FA8733",
  };

  const styles = status === 1 ? activeClass : inActiveClass;

  return (
    <Typography
      sx={{
        fontSize: "14px",
        //background: "blue",
        padding: "4px",
        width: "80px",
        textAlign: "center",
        borderRadius: "8px",
        fontWeight: 500,
        marginTop: "auto",
        marginBottom: "auto",
        fontFamily: "Lora",
        ...styles,
      }}
      variant="body1"
    >
      {status === 1 ? "Active" : "Inactive"}
    </Typography>
  );
};
