import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import Todo from "../../../assets/images/clients/Visits/todo.gif";
import Medication from "../../../assets/images/clients/Visits/Medication.gif";
import EditIcon from "../../../assets/images/clients/Visits/editicon.svg";
import EditButton from "../../../assets/images/clients/Visits/editbutton.svg";
import CancelIcon from "../../../assets/images/clients/Visits/cancelicon.svg";
import CancelButton from "../../../assets/images/clients/Visits/cancelbutton.svg";
import Right from "../../../assets/images/clients/Visits/right.svg";
import Wrong from "../../../assets/images/clients/Visits/wrong.svg";
import Notes from "../../../assets/images/clients/Visits/notes.svg";
import Alert from "../../../assets/images/clients/Visits/alert.svg";
import User from "../../../assets/images/users/User.svg";
import CancelVisitDialog from "./CancelVisitDialog";
import VisitDialogDashboard from "./visitDetailsDialog/VisitDialogDashboard";

// Helper function to calculate time difference in hours and minutes
const calculateDuration = (startTime, endTime) => {
  const [startHour, startMinute] = startTime.split(":").map(Number);
  const [endHour, endMinute] = endTime.split(":").map(Number);

  const startDate = new Date(0, 0, 0, startHour, startMinute);
  const endDate = new Date(0, 0, 0, endHour, endMinute);

  let diff = (endDate - startDate) / 1000 / 60; // Difference in minutes

  const hours = Math.floor(diff / 60);
  const minutes = diff % 60;

  return `${hours}h ${minutes}m`;
};

const VisitCard = ({ visit, clientData, setRefreshVisits }) => {
  // Independent hover states for each button
  const [isEditHovered, setIsEditHovered] = useState(false);
  const [isCancelHovered, setIsCancelHovered] = useState(false);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const duration = calculateDuration(
    visit.planned_start_time,
    visit.planned_end_time
  );

  const handleCancelOpen = () => {
    setOpenCancelDialog(true);
    //fetchVisits(); // Re-fetch visit data when the dialog is closed
  };
  const handleEditOpen = () => {
    setOpenEditDialog(true);
    //fetchVisits(); // Re-fetch visit data when the dialog is closed
    //console.log(clientData);
  };
  const handleOpen = () => {
    setOpenDialog(true);
    //fetchVisits(); // Re-fetch visit data when the dialog is closed
    //console.log(clientData);
  };
  //const handleClose = () => setOpenDialog(false);
  const handleClose = () => {
    setOpenDialog(false);
    //fetchVisits(); // Re-fetch visit data when the dialog is closed
  };
  const handleEditClose = () => {
    setOpenEditDialog(false);
    //fetchVisits(); // Re-fetch visit data when the dialog is closed
  };
  const handleCancelClose = () => {
    setOpenCancelDialog(false);

    //fetchVisits(); // Re-fetch visit data when the dialog is closed
  };

  return (
    <Box
      key={visit.id} // Use unique key for each card
      sx={{
        width: "calc(100% - 8px)",
        height: "calc(100% - 8px)",
        margin: "4px",
        backgroundColor:
          visit.status === "Scheduled"
            ? "#DBF9F4" // Color for "Scheduled"
            : visit.status === "Completed"
            ? "#D3FFDF" // Color for "Completed"
            : visit.status === "Cancelled"
            ? "#FFECDA" // Color for "Cancelled"
            : visit.status === "Inprogress"
            ? "#DFF1F8" // Color for "In Progress"
            : visit.status === "Notstarted"
            ? "#B637371c" // Color for "Not Started"
            : "#FFFFFF", // Default color if status is not one of the above

        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        cursor: "pointer", // <-- Add this line for the pointer cursor
      }}
      //onClick={handleOpen}
    >
      <Box sx={{ position: "relative" }}>
        {visit.status !== "Cancelled" && (
          <Box
            component="img"
            sx={{
              position: "absolute", // Position the button absolutely within the container
              top: "39px", // Adjust as necessary for positioning
              right: "10px", // Position to the right
              width: "auto",
              height: "26px",
              transition: "all 0.1s ease-in-out",
              cursor: "pointer", // <-- Add this line for the pointer cursor
            }}
            src={isEditHovered ? EditButton : EditIcon}
            alt="edit"
            onMouseEnter={() => setIsEditHovered(true)}
            onMouseLeave={() => setIsEditHovered(false)}
            onClick={handleEditOpen}
          />
        )}
        {/* Cancel Button */}
        {(visit.status === "Scheduled" || visit.status === "Notstarted") && (
          <Box
            component="img"
            sx={{
              position: "absolute", // Position the button absolutely within the container
              top: "70px", // Adjust as necessary for positioning
              right: "10px", // Position to the right
              width: "auto",
              height: "26px",
              transition: "all 0.1s ease-in-out",
              cursor: "pointer", // <-- Add this line for the pointer cursor
            }}
            src={isCancelHovered ? CancelButton : CancelIcon}
            alt="cancel"
            onMouseEnter={() => setIsCancelHovered(true)}
            onMouseLeave={() => setIsCancelHovered(false)}
            onClick={handleCancelOpen}
          />
        )}

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            padding: "5px",
            width: "100%",
          }}
          onClick={handleOpen}
        >
          {/* Time Range */}
          <Typography variant="body4" sx={{ fontSize: "16px" }}>
            {visit.planned_start_time} - {visit.planned_end_time} ({duration})
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "5px",
            textAlign: "Right",
          }}
          onClick={handleOpen}
        >
          {/* To-Do and Medication Icons */}

          {visit.status === "Completed" ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                // textAlign: {visit.status==="Scheduled"} ? "Left":"Right",
              }}
            >
              <Box>
                <img
                  style={{
                    width: "30px",
                    height: "auto",
                    mixBlendMode: "multiply",
                  }}
                  src={Todo}
                  alt="ToDo"
                />
                <Typography variant="body4">
                  {" : "}
                  {visit.completed_todos}
                </Typography>
                <img
                  style={{
                    width: "20px",
                    height: "auto",
                    margin: "0 4px",
                  }}
                  src={Right}
                  alt="Right"
                />
                <Typography variant="body4">
                  {" "}
                  {visit.incomplete_todos}
                </Typography>
                <img
                  style={{
                    width: "20px",
                    height: "auto",
                    margin: "0 4px",
                  }}
                  src={Wrong}
                  alt="Wrong"
                />
              </Box>
              <Box>
                <img
                  style={{
                    width: "30px",
                    height: "auto",
                    mixBlendMode: "multiply",
                  }}
                  src={Medication}
                  alt="Medication"
                />
                <Typography variant="body4">
                  {"  : "}
                  {visit.completed_medications}
                </Typography>
                <img
                  style={{
                    width: "20px",
                    height: "auto",
                    margin: "0 4px",
                  }}
                  src={Right}
                  alt="Right"
                />
                <Typography variant="body4">
                  {" "}
                  {visit.incomplete_medications}
                </Typography>
                <img
                  style={{
                    width: "20px",
                    height: "auto",
                    margin: "0 4px",
                  }}
                  src={Wrong}
                  alt="Wrong"
                />
              </Box>
              <Box sx={{ textAlign: "Left" }}>
                <img
                  style={{
                    width: "30px",
                    height: "auto",
                    //mixBlendMode: "multiply",
                    margin: "5px 5px 0 5px",
                  }}
                  src={Notes}
                  alt="Notes"
                />
                <img
                  style={{
                    width: "30px",
                    height: "auto",
                    //mixBlendMode: "multiply",
                    margin: "5px 5px 0 5px",
                  }}
                  src={Alert}
                  alt="Alert"
                />
              </Box>
            </Box>
          ) : (
            <Box>
              <Box>
                <Typography variant="body4">Todo</Typography>
                <Typography variant="body4">
                  {" : "}
                  {visit.incomplete_todos}
                </Typography>
              </Box>
              <Box sx={{ marginTop: "7px" }}>
                <Typography variant="body4">Medication</Typography>
                <Typography variant="body4">
                  {" : "}
                  {visit.incomplete_medications}
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          padding: "5px",
        }}
        onClick={handleOpen}
      >
        <Typography variant="h7">
          {/* Scheduled */}
          {visit.status}
        </Typography>
        <Box
          sx={{
            backgroundColor: "white",
            borderRadius: "15px",
            width: "50px",
            textAlign: "Left",
            paddingLeft: "5px",
          }}
        >
          <img
            style={{
              width: "auto",
              height: "13px",
              margin: "5px",
              marginBottom: "8px",
            }}
            src={User}
            alt="User"
          />
          <Typography variant="body4" sx={{ marginLeft: "3px" }}>
            {visit.number_of_carers_required}
          </Typography>
        </Box>
      </Box>
      {openCancelDialog && (
        <CancelVisitDialog
          openDialog={openCancelDialog}
          // visitId={visit.visit_details_id}
          // sessionsName={visit.session_type}
          visit={visit}
          handleCloseDialog={handleCancelClose}
          setRefreshVisits={setRefreshVisits}
        />
      )}
      {openEditDialog && (
        <VisitDialogDashboard
          openDialog={openEditDialog}
          visit={visit}
          handleCloseDialog={handleEditClose}
          setRefreshVisits={setRefreshVisits}
          clientMainData={clientData}
          isEdit={"10"}
        />
      )}
      {openDialog && (
        <VisitDialogDashboard
          openDialog={openDialog}
          visit={visit}
          handleCloseDialog={handleClose}
          setRefreshVisits={setRefreshVisits}
          clientMainData={clientData}
        />
      )}
    </Box>
  );
};

export default VisitCard;
