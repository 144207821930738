import React, { useState } from "react";
import { Button } from "@mui/material";

const PopupCloseButton = ({
  buttonText = "",
  onClickButton = () => {},
  imgUrlBeforeHover = "", // url before hove the img
  imgUrlAfterHover = "", // url after hover imge
  styles = {},
  isDisable = false,
  sxIcon = {},
}) => {
  const [isHover, setIsHover] = useState(false);

  return (
    <Button
      disabled={isDisable}
      onClick={onClickButton}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      sx={{
        textTransform: "none",
        color: "#969999",
        display: "flex",
        flexDirection: "column", // Stack the icon and text vertically
        alignItems: "center",
        justifyContent: "center",
        fontFamily: "Arvo",
        "&:hover": { color: "#279989" },
        ...styles,
      }}
    >
      <img
        alt="profile icon"
        src={isHover ? imgUrlAfterHover : imgUrlBeforeHover}
        style={{
          width: "20px",
          height: "20px",
          gap: 1,
          opacity: isDisable ? 0.5 : 1,
          ...sxIcon,
        }}
      />
      {buttonText}
    </Button>
  );
};

export default PopupCloseButton;
