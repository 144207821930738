import React, {
  useState,
  forwardRef,
  useEffect,
  useImperativeHandle,
} from "react";
import {
  Box,
  FormLabel,
  Typography,
  TextField,
  Autocomplete,
} from "@mui/material";
import { prefixs } from "../../cms/dropDown";

const Prefix = forwardRef(({ onPrefixSelect, preFilledvalue }, ref) => {
  const [prefix, setValue] = useState(preFilledvalue || "");
  const [inputprefix, setInputValue] = useState(preFilledvalue || "");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    // Update prefix and inputPrefix when preFilledValue changes
    const matchingPrefix = prefixs.find(
      (option) => option.text === preFilledvalue
    );
    setValue(matchingPrefix || null);
    setInputValue(preFilledvalue || "");
    setErrorMessage("");
  }, [preFilledvalue]);

  const handleChange = (e, newPrefix) => {
    setValue(newPrefix);
    setErrorMessage(""); // Clear error when a prefix is selected
    onPrefixSelect(newPrefix); // Pass the selected prefix to the parent
  };

  const handleValidation = () => {
    if (!prefix) {
      setErrorMessage("Prefix is required.");
      return false;
    }
    return true;
  };

  // Expose the validation function to the parent component
  useImperativeHandle(ref, () => ({
    handleValidation,
  }));

  return (
    <Box sx={{ textAlign: "left" }}>
      <FormLabel>
        <Typography variant="h6">
          Prefix<span className="star">*</span>
        </Typography>
      </FormLabel>
      <Autocomplete
        value={prefix}
        onChange={handleChange}
        inputValue={inputprefix}
        onInputChange={(e, newInputprefix) => setInputValue(newInputprefix)}
        options={prefixs}
        getOptionLabel={(option) => option?.text || ""}
        renderInput={(params) => <TextField {...params} placeholder="Prefix" />}
      />
      {errorMessage && <Typography variant="errmsg">{errorMessage}</Typography>}
    </Box>
  );
});

export default Prefix;
