import { Grid, Button, Box } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Admin from "../../../assets/images/users/Admin.svg";
// import { useTheme } from "@mui/material/styles";
// import useMediaQuery from "@mui/material/useMediaQuery";
// import { removeCookie } from "../../../utils/utils";
// import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import {
  Prefix,
  Name,
  Email,
  Calendar,
  RadioInput,
  NumberInput,
  Country,
  Dropdown,
} from "../../reusableComponents"; // Adjust the path
import ConfirmationDialog from "../../reusableComponents/ConfirmationDialog";
import Contact from "../../reusableComponents/Contact";
import dayjs from "dayjs";
import { getCookie } from "../../../utils/utils";
import { specialists } from "../../../cms/dropDown";

const initialPersonalInfo = {
  pseronal_id: 93,
  user_id: 179,
  prefix: "",
  first_name: "",
  middle_name: "",
  last_name: "",
  dob: "",
  age: "0",
  gender: "",
  contact_number: "",
  telephone_codes: "",
  country_code: "",
  email: "",
  address: "",
  city: "",
  postcode: "",
  country: "",
  country_name: "",
  specialist: "",
  dbs: "",
  dbs_expiry: "",
  drive: 0,
  licence: "",
  drive_expiry: "",
};
// Get Current Date and subtract by 16 Years to restrict Date of Birth to be 16 years and above. Use this in max
const today = new Date();
const currentDate = dayjs().subtract(16, "year");

const UserPersonalEdit = ({
  activeUserID,
  updateModeToView,
  setHasUnsavedChanges,
}) => {
  // const navigate = useNavigate();
  const [form, setForm] = useState(initialPersonalInfo);

  const prefixRef = useRef();
  const firstNameRef = useRef();
  const middleNameRef = useRef();
  const lastNameRef = useRef();
  const contactRef = useRef();
  const emailRef = useRef();
  const dobRef = useRef();
  const ageRef = useRef();
  const genderRef = useRef();
  const specialistRef = useRef();
  const addressRef = useRef();
  const cityRef = useRef();
  const postCodeRef = useRef();
  const countryRef = useRef();
  const dbsNumberRef = useRef();
  const dbsExpireRef = useRef();
  const driveRef = useRef();
  const licenceNumberRef = useRef();
  const licenceExpireRef = useRef();

  const [openDialog, setOpenDialog] = useState(false);

  const [emailExistsError, setEmailExistError] = useState("");
  const [contactExistsError, setContactExistsError] = useState("");

  const [oldEmail, setOldEmail] = useState("");
  const [isOldEmailOverrides, setIsOldEmailOverrides] = useState(false);

  const [oldNumberObj, setOldNumberObj] = useState({
    oldNumber: "",
    isOldNumberOverrides: false,
  });

  const [startingForm, setStartingForm] = useState(null);
  const areObjectsEqual = (obj1, obj2) => {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  };

  //const { activeUserID } = useParams();

  useEffect(() => {
    const fetchUsersAPI = async () => {
      if (!activeUserID) return;
      const token = getCookie();

      const BASE_URL = "https://3.8.158.142:8443/";
      const URL = `${BASE_URL}api/personal/${activeUserID}`;
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await fetch(URL, options);
      const data = await response.json();
      // console.log("get call response..........", data);

      if (response.ok) {
        // const parsedContactNumber = data.personalData.contact_number
        //   .split(" ")
        //   .reverse()[0];

        const personalDataForm = {
          ...data.personalData,
          drive: data?.personalData?.drive ? true : false,
        };

        // const personalDataForm = data?.personalData
        setForm(personalDataForm);
        setStartingForm(personalDataForm);
        setHasUnsavedChanges(false); // No unsaved changes on initial load

        setOldEmail(data?.personalData?.email);
        // setOldNumber(data?.personalData?.contact_number);

        setOldNumberObj({
          ...oldNumberObj,
          oldNumber: data?.personalData?.contact_number,
        });
      } else {
        console.log("get personal View failed");
      }
    };
    fetchUsersAPI();
  }, [activeUserID, setHasUnsavedChanges]);

  const showToastMessage = () => {
    toast.success("Add personal successfully", {
      position: "top-right",
    });
  };

  const handlekDateChange = (date) => {
    setForm((prevFormData) => {
      const updatedForm = {
        ...prevFormData,
        dob: date,
        age: calculateAge(date),
      };
      setHasUnsavedChanges(!areObjectsEqual(startingForm, updatedForm)); // Detect changes
      return updatedForm;
    });
  };

  function calculateAge(dateString) {
    const birthDate = new Date(dateString);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    return age;
  }

  const showToastFailureMessage = () => {
    toast.error("Failed to Personal user", {
      position: "top-right",
    });
  };

  const handleOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const onClickYesDialogBtn = () => {
    updateModeToView();
    setHasUnsavedChanges(false);
  };

  const checkIsValidsGiven = () => {
    // Call the handleValidations methods from the child component
    // prefix validation
    if (prefixRef?.current) {
      prefixRef?.current?.handleValidation();
    }

    // first name validation
    if (firstNameRef?.current) {
      firstNameRef?.current?.handleValidation();
    }

    // last name validation
    if (lastNameRef?.current) {
      lastNameRef?.current?.handleValidation();
    }

    // phone number validation
    if (contactRef?.current) {
      contactRef?.current?.handleValidation();
    }

    // email validation
    if (emailRef?.current) {
      emailRef?.current?.handleValidation();
    }

    console.log(emailRef?.current?.handleValidation(), "email ref====>");

    // // date of birth validation
    if (dobRef?.current) {
      dobRef?.current?.handleValidation();
    }

    // age validation
    if (ageRef?.current) {
      ageRef?.current?.handleValidation();
    }

    // gender validation
    if (genderRef?.current) {
      genderRef?.current?.handleValidation();
    }

    // Specialist validation
    if (specialistRef?.current) {
      specialistRef?.current?.handleValidation();
    }

    // Address validation
    if (addressRef?.current) {
      addressRef?.current?.handleValidation();
    }

    // City validation
    if (cityRef?.current) {
      cityRef?.current?.handleValidation();
    }

    // Post Code validation
    if (postCodeRef?.current) {
      postCodeRef?.current?.handleValidation();
    }

    // country Name  validation
    if (countryRef?.current) {
      countryRef?.current?.handleValidation();
    }

    // DBS Number validation
    if (dbsNumberRef?.current) {
      dbsNumberRef?.current?.handleValidation();
    }

    // // DBS Expire validation
    if (dbsExpireRef?.current) {
      dbsExpireRef?.current?.handleValidation();
    }

    // Licence Number name  validation
    console.log("first", form.drive);
    if (form.drive) {
      if (licenceNumberRef?.current) {
        licenceNumberRef?.current?.handleValidation();
      }
    }
    // Licence expire name  validation
    if ((form.drive ? true : false) && licenceExpireRef?.current) {
      licenceExpireRef?.current?.handleValidation();
    }

    // role name  validation
    if (driveRef?.current) {
      driveRef?.current?.handleValidation();
    }

    return (
      prefixRef?.current?.handleValidation() &&
      firstNameRef?.current?.handleValidation() &&
      lastNameRef?.current?.handleValidation() &&
      contactRef?.current?.handleValidation() &&
      emailRef?.current?.handleValidation() &&
      dobRef?.current?.handleValidation() &&
      ageRef?.current?.handleValidation() &&
      genderRef?.current?.handleValidation() &&
      specialistRef?.current?.handleValidation() &&
      addressRef?.current?.handleValidation() &&
      cityRef?.current?.handleValidation() &&
      postCodeRef?.current?.handleValidation() &&
      countryRef?.current?.handleValidation() &&
      dbsNumberRef?.current?.handleValidation() &&
      dbsExpireRef?.current?.handleValidation() &&
      ((form.drive ? true : false)
        ? licenceNumberRef?.current?.handleValidation()
        : true)

      // &&
      // licenceExpireRef?.current?.handleValidation()
    );
  };

  const postUserAPI = async () => {
    try {
      const token = getCookie();

      const body = {
        ...form,
        drive: form.drive ? 1 : 0,
        licence: form.drive ? form.licence : "",
        drive_expiry: form.drive ? form.drive_expiry : "",
        country: form?.country ? form?.country : undefined,
      };

      const BASE_URL = "https://3.8.158.142:8443/";
      // const BASE_URL = "http://3.8.158.142:3000/";
      const URL = `${BASE_URL}api/personal/${form.user_id}`;
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      };

      const response = await fetch(URL, options);
      const data = await response.json();
      if (response.ok && data.statusCode === 200) {
        showToastMessage();
        updateModeToView();
      } else {
        // email already exists

        if (data.message === "Email already exists.") {
          setEmailExistError(data.message);
        }

        // contact already exists
        if (data.message === "Contact number already exists.") {
          // Contact number already exists.
          setContactExistsError(data.message);
        }

        showToastFailureMessage();
        console.log("Failed to create user.");
      }
    } catch (error) {
      console.error("An error occurred:", error);
      showToastFailureMessage();
    }
  };

  const checkEmailOverrides = () => {
    if (form.admin && oldEmail && oldEmail !== form.email) {
      // form.admin  && oldEmail && oldEmail !== form.email
      setIsOldEmailOverrides(true);
      return false;
    } else {
      // setOldEmail("")
      setIsOldEmailOverrides(false);
      return true;
    }
  };

  const checkNumberOverrides = () => {
    if (
      oldNumberObj.oldNumber &&
      oldNumberObj.oldNumber !== form.contact_number
    ) {
      setOldNumberObj({
        ...oldNumberObj,
        isOldNumberOverrides: true,
      });
      return false;
    } else {
      setOldNumberObj({
        ...oldNumberObj,
        isOldNumberOverrides: false,
      });
      return true;
    }
  };

  const postUser = () => {
    if (
      checkIsValidsGiven() === true &&
      checkNumberOverrides() === true &&
      checkEmailOverrides() === true
    ) {
      postUserAPI();
    } else {
      handleClose();
    }
  };

  const onSubmitForm = (e) => {
    e.preventDefault();
    postUser();
    setHasUnsavedChanges(false);
  };

  const elementWidth = {
    width: {
      xs: "100%",
      // lg: "45%",
      lg: "22%",
    },
    gap: 1,
  };

  const renderPopups = () => {
    return (
      <>
        {/* Confirmation Dialog  Number overrides */}
        {oldNumberObj.isOldNumberOverrides && (
          <ConfirmationDialog
            openDialog={oldNumberObj.isOldNumberOverrides}
            TitleText="Confirm Contact Number Change"
            paraText="You are about to change your Contact Number. This will update your login information. Do you want to proceed?"
            IconUrl={Admin}
            cancelText="Cancel"
            confirmText="Confirm"
            onCancelFun={() => {
              setOldNumberObj({ ...oldNumberObj, isOldNumberOverrides: false });
            }}
            onConfirmFun={() => {
              setHasUnsavedChanges(false);
              setOldNumberObj({
                oldNumber: "",
                isOldNumberOverrides: false,
              });

              if (checkEmailOverrides() === true) {
                postUserAPI();
                // removeCookie();
                // navigate("/login");
              }
            }}
            handleCloseDialog={() =>
              setOldNumberObj({ ...oldNumberObj, isOldNumberOverrides: false })
            }
          />
        )}

        {/* Confirmation Dialog Email Overrides */}
        {isOldEmailOverrides && (
          <ConfirmationDialog
            openDialog={isOldEmailOverrides}
            TitleText="Confirm Email Address Change"
            paraText="You are about to change your Email Address. This will update your login information. Do you want to proceed?"
            IconUrl={Admin}
            cancelText="Cancel"
            confirmText="Confirm"
            onCancelFun={() => {
              setIsOldEmailOverrides(false);
            }}
            onConfirmFun={() => {
              setHasUnsavedChanges(false);
              setIsOldEmailOverrides(false);
              setOldEmail("");

              if (checkNumberOverrides() === true) {
                postUserAPI();
                // removeCookie();
                // navigate("/login");
              }
            }}
            handleCloseDialog={() => setIsOldEmailOverrides(false)}
          />
        )}

        {/* Confirmation Dialog */}
        {openDialog && (
          <ConfirmationDialog
            openDialog={openDialog}
            TitleText="Do you want to leave without saving changes?"
            paraText="Any unsaved changes will be lost."
            IconUrl={Admin}
            cancelText="Cancel"
            confirmText="Confirm"
            onCancelFun={() => {
              // navigate("/users");
              handleClose();
            }}
            onConfirmFun={onClickYesDialogBtn}
            handleCloseDialog={handleClose}
          />
        )}
      </>
    );
  };

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", width: "100%" }}
      // maxWidth={false}
    >
      <Box
        sx={{ elementWidth }}
        onSubmit={onSubmitForm}
        component="form"
        noValidate
        autoComplete="off"
      >
        <Grid container direction="row" alignItems="Top">
          <Grid item xs={12} lg={1.5}>
            <Prefix
              ref={prefixRef}
              onPrefixSelect={(prefixObj) => {
                const updatedForm = {
                  ...form,
                  prefix: prefixObj ? prefixObj.text : "",
                };
                setForm(updatedForm);
                setHasUnsavedChanges(
                  !areObjectsEqual(startingForm, updatedForm)
                ); // Detect changes
              }}
              preFilledvalue={form.prefix}
            />
          </Grid>
          <Grid item lg={0.5} xs={false} />
          <Grid item xs={12} lg={3}>
            <Name
              label={"First Name"}
              name={"firstName"}
              placeholder={"First Name"}
              //onValueChange={(value) => setForm({ ...form, first_name: value })}
              onValueChange={(value) => {
                const updatedForm = { ...form, first_name: value };
                setForm(updatedForm);
                setHasUnsavedChanges(
                  !areObjectsEqual(startingForm, updatedForm)
                ); // Detect changes
              }}
              required={true}
              AllCapital={false}
              ref={firstNameRef}
              preFilledvalue={form.first_name}
              maxLength={35}
            />
          </Grid>
          <Grid item lg={0.5} xs={false} />
          <Grid item xs={12} lg={3}>
            <Name
              label={"Middle Name"}
              name={"middleName"}
              placeholder={"Middle Name"}
              // onValueChange={(value) =>
              //   setForm({ ...form, middle_name: value })
              // }
              onValueChange={(value) => {
                const updatedForm = { ...form, middle_name: value };
                setForm(updatedForm);
                setHasUnsavedChanges(
                  !areObjectsEqual(startingForm, updatedForm)
                ); // Detect changes
              }}
              required={false}
              AllCapital={false}
              ref={middleNameRef}
              preFilledvalue={form?.middle_name || ""}
              maxLength={35}
            />
          </Grid>
          <Grid item lg={0.5} xs={false} />
          <Grid item xs={12} lg={3}>
            <Name
              label={"Last Name"}
              name={"lastName"}
              placeholder={"Last Name"}
              onValueChange={(value) => {
                const updatedForm = { ...form, last_name: value };
                setForm(updatedForm);
                setHasUnsavedChanges(
                  !areObjectsEqual(startingForm, updatedForm)
                ); // Detect changes
              }}
              required={true}
              AllCapital={false}
              ref={lastNameRef}
              preFilledvalue={form.last_name}
              maxLength={35}
            />
          </Grid>

          <Grid item md={5} xs={12}>
            <Contact
              onCountrySelect={(teleCountryCode) => {
                const updatedForm = {
                  ...form,
                  telephone_codes: teleCountryCode.id,
                  country_code: teleCountryCode.code,
                };
                setForm(updatedForm);
                setHasUnsavedChanges(
                  !areObjectsEqual(startingForm, updatedForm)
                ); // Detect changes
              }}
              onValueChange={(value) => {
                const updatedForm = { ...form, contact_number: value };
                setContactExistsError("");
                setForm(updatedForm);
                setHasUnsavedChanges(
                  !areObjectsEqual(startingForm, updatedForm)
                ); // Detect changes
              }}
              //defaultCountry={form.country_code} //  Pass the default country value
              ref={contactRef}
              // preFilledvalue={userData.contact_number}
              preFilledvalue={form.contact_number}
              preFilledcode={form.country_code}
              // teleCodeValue={form.telephone_codes}
              // numberValue={form.contact_number}
              contactExistsError={contactExistsError}
            />
          </Grid>
          <Grid item lg={0.5} xs={false} />
          <Grid item lg={6.5} xs={12}>
            <Email
              onEmailValueChange={(value) => {
                const updatedForm = { ...form, email: value };
                setEmailExistError("");
                setForm(updatedForm);
                setHasUnsavedChanges(
                  !areObjectsEqual(startingForm, updatedForm)
                ); // Detect changes
              }}
              required={true}
              ref={emailRef}
              value={form.email}
              emailExistsError={emailExistsError}
              preFilledvalue={form.email}
            />
          </Grid>

          <Grid item xs={12} lg={3.5}>
            <Calendar
              ref={dobRef}
              label="Date of Birth"
              onDateChange={handlekDateChange}
              //  onDateChange={(dateValue) => setForm({ ...form, dob: dateValue })}
              //required
              required={true}
              preFilledvalue={form.dob}
              maxDate={currentDate}
            />
          </Grid>
          <Grid item lg={0.5} xs={false} />
          <Grid item xs={12} lg={2.5}>
            <NumberInput
              ref={ageRef}
              label="Age"
              placeholder="0"
              // maxLength={5}
              // onValueChange={handleNumberChange}
              preFilledvalue={form.age}
              disabled // Disable the Age field
            />
          </Grid>
          <Grid item lg={0.5} xs={false} />
          <Grid item xs={12} lg={5}>
            <RadioInput
              ref={genderRef}
              label="Gender"
              required={true}
              preFilledvalue={form.gender}
              options={[
                { value: "Male", label: "Male" },
                { value: "Female", label: "Female" },
                { value: "Other", label: "Other" },
              ]}
              onValueChange={(genderValue) => {
                const updatedForm = { ...form, gender: genderValue };
                setForm(updatedForm);
                setHasUnsavedChanges(
                  !areObjectsEqual(startingForm, updatedForm)
                ); // Detect changes
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Dropdown
              ref={specialistRef}
              label="Specialist"
              placeholder="Specialist"
              //required={true}
              list={specialists}
              onDropdownSelect={(sepecialistObj) => {
                const updatedForm = {
                  ...form,
                  specialist: sepecialistObj ? sepecialistObj?.text : "",
                };
                setForm(updatedForm);
                setHasUnsavedChanges(
                  !areObjectsEqual(startingForm, updatedForm)
                ); // Detect changes
              }}
              preFilledvalue={form.specialist}
            />
          </Grid>
          <Grid item xs={12}>
            <Name
              label={"Address"}
              name={"address"}
              placeholder={"Address"}
              //onValueChange={(value) => setForm({ ...form, address: value })}
              onValueChange={(value) => {
                const updatedForm = { ...form, address: value };
                setForm(updatedForm);
                setHasUnsavedChanges(
                  !areObjectsEqual(startingForm, updatedForm)
                ); // Detect changes
              }}
              required={true}
              AllCapital={false}
              ref={addressRef}
              preFilledvalue={form.address}
            />
          </Grid>
          <Grid item xs={12} lg={3.5}>
            <Name
              label={"City"}
              name={"city"}
              placeholder={"City"}
              //onValueChange={(value) => setForm({ ...form, city: value })}
              onValueChange={(value) => {
                const updatedForm = { ...form, city: value };
                setForm(updatedForm);
                setHasUnsavedChanges(
                  !areObjectsEqual(startingForm, updatedForm)
                ); // Detect changes
              }}
              required={true}
              AllCapital={false}
              ref={cityRef}
              preFilledvalue={form.city}
              maxLength={35}
            />
          </Grid>
          <Grid item lg={0.75} xs={false} />
          <Grid item xs={12} lg={3.5}>
            <Name
              label={"Postcode"}
              name={"postCode"}
              placeholder={"Postcode"}
              onValueChange={(value) => {
                const updatedForm = { ...form, postcode: value };
                setForm(updatedForm);
                setHasUnsavedChanges(
                  !areObjectsEqual(startingForm, updatedForm)
                ); // Detect changes
              }}
              required={true}
              AllCapital={true}
              ref={postCodeRef}
              preFilledvalue={form.postcode}
              maxLength={10}
            />
          </Grid>
          <Grid item lg={0.75} xs={false} />
          <Grid item xs={12} lg={3.5}>
            <Country
              ref={countryRef}
              required
              onCountrySelect={(countryObj) => {
                const updatedForm = {
                  ...form,
                  country: countryObj ? countryObj.id : "",
                };
                setForm(updatedForm);
                setHasUnsavedChanges(
                  !areObjectsEqual(startingForm, updatedForm)
                ); // Detect changes
              }}
              name=""
              preFilledvalue={form.country_name}
              //required
            />
          </Grid>

          <Grid
            xs={12}
            container
            sx={{
              backgroundColor: "#E8F9F6",
              borderRadius: "8px",
              padding: "20px",
              margin: "15px 0",
            }}
          >
            <Grid item xs={12} lg={7}>
              <Name
                label={"DBS Number"}
                name={"dbsNumber"}
                placeholder={"DBS Number"}
                onValueChange={(value) => {
                  const updatedForm = { ...form, dbs: value };
                  setForm(updatedForm);
                  setHasUnsavedChanges(
                    !areObjectsEqual(startingForm, updatedForm)
                  ); // Detect changes
                }}
                required={true}
                AllCapital={true}
                ref={dbsNumberRef}
                preFilledvalue={form.dbs}
                maxLength={15}
              />
            </Grid>

            <Grid item lg={1} xs={false} />

            <Grid item xs={12} lg={4}>
              <Calendar
                ref={dbsExpireRef}
                label="Expiry"
                onDateChange={(value) => {
                  const updatedForm = {
                    ...form,
                    dbs_expiry: value,
                  };
                  setForm(updatedForm);
                  setHasUnsavedChanges(
                    !areObjectsEqual(startingForm, updatedForm)
                  ); // Detect changes
                }}
                preFilledvalue={form.dbs_expiry}
                required={true}
              />
            </Grid>
          </Grid>

          <Grid item xs={12} sx={{ marginLeft: "1px" }}>
            <RadioInput
              ref={driveRef}
              label="Drive"
              required
              preFilledvalue={form?.drive === true ? "yes" : "no"}
              options={[
                { value: "yes", label: "Yes" },
                { value: "no", label: "No" },
              ]}
              onValueChange={(driveValue) => {
                const updatedForm = {
                  ...form,
                  drive: driveValue === "yes" ? true : false,
                };
                setForm(updatedForm);
                setHasUnsavedChanges(
                  !areObjectsEqual(startingForm, updatedForm)
                ); // Detect changes
              }}
            />
            <Grid sx={{ paddingBottom: "20px" }}>
              {(form.drive ? true : false) && (
                <Grid
                  xs={12}
                  container
                  sx={{
                    backgroundColor: "#E8F9F6",
                    borderRadius: "8px",
                    padding: "20px",
                    margin: "15px 0",
                  }}
                >
                  <Grid item xs={12} lg={7}>
                    <Name
                      label={"Licence Number"}
                      name={"LicenceNumber"}
                      placeholder={"Licence Number"}
                      onValueChange={(value) => {
                        const updatedForm = { ...form, licence: value };
                        setForm(updatedForm);
                        setHasUnsavedChanges(
                          !areObjectsEqual(startingForm, updatedForm)
                        ); // Detect changes
                      }}
                      //required={true}
                      AllCapital={true}
                      ref={licenceNumberRef}
                      preFilledvalue={form.licence}
                      maxLength={20}
                    />
                  </Grid>

                  <Grid item lg={1} xs={false} />

                  <Grid item xs={12} lg={4}>
                    <Calendar
                      ref={licenceExpireRef}
                      label="Expiry"
                      onDateChange={(driveExpiryValue) => {
                        const updatedForm = {
                          ...form,
                          drive_expiry: driveExpiryValue,
                        };
                        setForm(updatedForm);
                        setHasUnsavedChanges(
                          !areObjectsEqual(startingForm, updatedForm)
                        ); // Detect changes
                      }}
                      // required={true}
                      preFilledvalue={form.drive_expiry}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Box sx={{ width: "fit-content" }}>
              <Button
                variant="customcancel"
                onClick={() => {
                  if (JSON.stringify(startingForm) === JSON.stringify(form)) {
                    updateModeToView();
                  } else {
                    handleOpen();
                  }
                }}
              >
                Cancel
              </Button>
              <Button type="submit" variant="customsave">
                Save
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {renderPopups()}

      <ToastContainer />
    </Box>
  );
};

export default UserPersonalEdit;
