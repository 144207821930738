import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from "react";
import {
  Box,
  FormLabel,
  Typography,
  TextField,
  Autocomplete,
  Chip,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { getCookie } from "../../utils/utils";

const Sessions = forwardRef(
  (
    {
      onSessionSelect,
      preFilledvalue = [],
      required = false,
      selectAllLabel = "Select All Sessions In a Day", // Label for the "select all" option
    },
    ref
  ) => {
    const [selectedSessions, setSelectedSessions] = useState(preFilledvalue); // For multiple selected sessions
    const [inputSession, setInputSession] = useState(""); // For search input
    const [errorMessage, setErrorMessage] = useState("");
    const [originalSessions, setOriginalSessions] = useState([]); // Session options

    // Fetch the list of sessions
    useEffect(() => {
      const fetchSessions = async () => {
        try {
          const token = getCookie();
          const BASE_URL = "https://3.8.158.142:8443/";
          const URL = `${BASE_URL}api/getAllSessions`;
          const options = {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          };

          const response = await fetch(URL, options);
          const data = await response.json();

          if (response.ok) {
            const modifiedSessionList = data.data.map((each) => ({
              id: each.id,
              text: each.session_type,
              value: each.session_type,
            }));

            setOriginalSessions(modifiedSessionList);

            // Handle pre-filled values: match the pre-filled values to the session list
            const preFilledSessions = modifiedSessionList.filter((option) =>
              preFilledvalue.includes(option.text)
            );
            console.log("prefilled values", preFilledSessions);

            setSelectedSessions(preFilledSessions); // Set the selected sessions based on preFilledvalue
          } else {
            setOriginalSessions([]);
            setSelectedSessions([]);
            setInputSession("");
          }
        } catch (e) {
          console.log("error", e.message);
          setOriginalSessions([]);
          setSelectedSessions([]);
          setInputSession("");
        }
      };

      fetchSessions();
      setErrorMessage("");
    }, []);

    // Handle change in selected sessions
    const handleChange = (e, newSelectedSessions) => {
      setSelectedSessions(newSelectedSessions);
      setErrorMessage(""); // Clear error when selection is made
      onSessionSelect(newSelectedSessions); // Pass the selected sessions to the parent
    };

    const handleSelectAll = (checked) => {
      const updatedSessions = checked ? [...originalSessions] : []; // Select all or none
      setSelectedSessions(updatedSessions); // Update the selectedSessions with full session objects
      setErrorMessage(""); // Clear error message when sessions are selected
      onSessionSelect(updatedSessions); // Pass the updated selections to parent
    };

    const areAllSessionsSelected =
      selectedSessions.length === originalSessions.length &&
      originalSessions.every((session) =>
        selectedSessions.some((selected) => selected.id === session.id)
      );

    // Validate the selection
    const handleValidation = () => {
      if (required && selectedSessions.length === 0) {
        setErrorMessage("At least one session is required.");
        return false;
      }
      return true;
    };

    // Expose the validation function to the parent component
    useImperativeHandle(ref, () => ({
      handleValidation,
    }));
    const filteredOptions = originalSessions.filter(
      (option) =>
        !selectedSessions.some((selected) => selected.id === option.id)
    );

    return (
      <Box sx={{ textAlign: "left" }}>
        <FormLabel>
          <Typography variant="h6">
            Session
            {required && <span className="star">*</span>}
          </Typography>
        </FormLabel>
        <Autocomplete
          multiple // Enable multiple selections
          value={selectedSessions} // Set the selected sessions
          onChange={handleChange} // Handle change in selections
          inputValue={inputSession} // Control input for search
          onInputChange={(e, newInputSession) =>
            setInputSession(newInputSession)
          } // Update input value for search
          options={filteredOptions} // Options fetched from API
          getOptionLabel={(option) => option?.text || ""} // Display session names
          isOptionEqualToValue={(option, value) => option.id === value.id} // Ensure proper comparison of options and selected values
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                {...getTagProps({ index })}
                label={option.text} // Display session name on the chip
                sx={{
                  backgroundColor: "#D7F9F4", // Blue background color
                  //color: "white", // White text color
                  border: "1px solid #279989",
                  //borderRadius: 5, // Square shape (no border radius)
                  marginLeft: "14px ! important",
                  marginTop: "5px ! important",
                  height: "22px",
                  fontFamily: "Lora",
                  borderRadius: "4px",
                  "& .MuiSvgIcon-root": {
                    height: "10px",
                    width: "10px",
                    color: "#969999 ! important",
                    "&:hover": {
                      color: "#279989 ! important",
                    },
                  },
                }}
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={selectedSessions.length > 0 ? "" : "Select Sessions"}
              className="multiline-textfield"
              multiline
              fullWidth
              rows={1}
              sx={{
                "& .MuiInputBase-input": {
                  padding: "0px 0px ! important",
                  paddingLeft: "8px ! important",
                  marginTop: "6px ! important",
                  minHeight: "27px",

                  //marginBottom: "8px ! important",
                },
              }}
            />
          )}
        />

        {/* Select All Option */}
        <Box sx={{ width: "100%", textAlign: "left", marginTop: "-13px" }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={areAllSessionsSelected}
                onChange={(e) => handleSelectAll(e.target.checked)}
              />
            }
            label={selectAllLabel}
            sx={{
              "& .MuiFormControlLabel-label": {
                fontFamily: "Arvo", // Custom font family
                fontSize: "12px", // Custom font size
                color: "#48464D",
              },
            }}
          />
        </Box>
        {errorMessage && (
          <Typography variant="errmsg">{errorMessage}</Typography>
        )}
      </Box>
    );
  }
);

export default Sessions;
