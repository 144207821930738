import React, { useState, useEffect } from "react";
import { ClientAvatarForm } from "../aboutMe/ClientAvatarForm";

import { Box, Typography, Grid } from "@mui/material";

export const ClientSideBarHeader = ({ clientMainData, isEditMode }) => {
  const [userName, setUserName] = useState("");

  useEffect(() => {
    if (clientMainData) {
      setUserName(
        `${clientMainData?.clientInfo?.first_name || ""} 
        ${clientMainData?.clientInfo?.middle_name || ""}
        ${clientMainData?.clientInfo?.last_name || ""}
        `
      );
    }
  }, [clientMainData]);

  const slicedText = () => {
    if (clientMainData) {
      let text =
        (clientMainData?.clientInfo?.first_name[0] || "") +
        (clientMainData?.clientInfo?.last_name[0] || "");

      return text.toUpperCase();
    }

    return "";
  };

  return (
    <Box
      sx={{
        borderRadius: "8px",
        padding: "10px 5px",
        // height: "100%",
      }}
    >
      {/* side bar image and Info  */}

      <Grid container justifyContent="center" alignItems="center">
        <ClientAvatarForm
          clientMainData={clientMainData}
          isEditMode={isEditMode}
        />
      </Grid>

      {/* Name and Info */}
      <Typography
        sx={{
          color: "black",
          margin: "15px",
        }}
        align="center"
      >
        {userName}
      </Typography>

      <Grid container direction="row" alignItems="Top">
        <Grid item xs={5} sx={{ textAlign: "left" }}>
          <Typography
            variant="body2"
            color="#6C7479"
            padding="5px 0px 5px 10px"
          >
            DOB
          </Typography>
        </Grid>
        <Grid item xs={7} sx={{ textAlign: "left" }}>
          <Typography
            variant="body2"
            sx={{
              color: "#05141F",
              padding: "5px 0px 5px 10px",
            }}
          >
            {clientMainData?.personalInfo?.dob || ""}
          </Typography>
        </Grid>
        <hr
          style={{
            width: "100%",
            margin: "6px 0px",
            border: "1.5px solid rgba(39, 153, 137, 0.20)",
          }}
        />
        <Grid item xs={5} sx={{ textAlign: "left" }}>
          <Typography
            variant="body2"
            color="#6C7479"
            padding="5px 0px 5px 10px"
          >
            Age
          </Typography>
        </Grid>
        <Grid item xs={7} sx={{ textAlign: "left" }}>
          <Typography
            variant="body2"
            sx={{
              color: "#05141F",
              padding: "5px 0px 5px 10px",
            }}
          >
            {clientMainData?.personalInfo?.age || ""}
          </Typography>
        </Grid>
        <hr
          style={{
            width: "100%",
            margin: "6px 0px",

            border: "1.5px solid rgba(39, 153, 137, 0.20)",
          }}
        />
        <Grid item xs={5} sx={{ textAlign: "left" }}>
          <Typography
            variant="body2"
            color="#6C7479"
            padding="5px 0px 5px 10px"
          >
            Address
          </Typography>
        </Grid>
        <Grid item xs={7} sx={{ textAlign: "left" }}>
          <Typography
            variant="body2"
            sx={{
              color: "#05141F",
              padding: "5px 0px 5px 10px",
            }}
          >
            {clientMainData?.clientInfo?.address || ""}
          </Typography>
        </Grid>
        <hr
          style={{
            width: "100%",
            margin: "6px 0px",

            border: "1.5px solid rgba(39, 153, 137, 0.20)",
          }}
        />
        <Grid item xs={5} sx={{ textAlign: "left" }}>
          <Typography
            variant="body2"
            color="#6C7479"
            padding="5px 0px 5px 10px"
          >
            NHS No
          </Typography>
        </Grid>
        <Grid item xs={7} sx={{ textAlign: "left", marginBottom: "15px" }}>
          <Typography
            variant="body2"
            sx={{
              color: "#05141F",
              padding: "5px 0px 5px 10px",
            }}
          >
            {clientMainData?.fundingYes?.nhs_number || clientMainData?.fundingNo?.nhs_number || ""}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};
