import React, { useState, useEffect } from "react";
import { Box, Typography, Button, Grid, IconButton } from "@mui/material";
import { RosterDropdown, RosterCalendar } from "../reusableComponents";
import { RosterViews, RosterRuns, RosterTeams } from "../../cms/dropDown";
import { Link } from "react-router-dom";
import { getCookie } from "../../utils/utils";
//import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ArrowBackIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import ArrowForwardIcon from "@mui/icons-material/ArrowForwardIosRounded";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import dayjs from "dayjs"; // For handling date manipulation

export default function RosterDashboardHeader({
  setView,
  setSelectedDate,
  selectedDate,
  setSelectedRun,
  selectedRun,
}) {
  //const [selectedDate, setSelectedDate] = useState(dayjs()); // Initialize with today's date
  const [selectedView, setSelectedView] = useState("Carer View"); // Initialize with today's date
  const [originalRunsList, setOriginaRunsList] = useState([]);

  const fetchRunsAPI = async () => {
    try {
      const token = getCookie();
      const URL = `${process.env.REACT_APP_BASE_URL}/roster-get-all-runs`;
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await fetch(URL, options);
      const data = await response.json();

      if (response.ok === true) {
        const modifiedRunsList = data.data.map((each) => ({
          ...each,
          id: each.id,
          text: each.run_name,
          value: each.run_name,
        }));

        setOriginaRunsList(modifiedRunsList);
      } else {
        console.log("Failed to fetch runs");
        setOriginaRunsList([]);
      }
    } catch (e) {
      console.log("Error:", e.message);
    }
  };

  // Fetch runs on initial render
  useEffect(() => {
    fetchRunsAPI();
    //console.log(selectedRun.run_name, "123");
  }, []);

  useEffect(() => {
    setView(selectedView);
  }, [selectedView]);
  const handlePreviousDay = () => {
    // Subtract 1 day from the selected date
    const newDate = dayjs(selectedDate).subtract(1, "day");
    setSelectedDate(newDate);
  };
  const handleRunChange = (value) => {
    setSelectedRun(value);
  };

  // Handle Next Day Button
  const handleNextDay = () => {
    // Add 1 day to the selected date using dayjs
    const newDate = dayjs(selectedDate).add(1, "day");

    // Update the selected date state
    setSelectedDate(newDate);
  };
  const handleDateChange = (newDate) => {
    setSelectedDate(newDate);
    const newsDate = dayjs(newDate);
  };
  const handleselect = (value) => {
    setSelectedView(value ? value?.text : "");
  };
  return (
    <Box
      sx={{
        width: "100%",
        height: "50px",
        backgroundColor: "#279989",
        display: "flex",
        alignItems: "center",
        position: "relative",
        borderRadius: " 8px 8px 0 0",
      }}
    >
      <Box
        sx={{ width: "80%", display: "flex", paddingLeft: "20px", gap: "30px" }}
      >
        <Box sx={{ width: "130px" }}>
          <RosterDropdown
            placeholder="Carer View"
            onDropdownSelect={handleselect}
            list={RosterViews}
            preFilledvalue={selectedView}
            nolabel
            readonlytextfield
          />
        </Box>
        <Box sx={{ width: "150px", position: "relative" }}>
          <RosterDropdown
            placeholder="Runs"
            onDropdownSelect={handleRunChange} // Callback for dropdown selection
            list={originalRunsList} // List of options to display
            preFilledvalue={selectedRun ? selectedRun.run_name : ""} // Prefill with selected run name if available
            nolabel // Boolean prop to hide label, if applicable
          />
          {selectedRun && (
            <Box sx={{ position: "absolute", top: 10, right: 17 }}>
              <IconButton
                sx={{
                  width: "20px ! important", // Explicit pixel size
                  height: "20px ! important",
                  //border: "1px solid blue",
                }}
                onClick={() => handleRunChange("")}
              >
                {" "}
                {/* Reset to empty selection */}
                <ClearRoundedIcon sx={{ width: "250%", height: "250%" }} />
              </IconButton>
            </Box>
          )}
        </Box>
        {/* //Teams
        <Box sx={{ width: "110px" }}>
          <RosterDropdown
            placeholder="Teams"
            //onDropdownSelect={handleselect}
            list={RosterTeams}
            //preFilledvalue={}
            nolabel
          />
        </Box> */}
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center", // Center the DayCalendar box
          gap: 2, // Space between items
          flexGrow: 1, // Ensures it takes up available space
          position: "absolute",
          left: "50%", // Horizontal center
          top: "50%",
          transform: "translate(-50%, -50%)", // Adjusts for centering
          //width: "500px",
        }}
      >
        {/* Previous Day Button */}
        <IconButton
          onClick={handlePreviousDay}
          sx={{ marginRight: "0px ! important" }}
        >
          <ArrowBackIcon sx={{ width: "85%", height: "85%", color: "white" }} />
        </IconButton>

        {/* DayCalendar (DatePicker from Material UI) */}
        <RosterCalendar
          //label="Select Date"
          preFilledvalue={selectedDate}
          onDateChange={handleDateChange}
          nolabel
        />

        {/* Next Day Button */}
        <IconButton onClick={handleNextDay}>
          <ArrowForwardIcon
            sx={{ width: "85%", height: "85%", color: "white" }}
          />
        </IconButton>
      </Box>
      <Link
        to="/roster/create-run"
        style={{
          textDecoration: "none",
          position: "absolute",
          right: "20px",
        }}
      >
        <Button
          sx={{
            textTransform: "none",
            fontFamily: "Arvo",
            fontSize: "14px",
            backgroundColor: "white",
            height: "30px",
            color: "black",
            boxShadow: "none",
            borderRadius: "8px",
            border: "1px solid #279989",
            padding: "5px 14px",
            "&:hover": {
              backgroundColor: "white", // Hover color
              //color: "#279989",
              padding: "5px 20px",
              //boxShadow: "0px 4px 10px white",
            },
            "&:disabled": {
              backgroundColor: "#E8E8E8", // Hover color
              color: "#279989",
              border: "1px solid #279989",
            },
          }}
        >
          Manage Run
        </Button>
      </Link>
    </Box>
  );
}

//export default RosterDashboard;
