import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Button,
  Typography,
  Box,
  FormLabel,
  Checkbox,
  FormControlLabel,
  Slider,
  Card,
} from "@mui/material";
import { Name, Calendar, RadioInput, Dropdown } from "../../reusableComponents"; // Adjust the path
import { ThemeProvider } from "@mui/material/styles";
import { ViewTheme } from "../../../themes/Theme";
import { getCookie } from "../../../utils/utils";
import dayjs from "dayjs";

export const AssessmentCardView = ({
  assessmentQuestions,
  assessmentId,
  assessmentName,
  user,
  name,
  selectedCard,
  setInActiveAssessment,
}) => {
  const signName = `${user.first_name + " " + user.last_name}`;

  const [initialForm, setInitialForm] = useState([]);

  const fetchAssessmetDetails = async () => {
    try {
      const token = getCookie();
      const URL = `${process.env.REACT_APP_BASE_URL}/${selectedCard.api_assessment_name}?client_id=${selectedCard.client_id}`;
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await fetch(URL, options);

      const data = await response.json();
      if (response.ok) {
        const activeData = data.data.filter((item) => item.status === "Active");
        const inactiveData = data.data.filter(
          (item) => item.status === "Inactive"
        );
        if (activeData.length > 0) {
          setInitialForm(activeData);
        } else {
          setInitialForm([]);
        }
      } else {
        setInitialForm([]);
      }
    } catch (e) {
      console.error("Error fetching assessment info:", e.message);
    }
  };

  useEffect(() => {
    fetchAssessmetDetails();
  }, []);

  const marks = [
    {
      value: 1,
      label: "1",
    },
    {
      value: 2,
      label: "2",
    },
    {
      value: 3,
      label: "3",
    },
    {
      value: 4,
      label: "4",
    },
    {
      value: 5,
      label: "5",
    },
  ];

  // Activity Risk Assessment section begins
  const [activities, setActivities] = useState([
    { activity: "", task: "", risk: "", range: 1, equipment: "", action: "" },
  ]);

  const activityRiskAssessment = () => {
    return (
      <Box>
        <Typography variant="h4">Activity Risk Assessment</Typography>
        <Typography variant="h6"></Typography>
        <Container sx={{ marginLeft: "-10px" }}>
          <RadioInput
            label={`Does ${name} require support with activities?`}
            name="q_support"
            options={[
              { value: "yes", label: "Yes" },
              { value: "no", label: "No" },
            ]}
            preFilledvalue={
              initialForm[0]?.q_support?.toLowerCase() || "" // Dynamically access the status field
            }
            disabled={true}
          />
        </Container>
        {initialForm[0]?.q_support?.toLowerCase() === "yes" && (
          <>
            {activities.map((activity, index) => (
              <Box
                key={index}
                sx={{
                  marginBlock: "20px",
                  border: "1px solid #2799894d",
                  padding: "5px 15px",
                  borderRadius: "8px",
                  position: "relative",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    display: "inline-block",
                    position: "absolute",
                    margin: "-19px 0 0 10px",
                    backgroundColor: "#F0FCFA",
                    fontWeight: "600",
                    color: "#279989",
                  }}
                >
                  Activity {index + 1}
                </Typography>
                <Grid container spacing={2} direction="row" paddingTop={4}>
                  <Grid item md={5.75} xs={12}>
                    <Name
                      label="Activity"
                      name="activity_name"
                      preFilledvalue={
                        initialForm[0]?.[`activity_${index}`]?.activity_name ||
                        ""
                      }
                      disabled
                    />
                  </Grid>
                  <Grid item md={0.5} xs={false} />
                  <Grid item md={5.75} xs={12}>
                    <Name
                      label="Task/Support Required"
                      name="task_name"
                      preFilledvalue={
                        initialForm[0]?.[`activity_${index}`]?.task_name || ""
                      }
                      disabled
                    />
                  </Grid>
                  <Grid item md={5.75} xs={12}>
                    <Dropdown
                      label="Risk"
                      name="risk_level"
                      list={[
                        { text: "Low" },
                        { text: "Medium" },
                        { text: "High" },
                      ]}
                      preFilledvalue={
                        initialForm[0]?.[`activity_${index}`]?.risk_level || ""
                      }
                      disabled
                    />
                  </Grid>
                  <Grid item md={5.75} xs={12} alignContent="flex-end">
                    <Box sx={{ paddingInline: "50px" }}>
                      <Slider
                        aria-label="Risk Level"
                        name="range"
                        defaultValue={
                          initialForm[0]?.[`activity_${index}`]?.range || ""
                        }
                        valueLabelDisplay="auto"
                        step={1}
                        min={1}
                        max={5}
                        sx={{
                          color: "#279989",
                          "& .MuiSlider-thumb": {
                            backgroundColor: "#279989",
                          },
                          "& .MuiSlider-track": {
                            backgroundColor: "#279989",
                          },
                          "& .MuiSlider-rail": {
                            backgroundColor: "#ccc",
                          },
                          "&.Mui-disabled .MuiSlider-track": {
                            backgroundColor: "#ccc",
                          },
                          "&.Mui-disabled .MuiSlider-thumb": {
                            backgroundColor: "#ccc",
                          },
                          "& .MuiSlider-valueLabel": {
                            backgroundColor: "#279989",
                          },
                        }}
                        disabled
                        marks={marks}
                      />
                    </Box>
                  </Grid>
                  <Grid item md={0.5} xs={false} />
                  <Grid item md={5.75} xs={12}>
                    <Name
                      label="Equipment"
                      name="equipment_name"
                      preFilledvalue={
                        initialForm[0]?.[`activity_${index}`]?.equipment_name ||
                        ""
                      }
                      disabled
                    />
                  </Grid>
                  <Grid item lg={12}>
                    <Name
                      className="multiline-textfield"
                      name="action_taken"
                      label="Action to be taken"
                      placeholder="Action to be taken"
                      multiline
                      rows="5"
                      preFilledvalue={
                        initialForm[0]?.[`activity_${index}`]?.action_taken ??
                        ""
                      }
                      disabled
                    />
                  </Grid>
                </Grid>
              </Box>
            ))}
          </>
        )}
      </Box>
    );
  };
  // Activity Risk Assessment section Ends

  //Behaviours That May Challenge Risk Assessment Begins

  const behavioursThatMayChallengeRiskAssessment = () => {
    const frequencyArray = [
      "Occassionally",
      "Weekly",
      "Daily",
      "Constantly",
      "In Specific environments",
      "Other",
    ];
    const behaviourArray = ["Staff", "Relatives", "Individual", "Other"];
    const triggerArray = [
      "Personality clash",
      "Frustration",
      "Drugs/Alcohol",
      "Lack of food/water",
      "Attention seeking",
      "Domestic provocation",
      "Lack of medication",
      "Incontinence",
      "Sexual Awareness Allergic Reaction",
      "Other",
    ];
    const hazardArray = [
      "Running Away",
      "Threatening",
      "Anxiety",
      "Spitting",
      "Biting",
      "Kicking",
      "Throwing Objectives",
      "Self-harm",
      "Verbal Abuse",
      "Unpredictability",
      "Scratching",
      "Punching",
      "Head Butting",
      "Weapons",
      "Bullying",
      "Sexualised Behaviour",
      "Involuntary Movement",
      "Shouting/Screaming",
      "Other",
    ];

    return (
      <Box>
        <Typography variant="h4">
          Behaviours That May Challenge Risk Assessment
        </Typography>
        <p>
          Describe the Service User or client who requires additional help with
          day to day general routine activities’ behaviours as per the
          Behaviours That May Challenge assessment.(Note)
        </p>
        <Typography variant="h6">Frequency Potential</Typography>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          {frequencyArray.map((each) => {
            return (
              <FormControlLabel
                key={each}
                label={each}
                style={{ marginRight: "40px" }}
                control={
                  <Checkbox
                    checked={
                      initialForm[0]?.frequency_potential?.[each] || false
                    }
                    name={each}
                    disabled={true}
                  />
                }
              />
            );
          })}
        </Box>
        {initialForm[0]?.frequency_potential === "Other" && (
          <Grid>
            <Grid item md={6} xs={12}>
              <Name
                name="other_frequency_potential"
                label="If Other"
                preFilledvalue={
                  initialForm[0]?.frequency_potential
                    ?.other_frequency_potential || ""
                }
              />
            </Grid>
          </Grid>
        )}

        <Typography variant="h6">
          Who may be affected by the behaviour
        </Typography>
        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
          {behaviourArray.map((each) => {
            return (
              <FormControlLabel
                key={each}
                label={each}
                style={{ marginRight: "40px" }}
                control={
                  <Checkbox
                    checked={
                      initialForm[0]?.affected_by_behaviour?.[each] || false
                    }
                    name={each}
                    disabled
                  />
                }
              />
            );
          })}
        </Box>
        {initialForm[0]?.affected_by_behaviour === "Other" && (
          <Grid>
            <Grid item md={6} xs={12}>
              <Name
                name="other_affected_by_behaviour"
                label="If Other"
                preFilledvalue={
                  initialForm[0]?.affected_by_behaviour
                    ?.other_affected_by_behaviour || ""
                }
                disabled
              />
            </Grid>
          </Grid>
        )}

        <Typography variant="h6">Potential Triggers</Typography>
        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
          {triggerArray.map((each) => {
            return (
              <FormControlLabel
                key={each}
                label={each}
                style={{ marginRight: "40px" }}
                control={
                  <Checkbox
                    checked={
                      Object.values(
                        initialForm[0]?.potential_triggers || {}
                      ).includes(each) || false
                    }
                    name={each}
                    disabled
                  />
                }
              />
            );
          })}
        </Box>
        {Object.values(initialForm[0]?.potential_triggers || {}).includes(
          "Other"
        ) && (
          <Grid>
            <Grid item md={6} xs={12}>
              <Name
                label="If Other"
                name="other_potential_triggers"
                preFilledvalue={
                  initialForm[0]?.potential_triggers
                    ?.other_potential_triggers || ""
                }
                disabled
              />
            </Grid>
          </Grid>
        )}

        <Typography variant="h6">Potential Hazards</Typography>
        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
          {hazardArray.map((each) => {
            return (
              <FormControlLabel
                key={each}
                label={each}
                style={{ marginRight: "40px" }}
                control={
                  <Checkbox
                    checked={
                      Object.values(
                        initialForm[0]?.potential_hazards || {}
                      ).includes(each) || false
                    }
                    name={each}
                    disabled
                  />
                }
              />
            );
          })}
        </Box>
        {Object.values(initialForm[0]?.potential_hazards || {}).includes(
          "Other"
        ) && (
          <Grid>
            <Grid item md={6} xs={12}>
              <Name
                label="If Other"
                name="other_potential_hazards"
                preFilledvalue={
                  initialForm[0]?.potential_trigger?.other_potential_hazards ||
                  ""
                }
                disabled={true}
              />
            </Grid>
          </Grid>
        )}

        {Object.keys(initialForm[0]?.potential_hazard || {}).map(
          (hazardArrayIndex) => (
            <Box
              key={hazardArrayIndex}
              sx={{
                marginBlock: "30px",
                border: "1px solid #2799894d",
                padding: "5px 15px",
                borderRadius: "8px",
                position: "relative",
              }}
            >
              <Box
                sx={{
                  display: "inline-block",
                  position: "absolute",
                  margin: "-19px 0 0 10px",
                  backgroundColor: "#FFFFFF",
                  fontWeight: "600",
                  color: "#279989",
                }}
              >
                {initialForm[0]?.potential_hazards?.[hazardArrayIndex]}
              </Box>
              <Grid container spacing={2} direction="row">
                <Grid item md={5.75} xs={12}>
                  <Dropdown
                    label="Level of Risk"
                    name={`level_of_risk`}
                    list={[
                      { text: "Low" },
                      { text: "Medium" },
                      { text: "High" },
                    ]}
                    preFilledvalue={
                      initialForm[0]?.level_of_risk?.[hazardArrayIndex] || ""
                    }
                    disabled={true}
                  />
                </Grid>

                <Grid item md={0.5} xs={false} />
                <Grid item md={5.75} xs={12} alignContent="flex-end">
                  <Box sx={{ paddingInline: "50px" }}>
                    <Slider
                      // aria-label="Risk Level"
                      defaultValue={
                        initialForm[0]?.risk_range?.[hazardArrayIndex] || 1
                      }
                      valueLabelDisplay="auto"
                      step={1}
                      marks={marks}
                      min={1}
                      max={5}
                      sx={{
                        color: "#279989",
                        "& .MuiSlider-thumb": {
                          backgroundColor: "#279989",
                        },
                        "& .MuiSlider-track": {
                          backgroundColor: "#279989",
                        },
                        "& .MuiSlider-rail": {
                          backgroundColor: "#ccc",
                        },

                        "&.Mui-disabled .MuiSlider-track": {
                          backgroundColor: "#ccc",
                        },
                        "&.Mui-disabled .MuiSlider-thumb": {
                          backgroundColor: "#ccc",
                        },
                        "& .MuiSlider-valueLabel": {
                          backgroundColor: "#279989",
                        },
                      }}
                      disabled
                    />
                  </Box>
                </Grid>
                <Grid item md={5.75} xs={12}>
                  <Name
                    label="Methods of support/management"
                    preFilledvalue={
                      initialForm[0]?.support_methods?.[hazardArrayIndex] || ""
                    }
                    disabled
                  />
                </Grid>
              </Grid>
              <Grid spacing={3}>
                <Grid item md={5.75} xs={12}>
                  <Container sx={{ marginLeft: "-10px" }}>
                    <RadioInput
                      label="Are the controls adequate?"
                      options={[
                        { value: "yes", label: "Yes" },
                        { value: "no", label: "No" },
                      ]}
                      preFilledvalue={
                        initialForm[0]?.controls_adequate?.[hazardArrayIndex] ||
                        ""
                      }
                      disabled
                    />
                  </Container>
                  {initialForm[0]?.controls_adequate?.[hazardArrayIndex]
                    ?.control_adequate === "yes" && (
                    <Name
                      label="Methods of support/management"
                      preFilledvalue={
                        initialForm[0]?.regulatory_measures?.[
                          hazardArrayIndex
                        ] || ""
                      }
                      disabled
                    />
                  )}
                </Grid>
              </Grid>
            </Box>
          )
        )}
      </Box>
    );
  };
  //Behaviours That May Challenge Risk Assessment Ends

  //COSHH Risk Assessment Begins

  const [substance, setSubstance] = useState([
    {
      name: "",
      harmType: "",
      description: "",
      color: "",
      harmDetails: "",
      contact: "",
      frequency: "",
      circumstances: "",
      safeAlternative: null,
      controls: "",
      emergencyProcedures: "",
      staffAwareness: null,
      riskReduction: null,
      actionRequired: "",
    },
  ]);

  const coshhRiskAssessment = () => {
    const substanceArray = ["Liquid", "Solid", "Vapor", "Gas"];
    return (
      <Box>
        <Typography variant="h4">COSHH Risk Assessment</Typography>
        <p>
          This assessment will only address the risk to health from the
          substances listed. Additional risk assessments may be required to
          control the risk from other hazards associated with the work being
          conducted.(Note)
        </p>
        {substance.map((sub, index) => (
          <Box
            key={index}
            sx={{
              marginBlock: "30px",
              border: "1px solid #2799894d",
              padding: "5px 15px",
              borderRadius: "8px",
              position: "relative",
            }}
          >
            <Box
              sx={{
                display: "inline-block",
                position: "absolute",
                margin: "-19px 0 0 10px",
                backgroundColor: "#FFFFFF",
                fontWeight: "600",
                color: "#279989",
              }}
            >
              Substance {index + 1}
            </Box>
            <Grid container spacing={3} alignItems="flex-end">
              <Grid item md={5.75} xs={12}>
                <Name
                  label="Name of product/substance"
                  name="substance_name"
                  preFilledvalue={
                    initialForm[0]?.[`substance_${index}`]?.substance_name || ""
                  }
                  disabled
                />
              </Grid>
              <Grid item md={0.5} xs={false} />
              <Grid item md={5.75} xs={12}>
                <Name
                  label="Type of harm the substance could cause"
                  name="substance_harm_type"
                  preFilledvalue={
                    initialForm[0]?.[`substance_${index}`]
                      ?.substance_harm_type || ""
                  }
                  disabled
                />
              </Grid>
              <Grid item md={5.75} xs={12}>
                <Typography variant="h6">
                  Description of the substance
                </Typography>
                <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                  {substanceArray.map((each) => (
                    <FormControlLabel
                      key={each}
                      label={each}
                      name="substance_description"
                      style={{ marginRight: "40px" }}
                      control={
                        <Checkbox
                          checked={
                            initialForm[0]?.[`substance_${index}`]
                              ?.substance_description || ""
                          }
                          disabled
                        />
                      }
                    />
                  ))}
                </Box>
                <Name
                  label="Color"
                  name="substance_color"
                  preFilledvalue={
                    initialForm[0]?.[`substance_${index}`]?.substance_color ||
                    ""
                  }
                  disabled
                />
              </Grid>
              <Grid item md={0.5} xs={false} />
              <Grid item md={5.75} xs={12}>
                <Name
                  label="Detail how the substance causes harm (i.e. being breathed in, being swallowed etc)"
                  name="substance_cause_harm"
                  preFilledvalue={
                    initialForm[0]?.[`substance_${index}`]
                      ?.substance_cause_harm || ""
                  }
                  disabled
                />
              </Grid>
              <Grid item md={5.75} xs={12}>
                <Name
                  label="Who is likely to come into contact with the substance?"
                  name="substance_contact"
                  preFilledvalue={
                    initialForm[0]?.[`substance_${index}`]?.substance_contact ||
                    ""
                  }
                  disabled
                />
              </Grid>
              <Grid item md={0.5} xs={false} />
              <Grid item md={5.75} xs={12}>
                <Name
                  label="How often is it used, or may it occur?"
                  name="how_often"
                  preFilledvalue={
                    initialForm[0]?.[`substance_${index}`]?.how_often || ""
                  }
                  disabled
                />
              </Grid>
              <Grid item md={5.75} xs={12}>
                <Name
                  label="What will the substance be used for or what circumstances/activity may produce the substance?"
                  name="substance_circumstance"
                  preFilledvalue={
                    initialForm[0]?.[`substance_${index}`]
                      ?.substance_circumstance || ""
                  }
                  disabled
                />
              </Grid>
            </Grid>

            <Container sx={{ marginLeft: "-10px" }}>
              <RadioInput
                name="safe_alternative"
                label="Can the hazardous substance, circumstances or activity be eliminated, or a safe alternative be used?"
                options={[
                  { value: "yes", label: "Yes" },
                  { value: "no", label: "No" },
                ]}
                preFilledvalue={
                  initialForm[0]?.[`substance_${index}`]?.safe_alternative
                    ?.toLowerCase || ""
                }
                disabled
              />
            </Container>

            {initialForm[0]?.[`substance_${index}`]?.safe_alternative ===
              "yes" && (
              <Box>
                <Grid container spacing={3} alignItems="flex-end">
                  <Grid item md={5.75} xs={12}>
                    <Name
                      label="Controls (list control measures for storage, use and handling, as appropriate)"
                      name="controls"
                      preFilledvalue={
                        initialForm[0]?.[`substance_${index}`]?.controls || ""
                      }
                      disabled
                    />
                  </Grid>
                  <Grid item md={0.5} xs={false} />
                  <Grid item md={5.75} xs={12}>
                    <Name
                      label="Detail emergency procedures in case of accidental spillage or contact"
                      name="emergency_procedure"
                      preFilledvalue={
                        initialForm[0]?.[`substance_${index}`]
                          ?.emergency_procedure || ""
                      }
                      disabled
                    />
                  </Grid>
                </Grid>

                <Grid container direction="column">
                  <Grid item md={6} xs={12}>
                    <Container sx={{ marginLeft: "-10px" }}>
                      <RadioInput
                        name="staff_aware"
                        label="Are all staff aware of this assessment, in particular the controls and emergency procedures?"
                        options={[
                          { value: "yes", label: "Yes" },
                          { value: "no", label: "No" },
                        ]}
                        preFilledvalue={
                          initialForm[0]?.[`substance_${index}`]?.staff_aware ||
                          ""
                        }
                        disabled
                      />
                    </Container>
                    {initialForm[0]?.[`substance_${index}`]?.staff_aware ===
                      "no" && (
                      <Typography sx={{ marginTop: "10px", fontWeight: 600 }}>
                        ***NOTE: Train all staff***
                      </Typography>
                    )}
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Container sx={{ marginLeft: "-10px" }}>
                      <RadioInput
                        name="risk_reduced"
                        label="Have controls reduced the risk of harm to an acceptable level?"
                        options={[
                          { value: "yes", label: "Yes" },
                          { value: "no", label: "No" },
                        ]}
                        preFilledvalue={
                          initialForm[0]?.[`substance_${index}`]
                            ?.risk_reduced || ""
                        }
                        disabled
                      />
                    </Container>
                    {initialForm[0]?.[`substance_${index}`]?.risk_reduced ===
                      "no" && (
                      <Box sx={{ marginBottom: "-15px" }}>
                        <Name
                          name="action_to_take"
                          className="multiline-textfield"
                          label="Action to be taken where required"
                          placeholder="Action to be taken"
                          multiline
                          rows="5"
                          preFilledvalue={
                            initialForm[0]?.[`substance_${index}`]
                              ?.action_to_take || ""
                          }
                        />
                      </Box>
                    )}
                  </Grid>
                </Grid>
              </Box>
            )}
          </Box>
        ))}
      </Box>
    );
  };

  //COSHH Risk Assessment Ends

  //Medication Risk Assessment Begin

  const medicationRiskAssessment = () => {
    return (
      <Box>
        <Typography variant="h4">Medication Risk Assessment</Typography>
        <Container sx={{ marginLeft: "-10px" }}>
          <Grid container spacing={3} alignItems="flex-end">
            <Grid item md={5.75} xs={12}>
              <RadioInput
                name="ordering_radio"
                label="Ordering"
                options={[
                  { value: "yes", label: "Yes" },
                  { value: "no", label: "No" },
                ]}
                preFilledvalue={
                  initialForm[0]?.ordering_radio?.toLowerCase() || ""
                }
                disabled
              />
              <Name
                name="ordering_comment"
                label="Comments"
                preFilledvalue={initialForm[0]?.ordering_comment || ""}
                disabled
              />
            </Grid>
            <Grid item md={0.5} xs={false} />
            <Grid item md={5.75} xs={12}>
              <RadioInput
                name="collecting_radio"
                label="Collecting"
                options={[
                  { value: "yes", label: "Yes" },
                  { value: "no", label: "No" },
                ]}
                preFilledvalue={
                  initialForm[0]?.collecting_radio?.toLowerCase() || ""
                }
                disabled
              />
              <Name
                name="collecting_comment"
                label="Comments"
                preFilledvalue={initialForm[0]?.collecting_comment || ""}
                disabled
              />
            </Grid>
            <Grid item md={5.75} xs={12}>
              <RadioInput
                name="verbal_prompt_radio"
                label="Verbal Prompt"
                options={[
                  { value: "yes", label: "Yes" },
                  { value: "no", label: "No" },
                ]}
                preFilledvalue={
                  initialForm[0]?.verbal_prompt_radio?.toLowerCase() || ""
                }
                disabled
              />
              <Name
                name="verbal_prompt_comment"
                label="Comments"
                preFilledvalue={initialForm[0]?.verbal_prompt_comment || ""}
                disabled
              />
            </Grid>
            <Grid item md={0.5} xs={false} />
            <Grid item md={5.75} xs={12}>
              <RadioInput
                name="assisting_radio"
                label="Assisting (physically opening boxes/bottles)"
                options={[
                  { value: "yes", label: "Yes" },
                  { value: "no", label: "No" },
                ]}
                preFilledvalue={
                  initialForm[0]?.assisting_radio?.toLowerCase() || ""
                }
                disabled
              />
              <Name
                name="assisting_comment"
                label="Comments"
                preFilledvalue={initialForm[0]?.assisting_comment || ""}
                disabled
              />
            </Grid>
            <Grid item md={5.75} xs={12}>
              <RadioInput
                name="administering_radio"
                label="Administering* (including creams, patches or lotions)"
                options={[
                  { value: "yes", label: "Yes" },
                  { value: "no", label: "No" },
                ]}
                preFilledvalue={
                  initialForm[0]?.administering_radio?.toLowerCase() || ""
                }
                disabled
              />
              <Name
                name="administering_comment"
                label="Comments"
                preFilledvalue={initialForm[0]?.administering_comment || ""}
                disabled
              />
            </Grid>
            <Grid item md={0.5} xs={false} />
            <Grid item md={5.75} xs={12}>
              <RadioInput
                name="specialized_support_radio"
                label="Specialized Support*"
                options={[
                  { value: "yes", label: "Yes" },
                  { value: "no", label: "No" },
                ]}
                preFilledvalue={
                  initialForm[0]?.specialized_support_radio?.toLowerCase() || ""
                }
                disabled
              />
              <Name
                name="specialized_support_comment"
                label="Comments"
                preFilledvalue={
                  initialForm[0]?.specialized_support_comment || ""
                }
                disabled
              />
            </Grid>
            <Grid item md={5.75} xs={12}>
              <RadioInput
                name="time_specific_radio"
                label="Time Specific"
                options={[
                  { value: "yes", label: "Yes" },
                  { value: "no", label: "No" },
                ]}
                preFilledvalue={
                  initialForm[0]?.time_specific_radio?.toLowerCase() || ""
                }
                disabled
              />
              <Name
                name="time_specific_comment"
                label="Comments"
                preFilledvalue={initialForm[0]?.time_specific_comment || ""}
                disabled
              />
            </Grid>
          </Grid>
        </Container>
        <p>
          *Where /AGENCY NAME/ is providing verbal, physical or specialized
          support or administering, a <b>MAR chart must be completed.</b> Where
          prescribed external medication (e.g. creams, patches or lotions)
          support is provided by /AGENCY NAME/, a{" "}
          <b>TMAR must also be completed.*(Notes)</b>
        </p>
        <Typography variant="h5">Controlled Drugs:</Typography>
        <Container sx={{ marginLeft: "-10px" }}>
          <Grid container spacing={3}>
            <Grid item md={5.75} xs={12}>
              <RadioInput
                name="controlled_drug_radio"
                label="Are any of the medicines a Controlled Drug?"
                options={[
                  { value: "yes", label: "Yes" },
                  { value: "no", label: "No" },
                ]}
                preFilledvalue={
                  initialForm[0]?.controlled_drug_radio?.toLowerCase() || ""
                }
                disabled
              />
              {(initialForm[0]?.controlled_drug_radio || "").toLowerCase() ===
                "yes" && (
                <Name
                  name="controlled_drug_comment"
                  label="If yes, give details:"
                  preFilledvalue={initialForm[0]?.controlled_drug_comment || ""}
                  disabled
                />
              )}
            </Grid>
          </Grid>
        </Container>

        <Typography variant="h5">Ordering and Collecting:</Typography>
        <Grid container spacing={3} alignItems="flex-end">
          <Grid item md={5.75} xs={12}>
            <Name
              name="ordering_collecting_1"
              label="Where <Agency name> is responsible for the collecting and/or ordering how will #Agency be notified of this? "
              preFilledvalue={initialForm[0]?.ordering_collecting_1 || ""}
              disabled
            />
          </Grid>
          <Grid item md={0.5} xs={false} />
          <Grid item md={5.75} xs={12}>
            <Name
              name="ordering_collecting_2"
              label="Where not responsible, when should <Agency name> expect your medication to be collected/ordered?"
              preFilledvalue={initialForm[0]?.ordering_collecting_2 || ""}
              disabled
            />
          </Grid>
        </Grid>

        <Typography variant="h5">PRN Medication:</Typography>
        <Grid container spacing={3}>
          <Grid item md={5.75} xs={12}>
            <Name
              name="pnr_radio"
              label="How will you notify the Care Worker when a dose of PRN medication is required?"
              preFilledvalue={initialForm[0]?.pnr_radio || ""}
              disabled
            />
          </Grid>
        </Grid>

        <Typography variant="h5">Storage:</Typography>
        <Container sx={{ marginLeft: "-10px" }}>
          <Grid container spacing={3}>
            <Grid item md={5.75} xs={12}>
              <RadioInput
                name="storage_radio"
                label="Is there a safe place to store medication? "
                options={[
                  { value: "yes", label: "Yes" },
                  { value: "no", label: "No" },
                ]}
                preFilledvalue={
                  initialForm[0]?.storage_radio?.toLowerCase() || ""
                }
                disabled
              />
              {(initialForm[0]?.storage_radio || "").toLowerCase() ===
                "yes" && (
                <Name
                  name="storage_comment"
                  label="If yes, please specify where:"
                  preFilledvalue={initialForm[0]?.storage_comment || ""}
                  disabled
                />
              )}
            </Grid>
          </Grid>
        </Container>
      </Box>
    );
  };
  //Medication Risk Assessment Ends

  //Equipment Register Begins
  const [equipment, setEquipment] = useState([
    {
      equipment: "",
      equipment_description: "",
      by: "",
      purpose: "",
      date_tested: "",
      test_date_completed: "",
    },
  ]);

  const equipmentRegister = () => [
    <Box>
      <Typography variant="h4">Equipment Register</Typography>
      <p>
        As part of delivering care, our Care Workers will often have to use care
        equipment, such as hoists or stand aids. These will usually be supplied
        and serviced by the Local Authority or other third party. It is an
        expectation that the Care Workers will routinely check that the
        equipment 'looks' suitable for use before carrying out any activity
        using that equipment. However, under legislation we are required to
        ensure any equipment being used by our employees is fit for use.
        <br />
        <br />
        This form must be used to record all equipment that will be used during
        the provision of care, and where appropriate, the equipment service/test
        dates..(Note)
      </p>
      {equipment.map((activity, index) => (
        <Box
          key={index}
          sx={{
            marginBlock: "30px",
            border: "1px solid #2799894d",
            padding: "5px 15px",
            borderRadius: "8px",
            position: "relative",
          }}
        >
          <Box
            sx={{
              display: "inline-block",
              position: "absolute",
              margin: "-19px 0 0 10px",
              backgroundColor: "#FFFFFF",
              fontWeight: "600",
              color: "#279989",
            }}
          >
            Equipment {index + 1}
          </Box>
          <Box sx={{ paddingTop: "10px" }}>
            <Name
              name="equipment"
              label="Equipment"
              preFilledvalue={
                initialForm[0]?.[`equipment_${index}`]?.equipment || ""
              }
              disabled
            />
          </Box>
          <Name
            name="equipment_description"
            className="multiline-textfield"
            label="Equipment Description"
            placeholder="Equipment Description"
            multiline
            rows="5"
            preFilledvalue={
              initialForm[0]?.[`equipment_${index}`]?.equipment_description ||
              ""
            }
            disabled
          />
          <Name
            name="by"
            label="Provided by"
            preFilledvalue={initialForm[0]?.[`equipment_${index}`]?.by || ""}
            disabled
          />
          <Name
            name="purspose"
            className="multiline-textfield"
            label="Purpose"
            placeholder="Purpose"
            multiline
            rows="5"
            preFilledvalue={
              initialForm[0]?.[`equipment_${index}`]?.purpose || ""
            }
            disabled
          />
          <Grid container spacing={3}>
            <Grid item md={5.75} xs={12}>
              <Calendar
                name="date_tested"
                label="Date of next test"
                preFilledvalue={
                  initialForm[0]?.[`equipment_${index}`]?.date_tested || ""
                }
                disabled
              />
            </Grid>
            <Grid item md={0.5} xs={false} />
            <Grid item md={5.75} xs={12}>
              <Calendar
                name="test_date_completed"
                label="Test completed on"
                preFilledvalue={
                  initialForm[0]?.[`equipment_${index}`]?.test_date_completed ||
                  ""
                }
                disabled
              />
            </Grid>
          </Grid>
        </Box>
      ))}
    </Box>,
  ];
  //Equipment Register Ends

  //Self-Administration of Medication Risk Assessment Begins
  const [medication, setMedication] = useState([
    { name: "", dose: "", route: "", time: "", self: "" },
  ]);

  const selfAdministrationofMedicationRiskAssessment = () => {
    const radioLabels = [
      `Does ${name} know what medicines they are taking and what they are for?`,
      `Does ${name} know what time to take the medicines?`,
      `Does ${name} know what dosage to take?`,
      `Does ${name} know how to take the medicines?`,
      `Does ${name} know about any special instructions?`,
      `Does ${name} know about common, possible side effects?`,
      `Does ${name} know what to do if they miss a dose?`,
      `Does ${name} have any difficulty in reading the label on the medicines?`,
      `Does ${name} open their medication (blister packs, bottles)?`,
      `Does ${name} understand the principles of safe storage for medicines, including their responsibility for safe keeping?`,
      `Does ${name} agree to notify staff of any changes to the prescribed medication and/or over-the-counter/homely medications?`,
      `Would ${name} be responsible for the re-ordering of prescribed medication and its collection?`,
    ];
    return (
      <Box>
        <Typography variant="h4">
          Self-Administration of Medication Risk Assessment
        </Typography>
        {/* <Name label="Name of GP" placeholder="Name of GP" disabled="true" /> */}
        <Typography variant="h5">Name of GP</Typography>
        <Container sx={{ marginLeft: "-10px" }}>
          <RadioInput
            name="medication_question"
            label={`Does ${name} want to self-administer fully or partially?`}
            options={[
              { value: "fully", label: "Fully" },
              { value: "partially", label: "Partially" },
            ]}
            preFilledvalue={
              initialForm[0]?.medication_question?.toLowerCase() || ""
            }
            disabled
          />
          {radioLabels.map((eachLabel, index) => (
            <RadioInput
              name={`medication_question_${index + 1}`}
              label={eachLabel}
              options={[
                { value: "yes", label: "Yes" },
                { value: "no", label: "No" },
              ]}
              preFilledvalue={
                initialForm[0]?.[
                  `medication_question_${index + 1}`
                ]?.toLowerCase() || ""
              }
              disabled
            />
          ))}
        </Container>
        <Typography variant="h6">
          Please note all the medications used by {name} and Medication Details:
        </Typography>
        {medication.map((activity, index) => (
          <Box
            key={index}
            sx={{
              marginBlock: "30px",
              border: "1px solid #2799894d",
              padding: "5px 15px",
              borderRadius: "8px",
              position: "relative",
            }}
          >
            <Box
              sx={{
                display: "inline-block",
                position: "absolute",
                margin: "-19px 0 0 10px",
                backgroundColor: "#FFFFFF",
                fontWeight: "600",
                color: "#279989",
              }}
            >
              Medication {index + 1}
            </Box>
            <Grid container spacing={3} paddingTop={3}>
              <Grid item md={5.75} xs={12}>
                <Name
                  name="medicine_name"
                  label="Name of Medicine"
                  preFilledvalue={
                    initialForm[0]?.[`medication_${index + 1}`]
                      ?.medicine_name || ""
                  }
                  disabled
                />
              </Grid>
              <Grid item md={0.5} xs={false} />
              <Grid item md={5.75} xs={12}>
                <Name
                  name="dose"
                  label="Dose"
                  preFilledvalue={
                    initialForm[0]?.[`medication_${index + 1}`]?.dose || ""
                  }
                  disabled
                />
              </Grid>
              <Grid item md={5.75} xs={12}>
                <Name
                  name="route"
                  label="Route"
                  preFilledvalue={
                    initialForm[0]?.[`medication_${index + 1}`]?.route || ""
                  }
                  disabled
                />
              </Grid>
              <Grid item md={0.5} xs={false} />
              <Grid item md={5.75} xs={12}>
                <Name
                  name="frequency"
                  label="Time/Frequency"
                  preFilledvalue={
                    initialForm[0]?.[`medication_${index + 1}`]?.frequency || ""
                  }
                  disabled
                />
              </Grid>
              <Grid item md={5.75} xs={12}>
                <Container sx={{ marginLeft: "-10px" }}>
                  <RadioInput
                    name="self_administration"
                    label="Self-Administration"
                    options={[
                      { value: "yes", label: "Yes" },
                      { value: "no", label: "No" },
                    ]}
                    preFilledvalue={
                      initialForm[0]?.[`medication_${index + 1}`]
                        ?.self_administration || ""
                    }
                    disabled
                  />
                </Container>
              </Grid>
            </Grid>
          </Box>
        ))}
      </Box>
    );
  };
  //Self-Administration of Medication Risk Assessment Ends

  //Financial Risk Assessment Begins

  const financialRiskAssessment = () => {
    return (
      <Box>
        <Typography variant="h4">Financial Risk Assessment</Typography>
        <Container sx={{ marginLeft: "-10px" }}>
          <RadioInput
            name="require_assistance"
            label={`Does ${name} require assistance with managing your finances?`}
            options={[
              { value: "yes", label: "Yes" },
              { value: "no", label: "No" },
            ]}
            preFilledvalue={
              initialForm[0]?.require_assistance?.toLowerCase() || ""
            }
            disabled
          />
        </Container>
        {initialForm[0]?.require_assistance?.toLowerCase() === "yes" && (
          <Grid container spacing={3} direction="row">
            <Grid item md={5.75} xs={12}>
              <Name
                label="Family Member"
                name="family_member"
                preFilledvalue={initialForm[0]?.family_member || ""}
                disabled
              />
            </Grid>
            <Grid item md={0.5} xs={false} />
            <Grid item md={5.75} xs={12}>
              <Name
                label="Local Authority"
                name="local_authority"
                preFilledvalue={initialForm[0]?.local_authority || ""}
                disabled
              />
            </Grid>
            <Grid item md={12}>
              <Typography variant="h5">Agency Name</Typography>
            </Grid>
            <Grid item md={5.75} xs={12}>
              <Name
                label="Other "
                name="other"
                preFilledvalue={initialForm[0]?.other || ""}
                disabled
              />
            </Grid>
          </Grid>
        )}
        <Typography variant="h6" sx={{ marginBlock: "15px" }}>
          Where /AGENCY NAME/ has responsibility please complete
        </Typography>
        <Container sx={{ marginLeft: "-10px" }}>
          <RadioInput
            label="Is there a spending budget/ limit in place?"
            options={[
              { value: "yes", label: "Yes" },
              { value: "no", label: "No" },
            ]}
            name="spend_budget"
            preFilledvalue={initialForm[0]?.spend_budget?.toLowerCase() || ""}
            disabled
          />
        </Container>
        <Grid container spacing={3} alignItems="flex-end">
          <Grid item md={5.75} xs={12}>
            <Name
              label="Details"
              name="details"
              preFilledvalue={initialForm[0]?.details || ""}
              disabled
            />
          </Grid>
          <Grid item md={0.5} xs={false} />
          <Grid item md={5.75} xs={12}>
            <Name
              label="Who has responsibility for deciding how the money is spent?"
              name="responsibility"
              preFilledvalue={initialForm[0]?.responsibility || ""}
              disabled
            />
          </Grid>
        </Grid>
        <Container sx={{ marginLeft: "-10px" }}>
          <RadioInput
            label="Are there any risks associated with the Service User or client who requires additional help with day to day general routine activities,’s finances?"
            options={[
              { value: "yes", label: "Yes" },
              { value: "no", label: "No" },
            ]}
            name="risk_associated"
            preFilledvalue={
              initialForm[0]?.risk_associated?.toLowerCase() || ""
            }
            disabled
          />
        </Container>
        {initialForm[0]?.risk_associated?.toLowerCase() === "yes" && (
          <Grid container>
            <Grid item md={5.75} xs={12}>
              <Name
                label="If yes, provide details"
                name="yes_risk_associated"
                preFilledvalue={initialForm[0]?.yes_risk_associated || ""}
                disabled
              />
            </Grid>
          </Grid>
        )}
        <Container sx={{ marginLeft: "-10px" }}>
          <RadioInput
            label="Is there a safe location to store cash and receipts? "
            options={[
              { value: "yes", label: "Yes" },
              { value: "no", label: "No" },
            ]}
            name="safe_location"
            preFilledvalue={initialForm[0]?.safe_location?.toLowerCase() || ""}
            disabled
          />
        </Container>
        {initialForm[0]?.safe_location?.toLowerCase() === "yes" && (
          <Grid container>
            <Grid item md={5.75} xs={12}>
              <Name
                label="If yes, provide details"
                name="yes_safe_location"
                preFilledvalue={initialForm[0]?.yes_safe_location || ""}
                disabled
              />
            </Grid>
          </Grid>
        )}
      </Box>
    );
  };
  //Financial Risk Assessment Ends

  return (
    <ThemeProvider theme={ViewTheme}>
      <Box
        sx={{
          textAlign: "Left",
          height: "100%",
          overflow: "auto",
          paddingRight: 2,
          width: "100%",
          boxShadow: "0px 0px 19px 7px #",
        }}
      >
        {assessmentId < 21 ? (
          <Box>
            {assessmentQuestions.length > 0 ? (
              assessmentQuestions
                .filter(
                  (assessment) => assessment.assessment_name === assessmentName
                )
                .map((assessment, index) => (
                  <Box key={index}>
                    <Typography variant="h4">
                      {assessment.assessment_name}
                    </Typography>
                    {assessment.questionslist.map((question, index, arr) => (
                      <>
                        <Typography
                          variant="h6"
                          sx={{
                            marginTop: "4px",
                            marginBottom: "-20px",
                          }}
                        >
                          {question.questionname}
                        </Typography>

                        {index === arr.length - 1 ? null : (
                          <Container sx={{ marginLeft: "-10px" }}>
                            <RadioInput
                              name={`${index + 1}`}
                              options={[
                                { value: "yes", label: "Yes" },
                                { value: "no", label: "No" },
                              ]}
                              // onValueChange={(e) => {
                              //   handleRadioChange(e, `status_${index}`);
                              // }}
                              disabled={true}
                              preFilledvalue={
                                initialForm[0]?.[
                                  `status_${question.questionid}`
                                ]?.toLowerCase() || "" // Dynamically access the status field
                              }
                            />

                            {initialForm[0]?.[
                              `status_${question.questionid}`
                            ]?.toLowerCase() === "yes" ? (
                              <Name
                                name={`comment_${question.questionid}`}
                                className="multiline-textfield"
                                label="Please Mention"
                                placeholder="Please Mention"
                                multiline
                                rows="7"
                                // onValueChange={handleValueChange}
                                preFilledvalue={
                                  initialForm[0]?.[
                                    `comment_${question.questionid}`
                                  ]?.toLowerCase() || "" // Dynamically access the status field
                                }
                                disabled
                              ></Name>
                            ) : null}
                          </Container>
                        )}
                      </>
                    ))}
                    <Name
                      className="multiline-textfield"
                      placeholder="Care properties and safety concerns"
                      multiline
                      rows="7"
                      // onValueChange={handleValueChange}
                      disabled={true}
                    />
                  </Box>
                ))
            ) : (
              <p>No assessment questions available</p>
            )}
          </Box>
        ) : assessmentId === 21 ? (
          <Box>{activityRiskAssessment()}</Box>
        ) : assessmentId === 22 ? (
          <Box>{behavioursThatMayChallengeRiskAssessment()}</Box>
        ) : assessmentId === 23 ? (
          <Box>{coshhRiskAssessment()}</Box>
        ) : assessmentId === 24 ? (
          <Box>{equipmentRegister()}</Box>
        ) : assessmentId === 25 ? (
          <Box>{financialRiskAssessment()}</Box>
        ) : assessmentId === 26 ? (
          <Box>{medicationRiskAssessment()}</Box>
        ) : assessmentId === 27 ? (
          <Box>{selfAdministrationofMedicationRiskAssessment()}</Box>
        ) : null}
        <FormLabel sx={{ fontSize: 18 }}>
          Signatures of All Involved Admins in the Assessment
        </FormLabel>

        <Grid //First signature
          container
          direction="row"
          alignItems="top"
          sx={{
            gap: 8,
            p: 2,
            borderRadius: "8px",
            backgroundColor: "#F0FCFA",
            mb: -2,
          }}
        >
          <Grid item xs={10} lg={4}>
            <Name
              name="sign_1"
              label="Name"
              value={signName}
              placeholder={signName}
              // placeholder={sign1 ? sign1 : "Name"}
              // onValueChange={handleSign1}
              preFilledvalue={initialForm[0]?.sign_1 || signName} // Prefill with sign_1 or signName
              disabled={true}
            />
          </Grid>
          <Grid item xs={10} lg={4}>
            <Calendar
              label="Date"
              name="date_1"
              // onDateChange={handleDate1}
              preFilledvalue={
                initialForm[0]?.date_1 ? initialForm[0]?.date_1 : dayjs() // Prefill with date_1 or current date
              }
              disabled={true}
            />
          </Grid>
        </Grid>

        {/* {!sign1Submitted ||
        (currentUserId == signUserId && sign1Submitted) ||
        currentUserId == signUserId ? null : ( //checks if the current user already filled or not */}

        {initialForm[0]?.sign_1 && initialForm[0]?.sign_1 !== signName ? (
          <Grid //Second signature
            container
            direction="row"
            alignItems="top"
            sx={{
              gap: 8,
              p: 2,
              borderRadius: "8px",
              backgroundColor: "#F0FCFA",
            }}
          >
            <Grid item xs={10} lg={4}>
              <Name
                name="sign_2"
                label="Name"
                placeholder="Name"
                // onValueChange={handleValueChange}
                preFilledvalue={initialForm[0]?.sign_2}
                disabled={true}
              />
            </Grid>
            <Grid item xs={10} lg={4}>
              <Calendar
                label="Date"
                name="date_2"
                // onDateChange={handleDateChange}
                preFilledvalue={initialForm[0]?.date_2}
                disabled={true}
              />
            </Grid>
          </Grid>
        ) : null}
        {/* )} */}
      </Box>
    </ThemeProvider>
  );
};
