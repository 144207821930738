import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Grid, Button, Box } from "@mui/material";
import MyCareNetworkView from "./MyCareNetworkView";
import MyCareNetworkEdit from "./MyCareNetworkEdit";
import { MyCareNetworkSideBar } from "./MyCareNetworkSideBar";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { ClientHeader } from "../../clients/clientReusableComponents/components";

const MyCareNetwork = ({ clientMainData, clientId, setHasUnsavedChanges }) => {
  const [activeTabID, setActiveTabID] = useState(0);
  const [searchParams] = useSearchParams();
  const isEditQueryParams = searchParams.get("edit");
  const [isEditMode, setIsEditMode] = useState(!!isEditQueryParams);
  const [isDelMode, setIsDelMode] = useState(false);
  const [userName, setUserName] = useState("");
  const [del, setDel] = useState(false);

  const initiateDel = () => setIsDelMode(true);
  const delButton = (state) => setDel(state);
  const toggleEditMode = () => {
    setIsEditMode(!isEditMode);
    setIsDelMode(false);
  };
  const setTabId = (ind) => {
    setActiveTabID(ind);
    setIsDelMode(false);
    if (ind === 0) {
      setIsEditMode(true);
    }
  };

  useEffect(() => {
    if (clientMainData) {
      setUserName(`${clientMainData?.clientInfo?.first_name || ""} `);
    }
  }, [clientMainData]);

  return (
    <Grid container direction="row" alignItems="top">
      <Box
        sx={{
          padding: 3,
          borderRadius: "10px",
          height: "calc(100vh - 220px)",
          boxShadow: "0px 0px 10px 0px #27998933",
          width: "100%",
        }}
      >
        <div style={{ display: "flex", height: "100%" }}>
          <div style={{ width: "20%", height: "100%" }}>
            {isEditMode === false && (
              <Button
                variant="outlined"
                color="secondary"
                startIcon={<EditIcon />}
                onClick={toggleEditMode}
                sx={{
                  margin: 0,
                  height: "35px",
                  position: "absolute",
                  top: "30px",
                  right: "35px",
                  zIndex: 11,
                }}
              >
                Edit
              </Button>
            )}
            {isEditMode === true && activeTabID !== 0 && del === true && (
              <Button
                variant="outlined"
                color="secondary"
                startIcon={<DeleteIcon />}
                onClick={initiateDel}
                sx={{
                  margin: 0,
                  height: "35px",
                  position: "absolute",
                  top: "30px",
                  right: "35px",
                  zIndex: 11,
                }}
              >
                Delete
              </Button>
            )}
            <MyCareNetworkSideBar
              clientId={clientId}
              clientMainData={clientMainData}
              setTabId={setTabId}
              activeTabID={activeTabID}
            />
          </div>
          <div
            style={{
              flex: 1,
              flexDirection: "column",
              overflow: "auto",
              width: "80%",
              paddingLeft: "25px",
              paddingRight: 5,
              position: "relative",
            }}
          >
            <Box
              sx={{
                paddingTop: "20px",
                paddingLeft: "20px",
              }}
            >
              <ClientHeader
                head={`${userName}'s My Care Network`}
                isHideButton={true}
              />
            </Box>
            <Grid
              container
              alignItems="top"
              spacing={"10px"}
              paddingTop={"15px"}
            >
              {isEditMode ? (
                <MyCareNetworkEdit
                  toggleEditMode={toggleEditMode}
                  clientId={clientId}
                  sideTabId={activeTabID}
                  setHasUnsavedChanges={setHasUnsavedChanges}
                  setTabId={setTabId}
                  handleDelete={isDelMode}
                  delButton={delButton}
                />
              ) : (
                <MyCareNetworkView
                  clientId={clientId}
                  sideTabId={activeTabID}
                />
              )}
            </Grid>
          </div>
        </div>
      </Box>
    </Grid>
  );
};

export default MyCareNetwork;
