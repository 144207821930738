import React, { useState } from "react";
import { Box, Typography, Grid } from "@mui/material";
import Cancelled from "../../assets/images/roster/Cancelled.svg";
import Completed from "../../assets/images/roster/Completed.svg";
import Inprogress from "../../assets/images/roster/Inprogress.svg";
import NotStarted from "../../assets/images/roster/NotStarted.svg";
import Scheduled from "../../assets/images/roster/Scheduled.svg";
import Unscheduled from "../../assets/images/roster/Unscheduled.svg";
import Male from "../../assets/images/roster/Male.svg";
import Female from "../../assets/images/roster/Female.svg";
import MaleG from "../../assets/images/roster/MaleG.svg";
import FemaleG from "../../assets/images/roster/FemaleG.svg";

const RosterVisitCardDetails = ({ visit }) => {
  //console.log("visit:", visit);
  const calculateDuration = (startTime, endTime) => {
    if (startTime && startTime) {
      const [startHour, startMinute] = startTime.split(":").map(Number);
      const [endHour, endMinute] = endTime.split(":").map(Number);

      const startDate = new Date(0, 0, 0, startHour, startMinute);
      const endDate = new Date(0, 0, 0, endHour, endMinute);

      let diff = (endDate - startDate) / 1000 / 60; // Difference in minutes

      const hours = Math.floor(diff / 60);
      const minutes = diff % 60;
      if (hours === 0 && minutes != 0) {
        return `${minutes} Minutes`;
      } else if (minutes === 0 && hours != 0) {
        return `${hours} Hours`;
      } else if (minutes === 0 && hours === 0) {
        return `0`;
      } else {
        return `${hours} Hours ${minutes} Minutes`;
      }
    } else {
      return "N/A";
    }
  };
  const duration = calculateDuration(visit.startTime, visit.endTime);
  const actualDuration = calculateDuration(
    visit.actualStartTime,
    visit.actualEndTime
  );
  return (
    <Box
      key={visit.id} // Use unique key for each card
      sx={{
        width: "100%",
        height: "100%",

        backgroundColor: "#2D4031",
        color: "#ffffff",
        borderRadius: "8px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Grid container>
        <Grid
          item
          xs={12}
          sx={{ padding: "10px", borderBottom: "1px solid #D4D2D242" }}
        >
          <Box
            sx={{
              display: "flex",
              direction: "row",
              justifyContent: "space-between",
              alignItems: "center",
              textAlign: "Left",
              width: "100%",
            }}
          >
            <Typography
              variant="body7"
              sx={{
                width: "50%",
                whiteSpace: "normal", // Allow text to wrap
                overflowWrap: "break-word", // Break long words if needed
                wordBreak: "break-word", // Force long words to break
                alignContent: "center",
                fontSize: "14px",
                //height: "20px",
              }}
            >
              {visit.clientName}
            </Typography>
            <Box
              sx={{
                borderRadius: "8px",
                backgroundColor:
                  visit.status === "Scheduled"
                    ? "#A79248" // Color for "Scheduled"
                    : visit.status === "Completed"
                    ? "#0C6C21" // Color for "Completed"
                    : visit.status === "Cancelled"
                    ? "#F80A0A" // Color for "Cancelled"
                    : visit.status === "Inprogress"
                    ? "#7F2A94" // Color for "In Progress"
                    : visit.status === "Notstarted"
                    ? "#931010" // Color for "Not Started"
                    : visit.userid.length === 0
                    ? "#F0FCFA" // Color for "Not Started"
                    : "#FFFFFF", // Default color if status is not one of the above
                padding: "0 10px 3px 10px",
              }}
            >
              <Typography
                variant="body7"
                sx={{
                  color: visit.userid.length === 0 ? "#279989" : "#ffffff",
                  fontWeight: "bold",
                  fontSize: "14px",
                }}
              >
                {visit.status.toUpperCase()}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ padding: "10px", borderBottom: "1px solid #D4D2D242" }}
        >
          <Box
            sx={{
              display: "flex",
              direction: "row",
              justifyContent: "space-between",
              alignItems: "center",
              textAlign: "Left",
              width: "100%",
            }}
          >
            <Box
              sx={{ width: "50%", display: "flex", flexDirection: "column" }}
            >
              <Typography
                variant="body7"
                sx={{
                  textDecoration: "underline", // Adds an underline to the text
                }}
              >
                Planned Time
              </Typography>
              <Typography variant="body7" sx={{}}>
                {visit.startTime} {" to "} {visit.endTime}
              </Typography>
              <Typography
                variant="body7"
                sx={{
                  textDecoration: "underline", // Adds an underline to the text
                  marginTop: "5px",
                }}
              >
                Actual Time
              </Typography>
              <Typography variant="body7" sx={{}}>
                {visit.actualStartTime && visit.actualEndTime
                  ? `${visit.actualStartTime} to ${visit.actualEndTime}`
                  : "N/A"}
              </Typography>
            </Box>
            <Box
              sx={{
                width: "50%",
                textAlign: "Right",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box item sx={{ marginTop: "11px" }}>
                <Typography variant="body7" sx={{ textAlign: "Right" }}>
                  {duration}
                </Typography>
              </Box>

              <Box item sx={{ marginTop: "15px" }}>
                <Typography variant="body7" sx={{ textAlign: "Right" }}>
                  {actualDuration}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ padding: "10px", borderBottom: "1px solid #D4D2D242" }}
        >
          <Box
            sx={{
              display: "flex",
              direction: "row",
              justifyContent: "space-between",
              alignItems: "center",
              textAlign: "Left",
              width: "100%",
            }}
          >
            <Box
              sx={{ width: "50%", display: "flex", flexDirection: "column" }}
            >
              <Typography variant="body7">Carers</Typography>
            </Box>
            <Box
              sx={{
                width: "50%",
                textAlign: "Right",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {visit.userName.map((name, index) => (
                <Typography
                  key={index}
                  variant="body7"
                  sx={{ textAlign: "right" }}
                >
                  {name}
                </Typography>
              ))}
              {visit.userName.length === 0 && (
                <Typography variant="body7" sx={{ textAlign: "right" }}>
                  N/A
                </Typography>
              )}
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ padding: "10px", borderBottom: "1px solid #D4D2D242" }}
        >
          <Box
            sx={{
              display: "flex",
              direction: "row",
              justifyContent: "space-between",
              alignItems: "center",
              textAlign: "Left",
              width: "100%",
            }}
          >
            <Box
              sx={{ width: "50%", display: "flex", flexDirection: "column" }}
            >
              <Typography variant="body7">Runs</Typography>
            </Box>
            <Box
              sx={{
                width: "50%",
                textAlign: "Right",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography variant="body7" sx={{ textAlign: "right" }}>
                {visit.run ? visit.run : "N/A"}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sx={{ padding: "10px" }}>
          <Box
            sx={{
              display: "flex",
              direction: "row",
              justifyContent: "space-between",
              alignItems: "center",
              textAlign: "Left",
              width: "100%",
            }}
          >
            <Box
              sx={{ width: "50%", display: "flex", flexDirection: "column" }}
            >
              <Typography variant="body7">Travel Time</Typography>
            </Box>
            <Box
              sx={{
                width: "50%",
                textAlign: "Right",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography variant="body7" sx={{ textAlign: "right" }}>
                {visit.travelTime ? `${visit.travelTime} Minutes` : "N/A"}
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
export default RosterVisitCardDetails;
