import React from "react";
import {
  Box,
  Typography,
//   Card,
//   Button,
//   TextField,
//   InputLabel,
//   Radio,
//   RadioGroup,
//   FormControlLabel,
//   FormLabel,
} from "@mui/material";
// import MenuItem from "@mui/material/MenuItem";
// import Select from "@mui/material/Select";

import { Link } from "react-router-dom";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

// import { useTheme } from "@mui/material/styles";
// import useMediaQuery from "@mui/material/useMediaQuery";
// import CountrySelect from "./CountrySelect";

import AddClientForm from "./addClient/AddClientForm";

import { InputEl, SelectEl, StyledRadio } from "../users/InputsEl";
import CustomButtom from "../reusableComponents/CustomButton";
import TopContentContainer from "../reusableComponents/TopContentContainer";
import ContentContainer from "../reusableComponents/ContentContainer";

export default function ClientFormView() {
  // const theme = useTheme();
  // const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box>
      <TopContentContainer>
        <Typography
          sx={{
            textTransform: "capitilize",
            fontSize: "16px",
            fontWeight: "600",
            color: "#279989",
            display: "flex",
            alignItems: "center",
          }}
        >
          Client
          <ChevronRightIcon
            sx={{
              color: "#279989",
              width: "20px",
            }}
          />
          Add Client
        </Typography>

        <Link to="/clients" style={{ textDecoration: "none" }}>
          <CustomButtom text="Back" />
        </Link>
      </TopContentContainer>
      <ContentContainer isTransparentBg={true}>
        <AddClientForm />
      </ContentContainer>
    </Box>
  );
}
