import {
  Box,
  Button,
  Typography,
  TextField,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Divider,
  Avatar,
  Grid,
} from "@mui/material";

import { useState, useRef, useEffect } from "react";
import ImageCrop from "../../common/ImageCrop";
import { getCookie } from "../../../utils/utils";

import ProfilePhotoEdit from "../../../assets/images/ProfilePhotoPopupIcons/ProfilePhotoEdit.svg";
import ProfilePhotoAddPhoto from "../../../assets/images/ProfilePhotoPopupIcons/ProfilePhotoAddPhoto.svg";
import ProfilePhotoSave from "../../../assets/images/ProfilePhotoPopupIcons/ProfilePhotoSave.svg";
import ProfilePhotoDelete from "../../../assets/images/ProfilePhotoPopupIcons/ProfilePhotoDelete.svg";
import ProfilePhotoClose from "../../../assets/images/ProfilePhotoPopupIcons/ProfilePhotoClose.svg";

import ProfilePhotoEditHover from "../../../assets/images/ProfilePhotoPopupIcons/ProfilePhotoEditHover.svg";
import ProfilePhotoAddPhotoHover from "../../../assets/images/ProfilePhotoPopupIcons/ProfilePhotoAddPhotoHover.svg";
import ProfilePhotoSaveHover from "../../../assets/images/ProfilePhotoPopupIcons/ProfilePhotoSaveHover.svg";
import ProfilePhotoDeleteHover from "../../../assets/images/ProfilePhotoPopupIcons/ProfilePhotoDeleteHover.svg";
import ProfilePhotoCloseHover from "../../../assets/images/ProfilePhotoPopupIcons/ProfilePhotoCloseHover.svg";
import ProfilePhotoPencile from "../../../assets/images/ProfilePhotoPopupIcons/ProfilePhotoPencile.svg";
import ProfilePhotoGreenPencile from "../../../assets/images/ProfilePhotoPopupIcons/ProfilePhotoGreenPencile.svg";

// ProfilePhotoGreenPencile

const ProfilePhotoButton = ({
  buttonText = "",
  onClickButton = () => {},
  imgUrlBeforeHover = "", // url before hove the img
  imgUrlAfterHover = "", // url after hover imge
  styles = {},
  isDisable = false,
  sxIcon = {},
}) => {
  const [isHover, setIsHover] = useState(false);

  return (
    <Button
      onClick={onClickButton}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      disabled={isDisable}
      sx={{
        textTransform: "none",
        color: "#969999",
        display: "flex",
        flexDirection: "column", // Stack the icon and text vertically
        alignItems: "center",
        justifyContent: "center",
        fontFamily: "Arvo",
        "&:hover": {
          color: "#279989", // apply the hover color of button
        },
        ...styles,
      }}
    >
      {/* change th margin, heights, width, as per requirements in styles of img */}

      <img
        alt="profile icon"
        src={isHover ? imgUrlAfterHover : imgUrlBeforeHover}
        style={{
          width: "20px",
          height: "20px",
          opacity: isDisable ? 0.5 : 1,
          gap: 1,
          ...sxIcon,
        }}
      />

      {buttonText}
    </Button>
  );
};

const ProfilePhotoPopup = ({
  image,
  setImage = () => {},
  onClose = () => {},
  openDialogue = false,
  textShow,
  clientMainData,
  setReload,
  reload,
  fileName,
}) => {
  const [selectedImage, setSelectedImage] = useState(image);
  const [onHoverAddPhoto, setOnHoverAddPhoto] = useState(false);
  const [open, setOpen] = useState(false);

  const [isFileChanged, setIsFileChanged] = useState(false);

  const [imageValidate, setImageValidate] = useState(false);
  const [errorImageText, setErrorImageText] = useState("");
  const [imageName, setImageName] = useState("");

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    setSelectedImage(image);
    setImageName(fileName);
  }, [image]);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Validate file size (should be less than 5MB)
      const maxSizeInBytes = 5 * 1024 * 1024; // 5MB
      // const maxSizeInBytes = 100 * 1024; // 100kb
      if (file.size > maxSizeInBytes) {
        setErrorImageText("File size must be less than 5MB.");
        setImageValidate(true);
        return;
      }

      // Validate file type (should be png or jpeg)
      const validTypes = ["image/png", "image/jpeg"];
      if (!validTypes.includes(file.type)) {
        setErrorImageText(
          // "Invalid file type. Please upload a PNG or JPEG image."
          "Invalid file type. Upload PNG or JPEG"
        );
        setImageValidate(true);
        return;
      }

      const reader = new FileReader();
      reader.onload = () => {
        setSelectedImage(reader.result);
        // setImage(reader.result);
        setImageName(file.name);
        setIsFileChanged(true);

        const extension = file.name.split(".").pop();
        const imageFileName = generateImageName(file.name, extension);
        setImageName(imageFileName);
        setErrorImageText(""); // Clear error message on successful upload
        setImageValidate(false); // Set error to inactive
      };
      reader.readAsDataURL(file);
    }
    // Clear the input value so the same file can be uploaded again
    event.target.value = null;
  };
  const generateImageName = (originalName, extension) => {
    return `${Date.now()}_${originalName}`;
  };

  const postImage = async () => {
    const userID = clientMainData?.personalInfo?.client_id || "";
    const url = `http://3.8.158.142:3000/api/client/aboutMe/image/${userID}`;

    const token = getCookie();

    const body = {
      // filename: "image",
      filename: imageName || "image",
      base64: selectedImage,
    };

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    };

    const response = await fetch(url, options);
    const data = await response.json();

    if (!response.ok) {
      setImage(null);
      setImageName("");
    }
    setReload(!reload);
  };

  const deleteImage = async () => {
    const userID = clientMainData?.personalInfo?.client_id || "";
    const url = `http://3.8.158.142:3000/api/client/aboutMe/image/${userID}`;

    const token = getCookie();

    const body = {
      filename: imageName || "image",
      base64: selectedImage,
    };
    const options = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    };

    const response = await fetch(url, options);
    const data = await response.json();

    if (response.ok) {
      setImage(null);
      setImageName("");
      setReload(!reload);
    }
  };

  return (
    <Dialog
      open={openDialogue}
      onClose={handleClose}
      PaperProps={{
        sx: {
          borderRadius: "8px",
        },
      }}
    >
      <DialogTitle sx={{ display: "flex" }}>
        <Typography
          variant="h6"
          sx={{
            fontWeight: 700,
            color: "#279989",
            fontFamily: "Arvo",
            marginRight: "auto",
          }}
        >
          Profile Photo
        </Typography>

        <ProfilePhotoButton
          buttonText=""
          onClickButton={handleClose}
          imgUrlBeforeHover={ProfilePhotoClose} // apply ProfilePhotoClose svg icon url
          imgUrlAfterHover={ProfilePhotoCloseHover} // apply ProfilePhotoCloseHover svg icon url
          styles={{
            position: "absolute",
            right: "0",
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        />
      </DialogTitle>
      <DialogContent>
        <Grid container justifyContent="center" alignItems="center">
          <Box
            sx={{
              width: 150,
              height: 150,
              borderRadius: "50%",
              background: "#269989",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              overflow: "hidden", // To ensure the image is clipped to the circle
            }}
          >
            {selectedImage ? (
              <img
                src={selectedImage}
                alt="Profile"
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            ) : (
              <Typography
                variant="h2"
                sx={{
                  color: "#FFF",
                  textAlign: "center",
                  fontFamily: "Lora",
                  fontSize: "77px",
                  fontStyle: "normal",
                  fontWeight: 400,
                }}
              >
                {textShow}
              </Typography>
            )}
          </Box>
        </Grid>
      </DialogContent>
      {errorImageText && (
        <Typography
          component="p"
          sx={{
            color: "red",
            textAlign: "center",
            fontFamily: "Lora",
            fontSize: "12px",
            marginTop: "-9px",
            marginBottom: "10px",
          }}
        >
          {errorImageText}
        </Typography>
      )}
      <Divider
        sx={{
          bgcolor: "#2799894D",
          height: "2px",
        }}
      />
      <DialogActions
        sx={{
          marginBottom: "4px",
          justifyContent: "space-between",
          padding: "0",
        }}
      >
        <Grid container justifyContent="space-between">
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {open && (
              <ImageCrop
                open={open}
                setOpen={setOpen}
                selectedImage={selectedImage}
                setImage={setImage}
                setSelectedImage={setSelectedImage}
              />
            )}

            <ProfilePhotoButton
              isDisable={selectedImage ? false : true}
              buttonText="Edit"
              onClickButton={() => setOpen(true)}
              imgUrlBeforeHover={ProfilePhotoEdit} // apply ProfilePhotoEdit svg icon url
              imgUrlAfterHover={ProfilePhotoEditHover} // apply ProfilePhotoEditHover svg icon url
            />
            <Button
              onMouseEnter={() => setOnHoverAddPhoto(true)}
              onMouseLeave={() => setOnHoverAddPhoto(false)}
              component="label" // This allows us to use the button to trigger the file input
              //   startIcon={<AddPhotoAlternateIcon />}
              sx={{
                textTransform: "none",
                color: "#969999",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                fontFamily: "Arvo",
                "&:hover": {
                  color: "#279989", // change the hover color of button
                },
              }}
            >
              <img
                alt="add  icon"
                src={
                  onHoverAddPhoto
                    ? ProfilePhotoAddPhotoHover
                    : ProfilePhotoAddPhoto
                }
                style={{
                  textTransform: "none",
                  color: "#969999",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  fontFamily: "Arvo",
                  "&:hover": {
                    color: "#279989", // change the hover color of button
                  },
                }}
                // style={{
                //   width: "20px",
                //   height: "20px",
                //   gap: 1,
                //   color:"#279989"
                // }}
              />
              Add Photo
              <input
                type="file"
                // accept="image/*"
                // accept=".jpeg,.jpg,.png" // Restrict to JPEG and PNG formats
                accept="image/jpeg,image/jpg,image/png" // Restrict to JPEG and PNG formats
                onChange={handleImageChange}
                hidden
              />
            </Button>
          </Box>
          <Box
            sx={{ display: "flex", alignItems: "center", marginRight: "10px" }}
          >
            <ProfilePhotoButton
              buttonText="Save"
              sxIcon={{ height: "16px", width: "16px", marginBottom: "2px" }}
              onClickButton={() => {
                if (selectedImage) {
                  postImage();
                }
                // setImage(selectedImage);
                onClose();
              }}
              imgUrlBeforeHover={ProfilePhotoSave} // apply ProfilePhotoSave svg icon url
              imgUrlAfterHover={ProfilePhotoSaveHover} // apply ProfilePhotoSaveHover svg icon url
            />

            <ProfilePhotoButton
              isDisable={selectedImage ? false : true}
              buttonText="Delete"
              onClickButton={() => {
                deleteImage();
                setSelectedImage(null);
                onClose();
              }}
              imgUrlBeforeHover={ProfilePhotoDelete} // apply ProfilePhotoDelete svg icon url
              imgUrlAfterHover={ProfilePhotoDeleteHover} // apply ProfilePhotoDeleteHover svg icon url
            />
          </Box>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default ProfilePhotoPopup;

export const ClientAvatarForm = ({ isEditMode, clientMainData }) => {
  const [image, setImage] = useState(null);
  const [openDialogue, setOpenDialogue] = useState(false);
  const [reload, setReload] = useState(false);
  const [fileName, setFileName] = useState("");

  // Function to handle file selection
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const getTwoUpperCaseLetters = () => {
    if (clientMainData) {
      let strList = `${clientMainData?.clientInfo?.first_name || ""} ${
        clientMainData?.clientInfo?.last_name || ""
      }`.trim();

      strList = strList.split(" ");

      if (strList.length > 1) {
        return `${strList[0][0]}${strList[1][0]}`.toUpperCase();
      }

      if (strList[0]) {
        return strList[0][0].toUpperCase();
      }
    }

    return " "; // Default placeholder
  };

  useEffect(() => {
    const getImage = async () => {
      const userID = clientMainData?.personalInfo?.client_id || "";
      const url = `http://3.8.158.142:3000/api/client/aboutMe/${userID}`;

      const token = getCookie();

      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await fetch(url, options);
      const data = await response.json();

      if (response.ok) {
        // setImage(data.data.profile_photo);
        setImage(data.data.personalInfo.base64);
        setFileName(data.data.personalInfo?.filename);
      } else {
        setImage(null);
      }
    };

    if (clientMainData?.personalInfo?.client_id) {
      getImage();
    }
  }, [clientMainData, reload]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Box
        component="div"
        sx={{
          height: "80px",
          width: "80px",
          marginTop: "10px",
          marginBottom: "10px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "50%",
          background: "#269989",
          position: "relative",
        }}
      >
        {image ? (
          image && (
            <img
              style={{
                height: "100%",
                width: "100%",
                borderRadius: "inherit",
                border: 0,
              }}
              alt="user avatar"
              src={image}
            />
          )
        ) : (
          <Typography
            sx={{
              color: "#FFF",
              textAlign: "center",
              fontFamily: "Lora",
              fontSize: "35px",
              fontStyle: "normal",
              fontWeight: 400,
            }}
          >
            {getTwoUpperCaseLetters()}
          </Typography>
        )}

        {isEditMode && (
          <div
            onClick={() => setOpenDialogue(true)}
            style={{
              background: "#BFE1DC",
              height: "25px",
              width: "25px",
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              position: "absolute",
              bottom: 0,
              right: "10%",
            }}
          >
            <img
              alt="edit icons"
              src={ProfilePhotoGreenPencile}
              style={{
                height: "13px",
                width: "13px",
                marginLeft: "2px",
                // borderRadius:"50%",
              }}
            />
          </div>
        )}
      </Box>

      {openDialogue && (
        <ProfilePhotoPopup
          openDialogue={openDialogue}
          onClose={() => setOpenDialogue(false)}
          onClickAddPhoto={handleImageUpload}
          textShow={getTwoUpperCaseLetters()}
          image={image}
          setImage={setImage}
          clientMainData={clientMainData}
          setReload={setReload}
          reload={reload}
          fileName={fileName}
        />
      )}
    </div>
  );
};
