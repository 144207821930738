import React, { useState, useEffect } from "react";
import { ClientHeader } from "../../clients/clientReusableComponents/components";
import { ClientSideBarHeader } from "../clientReusableComponents/ClientSideBarHeader";
import { Grid, Button, Card, Typography, Box } from "@mui/material";
import { ViewTheme } from "../../../themes/Theme";
import ConfirmationDialog from "../../reusableComponents/ConfirmationDialog";
import shareAccess from "../../../assets/images/clients/shareAccess.svg";
import { getCookie } from "../../../utils/utils";
import ShareAccessPopup from "./ShareAccessPopup";
import ShareAccessCard from "./ShareAccessCard";

const ShareAccess = ({ clientMainData, clientId, setHasUnsavedChanges }) => {
  const [userName, setUserName] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [openTPADialog, setOpenTPADialog] = useState(false);
  const [accessAvailed, setAccessAvailed] = useState([]);

  const handleOpen = () => setOpenDialog(true);
  const handleClose = () => setOpenDialog(false);
  const handleTPAOpen = () => setOpenTPADialog(true);
  const handleTPAClose = () => setOpenTPADialog(false);
  const handleRefresh = () => getAccess();

  useEffect(() => {
    if (clientMainData) {
      setUserName(`${clientMainData?.clientInfo?.first_name || ""}`);
    }
  }, [clientMainData]);

  useEffect(() => {
    if (clientId) {
      getAccess();
    }
  }, [clientId]);

  const getAccess = async () => {
    try {
      const token = getCookie();
      const URL = `${process.env.REACT_APP_BASE_URL}/access?client_id=${clientId}`;
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await fetch(URL, options);
      const data = await response.json();
      if (response.ok) {
        setAccessAvailed(data.data);
      } else {
        setAccessAvailed([]);
      }
    } catch (e) {
      console.error("Error fetching All Shared Acceess", e.message);
      setAccessAvailed([]);
    }
  };

  const onClickYesDialogBtn = async () => {
    if (!clientId) return;
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/access?client_id=${clientId}`;
      const token = getCookie();
      let accessData = {
        client_id: clientId,
        mcnId: 0,
        name: userName,
        email: clientMainData?.clientInfo.email,
        reason: "Providing Access",
        role: "Client",
        status: "Active",
      };
      // const clientAccess = accessAvailed.filter((a) => a.role === "Client");
      // const hasId = clientAccess.some((item) => item.hasOwnProperty("id"));
      // if (hasId) accessData.id = clientAccess[0].id || 0;
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(accessData),
      };
      const response = await fetch(url, options);
      const data = await response.json();
      if (response.ok && data.statusCode === 200) {
        handleClose();
        handleRefresh();
      } else {
        console.log("issue fetching");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const handleRevokeAll = async (acc) => {
    try {
      const token = getCookie();
      const URL = `${process.env.REACT_APP_BASE_URL}/access?client_id=${clientId}`;
      const options = {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await fetch(URL, options);
      if (response.ok) {
        handleRefresh();
      } else {
        console.log("Issue Revoking All");
      }
    } catch (e) {
      console.error("Error fetching Share Access revoke All", e.message);
    }
  };

  return (
    <Grid container direction="row" alignItems="top">
      <Box
        sx={{
          padding: 3,
          borderRadius: "10px",
          height: "calc(100vh - 220px)",
          boxShadow: "0px 0px 10px 0px #27998933",
          width: "100%",
        }}
      >
        <div style={{ display: "flex", height: "100%" }}>
          <div style={{ width: "20%", height: "100%" }}>
            <Box
              sx={{
                background: "#F0FCFA",
                borderRadius: "8px",
                padding: "10px",
                height: "100%",
                overflowY: "auto",
              }}
            >
              <ClientSideBarHeader clientMainData={clientMainData} />
            </Box>
          </div>
          <div
            style={{
              flex: 1,
              flexDirection: "column",
              overflow: "auto",
              width: "80%",
              paddingLeft: "25px",
              paddingRight: 5,
              position: "relative",
            }}
          >
            <Box
              sx={{
                paddingTop: "20px",
                paddingLeft: "20px",
              }}
            >
              <ClientHeader
                head={`Share Access to View Basic Details of ${userName}`}
                isHideButton={true}
              />
            </Box>
            <Grid
              container
              alignItems="top"
              spacing={"10px"}
              paddingTop={"13px"}
            >
              <Box
                sx={{
                  width: "98%",
                  padding: 0,
                }}
              >
                <Card
                  variant="elevated"
                  elevation={0}
                  sx={{ width: "100%", padding: 0 }}
                >
                  <Grid container direction="row" alignItems="center">
                    <Grid item xs={12} md={9.5}>
                      <Box
                        textAlign="left"
                        sx={{ padding: ViewTheme.spacing(2), width: "100%" }}
                      >
                        <Typography variant="h3">Share with client</Typography>
                        <Typography variant="body2">
                          Provide clients with access to their care notes by
                          sending a magic link to the email address saved in
                          About me section.
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={2.5}>
                      <Button
                        sx={{ height: "33px", fontSize: "13px" }}
                        onClick={handleOpen}
                        type="submit"
                        variant="outlinedSecondary"
                        disabled={
                          accessAvailed
                            ?.filter((a) => a.role === "Client")
                            ?.reverse()[0]?.status === "Active"
                        }
                      >
                        Share Access Link
                      </Button>
                    </Grid>
                    {accessAvailed?.filter((a) => a.role === "Client")?.length >
                    0 ? (
                      <>
                        <hr
                          style={{
                            width: "100%",
                            margin: "6px 0px",
                            border: "1.5px solid rgba(39, 153, 137, 0.20)",
                          }}
                        />
                        {accessAvailed
                          ?.filter((a) => a.role === "Client")
                          .reverse()
                          .map((access, index) => (
                            <ShareAccessCard
                              key={index}
                              accessAvailed={access}
                              clientMainData={clientMainData}
                              clientId={clientId}
                              handleRefresh={handleRefresh}
                            />
                          ))}
                      </>
                    ) : null}
                  </Grid>
                </Card>
              </Box>
            </Grid>
            <Grid
              container
              alignItems="top"
              spacing={"10px"}
              paddingTop={"15px"}
            >
              <Box sx={{ width: "98%", padding: 0 }}>
                <Card
                  variant="elevated"
                  elevation={0}
                  sx={{ width: "100%", padding: 0 }}
                >
                  <Grid container direction="row" alignItems="center">
                    <Grid item xs={12} md={9.5}>
                      <Box
                        textAlign="left"
                        sx={{ padding: ViewTheme.spacing(2), width: "100%" }}
                      >
                        <Typography variant="h3">
                          Share with Third Party
                        </Typography>
                        <Typography variant="body2">
                          Grant third-party access to view essential
                          information, medication log, and care notes for the
                          client.
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={2.5}>
                      <Button
                        sx={{ height: "33px", fontSize: "13px" }}
                        onClick={handleTPAOpen}
                        type="submit"
                        variant="outlinedSecondary"
                      >
                        Share Access Link
                      </Button>
                    </Grid>
                    {accessAvailed?.filter((a) => a.role !== "Client")?.length >
                    0 ? (
                      <>
                        <hr
                          style={{
                            width: "100%",
                            margin: "6px 0px",
                            border: "1.5px solid rgba(39, 153, 137, 0.20)",
                          }}
                        />
                        {accessAvailed
                          ?.filter((a) => a.role !== "Client")
                          .reverse()
                          .map((access, index) => (
                            <ShareAccessCard
                              key={index}
                              accessAvailed={access}
                              clientMainData={clientMainData}
                              clientId={clientId}
                              handleRefresh={handleRefresh}
                            />
                          ))}
                      </>
                    ) : null}
                  </Grid>
                </Card>
              </Box>
            </Grid>
            <Grid
              container
              alignItems="top"
              spacing={"10px"}
              paddingTop={"15px"}
            >
              <Box sx={{ width: "98%", padding: 0 }}>
                <Card
                  variant="elevated"
                  elevation={0}
                  sx={{ width: "100%", padding: 0 }}
                >
                  <Grid container direction="row" alignItems="center">
                    <Grid item xs={12} md={8.5}>
                      <Box
                        textAlign="left"
                        sx={{ padding: ViewTheme.spacing(2), width: "100%" }}
                      >
                        <Typography variant="h3">
                          Revoke access to the shared parties
                        </Typography>
                        <Typography variant="body2">
                          This will remove all access given to clients and third
                          parties. You will have to send a new link with access
                          codes-in case you want to continue sharing.
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={3.5}>
                      <Button
                        sx={{ height: "33px", fontSize: "13px" }}
                        disabled={
                          !accessAvailed.length ||
                          !accessAvailed.some((a) => a.status === "Active")
                        }
                        onClick={handleRevokeAll}
                        type="submit"
                        variant="outlinedSecondary"
                      >
                        Remove Access to all Shared Parties
                      </Button>
                    </Grid>
                  </Grid>
                </Card>
              </Box>
            </Grid>
          </div>
        </div>
      </Box>
      {openDialog && (
        <ConfirmationDialog
          openDialog={openDialog}
          TitleText="The Access link will be shared to"
          paraText={`Email ID: ${clientMainData?.clientInfo.email}`}
          IconUrl={shareAccess}
          cancelText="Cancel"
          confirmText="Confirm"
          onCancelFun={() => handleClose()}
          onConfirmFun={onClickYesDialogBtn}
          handleCloseDialog={handleClose}
        />
      )}
      {openTPADialog && (
        <ShareAccessPopup
          accessAvailed={accessAvailed}
          clientId={clientId}
          openDialogue={openTPADialog}
          onClose={handleTPAClose}
          handleRefresh={handleRefresh}
        />
      )}
    </Grid>
  );
};

export default ShareAccess;
