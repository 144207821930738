import React from "react";
import {
  Box,
  Card,
  Typography,
  Dialog,
  DialogContent,
  Grid,
  Checkbox,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
  TextField,
  IconButton,
} from "@mui/material";
import CloseIcon from "../../assets/icons/close.svg";
import CloseHoverIcon from "../../assets/icons/closehover.svg";
import { ThemeProvider } from "@mui/material/styles";
import { sections } from "../../components/users/userCompetency/questionnaire";
import { nonequestions } from "../../components/users/userCompetency/questionnaire";
import { ViewTheme } from "../users/Theme";
import { Calendar } from "../reusableComponents";
import dayjs from "dayjs";

export default function InformationDialog({
  competency,
  openDialog,
  userId,
  competencyId,
  handleCloseDialog = () => {},
}) {
  const formatDates = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };
  return (
    <ThemeProvider theme={ViewTheme}>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <Box textAlign="center" justifyContent="Center">
          {/* Close button */}
          <IconButton
            aria-label="close"
            onClick={handleCloseDialog}
            sx={{
              position: "absolute",
              right: 10,
              top: 10,
              width: "28px ! important",
              height: "28px ! important",
              backgroundImage: `url(${CloseIcon})`, // Default close.svg
              backgroundSize: "cover",
              "&:hover": {
                backgroundImage: `url(${CloseHoverIcon})`, // On hover, change to closehover.svg
              },
            }}
          >
            {/* Empty content since the SVG icons are used in background */}
          </IconButton>
          <DialogContent
            sx={{
              background: "#F0FCFA",
              // paddingBottom: "35px",
              // paddingRight: "18px",
              // paddingLeft: "18px",
              padding: "0",
            }}
          >
            <Grid item lg={12} xs={12}>
              <Card
                variant="elevated"
                elevation={0}
                sx={{
                  height: "calc(80vh)",
                  paddingRight: 2,
                  margin: 0,
                  backgroundColor: ["#ffffff", "!important"], // If you need !important
                }}
              >
                <Box
                  sx={{
                    height: "100%",
                    overflow: "auto",
                    paddingRight: 8,
                    width: "100%",
                  }}
                >
                  <Grid container direction="row" alignItems="top">
                    <Grid item xs={12}>
                      <Box
                        textAlign="left"
                        sx={{ padding: ViewTheme.spacing(3, 3) }}
                      >
                        {sections.map((section, index) => (
                          <Box key={index} sx={{ mb: 4 }}>
                            <Typography variant="h4">
                              {section.title}
                            </Typography>
                            {section.subtitle && (
                              <Typography variant="h5">
                                {section.subtitle}
                              </Typography>
                            )}
                            {section.subtitle === "Medicine form" ? (
                              <Box>
                                {section.questions.map((question) => (
                                  <FormControlLabel
                                    key={question.id}
                                    label={question.text}
                                    control={
                                      <Checkbox
                                        checked={competency[question.id]}
                                        name={question.id}
                                        disabled
                                      />
                                    }
                                  />
                                ))}
                              </Box>
                            ) : (
                              section.questions.map((question) => (
                                <Box key={question.id} sx={{ mb: 3 }}>
                                  {question.id.includes("checkbox") ? null : (
                                    <>
                                      <FormLabel>
                                        <Typography variant="h6">
                                          {question.text}
                                        </Typography>
                                      </FormLabel>
                                      <RadioGroup
                                        row
                                        sx={{ padding: "0", marginTop: "5px" }}
                                        name={question.id}
                                        value={competency[question.id]}
                                        disabled
                                      >
                                        <FormControlLabel
                                          value="yes"
                                          control={<Radio disabled />}
                                          label="Yes"
                                        />
                                        <FormControlLabel
                                          value="no"
                                          control={<Radio disabled />}
                                          label="No"
                                        />
                                        {nonequestions.includes(
                                          question.id
                                        ) && (
                                          <FormControlLabel
                                            value="none"
                                            control={<Radio />}
                                            label="None seen this time"
                                          />
                                        )}
                                      </RadioGroup>
                                    </>
                                  )}
                                </Box>
                              ))
                            )}
                          </Box>
                        ))}
                        <FormLabel>
                          <Typography variant="h4">
                            Any other information
                          </Typography>
                        </FormLabel>
                        <TextField
                          name="info"
                          variant="outlined"
                          className="multiline-textfield"
                          multiline
                          fullWidth
                          rows={10}
                          value={competency.info}
                          InputProps={{ readOnly: true }}
                        ></TextField>
                        <FormLabel>
                          <Typography variant="h4">
                            Actions/exceptions identified{" "}
                          </Typography>
                        </FormLabel>
                        <TextField
                          name="action"
                          variant="outlined"
                          className="multiline-textfield"
                          multiline
                          fullWidth
                          rows={10}
                          value={competency.action}
                          InputProps={{ readOnly: true }}
                        ></TextField>
                        <FormLabel>
                          <Typography variant="h4">
                            Signatures of All Involved Admins in the Assessment
                          </Typography>
                        </FormLabel>
                        <Grid
                          container
                          direction="row"
                          alignItems="top"
                          sx={{ gap: 8, mt: 2 }}
                        >
                          <Grid item xs={10} lg={4}>
                            <FormLabel>
                              <Typography variant="h6">Signature</Typography>
                            </FormLabel>
                            <TextField
                              name="sign_1"
                              variant="outlined"
                              fullWidth
                              value={competency.sign_1}
                              InputProps={{ readOnly: true }}
                            ></TextField>
                          </Grid>
                          <Grid item xs={10} lg={4}>
                            <FormLabel>
                              <Typography variant="h6">Date</Typography>
                            </FormLabel>
                            <TextField
                              name="date_1"
                              variant="outlined"
                              fullWidth
                              value={
                                competency.date_1
                                  ? formatDates(competency.date_1)
                                  : ""
                              }
                              InputProps={{ readOnly: true }}
                            ></TextField>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          direction="row"
                          alignItems="top"
                          sx={{ gap: 8 }}
                        >
                          <Grid item xs={10} lg={4}>
                            <FormLabel>
                              <Typography variant="h6">Signature</Typography>
                            </FormLabel>
                            <TextField
                              name="sign_2"
                              variant="outlined"
                              fullWidth
                              value={competency.sign_2}
                              InputProps={{ readOnly: true }}
                            />
                          </Grid>

                          <Grid item xs={10} lg={4}>
                            <FormLabel>
                              <Typography variant="h6">Date</Typography>
                            </FormLabel>
                            <TextField
                              name="date_2"
                              variant="outlined"
                              fullWidth
                              value={
                                competency.date_1
                                  ? formatDates(competency.date_2)
                                  : ""
                              }
                              InputProps={{ readOnly: true }}
                            ></TextField>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          direction="row"
                          alignItems="top"
                          sx={{ gap: 3 + 9 }}
                        >
                          {/* <Grid item>
                            <FormControlLabel
                              label={
                                "I hereby confirm the accuracy of the information provided above"
                              }
                              control={
                                <Checkbox
                                  checked={competency["c46-checkbox"]}
                                  name={"c46-checkbox"}
                                />
                              }
                            />
                          </Grid> */}
                        </Grid>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Card>
            </Grid>
          </DialogContent>
        </Box>
      </Dialog>
    </ThemeProvider>
  );
}
