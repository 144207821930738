import React, { useState, useEffect } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  IconButton,
  TextField,
  Grid,
  Autocomplete,
} from "@mui/material";
import CloseIcon from "../../assets/icons/close.svg";
import CloseHoverIcon from "../../assets/icons/closehover.svg";
import Checkbox from "@mui/material/Checkbox";
import User from "../../assets/icons/User.svg";
import { getCookie } from "../../utils/utils";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Car from "../../assets/images/userTableIcons/UserTableCar.svg";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#279989",
    padding: "8px 16px",
    border: "none",
    color: theme.palette.common.white,
    position: "sticky",
    top: 0,
    zIndex: 1,
  },
  [`&.${tableCellClasses.body}`]: {
    borderBottom: "2px solid #eefefa",
    fontSize: 14,
    padding: "8px 16px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#eefefa",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function RosterAssignCarerDialog({
  openDialog,
  visit,
  visits,
  carersList,
  handlestep,
  handleCloseDialog = () => {},
}) {
  const [usersList, setUsersList] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);
  const selectedUserLimit = visit.numberOfCarersRequired
    ? visit.numberOfCarersRequired
    : null;

  useEffect(() => {
    setUsersList(carersList || []); // Fallback to an empty array
    console.log("Users", carersList);
  }, [carersList]);

  useEffect(() => {
    setSelectedUser(visit?.userid || []);
  }, [visit?.userid]);

  const postAssignedCarers = async () => {
    try {
      const runid = visit.runid;
      const visitsWithRunId =
        runid != ""
          ? visits
              .filter((visit) => visit.runid === runid) // Filter visits with non-empty runid
              .map((visit) => visit.visitId)
          : ""; // Extract the visitid from each filtered visit

      console.log("visitrunid cehck visit", visitsWithRunId); // This will log an array of visitid's
      const body = {
        visit_details_id:
          visit.runid === "" ? [visit.visitId] : visitsWithRunId,
        user_id: selectedUser.map((user) => user),
      };
      const token = getCookie();
      const URL = `${process.env.REACT_APP_BASE_URL}/roster-run-assign-users`;

      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      };
      console.log("body", body); // This will log an array of visitid's
      const response = await fetch(URL, options);
      const data = await response.json();

      if (response.ok && data.statusCode === 200) {
        setSelectedUser([]);
        handlestep();
      } else {
        console.log("Failed to set assigned carers");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const handleCheckboxChange = (event, userid) => {
    setSelectedUser((prev) => {
      // Toggle the userId in the selectedUsers array based on whether the checkbox is checked or not
      const selectedUser = event.target.checked
        ? [...prev, userid] // Add userId if checked
        : prev.filter((id) => id !== userid); // Remove userId if unchecked
      return selectedUser;
    });
  };

  // useEffect(() => {
  //   console.log("Selected User:", selectedUser);
  // }, [selectedUser]);

  const onConfirmFun = () => {
    postAssignedCarers();
    handleCloseDialog();
  };
  const onCancelFun = () => {
    handleCloseDialog();
  };

  const handleClose = (event, reason) => {
    // Prevent closing when clicking outside or pressing Escape
    if (reason === "backdropClick" || reason === "escapeKeyDown") {
      return;
    }
    handleCloseDialog();
  };

  return (
    <Dialog
      open={openDialog}
      onClose={handleClose} // Custom close handler
      PaperProps={{
        sx: {
          width: "80%", // Set your desired width here
          maxWidth: "1200px", // Prevent it from shrinking to smaller widths
        },
      }}
    >
      <Box textAlign="center" justifyContent="Center">
        {/* Close button */}
        <IconButton
          aria-label="close"
          onClick={handleCloseDialog}
          sx={{
            position: "absolute",
            right: 1,
            top: 10,
            width: "20px !important",
            height: "20px !important",
            backgroundImage: `url(${CloseIcon})`, // Default close.svg
            backgroundSize: "cover",
            "&:hover": {
              backgroundImage: `url(${CloseHoverIcon})`, // On hover, change to closehover.svg
            },
          }}
        >
          {/* Empty content since the SVG icons are used in background */}
        </IconButton>
        <DialogContent sx={{ margin: "0px 30px" }}>
          {/* Header here */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            {/* Left Header Section */}
            <Box
              sx={{
                marginTop: "10px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                style={{ width: "25px", marginRight: "10px" }}
                src={User}
                alt="User"
              />
              {selectedUser.length > 0 ? (
                <Typography
                  variant="h3"
                  sx={{ margin: 0, padding: 0, textWrap: "nowrap" }}
                >
                  Change Users
                </Typography>
              ) : (
                <Typography
                  variant="h3"
                  sx={{ margin: 0, padding: 0, textWrap: "nowrap" }}
                >
                  Assign Users
                </Typography>
              )}
            </Box>
            {/* Right Header Section */}
            <Box
              sx={{
                marginTop: "10px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <TextField sx={{ minWidth: "250px" }}></TextField>
              <Typography
                variant="h3"
                sx={{
                  margin: 0,
                  padding: 0,
                  textWrap: "nowrap",
                  width: "250px",
                }}
              >
                {selectedUser.length}/{selectedUserLimit} Users Selected
              </Typography>
            </Box>
          </Box>
          {/* Table Content */}
          <Box sx={{ marginTop: "20px" }}>
            <TableContainer
              component={Paper}
              sx={{ maxHeight: "370px", overflow: "auto" }}
            >
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell padding="checkbox"></StyledTableCell>
                    <StyledTableCell>
                      <Typography
                        sx={{
                          fontFamily: "Arvo",
                          color: "white",
                          padding: "0",
                        }}
                      >
                        Name
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Typography
                        sx={{
                          fontFamily: "Arvo",
                          color: "white",
                          padding: "0",
                        }}
                      >
                        Contact
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Typography
                        sx={{
                          fontFamily: "Arvo",
                          color: "white",
                          padding: "0",
                        }}
                      >
                        Gender
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Typography
                        sx={{
                          fontFamily: "Arvo",
                          color: "white",
                          padding: "0",
                        }}
                      >
                        Admin
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Typography
                        sx={{
                          fontFamily: "Arvo",
                          color: "white",
                          padding: "0",
                          width: "80%",
                        }}
                      >
                        Status
                      </Typography>
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody sx={{ overflow: "auto" }}>
                  {usersList
                    .filter((user) => user.status === 1)
                    .map((user, index) => (
                      <StyledTableRow key={user.userid}>
                        <StyledTableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={selectedUser.includes(user.userid)}
                            onChange={(event) =>
                              handleCheckboxChange(event, user.userid)
                            }
                            disabled={
                              !selectedUser.includes(user.userid) &&
                              selectedUser.length >= selectedUserLimit
                            } // Disable if limit reached and this checkbox is not selected
                          />
                        </StyledTableCell>
                        <StyledTableCell
                          component="th"
                          scope="row"
                          sx={{
                            maxWidth: "150px",
                          }}
                        >
                          <Typography
                            sx={{
                              fontFamily: "Lora",
                              fontSize: "14px",
                              padding: "0",
                              textWrap: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {user.name}
                            {user.drive == 0 ? (
                              <img
                                src={Car}
                                alt="Car"
                                style={{
                                  marginLeft: "10px",
                                  verticalAlign: "middle",
                                }}
                              />
                            ) : null}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <Typography
                            sx={{
                              fontFamily: "Lora",
                              fontSize: "14px",
                              padding: "0",
                            }}
                          >
                            {user.contact_number}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <Typography
                            sx={{
                              fontFamily: "Lora",
                              fontSize: "14px",
                              padding: "0",
                            }}
                          >
                            {user.gender}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <Typography
                            sx={{
                              fontFamily: "Lora",
                              fontSize: "14px",
                              padding: "0",
                            }}
                          >
                            {user.admin == "1" ? "Yes" : "No"}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <Typography
                            sx={{
                              fontFamily: "Lora",
                              fontSize: "14px",
                              padding: "0",
                              border:
                                user.status == "1"
                                  ? "1px solid #03AE71"
                                  : "1px solid #F5891D", // Change border color based on status
                              borderRadius: "8px",
                              backgroundColor:
                                user.status == "1" ? "#F1FFF3" : "#FFF0E3",
                              padding: "4px",
                              color: user.status == "1" ? "#03AE71" : "#F5891D",
                              width: "80%",
                            }}
                          >
                            {user.status == "1" ? "Active" : "Inactive"}
                          </Typography>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="customcancel" onClick={onCancelFun} color="primary">
            Cancel
          </Button>
          <Button
            variant="customsave"
            onClick={onConfirmFun}
            color="primary"
            disabled={selectedUser.length !== selectedUserLimit}
          >
            Save
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
