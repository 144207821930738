import * as React from "react";
import { useEffect, forwardRef, useImperativeHandle } from "react";
import { Box, FormLabel, TextField, Typography } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import "./muiDatepicker.css"; // Import the custom CSS file

const Calendar = forwardRef(
  (
    {
      onDateChange,
      maxDate,
      minDate,
      disabled,
      label,
      name,
      required = false,
      preFilledvalue,
      nolabel = false,
    },
    ref
  ) => {
    const [value, setValue] = React.useState(null); // Set initial state with correct format
    const [errorMessage, setErrorMessage] = React.useState("");

    useEffect(() => {
      if (preFilledvalue && preFilledvalue !== "") {
        setValue(dayjs(preFilledvalue, "YYYY-MM-DD"));
        setErrorMessage("");
      } else {
        setValue(null); // Ensure the state remains null if no date is selected
        setErrorMessage("");
      }
    }, [preFilledvalue]);

    const handleDateChange = (newValue) => {
      if (!newValue) {
        // If no date is selected or the field is cleared, send an empty string
        setValue(null);
        onDateChange("", name); // Send empty string to backend
      } else {
        const dayjsValue = dayjs(newValue, "YYYY-MM-DD");
        let isValid = true;
        console.log("day", dayjsValue);
        console.log("Minday", minDate);

        if (maxDate && dayjsValue.isAfter(dayjs(maxDate, "YYYY-MM-DD"))) {
          setErrorMessage(
            `${label} cannot be after ${dayjs(maxDate).format("DD-MM-YYYY")}`
          );
          isValid = false;
        } else if (
          minDate &&
          dayjsValue.isBefore(dayjs(minDate, "YYYY-MM-DD"))
        ) {
          console.log("Minday", minDate);
          setErrorMessage(
            `${label} cannot bebe be before ${dayjs(minDate).format(
              "DD-MM-YYYY"
            )}`
          );
          isValid = false;
        } else if (required && !newValue) {
          setErrorMessage(`${label} is required.`);
          isValid = false;
        } else {
          setErrorMessage("");
        }

        if (isValid) {
          setValue(dayjsValue);
          onDateChange(dayjsValue.format("YYYY-MM-DD"), name); // Send the date in the required format
        }
      }
    };

    const handleValidation = () => {
      const dayjsValue = dayjs(value, "YYYY-MM-DD");
      if (required && !value) {
        setErrorMessage(`${label} is required.`);
        return false;
      } else if (maxDate && dayjsValue.isAfter(dayjs(maxDate, "YYYY-MM-DD"))) {
        setErrorMessage(
          `${label} cannot be after ${dayjs(maxDate).format("DD-MM-YYYY")}`
        );
        return false;
      } else if (minDate && dayjsValue.isBefore(dayjs(minDate, "YYYY-MM-DD"))) {
        setErrorMessage(
          `${label} cannot be before ${dayjs(minDate).format("DD-MM-YYYY")}`
        );
        return false;
      }
      return true;
    };

    useImperativeHandle(ref, () => ({
      handleValidation,
    }));

    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box sx={{ textAlign: "left" }}>
          {!nolabel && (
            <FormLabel>
              <Typography variant="h6">
                {label}
                {required && <span className="star">*</span>}
              </Typography>
            </FormLabel>
          )}
          <DatePicker
            sx={{ width: "100%" }}
            value={value}
            disabled={disabled}
            name={name}
            onChange={handleDateChange}
            views={["year", "month", "day"]}
            format="DD-MM-YYYY"
            maxDate={maxDate ? dayjs(maxDate, "YYYY-MM-DD") : undefined}
            minDate={minDate ? dayjs(minDate, "YYYY-MM-DD") : undefined}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" />
            )}
          />
          {errorMessage && (
            <Typography variant="errmsg">{errorMessage}</Typography>
          )}
        </Box>
      </LocalizationProvider>
    );
  }
);

export default Calendar;
