import React from 'react';
import { Container, Row, Col, Card, Button, Image, Form } from 'react-bootstrap';
import Mailsent from '../../assets/images/login/mail-sent.gif';
import './loginconf.css';
import './login.css';
import 'bootstrap/dist/css/bootstrap.min.css';

function Loginconf({email, goToLoginBack}) {
    return (
        <div className="Login">
            <Container>
                <Row>   
                    <Col md={8} sm={9}>
                        <Card className="card">
                            <Row>
                                <Col lg={4} md={6} xs={8}>
                                    <Image src={Mailsent} className="Logo-image" alt="logo" />
                                </Col>
                                <Col xs={11}>
                                    <hr/>
                                </Col>
                            </Row>
                            <Card.Body className="card-body">
                                <Row>
                                    <Col xs={9}>
                                        <Card.Title className="card-title mb-3">Check your email!</Card.Title>
                                        <Card.Text className="card-text">We have sent an email to your inbox at <span class="fgcolor">{email}</span>. It includes a link and a button to sign you into Care Esteem.</Card.Text>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={4} className="mt-3 mb-3">
                                        <Form>
                                            <Form.Group>
                                                <Button 
                                                onClick={()=>goToLoginBack()}
                                                className=" sendmagiclink mb-3" type="Submit">Back to login</Button>
                                            </Form.Group>
                                        </Form>
                                    </Col>
                                </Row>
                            </Card.Body>  
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Loginconf;
