import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import {
  Card,
  Grid,
  Switch,
  Typography,
  Paper,
  Button,
  Box,
} from "@mui/material";
import { ClientHeader } from "../../clients/clientReusableComponents/components";
import { SettingsSideBar } from "../settings/SettingsSideBar";
import { EditTheme } from "../../../themes/Theme";
import { ThemeProvider } from "@mui/material/styles";
import QrCodeIcon from "@mui/icons-material/QrCode";
import ServicePopup from "../../../assets/images/clients/settingspopup.svg";
import ServiceEditDialog from "./ServiceEditDialog";
import { QRCodeCanvas } from "qrcode.react";
import TemporaryInactiveDialog from "./TemporaryInactiveDialog";

const ClientSetting = ({ clientId, clientMainData, setTabId, activeTabID }) => {
  const [isInactive, setIsInactive] = useState(false);
  const [isNotificationEnabled, setNotificationEnabled] = useState(false);
  const [isGeoLocationEnabled, setGeoLocationEnabled] = useState(false);
  const [clientName, setClientName] = useState("");
  const [openServiceDialog, setOpenServiceDialog] = useState(false);
  const [openInactiveDialog, setOpenInactiveDialog] = useState(false);
  const [qrCodeData, setQRCodeData] = useState("");

  // Function to generate random data
  const generateRandomData = () => {
    // Generate a random string (could be changed to any format like random numbers, URLs, etc.)
    const randomString = Math.random().toString(36).substring(2, 15);
    setQRCodeData(randomString);
  };

  // Function to download QR code
  const handleDownload = () => {
    const canvas = document.querySelector("canvas");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    const downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = "qrcode.png";
    downloadLink.click();
  };

  const handleServiceDialogOpen = () => {
    setOpenServiceDialog(true);
  };
  const handleServiceDialogClose = () => {
    setOpenServiceDialog(false);
  };

  const handleInactiveDialogOpen = () => {
    setOpenInactiveDialog(true);
  };
  const handleInactiveDialogClose = () => {
    setOpenInactiveDialog(false);
  };

  useEffect(() => {
    if (clientMainData) {
      setClientName(`${clientMainData?.clientInfo?.first_name || ""} `);
    }
  }, [clientMainData]);

  const handleInactiveToggle = (event) => {
    setIsInactive(event.target.checked);
  };

  const handleNotificationToggle = (event) => {
    setNotificationEnabled(event.target.checked);
  };

  const handleGeoLocationToggle = (event) => {
    setGeoLocationEnabled(event.target.checked);
  };

  return (
    <ThemeProvider theme={EditTheme}>
      <Grid container direction="row" alignItems="top">
        <Box
          sx={{
            padding: 3,
            borderRadius: "10px",
            height: "calc(100vh - 220px)",
            boxShadow: "0px 0px 10px 0px #27998933",
            width: "100%",
          }}
        >
          <div style={{ display: "flex", height: "100%" }}>
            <div style={{ width: "20%", height: "100%" }}>
              <SettingsSideBar
                clientId={clientId}
                clientMainData={clientMainData}
                setTabId={setTabId}
                activeTabID={activeTabID}
              />
            </div>
            <div
              style={{
                flex: 1,
                flexDirection: "column",
                overflow: "auto",
                width: "80%",
                paddingTop: "15px",
                paddingLeft: "35px",
                paddingRight: "15px",
                position: "relative",
              }}
            >
              <Box sx={{ marginBottom: "20px" }}>
                <ClientHeader
                  head={`${clientName}'s Settings`}
                  isHideButton={false}
                />
              </Box>
              <Box>
                {/* Service Toggle */}
                <Card
                  elevation={"0px"}
                  sx={{
                    backgroundColor: "#f0fcfa",
                    padding: "10px",
                    borderRadius: "8px",
                    margin: "20px 0px",
                    boxShadow: "none ! important",
                  }}
                >
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    wrap="nowrap"
                  >
                    <Grid item xs sx={{ textAlign: "left" }}>
                      <Typography variant="h3">
                        {clientName} Service Start Date / End Date
                      </Typography>
                      <Typography variant="body1">
                        The Service Start Date is when the client's homecare
                        services begin, and the Service End Date is when the
                        services are concluded or the care plan is completed.
                      </Typography>
                    </Grid>
                    <Grid item sx={{ paddingRight: "20px" }}>
                      <img
                        src={ServicePopup}
                        alt="ServicePopup"
                        onClick={handleServiceDialogOpen}
                        style={{ cursor: "pointer" }}
                      />
                    </Grid>
                  </Grid>
                </Card>

                {/* Inactive Toggle */}
                <Card
                  elevation={"0px"}
                  sx={{
                    backgroundColor: "#f0fcfa",
                    padding: "10px",
                    borderRadius: "8px",
                    margin: "20px 0px",
                    boxShadow: "none ! important",
                  }}
                >
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    wrap="nowrap"
                  >
                    <Grid item xs sx={{ textAlign: "left" }}>
                      <Typography variant="h3">
                        {clientName} Temporary Inactive
                      </Typography>
                      <Typography variant="body1">
                        This status indicates that the homecare services are
                        paused for a period of time due to reasons such as
                        hospitalization, family support, vacation, reassessment
                        of care needs, or any other temporary circumstances.
                        Services are expected to resume once the situation is
                        resolved.
                      </Typography>
                    </Grid>
                    <Grid item sx={{ paddingRight: "20px" }}>
                      <Switch
                        checked={isInactive}
                        onChange={handleInactiveDialogOpen}
                        variant="ios" // Apply the custom iOS variant here
                        inputProps={{ "aria-label": "notification toggle" }}
                        color="success"
                      />
                    </Grid>
                  </Grid>
                </Card>

                {/* Notification Toggle */}
                <Card
                  elevation={"0px"}
                  sx={{
                    backgroundColor: "#f0fcfa",
                    padding: "10px",
                    borderRadius: "8px",
                    margin: "20px 0px",
                    boxShadow: "none ! important",
                  }}
                >
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    wrap="nowrap"
                  >
                    <Grid item xs sx={{ textAlign: "left" }}>
                      <Typography variant="h3">
                        {clientName}'s Notification
                      </Typography>
                      <Typography variant="body1">
                        Notifications of late or missing visits for Nolan
                      </Typography>
                    </Grid>
                    <Grid item sx={{ paddingRight: "20px" }}>
                      <Switch
                        checked={isNotificationEnabled}
                        onChange={handleNotificationToggle}
                        variant="ios" // Apply the custom iOS variant here
                        inputProps={{ "aria-label": "notification toggle" }}
                        color="success"
                      />
                    </Grid>
                  </Grid>
                </Card>

                {/* Geo Location Toggle */}
                <Card
                  elevation={"0px"}
                  sx={{
                    backgroundColor: "#f0fcfa",
                    padding: "10px",
                    borderRadius: "8px",
                    margin: "20px 0px",
                    boxShadow: "none ! important",
                  }}
                >
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    wrap="nowrap"
                  >
                    <Grid item xs sx={{ textAlign: "left" }}>
                      <Typography variant="h3">
                        Enable Geo Location Check-in and check-out
                      </Typography>
                      <Typography variant="body1">
                        Confirmation of carers location for check-in and
                        check-out to a visit
                      </Typography>
                    </Grid>
                    <Grid item sx={{ paddingRight: "20px" }}>
                      <Switch
                        checked={isGeoLocationEnabled}
                        onChange={handleGeoLocationToggle}
                        inputProps={{ "aria-label": "geo location toggle" }}
                        color="success"
                      />
                    </Grid>
                  </Grid>
                </Card>

                {/* QR Code Section */}
                <Card
                  elevation={"0px"}
                  sx={{
                    backgroundColor: "#f0fcfa",
                    padding: "10px",
                    borderRadius: "8px",
                    margin: "20px 0px",
                    boxShadow: "none ! important",
                  }}
                >
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Grid item xs sx={{ textAlign: "left" }}>
                      <Typography variant="h3">
                        QR Code check-in and check-out
                      </Typography>
                      <Typography variant="body1">
                        Check-in and check-out for a visit by scanning the QR
                        code
                      </Typography>
                      <Box sx={{ margin: "10px 20px" }}>
                        <Button
                          type="submit"
                          variant="outlinedSecondary"
                          sx={{ marginRight: "20px" }}
                          onClick={generateRandomData}
                        >
                          Regenerate QR Code
                        </Button>
                        <Button
                          variant="outlinedSecondary"
                          onClick={handleDownload}
                        >
                          Print QR Code
                        </Button>
                      </Box>
                    </Grid>
                    <Grid item>
                      <Box
                        sx={{
                          padding: "10px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <QRCodeCanvas
                          value={qrCodeData}
                          size={80}
                          fgColor="#269989" // Set the QR code color to the specified green
                          bgColor="transparent" // Set the background to transparent
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Card>
              </Box>
            </div>
          </div>
          {openServiceDialog && (
            <ServiceEditDialog
              openDialog={openServiceDialog}
              handleCloseDialog={handleServiceDialogClose}
            />
          )}
          {openInactiveDialog && (
            <TemporaryInactiveDialog
              openDialog={openInactiveDialog}
              handleCloseDialog={handleInactiveDialogClose}
            />
          )}
        </Box>
      </Grid>
    </ThemeProvider>
  );
};

export default ClientSetting;
