import React, { useState, useEffect, useMemo } from "react";
import { Container, Grid, FormLabel, Typography, Box } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { ViewTheme } from "../Theme";
import { getCookie } from "../../../utils/utils";

export default function UserPayroll({ userId }) {
  const initialPayrollInfo = useMemo(
    () => ({
      bank_name: "",
      branch_name: "",
      account_holder_name: "",
      account_number: "",
      sort_code: "",
      hourly_rate: "",
      pay_rate_per_mile: "",
      nin: "",
      employment_start: "",
      employment_end: "",
    }),
    []
  );
  const [payrollInfo, setPayrollInfo] = useState(initialPayrollInfo);
  useEffect(() => {
    const fetchPayroll = async () => {
      if (!userId) return;
      try {
        const token = getCookie();
        const URL = `${process.env.REACT_APP_BASE_URL}/payroll/${userId}`;
        const options = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        };
        const response = await fetch(URL, options);
        const data = await response.json();
        if (response.ok === true) {
          setPayrollInfo(data.data);
        } else {
          console.log("get all failed");
          setPayrollInfo(initialPayrollInfo);
        }
      } catch (e) {
        console.log("error", e.message);
      }
    };
    fetchPayroll();
  }, [initialPayrollInfo, userId]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  };

  return (
    <ThemeProvider theme={ViewTheme}>
      <Container
        sx={{ display: "flex", flexDirection: "column", width: "100%" }}
        maxWidth={false}
      >
        <Box
          sx={{
            padding: 0,
            width: { xxl: "50%", lg: "70%", xs: "98%" },
            margin: "15px 20px",
            textAlign: "left",
          }}
        >
          <Grid container direction="row" alignItems="top" spacing={"10px"}>
            <Grid item xs={3} lg={2.5}>
              <FormLabel>
                <Typography variant="h7">Bank Name</Typography>
              </FormLabel>
            </Grid>
            <Grid item xs={0.5}>
              <FormLabel sx={{ textAlign: "Center" }}>:</FormLabel>
            </Grid>
            <Grid item xs={8.5} lg={9}>
              <Typography variant="body4">{payrollInfo.bank_name}</Typography>
            </Grid>
            <Grid item xs={3} lg={2.5}>
              <FormLabel>
                <Typography variant="h7">Branch Name</Typography>
              </FormLabel>
            </Grid>
            <Grid item xs={0.5}>
              <FormLabel sx={{ textAlign: "Center" }}>:</FormLabel>
            </Grid>
            <Grid item xs={8.5} lg={9}>
              <Typography variant="body4">{payrollInfo.branch_name}</Typography>
            </Grid>
            <Grid item xs={3} lg={2.5}>
              <FormLabel>
                <Typography variant="h7">Account Holder Name</Typography>
              </FormLabel>
            </Grid>
            <Grid item xs={0.5}>
              <FormLabel sx={{ textAlign: "Center" }}>:</FormLabel>
            </Grid>
            <Grid item xs={8.5} lg={9}>
              <Typography variant="body4">
                {payrollInfo.account_holder_name}
              </Typography>
            </Grid>
            <Grid item xs={3} lg={2.5}>
              <FormLabel>
                <Typography variant="h7">Account Number</Typography>
              </FormLabel>
            </Grid>
            <Grid item xs={0.5}>
              <FormLabel sx={{ textAlign: "Center" }}>:</FormLabel>
            </Grid>
            <Grid item xs={8.5} lg={9}>
              <Typography variant="body4">
                {payrollInfo.account_number}
              </Typography>
            </Grid>
            <Grid item xs={3} lg={2.5}>
              <FormLabel>
                <Typography variant="h7">Sort Code</Typography>
              </FormLabel>
            </Grid>
            <Grid item xs={0.5}>
              <FormLabel sx={{ textAlign: "Center" }}>:</FormLabel>
            </Grid>
            <Grid item xs={8.5} lg={9}>
              <Typography variant="body4">{payrollInfo.sort_code}</Typography>
            </Grid>
            <Grid item xs={3} lg={2.5}>
              <FormLabel>
                <Typography variant="h7">Hourly Rate</Typography>
              </FormLabel>
            </Grid>
            <Grid item xs={0.5}>
              <FormLabel sx={{ textAlign: "Center" }}>:</FormLabel>
            </Grid>
            <Grid item xs={8.5} lg={9}>
              <Typography variant="body4">
                {payrollInfo.hourly_rate ? `£ ${payrollInfo.hourly_rate}` : ""}
              </Typography>
            </Grid>
            <Grid item xs={3} lg={2.5}>
              <FormLabel>
                <Typography variant="h7">Pay Rate Per Mile</Typography>
              </FormLabel>
            </Grid>
            <Grid item xs={0.5}>
              <FormLabel sx={{ textAlign: "Center" }}>:</FormLabel>
            </Grid>
            <Grid item xs={8.5} lg={9}>
              <Typography variant="body4">
                {payrollInfo.pay_rate_per_mile
                  ? `£ ${payrollInfo.pay_rate_per_mile}`
                  : ""}
              </Typography>
            </Grid>
            <Grid item xs={3} lg={2.5}>
              <FormLabel>
                <Typography variant="h7">NI Number</Typography>
              </FormLabel>
            </Grid>
            <Grid item xs={0.5}>
              <FormLabel sx={{ textAlign: "Center" }}>:</FormLabel>
            </Grid>
            <Grid item xs={8.5} lg={9}>
              <Typography variant="body4">{payrollInfo.nin}</Typography>
            </Grid>
            <Grid item xs={3} lg={2.5}>
              <FormLabel>
                <Typography variant="h7">Employee Start Date</Typography>
              </FormLabel>
            </Grid>
            <Grid item xs={0.5}>
              <FormLabel sx={{ textAlign: "Center" }}>:</FormLabel>
            </Grid>
            <Grid item xs={8.5} lg={9}>
              <Typography variant="body4">
                {payrollInfo.employment_start
                  ? formatDate(payrollInfo.employment_start)
                  : null}
              </Typography>
            </Grid>
            <Grid item xs={3} lg={2.5}>
              <FormLabel>
                <Typography variant="h7">Employee End Date</Typography>
              </FormLabel>
            </Grid>
            <Grid item xs={0.5}>
              <FormLabel sx={{ textAlign: "Center" }}>:</FormLabel>
            </Grid>
            <Grid item xs={8.5} lg={9}>
              <Typography variant="body4">
                {payrollInfo.employment_end
                  ? formatDate(payrollInfo.employment_end)
                  : null}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
