import { Box, Button, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ClientHeader } from "../clientReusableComponents/components";
import MARChartTable from "./MARChartTable";
import {  IconButton, Typography } from "@mui/material";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import dayjs from "dayjs";
import useStyles from "../styles/useStyles";

function MARChart({ clientMainData }) {
  const [userName, setUserName] = useState("");
  const classes = useStyles()
  const [currentDate, setCurrentDate] = useState(dayjs()); // Initialize with today's date

  // Get the number of days in the current month
  const daysInMonth = currentDate.daysInMonth();

  // Move to the previous month
  const handlePreviousMonth = () => {
    setCurrentDate(currentDate.subtract(1, "month"));
  };

  // Move to the next month
  const handleNextMonth = () => {
    setCurrentDate(currentDate.add(1, "month"));
  };
  useEffect(() => {
    if (clientMainData) {
      setUserName(`${clientMainData?.clientInfo?.first_name || ""} `);
    }
  }, [clientMainData]);
  return (
    <Grid container sx={{ width: "100%" ,pt:1}}>
      <Box sx={{display: "flex",alignItems: "center",gap: "0px",justifyContent: "space-between",width: "100%"}}>

      <Box className={classes.header} 
      sx={{ width: "100%" }}>
        <ClientHeader head={`MAR Chart for ${userName}`} />
      </Box>
      <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        // padding: "10px",
        // border: "1px solid #ccc",
        // borderRadius: "8px",
        width: "55%",
        marginRight: "15%",
        // backgroundColor: "#f9f9f9",
      }}
    >
      {/* Previous Button */}
      <IconButton onClick={handlePreviousMonth}>
        <ChevronLeft />
      </IconButton>

      {/* Current Month and Year */}
      <Typography variant="h6" sx={{ marginRight: "9px", fontWeight: "500",fontSize: "14px",color:'#279989' }}>
        {currentDate.format("MMMM YYYY")}
      </Typography>

      {/* Next Button */}
      <IconButton onClick={handleNextMonth}>
        <ChevronRight />
      </IconButton>
    </Box>
    <Box sx={{}}>
      <Button variant="outlinedSecondary" sx={{padding: "5px",width: "170px",height: "40px",fontSize: "14px"}}>Download Mar Chart</Button>
    </Box>
      </Box>
      <Box sx={{ width: "100%",pt:2 }}>
        <MARChartTable />
      </Box>

    </Grid>
  );
}

export default MARChart;
