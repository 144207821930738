import React from "react";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import StyledCheckbox from "../../reusableComponents/StyledCheckbox";
import incrementIcon from "../../../assets/images/common/incrementIcon.svg";
import {
  Typography,
  // Box
} from "@mui/material";
import ascDescIcon from "../../../assets/images/common/ascDescIcon.svg";
import { IMG, TablePopup } from "./Components";

const styles = {
  tableCell: {
    backgroundColor: "#279989",
    zIndex: 1,
  },
  paragraph: {
    color: "#FFF",
    // paddingLeft: "0px",
    padding: "0px",
    position: "relative",
  },
};

export default function ClientTableHeader({
  toggleAllIDs,
  setOrderByName,
  orderByName,
  isAdminOpen,
  setIAdminOpen,
  orderByAdminList,
  setOrderByAdminList,
  orderByStatusList,
  setOrderByStatusList,
  isStatusOpen,
  setIsStatusOpen,
}) {
  const adminFiltList = [
    {
      id: 1,
      text: "Low",
      onChange: () => {
        if (orderByAdminList.includes("Low")) {
          setOrderByAdminList(
            orderByAdminList.filter((each) => each !== "Low")
          );
        } else {
          setOrderByAdminList([...orderByAdminList, "Low"]);
        }
        setIAdminOpen(false);
      },
      checked: orderByAdminList.includes("Low"),
    },
    {
      id: 2,
      text: "Moderate",
      onChange: () => {
        if (orderByAdminList.includes("Moderate")) {
          setOrderByAdminList(
            orderByAdminList.filter((each) => each !== "Moderate")
          );
        } else {
          setOrderByAdminList([...orderByAdminList, "Moderate"]);
        }
        setIAdminOpen(false);
      },
      checked: orderByAdminList.includes("Moderate"),
    },
    {
      id: 3,
      text: "High",
      onChange: () => {
        if (orderByAdminList.includes("High")) {
          setOrderByAdminList(
            orderByAdminList.filter((each) => each !== "High")
          );
        } else {
          setOrderByAdminList([...orderByAdminList, "High"]);
        }
        setIAdminOpen(false);
      },
      checked: orderByAdminList.includes("High"),
    },
  ];

  const statusFiltList = [
    // {
    //   id: 1,
    //   text: "Select all",
    //   onChange: () => {
    //     setOrderByStatusList(
    //         orderByStatusList.length > 0 ? [] : ["all", "yes", "no"]
    //     );
    //     setIsStatusOpen(false)
    //   },
    //   checked: orderByStatusList.length === 3 ? true : orderByStatusList.includes("yes") && orderByStatusList.includes("no")? true:false,
    // },
    {
      id: 2,
      text: "Active",
      onChange: () => {
        if (orderByStatusList.includes("yes")) {
          setOrderByStatusList(
            orderByStatusList.filter((each) => each !== "yes")
          );
        } else {
          setOrderByStatusList([...orderByStatusList, "yes"]);
        }
        setIsStatusOpen(false);
      },
      checked: orderByStatusList.includes("yes"),
    },
    {
      id: 3,
      text: "InActive",
      onChange: () => {
        if (orderByStatusList.includes("no")) {
          setOrderByStatusList(
            orderByStatusList.filter((each) => each !== "no")
          );
        } else {
          setOrderByStatusList([...orderByStatusList, "no"]);
        }
        setIsStatusOpen(false);
      },
      checked: orderByStatusList.includes("no"),
    },
  ];

  return (
    <TableHead
      sx={{
        height: "45px",
        // boxShadow: "0px 4px 10px 0px #27998975",
        borderRadius: "15px",
      }}
    >
      <TableRow>
        <TableCell
          padding="checkbox"
          sx={{
            backgroundColor: "#279989",
            color: "#FFF",
            borderTopLeftRadius: "5px",
            "&:first-of-type": {
              borderTopLeftRadius: "5px",
            },
            "&:last-of-type": {
              borderTopRightRadius: "5px",
            },
          }}
        >
          <StyledCheckbox onChange={(e) => toggleAllIDs(e.target.checked)} />
        </TableCell>

        <TableCell sx={styles.tableCell}>
          <Typography variant="h6" sx={styles.paragraph}>
            Name{" "}
            <span style={{ opacity: orderByName === null ? 0.5 : 1 }}>
              <IMG
                onClick={() => {
                  if (orderByName === null) {
                    setOrderByName("asc");
                  } else if (orderByName === "asc") {
                    setOrderByName("dec");
                  } else {
                    setOrderByName(null);
                  }
                }}
                isDec={
                  orderByName === null
                    ? false
                    : orderByName === "asc"
                    ? false
                    : true
                }
                imgUrl={ascDescIcon}
              />
            </span>
          </Typography>
        </TableCell>

        <TableCell sx={{ ...styles.tableCell, textAlign: "center" }}>
          <Typography variant="h6" sx={styles.paragraph}>
            Contact
          </Typography>
        </TableCell>

        <TableCell sx={{ ...styles.tableCell, textAlign: "center" }}>
          <Typography variant="h6" sx={styles.paragraph}>
            Postcode
          </Typography>
        </TableCell>

        <TableCell sx={{ ...styles.tableCell, textAlign: "center" }}>
          <Typography variant="h6" sx={styles.paragraph}>
            Key User
          </Typography>
        </TableCell>

        <TableCell sx={{ ...styles.tableCell, textAlign: "center" }}>
          <Typography variant="h6" sx={styles.paragraph}>
            Risk Level
            <IMG
              onClick={() => setIAdminOpen(!isAdminOpen)}
              isDec={false}
              imgUrl={incrementIcon}
            />
            {isAdminOpen === true && (
              <TablePopup
                items={adminFiltList}
                onClose={() => setIAdminOpen(false)}
              />
            )}
          </Typography>
        </TableCell>

        <TableCell sx={{ ...styles.tableCell, textAlign: "center" }}>
          <Typography variant="h6" sx={styles.paragraph}>
            Action
          </Typography>
        </TableCell>
      </TableRow>
    </TableHead>
  );
}
