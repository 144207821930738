import React from "react"; // , { useState }
import {
  Grid,
  Button,
  Typography,
  Box,
  // Container,
  // Card,
  // Checkbox,
  // FormControlLabel,
  // FormLabel,
  // RadioGroup,
  // Radio,
  // TextField,
} from "@mui/material";
import User from "../../../assets/images/users/User.svg";
import { ViewTheme } from "../Theme";
import EditIcon from "@mui/icons-material/Edit";

export const UserHeader = ({ toggleEdit, head, mode }) => {
  return (
    <Box
      sx={{
        backgroundColor: "white",
        padding: 0,
        marginTop: 1.5,
        Width: "100%",
      }}
    >
      <Grid
        container
        direction="row"
        alignItems="Top"
        sx={{ paddingLeft: "15px" }}
      >
        <Grid item xs={10}>
          <Box
            textAlign="left"
            sx={{
              display: "flex",
              alignItems: "center",
              Width: "100%",
              padding: { xs: ViewTheme.spacing(0, 4) },
            }}
          >
            <img
              style={{
                height: "30%",
                width: "auto",
              }}
              src={User}
              alt="User"
            />
            <Typography variant="h2">{head}</Typography>
          </Box>
        </Grid>
        <>
          <Grid item xs={2}>
            <Box
              textAlign="right"
              sx={{ Width: "100%", padding: 0, margin: 0 }}
            >
              {mode === "View" && (
                <Button
                  variant="outlined"
                  color="secondary"
                  startIcon={<EditIcon />}
                  onClick={toggleEdit}
                  sx={{ margin: 0, height: "35px" }}
                >
                  Edit
                </Button>
              )}
            </Box>
          </Grid>
        </>
      </Grid>
    </Box>
  );
};
