import React, { useState, useEffect, useRef } from "react";
import { getCookie } from "../../../utils/utils";
import {
  Grid,
  Box,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
  FormLabel,
  Divider,
} from "@mui/material";
import { toast } from "react-toastify";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import CancelIcon from "@mui/icons-material/Cancel";
import { Calendar, Sessions, Days } from "../../reusableComponents";
import dayjs from "dayjs";
import ToDo_List_1 from "../../../assets/images/clients/todoList_Icon/ToDo_List_1.gif";
import ToDo_DownArrow_Icon from "../../../assets/images/clients/todoList_Icon/ToDo_DownArrow_Icon.svg";
import ToDo_UpArrow_Icon from "../../../assets/images/clients/todoList_Icon/ToDo_UpArrow_Icon.svg";
import ToDo_Add_Icon from "../../../assets/images/clients/todoList_Icon/ToDo_Add_Icon.svg";
import Todo_Normal_Add_Icon from "../../../assets/images/clients/todoList_Icon/Todo_Normal_Add_Icon.svg";
// import ToDo_Search_Icon from "../../../assets/images/clients/todoList_Icon/ToDo_Search_Icon.svg";
import TodoEdit_icon from "../../../assets/images/clients/todoList_Icon/TodoEdit_icon.svg";
import Todo_Right_Tic_Icon from "../../../assets/images/clients/todoList_Icon/Todo_Right_Tic_Icon.svg";
import Todo_Close_Icon from "../../../assets/images/clients/todoList_Icon/Todo_Close_Icon.svg";

const CreateTodoForm = ({ togoleCreateTodoPopup, clientId }) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const handleCheck = (task, e, todoId, todoName) => {
    let isChecked;
    if (e === true) {
      isChecked = e;
    } else {
      isChecked = e?.target?.checked;
    }

    const todoData = { ...task, todo_name_id: todoId, todo_name: todoName };
    if (isChecked) {
      setSelectedItems((prevSelectedItems) => [...prevSelectedItems, todoData]);
    } else {
      setSelectedItems((prevSelectedItems) =>
        prevSelectedItems.filter((item) => item.id !== task.id)
      );
    }
  };

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        top: 0,
        left: 0,
        position: "fixed",
        display: "flex",
        // background: "blue",
        background: "rgba(192, 198, 201, 0.3)",
        zIndex: 9,
        padding: "20px",
      }}
    >
      <Grid
        container
        spacing={2}
        sx={{
          padding: "20px",
          background: "#fff",
          borderRadius: "20px",
          margin: "auto",
          width: "95%",
          height: "90vh",
          overflow: "auto",
          //   height:"100%",
        }}
      >
        {/* <Grid item md={4.75}> */}
        <LeftSectionTodo
          handleCheck={handleCheck}
          selectedItems={selectedItems}
        />
        <Grid
          item
          md={0.5}
          xs={false}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <Divider
            orientation="vertical"
            flexItem
            sx={{
              borderRightWidth: 2,
              //  borderColor: "rgba(39, 153, 137, 1)",
              borderColor: "#279989",
            }}
          />
        </Grid>

        {/* <Grid item md={6.75}> */}
        <RightSectionTodo
          clientId={clientId}
          handleCheck={handleCheck}
          togoleCreateTodoPopup={togoleCreateTodoPopup}
          selectedTodos={selectedItems}
        />
        {/* </Grid> */}
      </Grid>
    </Box>
  );
};

export default CreateTodoForm;

export const TitleComponent = ({ imgUrl, text }) => {
  return (
    <div>
      <img alt="prefdefined" src={imgUrl} style={{ width: "60px" }} />
      <Typography
        sx={{
          color: "#1E3037",
          fontFamily: "Arvo",
          fontSize: "18px",
          fontWeight: 400,
          margin: "10px 0px",
        }}
      >
        {text}
      </Typography>
    </div>
  );
};
export const ICons = ({ imgUrl }) => {
  return (
    <Box sx={{ width: "16px" }}>
      <img alt="Icons" src={imgUrl} />
    </Box>
  );
};

export const TodoItem = ({
  label,
  postCustom,
  setActiveAddSection,
  activeAddSection,
  setFilteredTodos,
  filteredTodos,
}) => {
  const [value, setValue] = useState("");

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleRecordSubmit = () => {
    if (value.trim()) {
      // Ensure the value is not empty
      postCustom(value); // Call postCustom to send the data
      setValue(""); // Clear the input field after submission
    } else {
      toast.error("Please enter a description.");
    }
  };

  const handleRecordClose = () => {
    // Your logic for handling the close action
    setActiveAddSection(null);
  };

  return (
    <Grid item xs={12}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", flex: 1, gap: 1 }}>
          {/* <Checkbox /> */}

          <TextField
            variant="standard"
            placeholder="Enter New Description "
            value={value}
            onChange={handleChange}
            fullWidth
            InputProps={{
              disableUnderline: true, // Disable underline for minimal design
            }}
            sx={{
              input: {
                padding: "4px 0", // Adjust padding inside the input field
                fontSize: "16px", // Adjust font size to match your design
                color: "#757575", // Text color matching your design
              },
            }}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            marginLeft: "10px",
          }}
        >
          <Typography
            variant="body2"
            sx={{ padding: "2px", cursor: "pointer" }}
            onClick={handleRecordSubmit}
          >
            <ICons imgUrl={Todo_Right_Tic_Icon} />
          </Typography>
          <Typography
            variant="body2"
            sx={{ padding: "2px", marginLeft: "10px", cursor: "pointer" }}
            onClick={handleRecordClose} // Add the onClick event handler here
          >
            <ICons imgUrl={Todo_Close_Icon} />
          </Typography>
        </Box>
      </Box>
    </Grid>
  );
};

export const TodoItemEdit = ({
  label,
  postCustom,
  setEditInputField,
  activeAddSection,
  setFilteredTodos,
  filteredTodos,
  todoValue = "",
  todoMainIdName = "",
}) => {
  const [value, setValue] = useState(todoValue);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleRecordSubmit = () => {
    if (value.trim()) {
      // Ensure the value is not empty
      postCustom(activeAddSection, value, todoMainIdName); // Call postCustom to send the data
      setValue(""); // Clear the input field after submission
    } else {
      toast.error("Please enter a description.");
    }
  };

  const handleRecordClose = () => {
    // Your logic for handling the close action
    setEditInputField(null);
  };

  return (
    <Grid item xs={12}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          borderBottom: "1.5px solid rgba(39, 153, 137, 0.30)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flex: 1,
            gap: 1,
          }}
        >
          <TextField
            variant="standard"
            placeholder="Enter New Description "
            value={value}
            onChange={handleChange}
            fullWidth
            InputProps={{
              disableUnderline: true, // Disable underline for minimal design
            }}
            sx={{
              input: {
                padding: "5px 20px", // Adjust padding inside the input field
                fontSize: "14px", // Adjust font size to match your design
                color: "#48464D", // Text color matching your design
                fontWeight: "400",
                fontFamily: "Lora",
              },
            }}
          />
        </Box>
        <br />

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            marginLeft: "10px",
          }}
        >
          <Typography
            variant="body2"
            sx={{ padding: "2px", cursor: "pointer" }}
            onClick={handleRecordSubmit}
          >
            <ICons imgUrl={Todo_Right_Tic_Icon} />
          </Typography>
          <Typography
            variant="body2"
            sx={{ padding: "2px", marginLeft: "10px", cursor: "pointer" }}
            onClick={handleRecordClose} // Add the onClick event handler here
          >
            <ICons imgUrl={Todo_Close_Icon} />
          </Typography>
        </Box>
      </Box>
    </Grid>
  );
};

// Custom debounce hook
export function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler); // Cleanup function to prevent memory leaks
    };
  }, [value, delay]);

  return debouncedValue;
}

export const LeftSectionTodo = ({ handleCheck, selectedItems }) => {
  // const [predefinedTodos, setPredefinedTodos] = useState([]);
  const [openIndex, setOpenIndex] = useState(null); // Manage open state for individual dropdowns

  // Debounced search term to prevent frequent filtering
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 100); // 500ms delay
  const [todoSearchList, setTodoSearchList] = useState([]);
  const [filteredTodos, setFilteredTodos] = useState(todoSearchList);

  const [innerSearchTerms, setInnerSearchTerms] = useState({}); // Store individual inner search terms

  const fetchSessions = async () => {
    try {
      const token = getCookie();

      const BASE_URL = "https://3.8.158.142:8443/api";
      const URL = `${BASE_URL}/todo-get-all-list`;
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await fetch(URL, options);
      const data = await response.json();

      if (response.ok) {
        // setPredefinedTodos(data.data);
        setTodoSearchList(data.data);
      } else {
        console.log("get all failed");
        setTodoSearchList([]);
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  useEffect(() => {
    fetchSessions();
  }, []);

  // Filter todos when the debounced search term changes
  useEffect(() => {
    const getFilteredTodoSearchList = (todoSearchList) => {
      return todoSearchList
        .map((todoCategory) => {
          const filteredDetails = todoCategory.details.filter((todo) =>
            todo.todo_description
              .toLowerCase()
              .includes(debouncedSearchTerm.toLowerCase())
          );

          // Only include categories that have matching todos
          if (filteredDetails.length > 0) {
            return {
              ...todoCategory,
              details: filteredDetails,
            };
          }

          // Return null for categories with no matching todos
          return null;
        })
        .filter(Boolean); // Remove null entries
    };
    const filteredResults = getFilteredTodoSearchList(todoSearchList);
    setFilteredTodos(filteredResults);
    console.log("filteredResults ...:", filteredResults);
  }, [debouncedSearchTerm, todoSearchList]);

  // Inner search filtering logic for individual sections
  const handleInnerSearch = (id, value) => {
    setInnerSearchTerms((prev) => ({ ...prev, [id]: value.toLowerCase() }));

    const updatedTodos = todoSearchList.map((category) => {
      if (category.todo_name_id === id) {
        const filteredDetails = category.details.filter((detail) =>
          detail.todo_description.toLowerCase().includes(value.toLowerCase())
        );
        return { ...category, details: filteredDetails };
      }
      return category;
    });

    setFilteredTodos(updatedTodos);
  };

  const handleAccordionToggle = (index) => {
    // Toggle accordion open/close based on index
    setOpenIndex(openIndex === index ? null : index);
  };

  const TodoAddItemAPI = async (id, description) => {
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/todo-list`;
      const body = {
        to_do_name: [
          {
            todo_name_id: id,
            todo_description: description,
          },
        ],
      };
      const token = getCookie();
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      };
      const response = await fetch(url, options);
      const data = await response.json();
      if (response.ok && data.statusCode === 200) {
        // Show success message or toast notification here
        toast.success("Todo item added successfully!");
        const newData = data?.data?.[0];
        let newfilteredTodos = [...filteredTodos];
        newfilteredTodos[activeAddSection - 1].details.push({
          id: newData?.[0]?.id,
          todo_description: newData?.[0]?.todo_description,
          status: newData?.[0]?.status,
        });
        setFilteredTodos(newfilteredTodos);
        handleCheck(
          newData?.[0],
          true,
          newData?.[0]?.todo_name_id,
          filteredTodos[activeAddSection - 1].todo_name
        );

        // Optionally, you can update your local state here if needed
        console.log("Todo item added successfully.");
      }
    } catch (error) {
      console.error("An error occurred:", error);
      toast.error("An error occurred while adding the Todo.");
    }
  };

  const TodoAddItemEditAPI = async (id, description, todoMainIdName) => {
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/todo-list/${id}`;

      const body = {
        todo_description: description,
      };
      const token = getCookie();
      const options = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      };
      const response = await fetch(url, options);
      const data = await response.json();
      handleCheck(data.data, false, data.data?.todo_name_id, todoMainIdName);
      if (response.ok && data.statusCode === 200) {
        let newfilteredTodos = [...filteredTodos];
        // / Update 'todo_description' only where 'id' matches the given ID
        const updatedTodos = newfilteredTodos.map((todo) => ({
          ...todo,
          details: todo.details.map((detail) =>
            detail.id === id
              ? { ...detail, todo_description: description }
              : detail
          ),
        }));
        setFilteredTodos(updatedTodos);
        handleCheck(data.data, true, data.data?.todo_name_id, todoMainIdName);
        // Show success message or toast notification here
        toast.success("Todo item updated  successfully!");
      }
      if (response.ok === false && data.statusCode === 409) {
        toast.error(`Todo description already exists.`);
      }
    } catch (error) {
      console.error("An error occurred updating ......:", error);
      toast.error("An error occurred while updating the Todo.");
    }
  };

  const [activeAddSection, setActiveAddSection] = useState(null); // Track the section that is open for adding
  const [editInputField, setEditInputField] = useState(null);

  const handleTodoShowRecord = (todo_name_id) => {
    setActiveAddSection((prev) =>
      prev === todo_name_id ? null : todo_name_id
    ); // Toggle add input for the clicked section
  };

  const postCustom = (newDescription) => {
    TodoAddItemAPI(activeAddSection, newDescription); // Pass the correct ID

    setActiveAddSection(null); // Close the add input after submission
  };

  const postCustomEdit = (id, newDescription, todoMainIdName) => {
    // console.log("Edit Value: ", id, newDescription);
    TodoAddItemEditAPI(id, newDescription, todoMainIdName); // Pass the correct ID
    setEditInputField(null); // Close the add input after submission
  };

  return (
    <Grid item xs={4.75}>
      <TitleComponent imgUrl={ToDo_List_1} text=" Predefined To-Do's" />

      {/* Outer Search Input */}
      <Grid item xs={12}>
        <Typography
          sx={{
            color: "#1E3037",
            fontFamily: "Arvo",
            fontSize: "14px",
            fontWeight: 400,
            padding: "0px",
            textAlign: "left",
          }}
        >
          Predefined To-Do’s
        </Typography>
        <TextField
          onChange={(e) => {
            // lowering the text value
            let value = e.target.value;
            setSearchTerm(value !== undefined ? value.toLowerCase() : "");
          }}
          variant="outlined"
          fullWidth
          placeholder="Search..."
          sx={{
            height: "40px", // Set desired height
            "& .MuiInputBase-root": {
              height: "100%", // Ensures the input field expands to the parent container's height
              //   padding: "0 14px", // Adjust the padding as needed
            },
          }}
        />
      </Grid>
      <Box
        sx={{
          width: "100%",
          height: "450px",
          overflowY: "auto", // Enables vertical scrolling
          paddingRight: "5px",
          borderRadius: "8px",
        }}
      >
        {/* Predefined To-Do's List */}
        {filteredTodos.map((todo, index) => (
          <Grid item xs={12} key={index}>
            <Box>
              {/* Accordian Header */}
              <Box
                style={{
                  margin: "4px 0px",
                  borderRadius: "5px",
                  padding: "6px",
                  border: "1.5px solid rgba(39, 153, 137, 0.30)",
                  background: "#FFF",
                  boxShadow: "none !important",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => handleAccordionToggle(index)} // Only toggle when header is clicked
              >
                <Typography
                  // variant="body2"
                  sx={{
                    padding: 0,
                    flex: 1,
                    textAlign: "left",
                    color: "#707171",
                    fontFamily: "Lora",
                    fontWeight: "600",
                    fontSize: "14px",
                  }}
                >
                  {/* {todo.category} */}
                  {todo.todo_name}
                </Typography>
                <Typography variant="body2" sx={{ padding: "2px" }}>
                  {openIndex === index ? (
                    <ICons imgUrl={ToDo_UpArrow_Icon} />
                  ) : (
                    <ICons imgUrl={ToDo_DownArrow_Icon} />
                  )}
                </Typography>
              </Box>

              {/* Accordion Content */}
              {openIndex === index && (
                <Box
                  style={{
                    borderRadius: "5px",
                    border: "1.5px solid rgba(39, 153, 137, 0.30)",
                  }}
                >
                  {/* Inner Search Bar */}
                  {/* Search bar within each section */}
                  <div
                    style={{
                      borderRadius: "5px 5px 0px 0px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      padding: "0px 6px",
                      background: "#E8F9F6",
                    }}
                  >
                    <TextField
                      onChange={(e) =>
                        handleInnerSearch(todo.todo_name_id, e.target.value)
                      }
                      size="small"
                      placeholder={`Search for ${todo.todo_name}`}
                      sx={{
                        width: "100%",
                        margin: "6px",
                        "& .MuiOutlinedInput-root": {
                          paddingTop: "5px",
                          paddingBottom: "5px",
                          background: "none", // Set background to none
                          border: "none", // Optional: Remove border if needed
                          "& fieldset": {
                            border: "none", // Remove the fieldset border
                          },
                        },
                        "& .MuiInputBase-input": {
                          marginLeft: "-5px", // Fixed typo: 'MarginLeft' to 'marginLeft'
                          paddingLeft: 0,
                          background: "none", // Set input background to none
                        },
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon
                              sx={{
                                color: "#279989",
                                width: "18px",
                                marginLeft: "8px",
                                padding: 0,
                              }}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />

                    {/* Add New Todo Icon */}
                    <Typography
                      variant="body2"
                      sx={{ padding: "2px 2px 4px 6px", cursor: "pointer" }}
                      onClick={() => handleTodoShowRecord(todo.todo_name_id)}
                    >
                      <ICons imgUrl={ToDo_Add_Icon} />
                    </Typography>
                  </div>

                  {/* Task List */}
                  <div style={{ padding: "0px 10px" }}>
                    {activeAddSection === todo.todo_name_id && (
                      <TodoItem
                        label=""
                        postCustom={postCustom}
                        activeAddSection={activeAddSection}
                        setActiveAddSection={setActiveAddSection}
                        filteredTodos={filteredTodos}
                        setFilteredTodos={setFilteredTodos}
                      />
                    )}
                    {todo.details.map((task, id) => {
                      return (
                        <div>
                          {editInputField === task.id ? (
                            <>
                              <TodoItemEdit
                                label=""
                                postCustom={postCustomEdit}
                                activeAddSection={task.id}
                                setEditInputField={setEditInputField}
                                filteredTodos={filteredTodos}
                                setFilteredTodos={setFilteredTodos}
                                todoValue={task.todo_description}
                                todoMainIdName={todo.todo_name}
                              />
                            </>
                          ) : (
                            <div
                              key={task.id}
                              style={{
                                borderBottom:
                                  "1.5px solid rgba(39, 153, 137, 0.30)",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Box
                                sx={{
                                  width: "90%",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Checkbox
                                  checked={selectedItems.some((item) => {
                                    return item.id === task.id;
                                  })} // Check if the task is selected based on its id
                                  onChange={(e) =>
                                    handleCheck(
                                      task,
                                      e,
                                      todo?.todo_name_id,
                                      todo?.todo_name
                                    )
                                  } // Call handleCheck on change
                                />
                                <Typography
                                  variant="body2"
                                  style={{ textAlign: "left" }}
                                >
                                  {task.todo_description}
                                </Typography>
                              </Box>

                              <Box
                                sx={{
                                  width: "10%",
                                  // background:"gray",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "left",
                                }}
                              >
                                {task.status === "userdefined" && (
                                  <Typography
                                    variant="body2"
                                    sx={{
                                      padding: "2px",
                                      cursor: "pointer",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "left",
                                    }}
                                    onClick={() => setEditInputField(task.id)}
                                  >
                                    <ICons imgUrl={TodoEdit_icon} />
                                  </Typography>
                                )}
                              </Box>
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </Box>
              )}
            </Box>
          </Grid>
        ))}
      </Box>
    </Grid>
  );
};

export const CancelIconWithHover = ({ currentTodo, handleCheck }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <CancelIcon
      style={{
        position: "absolute",
        top: 2,
        right: 2,
        height: "12px",
        width: "12px",
        color: isHovered ? "#279989" : "#969999", // Change color on hover
        cursor: "pointer", // Add pointer cursor for better UX
      }}
      onClick={(e) => handleCheck(currentTodo, e)}
      onMouseEnter={() => setIsHovered(true)} // Set hover state to true on mouse enter
      onMouseLeave={() => setIsHovered(false)} // Set hover state to false on mouse leave
    />
  );
};

export const RightSectionTodo = ({
  togoleCreateTodoPopup,
  selectedTodos,
  handleCheck,
  clientId,
}) => {
  const initialTodoInfo = {
    client_id: parseInt(clientId),
    //visit_id: 218,
    start_date: "",
    end_date: "",
    select_days: [2, 4],
    choose_sessions: [],
    to_do_name: [],
    additional_notes: "",
    essential: 0,
  };

  const [todoInfo, setTodoInfo] = useState(initialTodoInfo);
  const [todoSelectError, setTodoSelectError] = useState(false);
  // const [todoInfoOriginal, setTodoInfoOriginal] = useState(initialTodoInfo);

  const [checkThisInc, setCheckThisInc] = useState(false);

  const start_dateRef = useRef();
  const end_dateRef = useRef();
  const select_daysRef = useRef();
  const choose_sessionsRef = useRef();
  const to_do_nameRef = useRef();
  const additional_notesRef = useRef();
  const essentialRef = useRef();

  const checkHandleChange = (event) => {
    setCheckThisInc(event.target.checked);
  };
  const essentialvalue = checkThisInc ? 1 : 0;

  const handleDaysSelect = (selectedDays) => {
    setTodoInfo((prevFormData) => {
      const updatedForm = { ...prevFormData, select_days: selectedDays };
      // setHasUnsavedChanges(!areObjectsEqual(updatedForm, payrollInfoOriginal)); // Detect changes
      return updatedForm;
    });
  };

  // Callback to handle session selection
  const handleSessionSelect = (selectedSessions) => {
    const sessionIds = selectedSessions.map((item) => item.id);

    setTodoInfo((prevFormData) => {
      const updatedForm = {
        ...prevFormData,
        choose_sessions: [...sessionIds],
      };
      // setHasUnsavedChanges(!areObjectsEqual(updatedForm, payrollInfoOriginal)); // Detect changes
      return updatedForm;
    });
  };
  const currentDate = dayjs().startOf("day");

  const handleDateChange = (date, name) => {
    setTodoInfo((prevFormData) => {
      const updatedForm = { ...prevFormData, [name]: date };
      // setHasUnsavedChanges(!areObjectsEqual(updatedForm, payrollInfoOriginal)); // Detect changes
      return updatedForm;
    });
  };
  const handleAdditionalNote = (event) => {
    setTodoInfo((prevFormData) => {
      const updatedForm = {
        ...prevFormData,
        additional_notes: event.target.value,
      };
      // setHasUnsavedChanges(!areObjectsEqual(updatedForm, payrollInfoOriginal)); // Detect changes
      return updatedForm;
    });
  };

  const handleSubmit = () => {
    let isValid = true;

    if (start_dateRef.current && !start_dateRef.current.handleValidation()) {
      isValid = false;
    }

    if (end_dateRef.current && !end_dateRef.current.handleValidation()) {
      isValid = false;
    }

    if (select_daysRef.current && !select_daysRef.current.handleValidation()) {
      isValid = false;
    }

    if (
      choose_sessionsRef.current &&
      !choose_sessionsRef.current.handleValidation()
    ) {
      isValid = false;
    }

    if (to_do_nameRef.current && !to_do_nameRef.current.handleValidation()) {
      isValid = false;
    }

    if (
      additional_notesRef.current &&
      !additional_notesRef.current.handleValidation()
    ) {
      isValid = false;
    }

    if (essentialRef.current && !essentialRef.current.handleValidation()) {
      isValid = false;
    }

    if (selectedTodos.length === 0) {
      isValid = false;
      setTodoSelectError(true);
    } else {
      setTodoSelectError(false);
    }

    if (isValid) {
      // setHasUnsavedChanges(false); // Reset unsaved state
      // Add your form submission logic here
      TodoPostApi();
      //toggleEdit();
    } else {
      console.log("Form is invalid. Please correct the errors and try again.");
    }
  };

  const TodoPostApi = async () => {
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/todo`;
      const body = {
        ...todoInfo,
        to_do_name: selectedTodos.map((eachItem) => ({
          id: eachItem.id,
          todo_name: eachItem.todo_name,
          todo_name_id: eachItem.todo_name_id,
          status: eachItem.status,
        })),
        essential: essentialvalue, // 0
      };
      const token = getCookie();
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      };
      const response = await fetch(url, options);
      const data = await response.json();
      if (response.ok && data.statusCode === 200) {
        togoleCreateTodoPopup();
      } else {
        console.log(
          "Failed to upload client Todo details.",
          data.message || "Unknown error"
        );
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  return (
    <Grid item xs={6.75}>
      <TitleComponent imgUrl={ToDo_List_1} text="Added To-Do’s" />
      <Grid
        container
        spacing={2}
        sx={{
          //  padding: "20px",
          textAlign: "left",
        }}
      >
        {/* Search Input */}
        <Grid item xs={12}>
          <Typography
            sx={{
              color: "#1E3037",
              fontFamily: "Arvo",
              fontSize: "14px",
              fontWeight: 400,
              padding: "0px",
              textAlign: "left",
            }}
          >
            Selected To-Do
          </Typography>

          <Box
            sx={{
              border: "1.5px solid #bee0db", // border color
              borderRadius: "10px",
              display: "flex",
              flexWrap: "wrap",
              padding: "4px",
              gap: "4px",
              minHeight: "40px",
              marginTop: "10px",
              position: "relative",
              alignItems: selectedTodos.length === 0 ? "flex-start" : " ", // Center vertically if empty
              justifyContent: selectedTodos.length === 0 ? "flex-start" : "", // Center horizontally if empty
            }}
          >
            {selectedTodos.length > 0 ? (
              selectedTodos.map((todo) => (
                <Box
                  key={todo.id}
                  style={{
                    borderRadius: "8px",
                    background: "#D7F9F4",
                    color: "#000000",
                    fontSize: "10px",
                    fontWeight: 400,
                    fontFamily: "Lora",
                    padding: "6px",
                    // padding: "8px 8px 2px",
                    paddingRight: "12px",
                    width: "fit-content",
                    position: "relative",
                    overflow: "hidden",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {todo.todo_description}
                  <CancelIconWithHover
                    currentTodo={todo}
                    handleCheck={handleCheck}
                  />
                </Box>
              ))
            ) : (
              <Typography
                sx={{
                  color: "#ccc", // Placeholder color
                  fontSize: "14px",
                  padding: "6px 8px 2px",
                  textAlign: "center",
                }}
              >
                Selected To-Do
              </Typography>
            )}
          </Box>
        </Grid>
        <Grid item xs={12}>
          {todoSelectError && selectedTodos.length < 1 && (
            <Typography
              component="p"
              sx={{
                textAlign: "left",
                margin: 0,
                fontFamily: "Lora",
                fontSize: "10px",
                color: "red",
                marginTop: "-10px",
                marginLeft: "-20px",
                marginBottom: "0px",
                display: "block",
                alignItems: "left",
              }}
            >
              At least one Selected To-Do
            </Typography>
          )}
        </Grid>

        {/* Days of the week selection */}
        <Grid item xs={12} md={5.75}>
          <Days
            // ref={daysRef} // Pass the ref to the Days component
            ref={select_daysRef} // Pass the ref to the Days component
            onDaysChange={handleDaysSelect} // Callback for day selection
            required={true} // Set to true if at least one day selection is required
          />
        </Grid>

        <Grid item md={0.5} xs={false} />
        {/* Sessions selection */}
        <Grid item xs={12} md={5.75}>
          <Grid item xs={12}>
            <Sessions
              ref={choose_sessionsRef} // Pass the ref to the Sessions component
              onSessionSelect={handleSessionSelect} // Callback for session selection
              required={true} // Set to true if at least one session is required
            />
          </Grid>
        </Grid>

        <Grid item xs={12} md={5.75}>
          <Calendar
            ref={start_dateRef}
            label="Start Date"
            onDateChange={handleDateChange}
            required
            preFilledvalue={todoInfo.start_date}
            name="start_date"
            minDate={currentDate}
          />
        </Grid>
        <Grid item md={0.5} xs={false} />
        <Grid item xs={12} md={5.75}>
          <Calendar
            ref={end_dateRef}
            label="End Date"
            onDateChange={handleDateChange}
            preFilledvalue={todoInfo.end_date}
            name="end_date"
            minDate={todoInfo.start_date ? todoInfo.start_date : currentDate}
          />
        </Grid>

        {/* Additional Notes */}
        <Grid item xs={12}>
          <FormLabel>
            <Typography
              variant="h6"
              sx={{
                padding: 0,
                fontFamily: "lora",
              }}
            >
              Additional Notes
            </Typography>
          </FormLabel>
          <TextField
            name="additional_notes"
            variant="outlined"
            className="multiline-textfield"
            multiline
            fullWidth
            rows={4}
            placeholder="Additional Notes"
            onChange={handleAdditionalNote}
          ></TextField>
        </Grid>

        {/* Alert Checkbox */}
        <Grid item xs={12} sx={{ textAlign: "left" }}>
          <FormControlLabel
            control={
              <Checkbox checked={checkThisInc} onChange={checkHandleChange} />
            }
            label="Check this to get an alert if the Todo is not completed."
          />
        </Grid>

        <Grid item xs={12} sx={{ mt: 4 }}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              // background: "red",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              variant="customcancel"
              color="primary"
              onClick={() => {
                togoleCreateTodoPopup();
              }}
            >
              Cancel
            </Button>
            <Button variant="customsave" color="primary" onClick={handleSubmit}>
              Save
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};
