import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Grid,
  Container,
  FormLabel,
  Typography,
  ThemeProvider,
  TextField,
  Button,
} from "@mui/material";
import { ViewTheme, EditTheme } from "../../../themes/Theme";
import Time from "../../reusableComponents/Time";
import dayjs from "dayjs";
import { getCookie } from "../../../utils/utils";
import Name from "../../reusableComponents/Name";

export default function LogVisitDetails({ selectedVisit = {} }) {
  const [isEditMode, setIsEditMode] = useState(false);
  const [visitDetails, setVisitDetails] = useState();
  const [error, setError] = useState(null);
  const [form, setForm] = useState({
    comments: "",
    actualStartTime: "",
    actualEndTime: "",
  });

  const fetchVisitDetails = async () => {
    try {
      const token = getCookie();
      const URL = `${process.env.REACT_APP_BASE_URL}/log-get-visit-details/${selectedVisit.visitdetailsid}`;
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await fetch(URL, options);
      const data = await response.json();

      if (response.ok) {
        const info = data.data[0];
        setVisitDetails(info); // Store the fetched visit data in the state
      } else {
        setError("Failed to fetch visit data.");
        setVisitDetails(); // Clear the visit data if there's an error
      }
    } catch (error) {
      console.error("Error fetching visit data:", error);
      setError("An error occurred while fetching data.");
      setVisitDetails(); // Clear the visit data if there's an error
    }
  };

  useEffect(() => {
    if (selectedVisit?.visitdetailsid) {
      fetchVisitDetails();
    } else {
      setVisitDetails();
    }
  }, [selectedVisit?.visitdetailsid]);

  // Update form with visitDetails when fetched
  useEffect(() => {
    if (visitDetails) {
      setForm({
        comments: visitDetails.comments || "",
        actualStartTime: visitDetails.actualStartTime || "",
        actualEndTime: visitDetails.actualEndTime || "",
      });
    }
  }, [visitDetails]);

  // Safely extract times or default to null
  const plannedStartTime = visitDetails?.plannedStarttime
    ? dayjs(`${dayjs().format("YYYY-MM-DD")} ${visitDetails.plannedStarttime}`)
    : null;
  const plannedEndTime = visitDetails?.plannedEndtime
    ? dayjs(`${dayjs().format("YYYY-MM-DD")} ${visitDetails.plannedEndtime}`)
    : null;

  // Calculate time difference or default to
  const timeDifferenceInMinutes =
    plannedStartTime && plannedEndTime
      ? plannedEndTime.diff(plannedStartTime, "minute")
      : "";

  const FormRow = ({ label, value }) => {
    return (
      <Grid container direction="row" alignItems="center" spacing={2}>
        <Grid item xs={3} lg={2.5}>
          <FormLabel>
            <Typography variant="h7">{label}</Typography>
          </FormLabel>
        </Grid>
        <Grid item xs={0.5}>
          <FormLabel sx={{ textAlign: "center" }}>:</FormLabel>
        </Grid>
        <Grid
          item
          xs={8.5}
          lg={9}
          sx={{ marginBottom: isEditMode ? "-5px" : "5px" }}
        >
          <Typography variant={isEditMode ? "body8" : "body4"}>
            {value || ""} {/* Fallback to  if value is undefined */}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const statusCard = (value) => {
    return (
      <Box
        sx={{
          display: "inline-flex",
          alignItems: "center",
          backgroundColor: (() => {
            const stringValue = String(value); // Convert to string for reliable comparison
            return stringValue === "Completed"
              ? "#279989"
              : stringValue === "In Progress"
              ? "#56ACE0"
              : stringValue === "Action Required"
              ? "#FF8080"
              : stringValue === "Follow Up"
              ? "#CF75E6"
              : "transparent";
          })(),
          borderRadius: "5px",
          color: "white",
          width: "auto",
          justifyContent: "center",
          padding: "5px 10px",
        }}
      >
        {value || ""}
      </Box>
    );
  };

  const containerRef = useRef(null);
  const scrollToTop = () => {
    containerRef.current.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleEditClick = () => {
    setIsEditMode(true);
    scrollToTop();
  };

  const handleCancelClick = () => {
    setIsEditMode(false);
    scrollToTop();
  };

  const handleInputChange = (name, value) => {
    setForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };

  const commentCard = () => {
    return (
      <Name
        name="comments"
        nolabel
        multiline={true}
        preFilledValue={form.comments || ""}
        rows={4}
        className="multiline-textfield"
        placeholder="Add Comments"
        sx={{ minWidth: "100%" }}
        disabled={!isEditMode}
        onValueChange={(e) =>
          handleInputChange("comments", e?.target?.value || "")
        } // Ensure event target value is accessed correctly
      />
    );
  };

  return (
    <ThemeProvider theme={isEditMode ? EditTheme : ViewTheme}>
      <Container
        ref={containerRef}
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "97%",
          maxHeight: "calc(100vh - 340px)",
          margin: "15px 20px",
          overflow: "scroll",
        }}
        maxWidth={false}
      >
        {selectedVisit && (
          <Box
            sx={{
              width: { xxl: "50%", lg: "70%", xs: "98%" },
              textAlign: "left",
            }}
          >
            {!isEditMode && (
              <Box
                sx={{
                  position: "absolute",
                  right: "20px",
                  top: "30px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button variant="outlinedSecondary" onClick={handleEditClick}>
                  Edit
                </Button>
              </Box>
            )}
            <Grid container direction="row" alignItems="top" gap={2}>
              <FormRow label="Name" value={visitDetails?.clientName || ""} />
              <FormRow
                label="Visit Date"
                value={visitDetails?.visit_date || ""}
              />
              <FormRow
                label="Status"
                value={statusCard(visitDetails?.status || "")}
              />
              <Grid container direction="row" alignItems="center" spacing={2}>
                <Grid item xs={3} lg={2.5}>
                  <Typography variant="h7">Comments</Typography>
                </Grid>
                <Grid item xs={0.5}>
                  <FormLabel sx={{ textAlign: "center" }}>:</FormLabel>
                </Grid>
                <Grid
                  item
                  xs={8.5}
                  lg={9}
                  sx={{ marginBottom: isEditMode ? "-5px" : "5px" }}
                >
                  {commentCard()}
                </Grid>
              </Grid>
              <FormRow
                label="Planned Time"
                value={
                  plannedStartTime && plannedEndTime
                    ? `${plannedStartTime.format(
                        "HH:mm"
                      )} - ${plannedEndTime.format("HH:mm")}`
                    : ""
                }
              />
              {!isEditMode ? (
                <FormRow
                  label="Actual Time"
                  value={
                    visitDetails?.actualStartTime && visitDetails?.actualEndTime
                      ? `${visitDetails.actualStartTime} - ${visitDetails.actualEndTime}`
                      : ""
                  }
                />
              ) : (
                <>
                  <FormRow
                    label="Actual Start Time"
                    value={
                      <Time
                        initialHours={
                          visitDetails?.actualStartTime
                            ? visitDetails.actualStartTime.split(":")[0]
                            : "00"
                        }
                        initialMinutes={
                          visitDetails?.actualStartTime
                            ? visitDetails.actualStartTime.split(":")[1]
                            : "00"
                        }
                      />
                    }
                  />
                  <FormRow
                    label="Actual End Time"
                    value={
                      <Time
                        initialHours={
                          visitDetails?.actualEndTime
                            ? visitDetails.actualEndTime.split(":")[0]
                            : "00"
                        }
                        initialMinutes={
                          visitDetails?.actualEndTime
                            ? visitDetails.actualEndTime.split(":")[1]
                            : "00"
                        }
                      />
                    }
                  />
                </>
              )}
              <FormRow
                label="Spent Time"
                value={
                  timeDifferenceInMinutes !== ""
                    ? `${timeDifferenceInMinutes} min`
                    : ""
                }
              />
              <FormRow
                label="Address"
                value={visitDetails?.clientaddress || ""}
              />
              <FormRow
                label="Carers"
                value={
                  visitDetails?.userName?.length > 0
                    ? visitDetails.userName.join(", ")
                    : ""
                }
              />
              <FormRow label="Run Name" value={visitDetails?.runName || ""} />
              {isEditMode && (
                <Box sx={{ display: "flex", marginTop: "10px" }}>
                  <Button variant="customcancel" onClick={handleCancelClick}>
                    Cancel
                  </Button>
                  <Button variant="customsave">Save</Button>
                </Box>
              )}
            </Grid>
          </Box>
        )}
      </Container>
    </ThemeProvider>
  );
}
