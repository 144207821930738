import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from "react";
import { Box, FormLabel, Typography, TextField } from "@mui/material";
import validator from "validator";

const Email = forwardRef(
  (
    {
      onEmailValueChange,
      emailExistsError = "",
      required = false,
      preFilledvalue,
      disabled = false,
      name,
    },
    ref
  ) => {
    const [value, setValue] = useState(preFilledvalue || "");
    const [errorMessage, setErrorMessage] = useState("");
    useEffect(() => {
      if (preFilledvalue) {
        setValue(preFilledvalue);
        setErrorMessage("");
      } else {
        setValue("");
        setErrorMessage("");
      }
    }, [preFilledvalue]);
    useEffect(() => {
      if (required && emailExistsError) {
        setErrorMessage(emailExistsError);
      }
    }, [emailExistsError]);

    // Function to handle changes in the text field
    const handleChange = (event) => {
      if (disabled) return;
      const inputValue = event.target.value;
      setValue(inputValue);
      setErrorMessage(""); // Clear error message as soon as value changes
      onEmailValueChange(inputValue, event.target.name); //); // Notify parent component about value change
    };

    // Function to validate the email input
    const handleValidation = () => {
      const emailValue = String(value).trim();

      if (required && !emailValue) {
        setErrorMessage("Email Address is required.");
        return false;
      }

      // Simple email regex pattern for validation
      // const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      // if (!emailPattern.test(emailValue)) {
      //   setErrorMessage("Please enter a valid email address.");
      //   return false;
      // }

      // Use validator library to check if the email is valid
      if (emailValue && !validator.isEmail(emailValue)) {
        setErrorMessage("Please enter a valid Email Address.");
        return false;
      }

      setErrorMessage("");
      return true;
    };

    // Expose the validation function to the parent component
    useImperativeHandle(ref, () => ({
      handleValidation,
    }));

    return (
      <Box sx={{ textAlign: "left" }}>
        <FormLabel>
          <Typography variant="h6">
            Email Address
            {required && <span className="star">*</span>}
          </Typography>
        </FormLabel>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Email Address"
          value={value}
          onChange={handleChange}
          disabled={disabled}
          name={name}
        />
        {errorMessage && (
          <Typography variant="errmsg">{errorMessage}</Typography>
        )}
      </Box>
    );
  }
);

export default Email;
