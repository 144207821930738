import React from "react";
import { Grid, Card, Typography, Button } from "@mui/material";
import CustomButton from "../../../components/reusableComponents/CustomButton";
import { getCookie } from "../../../utils/utils";

const ShareAccessCard = ({ accessAvailed, clientId, handleRefresh }) => {
  const handleResend = async () => {
    if (!clientId) return;
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/access?access_id=${accessAvailed.id}`;
      const token = getCookie();
      const options = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await fetch(url, options);
      const data = await response.json();
      if (response.ok && data.statusCode === 200) {
        handleRefresh();
      } else {
        console.log("issue fetching");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const handleRevoke = async () => {
    if (!clientId) return;
    try {
      const token = getCookie();
      const URL = `${process.env.REACT_APP_BASE_URL}/access?client_id=${clientId}&id=${accessAvailed.id}`;
      const options = {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await fetch(URL, options);
      const data = await response.json();
      if (response.ok) {
        handleRefresh();
      } else {
        console.log("issue");
      }
    } catch (e) {
      console.error("Error fetching Share Access", e.message);
    }
  };

  const styles = {
    colomn: {
      fontSize: "16px",
      fontWeight: 505,
      fontFamily: "Arvo",
      color: "#279989",
    },
    data: { color: "#1E3037", fontWeight: 400 },
  };

  return (
    <Card
      sx={{
        maxWidth: 425,
        background: "#FFF",
        padding: "10px",
      }}
    >
      <Grid container direction="row">
        <Grid item xs={9.5} md={9.5}>
          {accessAvailed?.name !== "" ? (
            <Grid item xs={12} md={12} sx={{ display: "flex" }}>
              <Typography variant="h6" sx={styles.colomn}>
                Name:{" "}
                <span style={styles.data}>{accessAvailed?.name || ""}</span>
              </Typography>
            </Grid>
          ) : null}
          <Grid item xs={12} md={12} sx={{ display: "flex" }}>
            <Typography variant="h6" sx={styles.colomn}>
              Email ID:{" "}
              <span style={styles.data}>{accessAvailed?.email || ""}</span>
            </Typography>
          </Grid>
          <Grid item xs={12} md={12} sx={{ display: "flex" }}>
            <Typography variant="h6" sx={styles.colomn}>
              {accessAvailed?.status === "Active" ? (
                <>Active Since: </>
              ) : (
                <>Revoked at: </>
              )}
              <span style={styles.data}>
                {accessAvailed?.updated_at?.split("T")[0] || ""}
              </span>
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={2.5} md={2.5}>
          <CustomButton
            style={
              accessAvailed?.status === "Active"
                ? {
                    background: "#F1FFF3",
                    color: "#03AE71",
                    border: "1.5px solid #03AE71",
                    alignItems: "right",
                    cursor: "default", // Remove pointer cursor
                  }
                : {
                    background: "#FFF0E3",
                    color: "#FA8733",
                    border: "1.5px solid #F5891D",
                    cursor: "default", // Remove pointer cursor
                  }
            }
            text={accessAvailed?.status || ""}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <br />
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button
            sx={{ height: "33px", fontSize: "13px" }}
            onClick={() => handleResend()}
            type="submit"
            variant="outlinedSecondary"
            disabled={accessAvailed?.status === "Inactive"}
          >
            Resend Access
          </Button>
          <Button
            sx={{ height: "33px", fontSize: "13px" }}
            onClick={() => handleRevoke()}
            type="submit"
            variant="outlinedSecondary"
            disabled={accessAvailed?.status === "Inactive"}
          >
            Revoke Access
          </Button>
        </Grid>
      </Grid>
    </Card>
  );
};

export default ShareAccessCard;
