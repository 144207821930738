import React, { useEffect, useState, useRef } from "react";
import { Box, Typography, Grid } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import UserDriveCar from "../../assets/images/roster/UserDriveCar.svg";

export default function RosterClientCard({ clientDetails }) {
  const words = clientDetails.name.split(" ");
  const initials =
    words.length > 1
      ? `${words[0][0]}${words[words.length - 1][0]}` // First and last word initials
      : words[0][0]; // If only one word, use the first initial

  const [hovered, setHovered] = useState(false);

  return (
    <Box
      sx={{
        position: "relative", // Needed for absolute positioning of the hover box
        width: "100%",
        height: "60px",
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {/* Main Content Box */}
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          overflow: "hidden",
          boxSizing: "border-box", // Ensure padding is included in width calculations
        }}
      >
        {/* LeftBox */}
        <Box sx={{ margin: "0px" }}>
          <Avatar
            sx={{
              bgcolor: "#279989",
              color: "white",
              fontFamily: "Arvo",
              fontSize: "17px",
            }}
          >
            {initials}
          </Avatar>
        </Box>
        {/* RightBox */}
        <Box
          sx={{
            width: "100%",
            height: "100%",
            margin: "0px",
            alignItems: "left",
            textAlign: "left",
            padding: "0px 2px",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Lora",
              fontSize: "14px",
              height: "25px",
              overflow: "hidden", // Hides overflow text
              textOverflow: "ellipsis",
              padding: "0px",
            }}
          >
            {clientDetails.name}
          </Typography>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              paddingRight: "8px",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Lora",
                fontSize: "11px",
                padding: "0px",
                margin: "0px",
                paddingLeft: "3px",
              }}
            >
              Assigned <br /> Visits
            </Typography>
            <Avatar
              sx={{
                bgcolor: "#279989",
                color: "white",
                fontSize: "10px",
                width: "20px",
                height: "20px",
                fontFamily: "Arvo",
              }}
            >
              {clientDetails.visits}
            </Avatar>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
