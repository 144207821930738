import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from "react";
import {
  Box,
  FormLabel,
  Typography,
  TextField,
  Autocomplete,
} from "@mui/material";
import { getCookie } from "../../utils/utils";

const Country = forwardRef(
  ({ onCountrySelect, preFilledvalue, required = false }, ref) => {
    const [country, setCountry] = useState("");
    const [inputcountry, setInputCountry] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [originalCountries, setOriginalCountries] = useState([]);

    useEffect(() => {
      const fetchCountries = async () => {
        try {
          const token = getCookie();
          const BASE_URL = "https://3.8.158.142:8443/";
          const URL = `${BASE_URL}api/countries`;
          const options = {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          };

          const response = await fetch(URL, options);
          const data = await response.json();

          if (response.ok) {
            const modifiedCountryList = data.data.map((each) => ({
              ...each,
              id: each.id,
              text: each.country,
              value: each.country,
            }));
            setOriginalCountries(modifiedCountryList);

            const selectedCountry =
              modifiedCountryList.find(
                (option) => option.text === preFilledvalue
              ) ||
              // modifiedCountryList.find(
              //   (option) => option.text === "American Samoa"
              // ) ||
              null;

            setCountry(selectedCountry || "");
            setInputCountry(selectedCountry?.text || "");
          } else {
            setOriginalCountries([]);
            setCountry("");
            setInputCountry("");
          }
        } catch (e) {
          console.log("error", e.message);
          setOriginalCountries([]);
          setCountry("");
          setInputCountry("");
        }
      };

      fetchCountries();
      setErrorMessage("");
    }, [preFilledvalue]);

    const handleChange = (e, newcountry) => {
      setCountry(newcountry);
      setErrorMessage(""); // Clear error when a country is selected
      onCountrySelect(newcountry); // Pass the selected country to the parent
    };

    const handleValidation = () => {
      if (required && !country) {
        setErrorMessage("Country is required.");
        return false;
      }
      return true;
    };

    // Expose the validation function to the parent component
    useImperativeHandle(ref, () => ({
      handleValidation,
    }));

    return (
      <Box sx={{ textAlign: "left" }}>
        <FormLabel>
          <Typography variant="h6">
            Country
            {required && <span className="star">*</span>}
          </Typography>
        </FormLabel>
        <Autocomplete
          value={country}
          onChange={handleChange}
          inputValue={inputcountry}
          onInputChange={(e, newInputcountry) =>
            setInputCountry(newInputcountry)
          }
          options={originalCountries}
          getOptionLabel={(option) => option?.text || ""}
          renderInput={(params) => (
            <TextField {...params} placeholder="Select Country" />
          )}
        />
        {errorMessage && (
          <Typography variant="errmsg">{errorMessage}</Typography>
        )}
      </Box>
    );
  }
);

export default Country;
