import React, { useState, forwardRef, useImperativeHandle } from "react";
import {
  TextField,
  Box,
  InputAdornment,
  IconButton,
  Popover,
  Typography,
} from "@mui/material";
import CalendarTodayIcon from "@mui/icons-material/Event";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";
import advancedFormat from "dayjs/plugin/advancedFormat";
import localizedFormat from "dayjs/plugin/localizedFormat";

// Extend dayjs with necessary plugins
dayjs.extend(isoWeek);
dayjs.extend(advancedFormat);
dayjs.extend(localizedFormat);

const WeekCalendar = forwardRef(
  (
    {
      onDateChange,
      maxDate,
      minDate,
      disabled,
      label,
      name,
      required = false,
      preFilledvalue,
      nolabel = false,
    },
    ref
  ) => {
    const [startDate, setStartDate] = useState(null); // Monday date
    const [endDate, setEndDate] = useState(null); // Sunday date
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null); // Tracks the date in the calendar
    const [errorMessage, setErrorMessage] = useState(""); // Error handling
    const [isYearMonthSelected, setIsYearMonthSelected] = useState(false); // Track selection state

    // Function to format and display the selected date range (Monday-Sunday)
    const dateRange =
      startDate && endDate
        ? `${startDate.format("D MMM YYYY")} - ${endDate.format("D MMM YYYY")}`
        : "";

    // Handle pre-filled values
    React.useEffect(() => {
      if (preFilledvalue && preFilledvalue !== "") {
        const monday = dayjs(preFilledvalue, "YYYY-MM-DD").isoWeekday(1);
        const sunday = monday.isoWeekday(7);
        setStartDate(monday);
        setEndDate(sunday);
      } else {
        setStartDate(null);
        setEndDate(null);
      }
    }, [preFilledvalue]);

    // Open calendar popover
    const handleCalendarClick = (event) => {
      if (startDate) {
        setSelectedDate(startDate); // Highlight the current start date
      }
      setAnchorEl(event.currentTarget);
    };

    // Close calendar popover
    const handleCloseCalendar = () => {
      setAnchorEl(null);
      setSelectedDate(null); // Reset selected date
      setIsYearMonthSelected(false); // Reset selection state
    };

    // Only allow Mondays to be selectable
    const disableNonMondays = (date) => {
      return date.day() !== 1;
    };

    // Handle the date selection from the calendar
    const handleDateSelect = (date) => {
      if (isYearMonthSelected) {
        // If a date is selected after year/month, close the calendar
        if (date && date.day() === 1) {
          const monday = dayjs(date);
          const sunday = monday.add(6, "day");

          // Validation checks
          let isValid = true;
          if (maxDate && monday.isAfter(dayjs(maxDate, "YYYY-MM-DD"))) {
            setErrorMessage(
              `${label} cannot be after ${dayjs(maxDate).format("YYYY-MM-DD")}`
            );
            isValid = false;
          } else if (minDate && monday.isBefore(dayjs(minDate, "YYYY-MM-DD"))) {
            setErrorMessage(
              `${label} cannot be before ${dayjs(minDate).format("YYYY-MM-DD")}`
            );
            isValid = false;
          } else {
            setErrorMessage("");
          }

          if (isValid) {
            setStartDate(monday);
            setEndDate(sunday);
            onDateChange(monday.format("YYYY-MM-DD"), name); // Send only Monday to backend
            handleCloseCalendar(); // Close the calendar only after a valid date selection
          }
        }
      } else {
        // If year or month is selected, update state and keep the calendar open
        setSelectedDate(date); // Update selected date
        setIsYearMonthSelected(true); // Set flag to true indicating year/month selection
      }
    };

    // Function for validation when needed
    useImperativeHandle(ref, () => ({
      handleValidation: () => {
        if (required && !startDate) {
          setErrorMessage(`${label} is required.`);
          return false;
        }
        return true;
      },
    }));

    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box>
          {!nolabel && (
            <Typography variant="h6">
              {label}
              {required && <span style={{ color: "red" }}>*</span>}
            </Typography>
          )}
          <TextField
            //label="Date Range"
            placeholder="DD MMM YYYY - DD MMM YYYY"
            value={dateRange}
            onClick={(e) => e.preventDefault()} // Prevent direct text editing
            fullWidth
            sx={{ width: "250px" }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleCalendarClick}>
                    <CalendarTodayIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(errorMessage)}
            helperText={errorMessage}
          />

          {/* Popover for the calendar */}
          <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleCloseCalendar}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <DateCalendar
              value={selectedDate}
              onChange={handleDateSelect}
              views={["year", "month", "day"]}
              shouldDisableDate={disableNonMondays} // Disable non-Mondays
            />
          </Popover>
        </Box>
      </LocalizationProvider>
    );
  }
);

export default WeekCalendar;
