import React, { useEffect, useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Avatar,
  Box,
  Chip,
} from "@mui/material";
import { Link } from "react-router-dom";
import { getCookie } from "../../../utils/utils";

import StyledCheckbox from "../../reusableComponents/StyledCheckbox";
import { StrikeBar } from "../clientReusableComponents/StrikeBar";

import { slicedText } from "../../../utils/utils";

import ClientGridTableEdit from "../../../assets/images/userTableIcons/UserTableEdit.svg";
import ClientGridTableEditHover from "../../../assets/images/userTableIcons/UserTableEditHover.svg";
import ClientGridTableView from "../../../assets/images/userTableIcons/UserTableView.svg";
import ClientGridTableViewHover from "../../../assets/images/userTableIcons/UserTableViewHover.svg";

const HoverImg = ({ imgBeforeHover, imgAfterHover }) => {
  const [hover, setHover] = useState(false);
  return (
    <img
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{
        width: "26px",
        height: "26px",
      }}
      alt="table icons"
      src={hover ? imgAfterHover : imgBeforeHover}
    />
  );
};

const IconsListView = ({ userID }) => {
  return (
    <div
      style={{
        display: "flex",
        position: "absolute",
        top: "11%", // icons position
        right: "3%",
        gap: "10px",
      }}
    >
      <Link
        to={`/clients/client-dashboard/${userID}`}
        style={{ textDecoration: "none" }}
      >
        <div>
          <HoverImg
            imgBeforeHover={ClientGridTableView}
            imgAfterHover={ClientGridTableViewHover}
          />
        </div>
      </Link>

      <Link
        to={`/clients/client-dashboard/${userID}?edit=true`}
        style={{ textDecoration: "none" }}
      >
        <div>
          <HoverImg
            imgBeforeHover={ClientGridTableEdit}
            imgAfterHover={ClientGridTableEditHover}
          />
        </div>
      </Link>
    </div>
  );
};

const ClientGridTable = ({
  usersList,
  toggleIDsInSelectedIDS,
  toggleAllIDs,
  selectedIDS,
}) => {
  const getUserNameTwoLetters = (user) => {
    if (user) {
      return `${user.first_name[0]}${user.last_name[0]}`.toUpperCase();
    }
    return "";
  };

  return (
    <Box sx={{ margin: "6px" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "15px",
        }}
      >
        <Typography
          variant="h3"
          style={{ margin: 0, padding: 0, marginRight: "8px" }}
        >
          Select all
        </Typography>
        <StyledCheckbox onChange={(e) => toggleAllIDs(e.target.checked)} />
      </div>

      <Grid
        container
        spacing={3}
        style={{
          paddingBottom: "20px",
          height: "calc(100vh - 235px)",
          overflow: "scroll",

          paddingTop: 0,
        }}
      >
        {usersList &&
          usersList.map((user) => (
            <Grid
              item
              lg={4}
              xs={12}
              sm={12}
              md={6}
              key={user.id}
              style={{
                marginTop: "5px",
                marginBottom: "5px",
                paddingTop: "2px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  padding: "10px",
                  color: "black",
                  borderRadius: "8px",
                  background: "#FFF",
                  boxShadow: "0px 0px 10px 1px rgba(39, 153, 137, 0.30)",
                  position: "relative",
                  marginBottom: "3px",
                  marginTop: "3px",
                  marginRight: "6px",
                }}
              >
                {user.base64 ? (
                  <Avatar
                    src={user.base64}
                    alt={user.name}
                    sx={{ width: 85, height: 85, marginRight: "15px" }}
                  />
                ) : (
                  <Box
                    sx={{
                      height: "85px",
                      width: "85px",
                      marginRight: "15px",
                      borderRadius: "50%",
                      flexShrink: 0,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      background: "#269989",
                      color: "#FFF",
                      textAlign: "center",
                      fontFamily: "Lora",
                      fontSize: "35px",
                      fontStyle: "normal",
                      fontWeight: 400,
                    }}
                  >
                    {getUserNameTwoLetters(user)}
                  </Box>
                )}
                <div
                  style={{
                    position: "absolute",
                    top: "6%", // checkbox position
                    left: "2%",
                  }}
                >
                  <StyledCheckbox
                    checked={selectedIDS.includes(user.id)}
                    onChange={() => toggleIDsInSelectedIDS(user.id)}
                  />
                </div>

                <IconsListView userID={user.id} />

                <Box style={{ width: "100%" }}>
                  <div style={{ display: "flex", padding: "0px 8px" }}>
                    <div>
                      <Typography
                        variant="body2"
                        style={{ paddingLeft: 0, textAlign: "left" }}
                      >
                        {user.first_name} {user.last_name}
                      </Typography>
                      <Typography
                        variant="body2"
                        style={{ paddingLeft: 0, textAlign: "left" }}
                      >
                        {user.country_code} {user.contact_number}
                      </Typography>
                      <Typography
                        variant="body2"
                        style={{ paddingLeft: 0, textAlign: "left" }}
                      >
                        {slicedText(`${user.address}`, 30)}
                      </Typography>

                      <Typography
                        variant="body2"
                        style={{ paddingLeft: 0, textAlign: "left" }}
                      >
                        {user.postcode}
                      </Typography>
                    </div>
                  </div>

                  <hr
                    style={{
                      width: "100%",
                      margin: 0,
                      margin: "8px 0px",

                      border: "1.5px solid rgba(39, 153, 137, 0.20)",
                    }}
                  />
                  <div style={{ padding: "0px 8px" }}>
                    <div
                      style={{
                        marginBottom: "10px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        variant="body2"
                        style={{
                          minWidth: "80px",
                          textAlign: "left",

                          padding: 0,
                          marginRight: "10px",
                        }}
                      >
                        Key Users:
                      </Typography>

                      <Typography variant="body2" style={{ padding: 0 }}>
                        {user.key_user}
                      </Typography>
                    </div>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        flex: 1,
                        width: "100%",
                      }}
                    >
                      <Typography
                        variant="body2"
                        style={{
                          minWidth: "80px",
                          textAlign: "left",

                          padding: 0,
                          marginRight: "10px",
                        }}
                      >
                        Risk Level:
                      </Typography>
                      <div
                        style={{
                          flex: 1,
                          justifyContent: "start",
                          alignItems: "center",

                          display: "flex",
                          width: "100%",
                          flex: 1,
                        }}
                      >
                        <div style={{ flex: 1 }}>
                          <StrikeBar riskLevel={user.risk_level} />
                        </div>
                        <Typography
                          variant="body2"
                          style={{ padding: 0, marginLeft: "10px" }}
                        >
                          {user.risk_level}
                        </Typography>
                      </div>
                    </Box>
                  </div>
                </Box>
              </Box>
            </Grid>
          ))}
      </Grid>
    </Box>
  );
};

export default ClientGridTable;
