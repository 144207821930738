import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
// import Header from "../components/common/header/Header";
// import { Card, Box } from "@mui/material";
import Login from "../components/login/login";
import Verify from "../components/common/Verify/Verify";
import DashboardLayout from "../layouts/DashboardLayout";
import RosterDashboardLayout from "../layouts/RosterDashboardLayout";
import LogDashboard from "../components/logs/LogDashboard";

import UserDashMain from "../components/users/userDash/UserDashMain";
import UserDashBoard from "../components/users/UserDashboard";
import UserFormView from "../components/users/UserFormView";

import ClientDashMain from "../components/clients/clientDashMain/ClientDashMain";
import ClientDashboard from "../components/clients/ClientDashboard";
import ClientFormView from "../components/clients/ClientFormView";

import RosterPage from "../pages/RosterPage";

// import RosterDashMain from "../components/roster/rosterDashMain/RosterDashMain";
import RosterDashboard from "../components/roster/RosterDashboard";
import RosterFormView from "../components/roster/RosterFormView";

import ReportPage from "../pages/ReportPage";
import BillingPage from "../pages/BillingPage";
import LogPage from "../pages/LogPage";
// import ClientPage from "../pages/ClientPage";
import ProtectedRoutes from "./ProtectedRoutes";

const AppRoutes = () => {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/api/dashboard/:token" element={<Verify />} />
        <Route element={<ProtectedRoutes />}>
          <Route path="/" element={<Navigate to="/users" replace />} />

          <Route path="/users" element={<DashboardLayout />}>
            <Route index element={<UserDashBoard />} />
            <Route path="add-user" element={<UserFormView />} />
            <Route path="user-dashboard/:userID" element={<UserDashMain />} />
          </Route>

          <Route path="/clients" element={<DashboardLayout />}>
            <Route index element={<ClientDashboard />} />
            <Route path="add-client" element={<ClientFormView />} />
            <Route
              path="client-dashboard/:userID"
              element={<ClientDashMain />}
            />
          </Route>

          <Route path="/roster" element={<RosterDashboardLayout />}>
            <Route index element={<RosterDashboard />} />
            <Route path="create-run" element={<RosterFormView />} />
            {/* <Route
              path="roster-dashboard/:userID"
              element={<RosterDashMain />}
            /> */}
          </Route>
          {/* <Route exact path="/roster" element={<RosterPage />} /> */}
          <Route exact path="/reports" element={<ReportPage />} />
          <Route exact path="/billing" element={<BillingPage />} />
          <Route exact path="/log" element={<DashboardLayout />}>
            {" "}
            <Route index element={<LogDashboard />} />
          </Route>
        </Route>
      </Routes>
    </Router>
  );
};

export default AppRoutes;
