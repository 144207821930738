// ProtectedRoute.js
import React from "react";
import { useEffect } from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { getCookie, checkTokenAndRedirect } from "../utils/utils"; // Assuming you have cookieUtils.js for managing cookies

const ProtectedRoutes = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const tokenCheckInterval = setInterval(() => {
      const token = getCookie();
      if (!token) {
        navigate("/login"); // Redirect to login if token is missing
      }
    }, 1000); // Check every 1 second

    return () => clearInterval(tokenCheckInterval);
  }, [navigate]);

  const token = getCookie();
  return token ? <Outlet /> : <Navigate to="/login" replace />;
};

export default ProtectedRoutes;
