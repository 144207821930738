import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  // Card,
  // Button,
  // TextField,
  // InputLabel,
  // Radio,
  // RadioGroup,
  // FormControlLabel,
  // FormLabel,
} from "@mui/material";
// import MenuItem from "@mui/material/MenuItem";
// import Select from "@mui/material/Select";

import { Link, useSearchParams } from "react-router-dom";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { ScreenHeights } from "../users/Theme";
import dayjs from "dayjs";

// import { useTheme } from "@mui/material/styles";
// import useMediaQuery from "@mui/material/useMediaQuery";
// import CountrySelect from "./CountrySelect";

// import { InputEl, SelectEl, StyledRadio } from "./InputsEl";
import CustomButtom from "../reusableComponents/CustomButton";
import TopContentContainer from "../reusableComponents/TopContentContainer";
import ContentContainer from "../reusableComponents/ContentContainer";
import CreateRunForm from "./CreateRunForm";
import AddVisits from "./AddVisits";

export default function RosterFormView() {
  const [openAddVisitsForm, setOpenAddVisitsForm] = useState(false);

  const [selectedRun, setSelectedRun] = useState(null);
  const handleLinkClick = () => {
    openAddVisitsForm && setOpenAddVisitsForm(false);
  };
  const [searchParams] = useSearchParams();

  const date = decodeURIComponent(searchParams.get("date") || "");
  const runName = decodeURIComponent(searchParams.get("runname") || "");
  const runId = decodeURIComponent(searchParams.get("runid") || "");
  useEffect(() => {
    console.log("date", date);
    console.log("runName", runName, "123");
    console.log("runId", runId);
    if (runId != "") {
      setOpenAddVisitsForm(true);
      setSelectedRun({ id: Number(runId), run_name: runName });
    }
  }, [date]);

  return (
    <Box
      component="div"
      sx={{
        background: "white",
        // backgroundColor:"red",
        borderRadius: "10px",
        border: "2px solid #27998933",
        boxShadow: "0px 0px 10px 0px #27998933",
        padding: "40px",
        // display: "flex",
        // justifyContent: "center",
        height: ScreenHeights.contentContainerFullHeight, // the main content should happens here please do change the 190px if you requires
        overflowY: "auto",
        margin: "0px",
      }}
    >
      <Box sx={{ width: "100%", height: "100%" }}>
        {openAddVisitsForm ? (
          <AddVisits
            setOpenAddVisitsForm={setOpenAddVisitsForm}
            selectedRun={selectedRun}
            date={dayjs(date).format("YYYY-MM-DD")}
          />
        ) : (
          <CreateRunForm
            setOpenAddVisitsForm={setOpenAddVisitsForm}
            selectedRun={selectedRun}
            setSelectedRun={setSelectedRun}
          />
        )}
      </Box>
    </Box>
  );
}
