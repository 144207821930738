import React, { useEffect, useState } from "react";
import {
  Box,
  Modal,
  Typography,
  Button,
  Divider,
  IconButton,
} from "@mui/material";
import { Name } from "../../reusableComponents";

import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import useStyles from "../styles/useStyles";
import CustomButtom from "../../reusableComponents/CustomButton";
import CloseIcon from "../../../assets/icons/close.svg";
import CloseHoverIcon from "../../../assets/icons/closehover.svg";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 6;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 180,
    },
  },
};
function MarChartModal({
  popupData,
  setPopupData,
  editOpen,
  setEditOpen,
  statusData,
}) {
  const [selectedStatus, setSelectedStatus] = useState("");
  const [refreshData, setrefreshData] = useState("");
  const [log, setlog] = useState("");
  useEffect(() => {
    // Find the default status ID from popupData
    const defaultStatus = statusData?.find(
      (item) => item?.status === popupData?.status?.label
    );
    if (defaultStatus) {
      setSelectedStatus(defaultStatus.id);
      setrefreshData(defaultStatus.id); // Set the default value as the ID
    }
  }, [popupData]);
  const handleChange = (event) => {
    setSelectedStatus(event.target.value); // Update the state with the selected value
  };
  const classes = useStyles();
  const handleValueChange = (newValue, name) => {
    setlog((prevFormData) => {
      const updatedForm = { ...prevFormData, [name]: newValue };

      return updatedForm;
    });
  };
  const closeEdit = () => {
    setEditOpen(false);
    setSelectedStatus(refreshData);
  };

  return (
    <div>
      <Modal
        open={!!popupData}
        onClose={() => {}}
        sx={{ px: "0px" }}
        BackdropProps={{
          style: { backgroundColor: "rgba(0, 0, 0, 0.5)" }, 
        }}
      >
        <Box className={classes.popupBox}>
          <Box className={classes.popupHeader}>
            <Box>
              <h5 style={{ margin: "0px" }} variant="body2">
                {popupData?.medication}
              </h5>
              <small>{popupData?.type}</small>
            </Box>
            <IconButton
              aria-label="close"
              onClick={() => setPopupData(null)}
              sx={{
                position: "absolute",
                right: 1,
                top: 10,
                width: "20px !important",
                height: "20px !important",
                backgroundImage: `url(${CloseIcon})`, // Default close.svg
                backgroundSize: "cover",
                "&:hover": {
                  backgroundImage: `url(${CloseHoverIcon})`, // On hover, change to closehover.svg
                },
              }}
            >
              {/* Empty content since the SVG icons are used in background */}
            </IconButton>
          </Box>
          <Divider sx={{ my: "12px", borderWidth: "1px" }} />
          <Box className={classes.popupContent}>
            <Typography
              sx={{ color: "#969999", fontWeight: "500", fontSize: "14px" }}
            >
              Carer
            </Typography>
            <Typography>Naveen</Typography>
          </Box>
          <Box className={classes.popupContent}>
            <Typography
              sx={{ color: "#969999", fontWeight: "500", fontSize: "14px" }}
            >
              Time
            </Typography>
            <Typography>{popupData?.time}</Typography>
          </Box>
          <Divider sx={{ my: "15px", borderWidth: "1px" }} />
          {!editOpen ? (
            <>
              <Box sx={{ width: "40px", marginLeft: "80%" }}>
                <CustomButtom
                  text="Edit"
                  onClick={() => setEditOpen(!editOpen)}
                />
              </Box>
            </>
          ) : (
            <>
              <FormControl
                sx={{
                  width: "180px",
                  height: "40px",
                  mt: 1,
                  border: " 1.5px solid #2799894D",
                  borderRadius: "8px",
                  padding: "0px",
                  alignSelf: "flex-end",
                  marginRight: "5.5%",
                  backgroundColor: "#FFFFFF",
                }}
              >
                <Select
                  value={selectedStatus}
                  onChange={handleChange}
                  input={<OutlinedInput />}
                  renderValue={(selected) => {
                    if (!selected) {
                      return <em>Select Status</em>;
                    }
                    const selectedItem = statusData.find(
                      (item) => item.id === selected
                    );
                    return (
                      <Box
                        sx={{ display: "flex", alignItems: "center", gap: 1 }}
                      >
                        <img
                          src={selectedItem.image}
                          alt={selectedItem.status}
                          style={{ width: "16px", height: "16px" }}
                        />
                        <span>{selectedItem.status}</span>
                      </Box>
                    );
                  }}
                  MenuProps={MenuProps}
                  sx={{
                    width: "180px",
                    padding: "0px !important",
                    ".MuiSelect-select": {
                      padding: "8px 6px !important",
                      fontSize: "13px", // Remove padding from the select input
                      color: "#969999",
                      fontWeight: "500",
                    },
                  }}
                >
                  <MenuItem disabled value="" sx={{ padding: "0px" }}>
                    {/* <em>Select Status</em> */}
                  </MenuItem>
                  {statusData.map((item) => (
                    <MenuItem
                      key={item.id}
                      value={item.id}
                      sx={{
                        width: 200,
                        paddingLeft: "0px !important",
                        paddingRight: "0px !important",
                        margin: "0px !important",
                        fontSize: "13px",
                        color: "#969999",
                        fontWeight: "500",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 1,
                          paddingLeft: "0px !important",
                          paddingRight: "0px !important",
                          fontSize: "13px",
                        }}
                      >
                        <img
                          src={item.image}
                          alt={item.status}
                          style={{
                            borderRadius: "50%",
                            width: "16px",
                            height: "16px",
                            marginLeft: "6px",
                          }}
                        />
                        {item.status}
                      </Box>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </>
          )}
          <Box className={classes.popupContent} sx={{ pt: "10px" }}>
            <Typography
              variant="body2"
              sx={{ color: "#969999", fontWeight: "500", fontSize: "14px" }}
            >
              Outcome
            </Typography>
            <Typography
              variant="body2"
              sx={{
                pr: "8%",
                fontSize: "14px",
                color: "#969999",
                fontWeight: "500",
              }}
            >
              <span style={{ marginRight: "15px" }}>
                <img
                  src={popupData?.status?.img}
                  style={{ width: "16px", height: "16px" }}
                />
              </span>
              {popupData?.status?.label}
            </Typography>
          </Box>
          <Box sx={{ px: "28px", pb: "10px" }}>
            <Name
              label="Log"
              rows={4}
              name="log"
              placeholder={popupData?.status?.label}
              className="custom-textarea"
              onValueChange={(value) => handleValueChange(value, "log")}
              multiline
            />
          </Box>
          {editOpen && ( // Show Save and Cancel buttons if edit mode is active
            <Box
              sx={{
                mb: 4,
                mt: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                variant="customcancel"
                color="primary"
                sx={{ width: "30%" }}
                onClick={closeEdit}
              >
                Cancel
              </Button>
              <Button
                variant="customsave"
                color="primary"
                sx={{ width: "30%" }}
              >
                Save
              </Button>
            </Box>
          )}
        </Box>
      </Modal>
    </div>
  );
}

export default MarChartModal;
