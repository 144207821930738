import React, { useState } from "react";
import { Box } from "@mui/material";
import RosterDashboardHeader from "./RosterDashboardHeader";
import RosterTable from "./RosterTable";
//import { useState } from "react";
import dayjs from "dayjs";

export default function RosterDashboard() {
  const [view, setView] = useState(); // Initialize with today's date
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [selectedRun, setSelectedRun] = useState(null);
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
      }}
    >
      <RosterDashboardHeader
        setView={setView}
        setSelectedDate={setSelectedDate}
        selectedDate={selectedDate}
        setSelectedRun={setSelectedRun}
        selectedRun={selectedRun}
      />
      <RosterTable
        view={view}
        selectedDate={selectedDate}
        selectedRun={selectedRun}
      />
    </Box>
  );
}

//export default RosterDashboard;
