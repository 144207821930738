import { Box, Button, Container, Grid } from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Calendar,
  Dropdown,
  RadioInput,
  Sessions,
  Days,
  Name,
  NumberInput,
  Time,
} from "../../reusableComponents";
import dayjs from "dayjs";
import { BlisterSesiions, medicationPrnTime } from "../../../cms/dropDown";
import BodyMap from "./BodyMap";
import {
  getCookie,
  getMedicationIdsLocalStorage,
  setMedicationIdsLocalStorage,
} from "../../../utils/utils";
import Admin from "../../../assets/images/users/Admin.svg";
import { toast, ToastContainer } from "react-toastify";
import ConfirmationDialog from "../../reusableComponents/ConfirmationDialog";

function AddMedicationForm({
  clientId,
  medicines,
  medicationroute,
  setActiveTabId,
  setHasUnsavedChanges
}) {
  const initialTodoInfo = useMemo(() =>({
    client_id: parseInt(clientId),
    visit_id: 218,
    NHS_Medicine_Database:'',
    medication_Route: "",
    quantity_for_dose: "",
    adm_qty_for_dose: "",
    select_days: [],
    blister_main_session: [],
    // choose_sessions: [],
    medication_support: "",
    medication_type: "",
    blister_sDate: "",
    bister_eDate: "",
    blister_start_session: "",
    blister_end_session: "",
    //sheduled
    sheduled_many_times: "",
    sheduled_select_days: [],
    Select_Preferences: "By Session",
    sheduled_session: [],
    sheduled_by_session_startDate: "",
    sheduled_by_session_endDate: "",
    sheduled_by_session_start: "",
    sheduled_by_session_end: "",
    starthtime: "00",
    startmtime: "00",
    By_Exact_start_time: "",
    By_Exact_end_time: "",
    prn_dose: "",
    prn_dose_time: "",
    prn_dose_timeDropdown: "",
    prn_dose_offered: "",
    prn_dose_given: "",
    Prn_sDate: "",
    Prn_eDate: "",
    body_mapping: "",
  }),[]);
  const [medicationType, setMedicationType] = useState("");
  const [medicationInfo, setMedicationInfo] = useState(initialTodoInfo);
  const [medicationInfoOriginal, setMedicationInfoOriginal] = useState(initialTodoInfo);
  const [bodyData, setBodyData] = useState("");
  const [bodyData1, setBodyData1] = useState("");
  const [bodyData2, setBodyData2] = useState("");
  const [bodyModeEnable, setBodyModeEnable] = useState("");
  const [selectBysession, setSelectBySession] = useState("By Session");
  const [errors, setErrors] = useState({
    prn_dose: "",
    prn_dose_time: "",
    prn_dose_timeDropdown: "",
  });
  const [openDialog, setOpenDialog] = useState(false);

  const handleOpen = () => setOpenDialog(true);
  const handleClose = () => setOpenDialog(false);
  const onClickYesDialogBtn = () => {
    // toggleEdit();
    setHasUnsavedChanges(false); // Reset unsaved changes after confirming dialog
     setActiveTabId("section3");
  };

  // const [medicines , setmedicines] = useState([]);
  const NhsMedicine = useRef();
  const quantityForDose = useRef();
  const admQtyForDose = useRef();
  const choose_sessionsRef = useRef();
  const blister_start_dateRef = useRef();
  const blister_end_dateRef = useRef();
  const blister_main_sessionRef = useRef();
  const blister_start_sessionRef = useRef();
  const blister_end_sessionRef = useRef();
  const select_daysRef = useRef();
  const medication_supportRef = useRef();
  const medication_typeRef = useRef();
  const prnNumberRef = useRef();
  const prnTimeRef = useRef();
  const prnTimeDropdownRef = useRef();
  const prnDoseOfferRef = useRef();
  const prnDoseGivenRef = useRef();
  const prnDateStartRef = useRef();
  const prnDateEndRef = useRef();
  const sheduled_many_timesRef = useRef();
  const sheduled_select_daysRef = useRef();
  const Select_PreferencesRef = useRef();
  const sheduled_sessionRef = useRef();
  const sheduled_by_session_startDateRef = useRef();
  const sheduled_by_session_endDateRef = useRef();
  const sheduled_by_session_startRef = useRef();
  const sheduled_by_session_endRef = useRef();
  const starttimeRef = useRef();
  const By_Exact_start_timeRef = useRef();
  const By_Exact_end_timeRef = useRef();
  const currentDate = dayjs().startOf("day");
  const formattedDate = dayjs().format("YYYY-MM-DD");


  const showToastMessage = (msg) => {
    toast.success(msg, {
      position: "top-right",
      autoClose: 2000, // Duration in milliseconds
    });
  };
  const resetForm = () => {
     if(!areObjectsEqual(medicationInfo, medicationInfoOriginal)){
     handleOpen()
    }else{
      setHasUnsavedChanges(false)
     
      setMedicationInfo(initialTodoInfo);
    }
   
  };
  const showToastFailureMessage = (msg) => {
    toast.error(msg, {
      position: "top-right",
    });
  };
  const handleDropDownChange = (value, name) => {
    setMedicationInfo((prevFormData) => {
      const updatedForm = {
        ...prevFormData,
        [name]: value?.id || value.text,
      };
      setHasUnsavedChanges(!areObjectsEqual(updatedForm, medicationInfoOriginal));
      return updatedForm;
    });
  };
  const handleDropDownChange2 = (value, name) => {
    setMedicationInfo((prevFormData) => {
      const updatedForm = {
        ...prevFormData,
        prn_dose_timeDropdown: value?.text,
      };
      setHasUnsavedChanges(!areObjectsEqual(updatedForm, medicationInfoOriginal));
      return updatedForm;
    });
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };
  const handleValueChange = (newValue, name) => {
    setMedicationInfo((prevFormData) => {
      const updatedForm = { ...prevFormData, [name]: newValue };
      setHasUnsavedChanges(!areObjectsEqual(updatedForm, medicationInfoOriginal));
      return updatedForm;
    });
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };
  const handleRadioChange = (value, name) => {
    setMedicationInfo((prevFormData) => {
      const updatedForm = {
        ...prevFormData,
        [name]: value,
      };
      if (name === "medication_type") {
        setMedicationType(value);
      }
      if (name === "Select_Preferences") {
        // Update preference name to match your RadioInput name
        setSelectBySession(value);
      }
      if (name === "Body-map") {
        // Update preference name to match your RadioInput name
        setBodyModeEnable(value);
      }

      setHasUnsavedChanges(!areObjectsEqual(updatedForm, medicationInfoOriginal));
      return updatedForm;
    });
  };
  const handleDateChange = (date, name) => {
    setMedicationInfo((prevFormData) => {
      const updatedForm = { ...prevFormData, [name]: date };
      setHasUnsavedChanges(!areObjectsEqual(updatedForm, medicationInfoOriginal));
      return updatedForm;
    });
    console.log("start date", medicationInfo.blister_sDate);
  };
  const handleChange = (name, value) => {
    setMedicationInfo((prevFormData) => {
      const updatedForm = { ...prevFormData, [name]: value };
      setHasUnsavedChanges(!areObjectsEqual(updatedForm, medicationInfoOriginal));
      return updatedForm;
    });
  };
  // Updated handleSessionSelect to accept sessionName as an additional parameter
  const handleSessionSelect = (selectedSessions, sessionName) => {
    const sessionIds = selectedSessions.map((item) => item.id);
    setMedicationInfo((prevFormData) => {
      const updatedForm = {
        ...prevFormData,
        [sessionName]: [...sessionIds],
      };
      setHasUnsavedChanges(!areObjectsEqual(updatedForm, medicationInfoOriginal));
      return updatedForm;
    });
  };
  const areObjectsEqual = (obj1, obj2) => {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  };

  const handleSubmit = () => {
    let isValid = true;
    const newErrors = { ...errors };
    if (medicationInfo.medication_type === "PRN" && !medicationInfo.prn_dose) {
      isValid = false;
      newErrors.prn_dose = "enter a valid dose.";
      setErrors(newErrors);
    }
    // Custom validation for prn_dose_time
    if (
      medicationInfo.medication_type === "PRN" &&
      !medicationInfo.prn_dose_time
    ) {
      isValid = false;
      newErrors.prn_dose_time = "enter a valid dose time.";
      setErrors(newErrors);
      console.error("Error: prn_dose_time is required");
    }
    // Custom validation for prn_dose_timeDropdown
    if (
      medicationInfo.medication_type === "PRN" &&
      !medicationInfo.prn_dose_timeDropdown
    ) {
      isValid = false;
      newErrors.prn_dose_timeDropdown = "Please select a valid time option.";
      setErrors(newErrors);
    }
    if (NhsMedicine.current && !NhsMedicine.current.handleValidation()) {
      isValid = false;
    }
    if (
      quantityForDose.current &&
      !quantityForDose.current.handleValidation()
    ) {
      isValid = false;
    }
    if (admQtyForDose.current && !admQtyForDose.current.handleValidation()) {
      isValid = false;
    }
    if (
      choose_sessionsRef.current &&
      !choose_sessionsRef.current.handleValidation()
    ) {
      isValid = false;
    }
    if (
      blister_start_dateRef.current &&
      !blister_start_dateRef.current.handleValidation()
    ) {
      isValid = false;
    }
    if (
      blister_end_dateRef.current &&
      !blister_end_dateRef.current.handleValidation()
    ) {
      isValid = false;
    }
    if (select_daysRef.current && !select_daysRef.current.handleValidation()) {
      isValid = false;
    }
    if (
      medication_supportRef.current &&
      !medication_supportRef.current.handleValidation()
    ) {
      isValid = false;
    }
    if (
      medication_typeRef.current &&
      !medication_typeRef.current.handleValidation()
    ) {
      isValid = false;
    }
    if (
      blister_main_sessionRef.current &&
      !blister_main_sessionRef.current.handleValidation()
    ) {
      isValid = false;
    }
    if (
      blister_start_sessionRef.current &&
      !blister_start_sessionRef.current.handleValidation()
    ) {
      isValid = false;
    }
    if (
      blister_end_sessionRef.current &&
      !blister_end_sessionRef.current.handleValidation()
    ) {
      isValid = false;
    }
    // sheduled form
    if (
      sheduled_many_timesRef.current &&
      !sheduled_many_timesRef.current.handleValidation()
    ) {
      isValid = false;
    }
    if (
      sheduled_select_daysRef.current &&
      !sheduled_select_daysRef.current.handleValidation()
    ) {
      isValid = false;
    }
    if (
      Select_PreferencesRef.current &&
      !Select_PreferencesRef.current.handleValidation()
    ) {
      isValid = false;
    }
    if (
      sheduled_sessionRef.current &&
      !sheduled_sessionRef.current.handleValidation()
    ) {
      isValid = false;
    }
    if (
      sheduled_by_session_startDateRef.current &&
      !sheduled_by_session_startDateRef.current.handleValidation()
    ) {
      isValid = false;
    }
    if (
      sheduled_by_session_endDateRef.current &&
      !sheduled_by_session_endDateRef.current.handleValidation()
    ) {
      isValid = false;
    }
    if (
      sheduled_by_session_startRef.current &&
      !sheduled_by_session_startRef.current.handleValidation()
    ) {
      isValid = false;
    }
    if (
      sheduled_by_session_endRef.current &&
      !sheduled_by_session_endRef.current.handleValidation()
    ) {
      isValid = false;
    }
    if (starttimeRef.current && !starttimeRef.current.handleValidation()) {
      isValid = false;
    }
    if (
      By_Exact_start_timeRef.current &&
      !By_Exact_start_timeRef.current.handleValidation()
    ) {
      isValid = false;
    }
    if (
      By_Exact_end_timeRef.current &&
      !By_Exact_end_timeRef.current.handleValidation()
    ) {
      isValid = false;
    }
    if (prnNumberRef.current && !prnNumberRef.current.handleValidation()) {
      isValid = false;
    }
    if (prnTimeRef.current && !prnTimeRef.current.handleValidation()) {
      isValid = false;
    }
    if (
      prnTimeDropdownRef.current &&
      !prnTimeDropdownRef.current.handleValidation()
    ) {
      isValid = false;
    }
    if (
      prnDoseOfferRef.current &&
      !prnDoseOfferRef.current.handleValidation()
    ) {
      isValid = false;
    }
    if (
      prnDoseGivenRef.current &&
      !prnDoseGivenRef.current.handleValidation()
    ) {
      isValid = false;
    }
    if (
      prnDoseOfferRef.current &&
      !prnDoseOfferRef.current.handleValidation()
    ) {
      isValid = false;
    }
    if (
      prnDateStartRef.current &&
      !prnDateStartRef.current.handleValidation()
    ) {
      isValid = false;
    }
    if (prnDateEndRef.current && !prnDateEndRef.current.handleValidation()) {
      isValid = false;
    }
    console.log("bodydata1", bodyData1);
    console.log("bodydata2", bodyData2);
    console.log("bodydata3", bodyData);
    if (isValid) {
      if(medicationInfo === medicationInfoOriginal){
        const msg = 'there is no change in the form';
        showToastMessage(msg);
      }else{
       
        setHasUnsavedChanges(false);
        postapi();
        // const msg = "Medication Added Successfully";
        // showToastMessage(msg);
      }
   

      console.log("form data submitted", medicationInfo);
    } else {
      console.log("invalid");
      const msg = "Please fill all the required fields";
      showToastFailureMessage(msg);
    }
  };
  const postapi = async () => {
    console.log("post api called");
    try {
      const token = getCookie();
      const BASE_URL = "https://3.8.158.142:8443/api/medication";
      // const URL = `${BASE_URL}/add`;
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          medicationInfo: {
            client_id: parseInt(clientId),
            nhs_medicine: medicationInfo.NHS_Medicine_Database,
            medication_support: medicationInfo.medication_support,
            medication_route: medicationInfo.medication_Route,
            quantity_each_dose: medicationInfo.quantity_for_dose,
            medication_type: medicationInfo.medication_type,
          },

          scheduledInfo: {
            how_many_times_day: parseInt(medicationInfo?.sheduled_many_times),
            frequency_medication: medicationInfo?.sheduled_select_days,
            select_preference: medicationInfo?.Select_Preferences,
            medication_administrated: medicationInfo?.sheduled_session,
            start_date: medicationInfo?.sheduled_by_session_startDate,
            start_choose_session:
              medicationInfo?.sheduled_by_session_start || 0,
            end_date: medicationInfo?.sheduled_by_session_endDate,
            end_choose_session: medicationInfo?.sheduled_by_session_end || 0,
            by_exact_time: `${medicationInfo.starthtime}:${medicationInfo.startmtime}`,
            by_exact_start_date: medicationInfo?.By_Exact_start_time,
            by_exact_end_date: medicationInfo?.By_Exact_end_time,
          },

          blisterPackInfo: {
            how_many_times_day: parseInt(medicationInfo?.adm_qty_for_dose),
            frequency_medication: medicationInfo?.select_days,
            medication_administrated: medicationInfo?.blister_main_session,
            start_date: medicationInfo?.blister_sDate,
            start_choose_session: medicationInfo?.blister_start_session || 0,
            end_date: medicationInfo?.bister_eDate || '',
            end_choose_session: medicationInfo?.blister_end_session || 0,
          },
          prnInfo: {
            dose_per: parseInt(medicationInfo?.prn_dose),
            doses: parseInt(medicationInfo?.prn_dose_time),
            time_frame: medicationInfo?.prn_dose_timeDropdown,
            prn_offered: medicationInfo?.prn_dose_offered,
            prn_be_given: medicationInfo?.prn_dose_given,
            start_date: medicationInfo?.Prn_sDate,
            end_date: medicationInfo?.Prn_eDate,
          },
          bodyMapInfo: {
            body_part_names: bodyData,
            file_name: bodyData1,
            body_image: bodyData2,
          },
        }),
      };
      const response = await fetch(BASE_URL, options);
      const data = await response.json();
      if (response.ok && data?.statusCode === 200) {
        const msg = 'Medication Added Successfully';
        showToastMessage(msg);
        setActiveTabId("section3");
        const medicationId = data?.data?.medication[0]?.id;
        setMedicationIdsLocalStorage(medicationId);
        resetForm();
      } else {
        console.log("API request failed with status:", response.status);
        const msg = "Failed to add medication";
        showToastFailureMessage(msg);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const medicationid = getMedicationIdsLocalStorage();
  console.log(
    "=====================================>medicationid === from local storage",
    medicationid
  );
  return (
    <Container sx={{ width: "790px" }}>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "15px" }}>
        <Dropdown
          label="NHS medicine database (dmd-browser) helps you in identifying the exact medication required "
          ref={NhsMedicine}
          name="NHS_Medicine_Database"
          onDropdownSelect={(value) =>
            handleDropDownChange(value, "NHS_Medicine_Database")
          }
          list={medicines}
          placeholder="Type to Search"
          preFilledvalue={null}
          required={true}
        />
        <RadioInput
          label="Medication Support?"
          name="medication_support"
          ref={medication_supportRef}
          options={[
            { value: "Administer", label: "Administer" },
            { value: "Assist", label: "Assist" },
            { value: "Prompt", label: "Prompt" },
          ]}
        
          onValueChange={(value) =>
            handleRadioChange(value, "medication_support")
          }
          preFilledvalue={medicationInfo?.medication_support}
          required={true}
        />
        <Grid container justifyContent={"space-between"}>
          <Grid md={5.5}>
            <Dropdown
              label="Medication Route"
              ref={choose_sessionsRef}
              name=" medication_Route"
              onDropdownSelect={(value) =>
                handleDropDownChange(value, "medication_Route")
              }
              list={medicationroute}
              preFilledvalue={null}
              placeholder="Medication Route"
              required={true}
            />
          </Grid>
          <Grid md={5.5}>
            <NumberInput
              ref={quantityForDose}
              label="Quantity for each dose"
              placeholder="Quantity for each dose"
              onValueChange={(value) =>
                handleValueChange(value, "quantity_for_dose")
              }
              name="quantity_for_dose"
              preFilledvalue={medicationInfo.quantity_for_dose}
              required={true}
              enableSpinner
            />
          </Grid>
        </Grid>
        <RadioInput
          label="Medication Type?"
          name="medication_type"
          ref={medication_typeRef}
          options={[
            { value: "Blister Pack", label: "Blister Pack" },
            { value: "Scheduled", label: "Scheduled" },
            { value: "PRN", label: "When required (PRN)" },
          ]}
          onValueChange={(value) => handleRadioChange(value, "medication_type")}
          preFilledvalue={medicationInfo?.medication_type}  
          required={true}
        />
        {medicationType === "Blister Pack" && (
          <>
            {/* medication type Blister Pack  */}
            <Box sx={{ backgroundColor: "#F0FCFA", px: "30px", py: "18px",borderRadius:"15px" }}>
              <Grid item md={5.5}>
                <NumberInput
                  ref={admQtyForDose}
                  label="How many times in a day"
                  placeholder="How many times in a day"
                  onValueChange={(value) =>
                    handleValueChange(value, "adm_qty_for_dose")
                  }
                  name="adm_qty_for_dose"
                  preFilledvalue={medicationInfo.adm_qty_for_dose}
                  required={true}
                  enableSpinner
                />
              </Grid>
              <Grid item xs={12} md={6.5}>
                <Days
                  label="Frequency Of The Medication"
                  ref={select_daysRef} // Pass the ref to the Days component
                  initialSelectedDays={medicationInfo.select_days}
                  onDaysChange={(updatedDays) => {
                    handleChange("select_days", updatedDays || []);
                  }}
                  preFilledvalue={medicationInfo?.select_days}
                  required={true}
                />
              </Grid>
              <Grid item xs={12} md={5.5}>
                <Sessions
                  ref={blister_main_sessionRef} // Pass the ref to the Sessions component
                  onSessionSelect={(selectedSessions) =>
                    handleSessionSelect(
                      selectedSessions,
                      "blister_main_session"
                    )
                  } // Callback for session selection
                  preFilledvalue={medicationInfo?.blister_main_session}
                  required={true}
                  // Set to true if at least one session is required
                />
              </Grid>

              <Grid container justifyContent={"space-between"}>
                <Grid item md={5.6}>
                  <Calendar
                    ref={blister_start_dateRef}
                    label="Start Date"
                    onDateChange={handleDateChange}
                    required
                    name="blister_sDate"
                    preFilledvalue={medicationInfo.blister_sDate}
                    minDate={currentDate}
                  />
                </Grid>
                <Grid md={5.6}>
                  <Dropdown
                    label="Session"
                    ref={blister_start_sessionRef}
                    name="blister_start_session"
                    onDropdownSelect={(value) =>
                      handleDropDownChange(value, "blister_start_session")
                    }
                    list={BlisterSesiions}
                    placeholder="Select Session"
                    preFilledvalue={null}
                    required={true}
                  />
                </Grid>
              </Grid>
              <Grid container justifyContent={"space-between"}>
                <Grid item md={5.6}>
                  <Calendar
                    ref={blister_end_dateRef}
                    label="End Date"
                    onDateChange={handleDateChange}
                    // required
                    preFilledvalue={medicationInfo.bister_eDate}
                    name="bister_eDate"
                    minDate={
                      medicationInfo.blister_sDate
                        ? medicationInfo.blister_sDate
                        : currentDate
                    }
                  />
                </Grid>
                <Grid md={5.6}>
                  <Dropdown
                    label="Session"
                    ref={blister_end_sessionRef}
                    name="blister_end_session"
                    onDropdownSelect={(value) =>
                      handleDropDownChange(value, "blister_end_session")
                    }
                    list={BlisterSesiions}
                    placeholder="Select Session"
                    preFilledvalue={null}
                    // required={true}
                  />
                </Grid>
              </Grid>
            </Box>
          </>
        )}
        {medicationType === "Scheduled" && (
          <>
            <Box sx={{ backgroundColor: "#F0FCFA", px: "30px", py: "18px",borderRadius:"15px" }}>
              <Grid item xs={12} md={5.5}>
                <NumberInput
                  ref={sheduled_many_timesRef}
                  name="sheduled_many_times"
                  label="How many times in a day"
                  placeholder="How many times in a day"
                  maxLength={5}
                  onValueChange={(value) =>
                    handleValueChange(value, "sheduled_many_times")

                  }
                  preFilledvalue={medicationInfo.sheduled_many_times}
                  required
                  enableSpinner
                />
              </Grid>
              <Grid item xs={12} md={6.5}>
                <Days
                  label="Frequency Of The Medication"
                  ref={sheduled_select_daysRef} // Pass the ref to the Days component
                  initialSelectedDays={medicationInfo.sheduled_select_days}
                  onDaysChange={(updatedDays) => {
                    handleChange("sheduled_select_days", updatedDays || []);
                  }}
                  preFilledvalue={medicationInfo?.sheduled_select_days}
                  required={true}
                />
              </Grid>
              <RadioInput
                label="Select Preferences?"
                name="Select_Preferences"
                ref={Select_PreferencesRef}
                options={[
                  { value: "By Session", label: "By Session" },
                  { value: "By Exact Time", label: "By Exact Time" },
                ]}
                preFilledvalue={medicationInfo.Select_Preferences}
                onValueChange={(value) =>
                  handleRadioChange(value, "Select_Preferences")
                }
                required={true}
              />
              {medicationInfo.Select_Preferences === "By Session" ? (
                <>
                  <Grid item xs={12} md={5.5}>
                    <Sessions
                      ref={sheduled_sessionRef} // Pass the ref to the Sessions component
                      onSessionSelect={(selectedSessions) =>
                        handleSessionSelect(
                          selectedSessions,
                          "sheduled_session"
                        )
                      }
                      preFilledvalue={medicationInfo?.sheduled_session}
                      required={true}
                    />
                  </Grid>
                  <Grid container justifyContent={"space-between"}>
                    <Grid item md={5.6}>
                      <Calendar
                        ref={sheduled_by_session_startDateRef}
                        label="Start Date"
                        onDateChange={handleDateChange}
                        required
                        preFilledvalue={
                          medicationInfo.sheduled_by_session_startDate
                        }
                        name="sheduled_by_session_startDate"
                        minDate={currentDate}
                      />
                    </Grid>
                    <Grid md={5.6}>
                      <Dropdown
                        label="Session"
                        ref={sheduled_by_session_startRef}
                        name="sheduled_by_session_start"
                        onDropdownSelect={(value) =>
                          handleDropDownChange(
                            value,
                            "sheduled_by_session_start"
                          )
                        }
                        list={BlisterSesiions}
                        placeholder="Select Session"
                        preFilledvalue={null}
                        required={true}
                      />
                    </Grid>
                  </Grid>
                  <Grid container justifyContent={"space-between"}>
                    <Grid item md={5.6}>
                      <Calendar
                        ref={sheduled_by_session_endDateRef}
                        label="End Date"
                        onDateChange={handleDateChange}
                        // required
                        preFilledvalue={
                          medicationInfo.sheduled_by_session_endDate
                        }
                        name="sheduled_by_session_endDate"
                        minDate={
                          medicationInfo.sheduled_by_session_startDate
                            ? medicationInfo.sheduled_by_session_startDate
                            : currentDate
                        }
                      />
                    </Grid>
                    <Grid md={5.6}>
                      <Dropdown
                        label="Session"
                        ref={sheduled_by_session_endRef}
                        name="sheduled_by_session_end"
                        onDropdownSelect={(value) =>
                          handleDropDownChange(value, "sheduled_by_session_end")
                        }
                        list={BlisterSesiions}
                        placeholder="Select Session"
                        preFilledvalue={null}
                        // required={true}
                      />
                    </Grid>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={12} md={9}>
                    <Time
                      ref={starttimeRef}
                      label="What time should the medication be administrated"
                      required
                      onHoursChange={(value) =>
                        setMedicationInfo({
                          ...medicationInfo,
                          starthtime: value,
                        })
                      }
                      onMinutesChange={(value) =>
                        setMedicationInfo({
                          ...medicationInfo,
                          startmtime: value,
                        })
                      }
                    />
                  </Grid>
                  <Grid container justifyContent={"space-between"}>
                    <Grid item md={5.6}>
                      <Calendar
                        ref={By_Exact_start_timeRef}
                        label="Start Date"
                        onDateChange={handleDateChange}
                        required
                        preFilledvalue={medicationInfo.By_Exact_start_time}
                        name="By_Exact_start_time"
                        minDate={currentDate}
                      />
                    </Grid>
                    <Grid md={5.6}>
                      <Calendar
                        ref={By_Exact_end_timeRef}
                        label="End Date"
                        onDateChange={handleDateChange}
                        // required
                        preFilledvalue={medicationInfo.By_Exact_end_time}
                        name="By_Exact_end_time"
                        minDate={
                          medicationInfo.By_Exact_start_time
                            ? medicationInfo.By_Exact_start_time
                            : currentDate
                        }
                      />
                    </Grid>
                  </Grid>
                </>
              )}
            </Box>
          </>
        )}
        {medicationType === "PRN" && (
          <>
            {/* medication type PRN */}
            <Box
              sx={{
                backgroundColor: "#F0FCFA",
                px: "30px",
                paddingBottom: "18px",
                borderRadius:"15px"
              }}
            >
              <Grid item md={8} sx={{ marginTop: "20px" }}>
                <p className="form-para">
                  what is the time frame between doses *
                </p>
              </Grid>
              <Grid container direction={"row"}>
                <Grid item md={0.6}>
                  <NumberInput
                    label=""
                    ref={prnNumberRef}
                    placeholder="1"
                    name="prn_dose"
                    onValueChange={(value) =>
                      handleValueChange(value, "prn_dose")
                    }
                    preFilledvalue={medicationInfo.prn_dose}
                    // required
                  />
                  {errors.prn_dose && (
                    <p style={{ color: "red", fontSize: "8px", width: "80px" }}>
                      {errors.prn_dose}
                    </p>
                  )}
                </Grid>
                <Grid item md={1.2}>
                  <h6 style={{ paddingTop: "43px", fontSize: "12px" }}>
                    Dose Per
                  </h6>
                </Grid>
                <Grid item md={0.6}></Grid>
                <Grid item md={0.6}>
                  <NumberInput
                    label=""
                    ref={prnTimeRef}
                    placeholder="1"
                    name="prn_dose_time"
                    onValueChange={(value) =>
                      handleValueChange(value, "prn_dose_time")
                    }
                    preFilledvalue={medicationInfo.prn_dose_time}
                    // required
                  />
                  {errors.prn_dose_time && (
                    <p
                      style={{
                        color: "red",
                        fontSize: "8px",
                        width: "80px",
                        paddingRight: "34px",
                      }}
                    >
                      {errors.prn_dose_time}
                    </p>
                  )}
                </Grid>
                <Grid item md={0.25}></Grid>
                <Grid item md={2}>
                  <Dropdown
                    ref={prnTimeDropdownRef}
                    label=""
                    name="prn_dose_timeDropdown"
                    onDropdownSelect={(value) =>
                      handleDropDownChange2(value, "prn_dose_timeDropdown")
                    }
                    list={medicationPrnTime}
                    // preFilledvalue={medicationPrnTime[0].text}
                    placeholder="Hours"
                  />
                  {errors.prn_dose_timeDropdown && (
                    <h6 style={{ color: "red", fontSize: "8px" }}>
                      {errors.prn_dose_timeDropdown}
                    </h6>
                  )}
                </Grid>
              </Grid>
              <Name
                ref={prnDoseOfferRef}
                label="Why are they each PRN offered?"
                rows={4}
                onValueChange={(value) =>
                  handleValueChange(value, "prn_dose_offered")
                }
                name="prn_dose_offered"
                required={true}
                preFilledvalue={medicationInfo.prn_dose_offered}
                multiline
                className="custom-textarea"
              />
              <Name
                ref={prnDoseGivenRef}
                label="When would this PRN be given?"
                rows={3}
                onValueChange={(value) =>
                  handleValueChange(value, "prn_dose_given")
                }
                name="prn_dose_given"
                required={true}
                
                multiline
                className="custom-textarea1"
              />
              <Grid container justifyContent={"space-between"}>
                <Grid item md={5.6}>
                  <Calendar
                    ref={prnDateStartRef}
                    label="Start Date"
                    onDateChange={handleDateChange}
                    required
                    preFilledvalue={medicationInfo.Prn_sDate}
                    name="Prn_sDate"
                    minDate={currentDate}
                  />
                </Grid>
                <Grid md={5.6}>
                  <Calendar
                    ref={prnDateEndRef}
                    label="End Date"
                    onDateChange={handleDateChange}
                    // required
                    preFilledvalue={medicationInfo.Prn_eDate}
                    name="Prn_eDate"
                    minDate={
                      medicationInfo.Prn_sDate
                        ? medicationInfo.Prn_sDate
                        : currentDate
                    }
                  />
                </Grid>
              </Grid>
            </Box>
          </>
        )}
        <Box>
          {medicationType === "Blister Pack" ||
          medicationType === "Scheduled" ||
          medicationType === "PRN" ? (
            <>
              <RadioInput
                label="Body Map?"
                name="Body-map"
                // ref={medication_supportRef}
                options={[
                  { value: "Disable", label: "Disable" },
                  { value: "Enable", label: "Enable" },
                ]}
                onValueChange={(value) => handleRadioChange(value, "Body-map")}
                preFilledvalue="Disable"
              />

              {bodyModeEnable === "Enable" && (
                <>
                  <Box sx={{ backgroundColor: "#F0FCFA",borderRadius:"15px" }}>
                    <BodyMap
                      setBodyData={setBodyData}
                      setBodyData1={setBodyData1}
                      setBodyData2={setBodyData2}
                    />
                  </Box>
                </>
              )}
            </>
          ) : (
            ""
          )}
        </Box>
        <Box sx={{ mt: 1 }}>
          <Grid container direction="row" alignItems="center">
            <Grid item>
              <Button
                variant="customcancel"
                color="primary"
                onClick={resetForm}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="customsave"
                color="primary"
                onClick={handleSubmit}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
      {openDialog && (
        <ConfirmationDialog
          openDialog={openDialog}
          TitleText="Do you want to leave without saving changes?"
          paraText="Any unsaved changes will be lost."
          IconUrl={Admin}
          cancelText="Cancel"
          confirmText="Confirm"
          onCancelFun={() => handleClose()}
          onConfirmFun={onClickYesDialogBtn}
          handleCloseDialog={handleClose}
        />
      )}
      <ToastContainer />
    </Container>
  );
}

export default AddMedicationForm;
