import { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import { Box } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import React from "react";
import PaginationCust from "./PaginationCust";
import { Link } from "react-router-dom";
import StyledCheckbox from "../../reusableComponents/StyledCheckbox";
import EnhancedTableHead from "./UserTableHeader";
import { Typography } from "@mui/material";
import { PAGINATION_LIST } from "../../../constants/constants";
import { ScreenHeights } from "../Theme";
import { Status } from "./Components";
import { slicedText } from "../../../utils/utils";
import UserTableEdit from "../../../assets/images/userTableIcons/UserTableEdit.svg";
import UserTableEditHover from "../../../assets/images/userTableIcons/UserTableEditHover.svg";
import UserTableView from "../../../assets/images/userTableIcons/UserTableView.svg";
import UserTableViewHover from "../../../assets/images/userTableIcons/UserTableViewHover.svg";
import UserTableDriveImage from "../../../assets/images/userTableIcons/UserTableCar.svg";

const useStyles = (theme) => ({
  root: {
    width: "100%",
    // maxWidth: 800,
    background: "blue",
  },
  container: {
    maxHeight: 440,
  },
  paper: {
    width: "100%",
    // marginBottom: theme.spacing(2),
    marginBottom: 2,
  },
  table: {
    // minWidth: 400,
    // maxWidth:500,
    maxHeight: 200,
    overflowY: "auto",
    width: "100%",

    border: "none",
    //boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Add box shadow
  },
  tablebody: {
    // minWidth: 400,
    // maxWidth:500,
    //maxHeight: 200,
    overflowY: "auto",
    //width: "100%",
    //border: "none",
    //boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Add box shadow
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
});

const HoverImg = ({ imgBeforeHover, imgAfterHover }) => {
  const [hover, setHover] = useState(false);
  return (
    <img
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{
        width: "26px",
        height: "26px",
      }}
      alt="table icons"
      src={hover ? imgAfterHover : imgBeforeHover}
    />
  );
};

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  maxHeight: ScreenHeights.userTableHeight,
  minWidth: 200,
  border: "none",
  boxShadow: "none",
  borderRadius: "5px", // Add border radius here
  background: "#FFFFFF",
  boxShadow: "0px 0px 10px 0px rgba(39, 153, 137, 0.46)",
  overflowY: "auto", // Ensure content inside respects border-radius
}));

const StyledTable = styled(Table)(({ theme }) => ({
  borderRadius: "5px", // Add border radius here
  overflow: "hidden", // Ensure content inside respects border-radius
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#EEFEFA",
    // border:"0px"
  },
  "&.MuiTableRow-root": {
    borderBottom: "none",
  },
}));

const styles = {
  tableCell: {
    borderBottom: "none", // This removes the border from the cells,
    paddingTop: "8px",
    paddingBottom: "8px",
  },
  pagragraph: { paddingLeft: "0px", fontSize: "13px", padding: "0px" },
};

export default function UserTable({
  usersList,
  toggleIDsInSelectedIDS,
  toggleAllIDs,
  selectedIDS,
}) {
  const classes = useStyles();
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationList, setPaginationList] = useState([]);
  const [itemsRange, setItemsRange] = useState(PAGINATION_LIST[1]);

  const [dense, setDense] = React.useState(true);
  const [orderByName, setOrderByName] = useState(null);
  const [order, setOrder] = useState("asc");

  const [orderByAdminList, setOrderByAdminList] = useState([]);
  const [isAdminOpen, setIAdminOpen] = useState(false);

  const [orderByStatusList, setOrderByStatusList] = useState([]);
  const [isStatusOpen, setIsStatusOpen] = useState(false);

  const [orderBy, setOrderBy] = useState("name");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  // Slicing and showing the list based on current page and pagination length
  const getSlicedList = (isGetLength = false) => {
    let start = currentPage === 1 ? 0 : (currentPage - 1) * itemsRange;
    let end = start + itemsRange;

    // return getFilters(usersList.slice(start, end));
    const filtedUserList = getFilters(usersList);

    // setMainUserList(filtedUserList)

    if (isGetLength) {
      return filtedUserList.length;
    }
    return filtedUserList.slice(start, end);
  };

  useEffect(() => {
    // let total = Math.ceil(usersList.length / itemsRange);

    const length = getSlicedList(true);
    let total = Math.ceil(length / itemsRange);

    let numbList = [];
    for (let i = 1; i <= total; i++) {
      numbList.push(i);
    }

    setPaginationList(numbList);
    setCurrentPage(1);
  }, [usersList, itemsRange, orderByName, orderByAdminList, orderByStatusList]);
  // [mainUserList , itemsRange]

  const incPagination = () => {
    if (currentPage < paginationList.length) {
      setCurrentPage(currentPage + 1);
    }
  };

  const decPagination = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const getFilters = (users) => {
    let filtUsers = users;

    // Sort in ascending order (A to Z)
    if (orderByName === null) {
      filtUsers = users;
    }

    // Sort in ascending order (A to Z)
    if (orderByName === "asc") {
      filtUsers = filtUsers.sort((a, b) =>
        a.first_name.localeCompare(b.first_name)
      );
    }

    // Sort in descending order (Z to A)
    if (orderByName === "dec") {
      filtUsers = filtUsers.sort((a, b) =>
        b.first_name.localeCompare(a.first_name)
      );
    }

    // Sort by admin  yes
    if (orderByAdminList.length === 1 && orderByAdminList.includes("yes")) {
      filtUsers = filtUsers.filter((each) => each.admin === 1);
    }

    // Sort by admin no
    if (orderByAdminList.length === 1 && orderByAdminList.includes("no")) {
      filtUsers = filtUsers.filter((each) => each.admin === 0);
    }

    // Sort by statu  Active
    if (orderByStatusList.length === 1 && orderByStatusList.includes("yes")) {
      filtUsers = filtUsers.filter((each) => each.status === 1);
    }

    // Sort by statu  Inactive
    if (orderByStatusList.length === 1 && orderByStatusList.includes("no")) {
      filtUsers = filtUsers.filter((each) => each.status === 0);
    }

    return filtUsers;
  };

  const getDataNotFound = () => {
    return (
      <StyledTableRow
        sx={{
          width: "100%",
          height: "100px",
          borderRadius: "10px",
          position: "relative",
        }}
      >
        <td>
          <Typography
            variant="body0"
            sx={{
              ...styles.pagragraph,
              width: "100%",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            No Users Found
          </Typography>
        </td>
      </StyledTableRow>
    );
  };

  return (
    <div className={classes.root}>
      <StyledTableContainer className={classes.container}>
        <StyledTable
          sx={classes.table}
          aria-labelledby="tableTitle"
          size={dense ? "small" : "medium"}
          aria-label="enhanced table"
          stickyHeader
        >
          <EnhancedTableHead
            setOrderByName={setOrderByName}
            orderByName={orderByName}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            toggleAllIDs={toggleAllIDs}
            orderByAdminList={orderByAdminList}
            setOrderByAdminList={setOrderByAdminList}
            isAdminOpen={isAdminOpen}
            setIAdminOpen={setIAdminOpen}
            orderByStatusList={orderByStatusList}
            setOrderByStatusList={setOrderByStatusList}
            isStatusOpen={isStatusOpen}
            setIsStatusOpen={setIsStatusOpen}
          />
          <TableBody sx={classes.tablebody}>
            {getSlicedList().length > 0
              ? getSlicedList().map((each, index) => (
                  <StyledTableRow key={index}>
                    <TableCell padding="checkbox" sx={styles.tableCell}>
                      <StyledCheckbox
                        checked={selectedIDS.includes(each.id)}
                        onChange={() => toggleIDsInSelectedIDS(each.id)}
                      />
                    </TableCell>

                    <TableCell sx={{ ...styles.tableCell, width: "20%" }}>
                      <Typography
                        variant="body0"
                        sx={{
                          ...styles.pagragraph,
                          textTransform: "capitalize",
                        }}
                      >
                        {slicedText(`${each.first_name} ${each.last_name}`)}

                        {each.drive ? (
                          <img
                            style={{
                              width: "20px",
                              height: "20px",
                              marginLeft: "8px",
                            }}
                            alt="car"
                            src={UserTableDriveImage} // Hear show the userTable car image
                          />
                        ) : (
                          ""
                        )}
                      </Typography>
                    </TableCell>

                    <TableCell sx={{ ...styles.tableCell, width: "20%" }}>
                      <Typography variant="body0" sx={styles.pagragraph}>
                        {slicedText(each.email)}
                      </Typography>
                    </TableCell>

                    <TableCell
                      sx={{
                        ...styles.tableCell,
                        textAlign: "center",
                        width: "17%",
                      }}
                    >
                      <Typography variant="body0" sx={styles.pagragraph}>
                        {/* {`${each.telephone_codes} ${each.contact_number}`} */}
                        {/* {`+${each.telephone_codes.split("+")[1]} ${
                          each.contact_number
                        }`} */}
                        {`${each.country_code} ${each.contact_number}`}
                      </Typography>
                    </TableCell>

                    <TableCell
                      sx={{
                        ...styles.tableCell,
                        textAlign: "center",
                        width: "14%",
                      }}
                    >
                      <Typography variant="body0" sx={styles.pagragraph}>
                        {each.admin === 1 ? "Yes" : "No"}
                      </Typography>
                    </TableCell>

                    <TableCell
                      sx={{ ...styles.tableCell, textAlign: "center" }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Status status={each.status} />
                      </Box>
                    </TableCell>

                    <TableCell
                      sx={{ ...styles.tableCell, textAlign: "center" }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          flexShrink: "0",
                        }}
                      >
                        <Link
                          to={`/users/user-dashboard/${each.id}`}
                          style={{ textDecoration: "none" }}
                        >
                          <HoverImg
                            imgBeforeHover={UserTableView}
                            imgAfterHover={UserTableViewHover}
                          />
                        </Link>

                        <Link
                          to={`/users/user-dashboard/${each.id}?edit=true`}
                          style={{
                            textDecoration: "none",
                            marginLeft: "8px",
                            marginTop: "1px",
                          }}
                        >
                          <HoverImg
                            imgBeforeHover={UserTableEdit}
                            imgAfterHover={UserTableEditHover}
                          />
                        </Link>
                      </Box>
                    </TableCell>
                  </StyledTableRow>
                ))
              : getDataNotFound()}
          </TableBody>
        </StyledTable>
      </StyledTableContainer>

      <PaginationCust
        total={getSlicedList(true)}
        tableName="Users"
        paginationList={paginationList}
        currentPage={currentPage}
        incPagination={incPagination}
        decPagination={decPagination}
        itemsRange={itemsRange}
        setItemsRange={setItemsRange}
        isDisabled={getSlicedList().length > 0}
      />
    </div>
  );
}
