import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  IconButton,
  Grid,
} from "@mui/material";
import CloseIcon from "../../assets/icons/close.svg";
import Name from "../reusableComponents/Name";
import CloseHoverIcon from "../../assets/icons/closehover.svg";
import AddNewRun from "../../assets/images/roster/addnewrun.svg";
import { getCookie } from "../../utils/utils";
import ConfirmationDialog from "../reusableComponents/ConfirmationDialog";
import Admin from "../../assets/images/users/Admin.svg";

export default function OpenAddNewRunDialog({
  openDialog,
  selectedRun,
  runsList,
  handleCloseDialog = () => {},
}) {
  const [form, setForm] = useState();
  const [error, setError] = useState(""); // State to store error message
  const [initialFormData, setInitialFormData] = useState();
  const [confirmationOpen, setConfirmationOpen] = useState(false); // New state for confirmation dialog open

  useEffect(() => {
    // If selectedRun is provided, set the run_name to selectedRun.run_name, else set it to an empty string
    setForm({ run_name: selectedRun?.run_name || "" });
    setInitialFormData({ run_name: selectedRun?.run_name || "" });
  }, [selectedRun]);

  const handleClose = (event, reason) => {
    if (reason === "backdropClick" || reason === "escapeKeyDown") {
      return;
    }
    handleCloseDialog();
  };

  const handleChange = (value) => {
    setForm((prevFormData) => {
      const updatedForm = { ...prevFormData, run_name: value };
      return updatedForm;
    });
  };

  const postAddNewRunAPI = async () => {
    try {
      const body = {
        run_name: form.run_name,
      };
      const token = getCookie();
      const URL = `${process.env.REACT_APP_BASE_URL}/roster-run`;

      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      };

      const response = await fetch(URL, options);
      const data = await response.json();

      if (response.ok && data.statusCode === 200) {
        handleCloseDialog();
      } else {
        console.log("Failed to create NewRun.");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const putEditRunNameAPI = async () => {
    try {
      const body = {
        run_name: form.run_name,
      };
      const token = getCookie();
      const URL = `${process.env.REACT_APP_BASE_URL}/roster-run/${selectedRun.id}`;

      const options = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      };

      const response = await fetch(URL, options);
      const data = await response.json();

      if (response.ok && data.statusCode === 200) {
        handleCloseDialog();
      } else {
        console.log("Failed to Edit Run Name.");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const runNameRef = useRef();

  const onConfirmFun = () => {
    let isValid = true;
    if (runNameRef.current && !runNameRef.current.handleValidation()) {
      isValid = false;
      setError("");
    }

    const duplicate = runsList.some(
      (run) =>
        run.run_name.trim() === form.run_name.trim() &&
        run.id !== selectedRun?.id
    );

    if (duplicate) {
      setError("This run name already exists. Please choose a different name.");
      return; // Prevent API call
    }

    if (isValid) {
      selectedRun ? putEditRunNameAPI() : postAddNewRunAPI();
    } else {
      console.error(
        "Form is invalid. Please correct the errors and try again."
      );
    }
  };

  const onCancelFun = () => {
    if (JSON.stringify(initialFormData) !== JSON.stringify(form)) {
      setConfirmationOpen(true); // Open confirmation dialog if there are unsaved changes
    } else {
      handleCloseDialog(); // Close directly if there are no changes
    }
  };

  return (
    <Dialog
      open={openDialog}
      onClose={handleClose}
      PaperProps={{
        sx: {
          width: "50%",
          maxWidth: "700px",
        },
      }}
    >
      <Box textAlign="center" justifyContent="Center">
        <IconButton
          aria-label="close"
          onClick={onCancelFun}
          sx={{
            position: "absolute",
            right: 1,
            top: 10,
            width: "20px !important",
            height: "20px !important",
            backgroundImage: `url(${CloseIcon})`,
            backgroundSize: "cover",
            "&:hover": {
              backgroundImage: `url(${CloseHoverIcon})`,
            },
          }}
        />
        <DialogContent sx={{ margin: "0px 30px" }}>
          <Box sx={{ marginTop: "10px" }}>
            <img
              style={{
                width: "10%",
                height: "auto",
              }}
              src={AddNewRun}
              alt="AddNewRun"
            />
            {selectedRun === null ? (
              <Typography variant="h3">Add New Run</Typography>
            ) : (
              <Typography variant="h3">Edit Run Name</Typography>
            )}
          </Box>

          <Box>
            <Grid xs={12}>
              <Name
                ref={runNameRef}
                label="Run Name"
                placeholder="Run Name"
                preFilledvalue={
                  selectedRun === null ? "" : selectedRun.run_name
                }
                onValueChange={handleChange}
                required
              />
              {error && (
                <Typography variant="errmsg" sx={{ textAlign: "left" }}>
                  {error}
                </Typography>
              )}
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="customcancel" onClick={onCancelFun} color="primary">
            Cancel
          </Button>
          <Button variant="customsave" onClick={onConfirmFun} color="primary">
            Save
          </Button>
        </DialogActions>
        {confirmationOpen && (
          <ConfirmationDialog
            openDialog={confirmationOpen}
            TitleText="Do you want to leave without saving changes?"
            paraText="Any unsaved changes will be lost."
            IconUrl={Admin}
            cancelText="Cancel"
            confirmText="Confirm"
            onCancelFun={() => setConfirmationOpen(false)}
            onConfirmFun={() => {
              setConfirmationOpen(false);
              handleCloseDialog();
            }}
          />
        )}
      </Box>
    </Dialog>
  );
}
