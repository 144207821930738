import React from "react";
import {
  Box,
  Typography,
  // Card,
  // Button,
  // TextField,
  // InputLabel,
  // Radio,
  // RadioGroup,
  // FormControlLabel,
  // FormLabel,
} from "@mui/material";
// import MenuItem from "@mui/material/MenuItem";
// import Select from "@mui/material/Select";

import { Link } from "react-router-dom";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

// import { useTheme } from "@mui/material/styles";
// import useMediaQuery from "@mui/material/useMediaQuery";
// import CountrySelect from "./CountrySelect";

import AddUserForm from "./adduser/AddUserForm";

// import { InputEl, SelectEl, StyledRadio } from "./InputsEl";
import CustomButtom from "../reusableComponents/CustomButton";
import TopContentContainer from "../reusableComponents/TopContentContainer";
import ContentContainer from "../reusableComponents/ContentContainer";

export default function UserFormView() {
  // const theme = useTheme();
  // const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box>
      <TopContentContainer>
        <Typography
          sx={{
            textTransform: "capitilize",
            fontSize: "16px",
            fontWeight: "600",
            color: "#279989",
            display: "flex",
            alignItems: "center",
          }}
        >
          User
          <ChevronRightIcon
            sx={{
              color: "#279989",
              width: "20px",
            }}
          />
          Add User
        </Typography>

        <Link to="/users" style={{ textDecoration: "none" }}>
          <CustomButtom text="Back" />
        </Link>
      </TopContentContainer>
      <ContentContainer isTransparentBg={true}>
        <AddUserForm />
      </ContentContainer>
    </Box>
  );
}
