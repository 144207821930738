import React, { useEffect, useRef, useState } from "react";
import { useJsApiLoader, GoogleMap, Marker } from "@react-google-maps/api";

// Map container style
const containerStyle = {
  width: "100%",
  height: "400px",
};

// Component
const MapWithGeocoding = ({ postcode }) => {
  const { isLoaded, loadError } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyAw6CI20mwdHFwffUeQh_2mN27YvB_PxKo",
    libraries: ["places"],
  });

  const mapRef = useRef(null);
  const markerRef = useRef(null);
  const [formattedAddress, setFormattedAddress] = useState("");
  const [circle, setCircle] = useState(null); // State to hold the Circle instance
  const [circleCenter, setCircleCenter] = useState(null);
  const [radius] = useState(200); // Set radius to 200 meters

  useEffect(() => {
    if (isLoaded) {
      initMap();
    }
  }, [isLoaded]);

  // Initialize the map
  const initMap = () => {
    mapRef.current = new window.google.maps.Map(
      document.getElementById("map"),
      {
        zoom: 15,
        center: { lat: -34.397, lng: 150.644 },
        mapTypeControl: false,
      }
    );

    // Create a marker
    markerRef.current = new window.google.maps.Marker({
      map: mapRef.current,
      draggable: true,
    });

    // Add listener for marker drag end
    markerRef.current.addListener("dragend", (event) => {
      const position = markerRef.current.getPosition();
      setCircleCenter(position);
      updateCircle(position); // Update the circle position when marker is dragged

      // Call geocode with the new position
      geocodeFromPosition(position);
    });

    // If a postcode is provided, geocode it
    if (postcode) {
      geocode(postcode);
    }
  };

  const geocode = (postcode) => {
    clear(); // Clear previous marker and circle
    const geocoder = new window.google.maps.Geocoder();

    geocoder
      .geocode({ address: postcode })
      .then((result) => {
        const { results } = result;

        if (results && results[0]) {
          const location = results[0].geometry.location;
          const formattedAddress = results[0].formatted_address;

          mapRef.current.setCenter(location);
          markerRef.current.setPosition(location);
          markerRef.current.setMap(mapRef.current);

          setFormattedAddress(formattedAddress);
          setCircleCenter(location); // Set circle center to the geocoded location
          updateCircle(location); // Create and display the circle
        }
      })
      .catch((e) => {
        alert("Geocode was not successful for the following reason: " + e);
      });
  };

  const geocodeFromPosition = (position) => {
    const geocoder = new window.google.maps.Geocoder();

    geocoder
      .geocode({ location: position })
      .then((result) => {
        const { results } = result;

        if (results && results[0]) {
          const formattedAddress = results[0].formatted_address;
          setFormattedAddress(formattedAddress); // Update formatted address
        }
      })
      .catch((e) => {
        alert("Geocode was not successful for the following reason: " + e);
      });
  };

  const updateCircle = (position) => {
    // If the circle already exists, remove it from the map
    if (circle) {
      circle.setMap(null);
    }

    // Create a new circle
    const newCircle = new window.google.maps.Circle({
      strokeColor: "#0000FF",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "rgba(0, 0, 255, 0.2)",
      fillOpacity: 0.35,
      map: mapRef.current,
      center: position,
      radius: radius, // Set radius in meters
    });

    // Update the state with the new circle
    setCircle(newCircle);
  };

  const clear = () => {
    if (markerRef.current) {
      markerRef.current.setMap(null);
    }
    if (circle) {
      circle.setMap(null); // Remove the existing circle from the map
    }
    setCircleCenter(null);
    setFormattedAddress("");
    setCircle(null); // Clear the circle state
  };

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading...</div>;

  return (
    <div>
      <div id="map" style={containerStyle}></div>
      {formattedAddress && (
        <div>
          <strong>Formatted Address:</strong> {formattedAddress}
        </div>
      )}
    </div>
  );
};

export default MapWithGeocoding;
