import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import {
  Box,
  FormLabel,
  Typography,
  TextField,
  InputAdornment,
} from "@mui/material";

const Currency = forwardRef(
  (
    {
      label,
      preFilledvalue,
      name,
      placeholder,
      onValueChange,
      required = false,
      maxLength,
      disabled,
    },
    ref
  ) => {
    const [value, setValue] = useState(preFilledvalue);
    const [errorMessage, setErrorMessage] = useState("");
    const [isFocused, setIsFocused] = useState(false);

    useEffect(() => {
      setValue(preFilledvalue);
      setErrorMessage("");
    }, [preFilledvalue]);

    const handleChange = (event) => {
      const inputValue = event.target.value;
      const sanitizedValue = sanitizeInput(inputValue);

      setValue(sanitizedValue);
      setErrorMessage(""); // Clear error message
      onValueChange(sanitizedValue, event.target.name); // Notify parent component about value change
    };

    const sanitizeInput = (input) => {
      const sanitized = String(input)
        .replace(/[^0-9.]/g, "") // Remove all non-numeric and non-decimal characters
        .replace(/^0+(?=\d)/, "") // Remove leading zeros
        .replace(/(\.\d{2})\d+/, "$1"); // Allow only 2 digits after decimal

      const parts = sanitized.split(".");
      if (parts.length > 2) {
        // Prevent multiple decimal points
        return `${parts[0]}.${parts[1].slice(0, 2)}`;
      }
      return sanitized;
    };

    const handleValidation = () => {
      const strValue = String(value).trim(); // Ensure value is treated as a string
      if (required && !strValue) {
        setErrorMessage(`${label} is required.`);
        return false;
      }
      if (!/^\d*(\.\d{1,2})?$/.test(strValue)) {
        // Check if the value is a valid currency format
        setErrorMessage(`Please enter a valid ${label}.`);
        return false;
      }
      if (maxLength && strValue.length > maxLength) {
        setErrorMessage(`${label} cannot exceed ${maxLength} characters.`);
        return false;
      }
      setErrorMessage("");
      return true;
    };

    useImperativeHandle(ref, () => ({
      handleValidation,
    }));

    const shouldShowCurrencySymbol = isFocused || value !== "";

    return (
      <Box sx={{ textAlign: "left" }}>
        <FormLabel>
          <Typography variant="h6">
            {label}
            {required && <span className="star">*</span>}
          </Typography>
        </FormLabel>
        <TextField
          fullWidth
          variant="outlined"
          placeholder={placeholder}
          value={value}
          disabled={disabled}
          name={name}
          onChange={handleChange}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          inputProps={{ maxLength }} // Restrict max length in the input field itself
          InputProps={{
            startAdornment: shouldShowCurrencySymbol && (
              <InputAdornment
                position="start"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%", // Ensures the adornment height matches the text field
                  marginRight: "0px",
                }}
              >
                <Box
                  sx={{
                    color: "#48464D",
                    paddingLeft: "10px",
                    marginRight: 0,
                  }}
                >
                  £
                </Box>
              </InputAdornment>
            ), // Pound symbol on the left, centered vertically
          }}
        />
        {errorMessage && (
          <Typography variant="errmsg">{errorMessage}</Typography>
        )}
      </Box>
    );
  }
);

export default Currency;
