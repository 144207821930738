import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import { useNavigate } from "react-router-dom";

import { Card, Box, Typography, Button } from "@mui/material";

import { removeCookie } from "../../../utils/utils";

export default function LogoutModal({ closeModal }) {
  const navigate = useNavigate();

  return (
    <Box
      component="div"
      position="fixed"
      sx={{
        height: "100%",
        width: "100%",
        position: "fixed",
        top: 0,
        left: 0,
        backgroundColor: "rgba(0,0,0,0.4)",
        zIndex: 3,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        component="div"
        sx={{
          backgroundColor: "white",
          borderRadius: "10px",
          padding: "40px 20px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "90%",
          maxWidth: "400px",
          margin: "auto",
        }}
      >
        <Typography
          variant="body2"
          sx={{
            fontSize: "14px",
            marginBottom: "40px",
          }}
          component="p"
        >
          Are you sure, you want to logout ??{" "}
        </Typography>
        <div style={{ width: "fit-content" }}>
          <Button
            onClick={() => {
              removeCookie();
              closeModal();
              navigate("/login");
            }}
            variant="outlined"
            style={{
              textTransform: "capitalize",
              fontSize: "12px",
              height: "35px",
              color: "#D84141",
              minWidth: "120px",
              border: "1.5px solid #D84141",
              marginRight: "20px",
              borderRadius: "8px",
            }}
          >
            Logout
          </Button>
          <Button
            onClick={closeModal}
            variant="outlined"
            style={{
              textTransform: "capitalize",
              fontSize: "12px",
              height: "35px",
              borderRadius: "8px",

              color: "#279989",
              minWidth: "120px",
              border: "1.5px solid #279989",
            }}
          >
            Cancel
          </Button>
        </div>
      </Box>
    </Box>
  );
}
