import { Box, Typography } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { PAGINATION_LIST } from "../../../constants/constants";

import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import Dropdown from "../../../assets/icons/dropdown.svg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";

export const SelectEl = ({ itemsRange, setItemsRange, isDisabled = false }) => {
  const menuItemStyle = {
    "&:hover": {
      backgroundColor: "#27998926", // Change background color on hover
      color: "rgba(39, 153, 137, 1)", // Adjust text color on hover
      fontWeight: 700,
    },
    fontSize: "12px",          // Adjust font size
    textAlign: "center",
    lineHeight: "normal",
    fontFamily: "Lora",
    fontStyle: "normal",
    marginTop: "1px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: 0,
    marginRight: 0,
  };

  const MenuProps = {

    anchorOrigin: {
      vertical: "top",
      horizontal: "center",
    },
    transformOrigin: {
      vertical: "bottom",
      horizontal: "center",
    },
  };

  return (
    <Select
      value={itemsRange}
      onChange={(e) => setItemsRange(e.target.value)}
      size="small"
      IconComponent={(props) => (
        <ExpandMoreRoundedIcon
          {...props}
          sx={{
            fontSize: "20px", // Reduce the size by 2px (default is 24px)
            color: "Black",
          }}
        />
      )}
      MenuProps={MenuProps}
      sx={{
        borderRadius: "5px",
        minWidth: "75px", 
        padding: "2px 5px",
        fontSize: "12px",
        height: "28px",
        margin: "0px",
        background: !isDisabled === true ? "rgba(232, 232, 232, 1)" : "#FFFFFF",
        cursor: !isDisabled === true ? "not-allowed" : "pointer",
        pointerEvents: !isDisabled === true ? "none" : "auto",
    
      }}
      defaultValue="diabled"
    >
      {PAGINATION_LIST.map((each, index) => (
        <MenuItem key={index} sx={menuItemStyle} value={each}>
          {each}
        </MenuItem>
      ))}
    </Select>
  );
};

export default function PaginationCust({
  total = 0,
  tableName = "",
  paginationList,
  currentPage,
  incPagination,
  decPagination,
  itemsRange,
  setItemsRange,
  isDisabled,
}) {
  const getPaginationText = () => {
    let start = currentPage === 1 ? 1 : (currentPage - 1) * itemsRange;
    let end = start + itemsRange;
    if (currentPage === 1) {
      end = end - 1;
    }

    if (end > total) {
      end = total;
    }

    if (currentPage !== 1) {
      start = start + 1;
    }

    // return `${start}-${end}`;
    return `${end === 0 ? 0 : start}-${end}`;
  };

  return (
    <Box
      component="div"
      sx={{
        background: "#E4F6F3",
        borderRadius: "8px",
        padding: "8px 10px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: "15px",
        marginBottom: "0px",
      }}
    >
      <Typography
        sx={{
          fontSize: "12px",
          textAlign: "left",
          fontFamily: "Arvo",
        }}
        component="p"
      >
        Showing {getPaginationText()} of {total} {tableName}
      </Typography>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <SelectEl
          isDisabled={isDisabled}
          itemsRange={itemsRange}
          setItemsRange={setItemsRange}
        />

        <hr
          style={{
            borderRight: "1.5px solid gray",
            height: "25px",
            margin: "0px 20px",
          }}
        />

        <ArrowForwardIosRoundedIcon
          onClick={currentPage > 1 ? decPagination : null}
          sx={{
            transform: "rotate(180deg)",
            marginRight: "15px",
            color: "#02020299",
            display: "flex",
            width: "16px",
            height: "16px",
            padding: "3px 3px",
            alignItems: "center",
            borderRadius: "3px",
            background: currentPage === 1 ? "#D6E7E4" : "white",
            cursor: currentPage === 1 ? "not-allowed" : "pointer",
            pointerEvents: currentPage === 1 ? "none" : "auto",
          }}
        />

        <ArrowForwardIosRoundedIcon
          onClick={currentPage < paginationList.length ? incPagination : null}
          sx={{
            color: "#02020299",
            display: "flex",
            width: "16px",
            height: "16px",
            padding: "3px 3px",
            alignItems: "center",
            borderRadius: "3px",
            background:
              currentPage < paginationList.length ? "white" : "#D6E7E4",
            cursor:
              currentPage < paginationList.length ? "pointer" : "not-allowed",
            pointerEvents:
              currentPage < paginationList.length ? "auto" : "none",
          }}
        />
      </Box>
    </Box>
  );
}
