import React, { useState, useEffect } from "react";
import { ClientAvatarForm } from "../aboutMe/ClientAvatarForm";
import {
  Box,
  Typography,
  Grid,
  Button,
  Autocomplete,
  FormLabel,
  TextField,
} from "@mui/material";
import { getCookie } from "../../../utils/utils";
import { ClientSideBarHeader } from "../clientReusableComponents/ClientSideBarHeader";
import UploadDocument from "../../../assets/images/clients/Upload_document.svg";
import Add from "../../../assets/images/clients/add.svg";

const Tab = ({ each, setActiveTabID, isActiveTab = false }) => {
  const [isHover, setHover] = useState(false);
  return (
    <Button
      onClick={() => setActiveTabID(each.id)}
      key={each.id}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      variant="outlinedSecondary"
      style={{
        border: isActiveTab
          ? "2px solid #279989"
          : isHover
          ? "2px solid #279989"
          : "2px solid rgba(39, 153, 137, 0.30)",
        color: isActiveTab ? "#279989" : isHover ? "#279989" : "#707171",
        background: each.id === 0 ? "#f0fcfa" : "white",
        borderRadius: each.id === 0 ? "8px" : "5px",
        width: "100%",
        margin: "5px 0px",
        marginBottom: each.id === 0 ? "20px" : "",
        display: "flex",
        justifyContent: "start",
        "&:hover": {
          background: "white",
          color: "#279989",
        },
      }}
    >
      {each.id === 0 ? (
        "+"
      ) : (
        <svg
          width="7"
          height="14"
          viewBox="0 0 7 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 1.5L6 7L1 12.5"
            stroke={isHover ? "#279989" : "#969999"}
            stroke-width="2"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      )}
      <span style={{ margin: "auto" }}> {each.occupation_type}</span>
    </Button>
  );
};

export const SettingsSideBar = ({
  clientId,
  clientMainData,
  setTabId,
  activeTabID,
}) => {
  const [userName, setUserName] = useState("");
  const [activeTab, setActiveTab] = useState("");
  const [mcnTabs, setMcnTabs] = useState([]);
  const setActiveTabID = (tab) => {
    setActiveTab(tab);
    setTabId(tab);
  };

  useEffect(() => {
    setActiveTab(activeTabID);
  }, [activeTabID]);

  useEffect(() => {
    if (clientMainData) {
      setUserName(
        `${clientMainData?.clientInfo?.first_name || ""} 
        ${clientMainData?.clientInfo?.middle_name || ""}
        ${clientMainData?.clientInfo?.last_name || ""}`
      );
    }
  }, [clientMainData]);

  useEffect(() => {
    const fetchNetwork = async () => {
      if (!clientId) return;
      try {
        const token = getCookie();
        const URL = `${process.env.REACT_APP_BASE_URL}/care-network-get-all/${clientId}`;
        const options = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        };
        const response = await fetch(URL, options);
        const data = await response.json();
        if (response.ok === true) {
          const info = data.data;
          setMcnTabs(info);
          if (activeTab === "") {
            setActiveTab(info[0].id);
            setTabId(info[0].id);
          } else {
            const active = info.filter((f) => f.id === activeTab)[0];
            setActiveTab(active.id);
            setTabId(active.id);
          }
        } else {
          setMcnTabs([]);
          setActiveTab(0);
          setTabId(0);
        }
      } catch (e) {
        console.error("error", e.message);
      }
    };
    fetchNetwork();
  }, [clientId, setTabId, activeTab]);

  return (
    <Box
      sx={{
        background: "#F0FCFA",
        borderRadius: "8px",
        padding: "10px",
        height: "100%",
        overflowY: "hidden",
        display: "flex",
        flexDirection: "column", // Ensure children stack vertically
      }}
    >
      <ClientSideBarHeader
        clientMainData={clientMainData}
        //isEditMode={isEditMode}
      />
    </Box>
  );
};
