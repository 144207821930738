import { Box, Button, Grid } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { ClientHeader } from "../clientReusableComponents/components";
import { getCookie } from "../../../utils/utils";
import MangeMedicationTable from "./MangeMedicationTable";
import useStyles from "../styles/useStyles";
import Loader from "../../../assets/images/common/logo_animation.gif";
import CustomButtom from "../../reusableComponents/CustomButton";


function ManageMedication({ clientMainData,clientId,setActiveTabId,setHasUnsavedChanges }) {
  const [userName, setUserName] = useState("");
  const [medicationData, setMedicationData] = useState([]);
  const [selectToDownload, setSelectToDownload] = useState([]);
  const [loading, setLoading] = useState(true);
  const classes = useStyles()
  const childRef = useRef();
  useEffect(() => {
    if (clientMainData) {
      setUserName(`${clientMainData?.clientInfo?.first_name || ""} `);
    }
  }, [clientMainData]);


  // const fetchMedicationData = async ()=>{
  // const apiUrl = `https://3.8.158.142:8443/api/medication?client_id=${clientId}`;
  //   try{
  //     const response = await fetch(apiUrl,{
  //       method: 'GET',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         'Authorization': `Bearer ${getCookie()}`
  //       }
  //     });
  //     if(response.ok === true && response.status === 200){
  //       setLoading(false);
  //     }
  //     const data = await response.json();
  //     console.log(data.data,'=======================>');
  //     setMedicationData(data.data);
  //     // setLoading(false);
  //   } catch (error){
  //     console.log(error);
  //   }
  // }
  const fetchMedicationData = async () => {
    const apiUrl = `https://3.8.158.142:8443/api/medication?client_id=${clientId}`;
    try {
      const response = await fetch(apiUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getCookie()}`,
        },
      });
  
      if (response?.ok) {
        const data = await response.json();
        console.log(data.data, '=======================>');
        
        if (data?.data && data?.data?.length > 0) {
          setMedicationData(data.data); // Set medication data
        } else {
          setMedicationData([]); // Handle no data
        }
      } else {
        console.log(`API Error: ${response.status}`);
      }
    } catch (error) {
      console.error('Error fetching medication data:', error);
      setMedicationData([]); // Handle errors by setting data to empty
    } finally {
      setLoading(false); // Ensure loading is turned off regardless of success or failure
    }
  };
  
  useEffect(() => {
  
    fetchMedicationData();
  }, [clientId]);
  const refreshData = async() => {
   await fetchMedicationData();
  };
  if (loading) {
    return (
      <Box
        sx={{
          width: "100%",
          height: "calc(100% - 50px)",
          backgroundColor: "white",
          border: "1px solid #dddddd",
          overflowY: "auto",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <img src={Loader} alt="Loading..." />
      </Box>
    ); // Render nothing while loading
    // Alternatively, you can render a loader like: <p>Loading...</p>
  }

   // Button click handler to trigger the PDF export in the child
   const handleDownloadClick = () => {
    // Trigger the exportClientsPdf function in the child component using the ref
    if (childRef.current) {
      childRef.current.exportClientsPdf();
      childRef.current.setSelectedMedications([]);
      setSelectToDownload([]);
    }
  };
  
  return (
    <Grid container sx={{width: "100%"}}>
      <Box sx={{display: "flex",alignItems: "center",gap: "0px",justifyContent: "space-between",width: "100%"}}>
      <Box className={classes.header} >
        <ClientHeader head={`Manage Medication for ${userName}`} />
      </Box>
      <CustomButtom text ='Download' onClick={handleDownloadClick} disabled={selectToDownload.length < 1} />
      </Box>
      <Box sx={{mt:3,width: "100%"}}>
      <MangeMedicationTable  ref={childRef}  medicationData ={medicationData}  setActiveTabId={setActiveTabId} refreshData ={refreshData} setHasUnsavedChanges={setHasUnsavedChanges} setSelectToDownload ={setSelectToDownload} /> 
      </Box>
      
    </Grid>
  );
}

export default ManageMedication;
