// theme.js
const themeStyle = {
  colors: {
    primary: {
      light: '#F0FCFA',
      dark: '#279989',
      white: '#FFFFFF'
    },
    secondary: {
      main: '#',
      light: '#ff79b0',
      dark: '#c60055',
      contrastText: '#ffffff',
    },
    nuetral: {
      white: '#FFFFFF',
      text: '',
      border: '',
    },
    success: {
      main: '#4caf50',
      light: '#80e27e',
      dark: '#087f23',
    },
    error: {
      main: '#f44336',
      light: '#ff7961',
      dark: '#ba000d',
    },
    warning: {
      main: '#ff9800',
      light: '#ffc947',
      dark: '#c66900',
    },
    info: {
      main: '#2196f3',
      light: '#6ec6ff',
      dark: '#0069c0',
    },
  },
  borders: {
    // primary:"10px",
    // secondary:"8px",
    borderRadius:"10px",
    primary: {
      light: '1px solid #63a4ff',
      main: '1px solid #1976d2',
      dark: '1px solid #004ba0',
    },
    secondary: {
      light: '1px solid #ff79b0',
      main: '1px solid #ff4081',
      dark: '1px solid #c60055',
    },
    neutral: {
      main: '1px solid #e0e0e0',
      focus: '1px solid #333333',
    },
  },
  spacing: {
    xs: '4px',
    small: '8px',
    medium: '16px',
    large: '24px',
    xl: '32px',
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: {
      small: '12px',
      medium: '14px',
      large: '18px',
      xl: '24px',
    },
    fontWeight: {
      regular: 400,
      bold: 700,
      light: 300,
    },
    headings: {
      h1: {
        fontSize: '32px',
        fontWeight: 700,
      },
      h2: {
        fontSize: '28px',
        fontWeight: 700,
      },
      h3: {
        fontSize: '24px',
        fontWeight: 700,
      },
    },
  },
  shadows:{
    header:"0px 4px 4px 0px #27998933",
    box:"0px 0px 10px 0px #27998933"

  }
  // Add more categories as needed
};

export default themeStyle;
