import React from "react";
import { useState, useEffect, useRef } from "react";
import { Container, Grid } from "@mui/material";
import { Typography, Button, Box } from "@mui/material";
import ClientAboutMeView from "./ClientAboutMeView";
import { ClientAboutMeEdit } from "./ClientAboutMeEdit";
import EditIcon from "@mui/icons-material/Edit";
import { ClientSideBar } from "../aboutMe/ClientSideBar";
import { useParams, useSearchParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

const AboutMe = ({ clientMainData, setHasUnsavedChanges, reloadTheData }) => {
  const [clientData, setClientData] = useState({});
  const [activeId, setActiveId] = useState(null);

  const [activeTabID, setActiveTabID] = useState("");

  const [searchParams] = useSearchParams();
  const isEditQueryParams = searchParams.get("edit");

  const [isEditMode, setIsEditMode] = useState(
    isEditQueryParams ? true : false
  );

  const toggleEditMode = () => {
    setIsEditMode(!isEditMode);
    reloadTheData();
  };

  useEffect(() => {
    setActiveTabID(tabsListConstants[0].id);
  }, []);

  const tabsListConstants = [
    {
      id: "section1",
      text: "Personal Info",
      ref: useRef(null),
    },
    {
      id: "section2",
      text: "Next to Kin",
      ref: useRef(null),
    },
    {
      id: "section3",
      text: "Funding Status",
      ref: useRef(null),
    },
  ];

  const scrollToSection = (id) => {
    const tab = tabsListConstants.find((tab) => tab.id === id);

    if (tab && tab.ref.current) {
      tab.ref.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  // Set up IntersectionObserver
  useEffect(() => {
    const options = {
      root: null, // viewport
      threshold: 0.5, // 50% of the section is visible
    };

    const observerCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const visibleSection = tabsListConstants.find(
            (section) => section.ref.current === entry.target
          );
          if (visibleSection) {
            setActiveTabID(visibleSection.id); // Update activeTabID
          }
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, options);

    // Attach observer to all section refs
    tabsListConstants.forEach((tab) => {
      if (tab.ref.current) {
        observer.observe(tab.ref.current);
      }
    });

    // Cleanup observer on unmount
    return () => {
      tabsListConstants.forEach((tab) => {
        if (tab.ref.current) {
          observer.unobserve(tab.ref.current);
        }
      });
    };
  }, [tabsListConstants]);

  return (
    <Grid container direction="row" alignItems="top">
      <Box
        sx={{
          padding: 3,
          borderRadius: "10px",
          height: "calc(100vh - 220px)",
          boxShadow: "0px 0px 10px 0px #27998933",
          width: "100%",
        }}
      >
        <div style={{ display: "flex", height: "100%" }}>
          <div style={{ width: "20%", height: "100%" }}>
            {isEditMode === false && (
              <Button
                variant="outlined"
                color="secondary"
                startIcon={<EditIcon />}
                onClick={toggleEditMode}
                sx={{
                  margin: 0,
                  height: "35px",
                  position: "absolute",
                  top: "30px",
                  right: "35px",
                  zIndex: 11,
                }}
              >
                Edit
              </Button>
            )}

            <ClientSideBar
              isEditMode={isEditMode}
              clientMainData={clientMainData}
              scrollToSection={scrollToSection}
              tabsListConstants={tabsListConstants}
              activeTabID={activeTabID}
              setActiveTabID={setActiveTabID}
            />
          </div>

          <div
            style={{
              flex: 1,
              flexDirection: "column",
              overflow: "auto",
              width: "80%",
              paddingLeft: "35px",
              paddingRight: 5,
              position: "relative",
            }}
          >
            <Grid item xs={10}>
              {isEditMode ? (
                <ClientAboutMeEdit
                  clientMainData={clientMainData}
                  toggleEditMode={toggleEditMode}
                  tabsListConstants={tabsListConstants}
                  setHasUnsavedChanges={setHasUnsavedChanges}
                />
              ) : (
                <ClientAboutMeView
                  clientMainData={clientMainData}
                  tabsListConstants={tabsListConstants}
                  toggleEditMode={toggleEditMode}
                  isEditMode={isEditMode}
                  activeTabID={activeTabID}
                  setActiveTabID={setActiveTabID}
                  // setHasUnsavedChanges={setHasUnsavedChanges}
                />
              )}
            </Grid>
          </div>
        </div>
      </Box>
    </Grid>
  );
};

export default AboutMe;
