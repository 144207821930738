import React, { useState, forwardRef } from "react";
import { Box, TextField, Autocomplete, Checkbox } from "@mui/material";

const MultipleSelectDropdown = forwardRef(
  ({ list, required = false, onDropdownSelect, selectedOptions = {} }, ref) => {
    //const [selectedOptions, setSelectedOptions] = useState([]);

    return (
      <Box sx={{ textAlign: "left" }}>
        <Autocomplete
          multiple
          options={list}
          disableCloseOnSelect
          getOptionLabel={(option) => option?.text || ""}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          value={selectedOptions}
          onChange={onDropdownSelect} // Update selected options
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                checked={selected}
                sx={{
                  padding: "0 8px 0 0",
                  color: selected ? "#279989" : "#969999",
                }}
              />
              {option.text}
            </li>
          )}
          renderTags={(value) => {
            const maxVisible = 1;
            const visibleLabels = value
              .slice(0, maxVisible)
              .map((option) => option.text)
              .join(", ");
            const additionalCount = value.length - maxVisible;

            return (
              <span>
                {visibleLabels}
                {additionalCount > 0 && `, +${additionalCount}`}
              </span>
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={selectedOptions.length === 0 ? "Select" : ""}
              fullWidth
              InputProps={{
                ...params.InputProps,
                readOnly: true,
                style: { cursor: "none" }, // Show pointer cursor
              }} // Disable typing
              sx={{
                "& .MuiInputBase-root": {
                  padding: "0 8px",
                  textAlign: "center",
                  alignItems: "center",
                  alignContent: "center",
                },
                "& .MuiInputBase-input": {
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  cursor: "pointer", // Show pointer cursor to indicate clickability
                },
              }}
            />
          )}
          onKeyDown={(event) => {
            event.preventDefault(); // Prevent all typing
          }}
        />
      </Box>
    );
  }
);

export default MultipleSelectDropdown;
