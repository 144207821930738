import React, { useState } from "react";
import {
  Box,
  Card,
  Typography,
  Dialog,
  DialogContent,
  Grid,
  FormLabel,
  TextField,
  IconButton,
  Container,
} from "@mui/material";
import { Name, Calendar, RadioInput } from "../../reusableComponents"; // Adjust the path
import CloseIcon from "../../../assets/icons/close.svg";
import CloseHoverIcon from "../../../assets/icons/closehover.svg";
import { ThemeProvider } from "@mui/material/styles";
import { ViewTheme } from "../../users/Theme";
import dayjs from "dayjs";

export default function CarePlanInformationDialog({
  inactiveassessment,
  assessmentQuestions,
  assessmentName,
  user,
  open,
  onClose,
}) {
  const signName = `${user.first_name + " " + user.last_name}`;

  const formatDates = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };
  return (
    <ThemeProvider theme={ViewTheme}>
      <Dialog open={open} onClose={onClose}>
        <Box textAlign="center" justifyContent="Center">
          {/* Close button */}
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 10,
              top: 10,
              width: "28px ! important",
              height: "28px ! important",
              backgroundImage: `url(${CloseIcon})`, // Default close.svg
              backgroundSize: "cover",
              "&:hover": {
                backgroundImage: `url(${CloseHoverIcon})`, // On hover, change to closehover.svg
              },
            }}
          >
            {/* Empty content since the SVG icons are used in background */}
          </IconButton>
          <DialogContent
            sx={{
              background: "#F0FCFA",
              padding: "0",
            }}
          >
            <Grid item lg={12} xs={12}>
              <Card
                variant="elevated"
                elevation={0}
                sx={{
                  height: "calc(80vh)",
                  paddingRight: 2,
                  margin: 0,
                  backgroundColor: ["#ffffff", "!important"], // If you need !important
                }}
              >
                <Box
                  sx={{
                    height: "100%",
                    overflow: "auto",
                    paddingRight: 8,
                    width: "100%",
                  }}
                >
                  <Grid container direction="row" alignItems="top">
                    <Grid item xs={12}>
                      <Box
                        textAlign="left"
                        sx={{ padding: ViewTheme.spacing(3, 3) }}
                      >
                        {assessmentQuestions
                          .filter(
                            (assessment) =>
                              assessment.assessment_name === assessmentName
                          )
                          .map((assessment, index) => (
                            <Box key={index}>
                              <Typography variant="h4">
                                {assessment.assessment_name}
                              </Typography>
                              {assessment.questionslist.map(
                                (question, index, arr) => (
                                  <>
                                    <Typography
                                      variant="h6"
                                      sx={{
                                        marginTop: "4px",
                                        marginBottom: "-20px",
                                      }}
                                    >
                                      {question.questionname}
                                    </Typography>

                                    {index == arr.length - 1 ? null : (
                                      <Container sx={{ marginLeft: "-10px" }}>
                                        <RadioInput
                                          // name={`Radio${index}`}
                                          options={[
                                            { value: "yes", label: "Yes" },
                                            { value: "no", label: "No" },
                                          ]}
                                          onValueChange={() => {}}
                                          disabled="true"
                                          preFilledvalue={
                                            inactiveassessment[
                                              `status_${question.questionid}`
                                            ]?.toLowerCase() || ""
                                          }
                                        />

                                        {inactiveassessment[
                                          `status_${question.questionid}`
                                        ]?.toLowerCase() === "yes" ? (
                                          <Name
                                            // name={`comment_${question.questionid}`}
                                            className="multiline-textfield"
                                            label="Please Mention"
                                            placeholder="Please Mention"
                                            multiline
                                            rows="7"
                                            // onValueChange={handleValueChange}
                                            preFilledvalue={
                                              inactiveassessment[
                                                `comment_${question.questionid}`
                                              ] || "" // Dynamically access the status field
                                            }
                                            disabled={true}
                                          ></Name>
                                        ) : null}
                                      </Container>
                                    )}
                                  </>
                                )
                              )}

                              <Name
                                className="multiline-textfield"
                                placeholder="Care properties and safety concerns"
                                multiline
                                rows="7"
                                // onValueChange={handleValueChange}
                                disabled="true"
                              />

                              <FormLabel sx={{ fontSize: 18 }}>
                                Signatures of All Involved Admins in the
                                Assessment
                              </FormLabel>

                              <Grid //First signature
                                container
                                direction="row"
                                alignItems="top"
                                sx={{
                                  gap: 8,
                                  p: 2,
                                  mb: -2,
                                }}
                              >
                                <Grid item xs={10} md={5}>
                                  <Name
                                    label="Name"
                                    value={signName}
                                    placeholder={signName}
                                    preFilledvalue={inactiveassessment.sign_1}
                                    disabled={true}
                                  />
                                </Grid>
                                <Grid item xs={10} md={5}>
                                  <Calendar
                                    label="Date"
                                    preFilledvalue={inactiveassessment.date_1}
                                    disabled={true}
                                  />
                                </Grid>
                              </Grid>

                              <Grid //Second signature
                                container
                                direction="row"
                                alignItems="top"
                                sx={{
                                  gap: 8,
                                  p: 2,
                                }}
                              >
                                <Grid item xs={10} md={5}>
                                  <Name
                                    label="Name"
                                    placeholder="Name"
                                    preFilledvalue={inactiveassessment.sign_2}
                                    disabled={true}
                                  />
                                </Grid>
                                <Grid item xs={10} md={5}>
                                  <Calendar
                                    label="Date"
                                    preFilledvalue={inactiveassessment.date_2}
                                    disabled={true}
                                  />
                                </Grid>
                              </Grid>
                            </Box>
                          ))}
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Card>
            </Grid>
          </DialogContent>
        </Box>
      </Dialog>
    </ThemeProvider>
  );
}
