import React from "react";
import { TodoDashBord } from "./TodoDashBord";
import { Box, Grid } from "@mui/material";

const Todos = ({clientId, clientMainData}) => {
  return (
    <Grid container direction="row" alignItems="top">
      <Box
        sx={{
          // padding: 3,
          borderRadius: "10px",
          height: "calc(100vh - 220px)",
          boxShadow: "0px 0px 10px 0px #27998933",
          width: "100%",
        }}
      >
        <TodoDashBord  clientId={clientId} clientMainData={clientMainData}/>
      </Box>
    </Grid>
  );
};

export default Todos;
