import React from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';

// Custom styles using styled
const StyledAppBar = styled(AppBar)({
  position: 'relative',
});

const FlexTypography = styled(Typography)({
  flex: 1,
});

const ImgContainer = styled('div')({
  position: 'relative',
  flex: 1,
  padding: 16,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const StyledImg = styled('img')({
  maxWidth: '100%',
  maxHeight: '100%',
});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class ImgDialog extends React.Component {
  state = {
    open: false,
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    return (
      <Dialog
        fullScreen
        open={!!this.props.img}
        onClose={this.props.onClose}
        TransitionComponent={Transition}
      >
        <StyledAppBar>
          <Toolbar>
            <IconButton
              color="inherit"
              onClick={this.props.onClose}
              aria-label="Close"
            >
              <CloseIcon />
            </IconButton>
            <FlexTypography
              variant="h6"
              color="inherit"
            >
              Cropped image
            </FlexTypography>
          </Toolbar>
        </StyledAppBar>
        <ImgContainer>
          <StyledImg src={this.props.img} alt="Cropped" />
        </ImgContainer>
      </Dialog>
    );
  }
}

export default ImgDialog;
