import React from "react";
import { Box, } from "@mui/material";

export default function TopContentContainer(props) {

    return (
        <Box sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "30px auto",
            backgroundColor: "transparent",
            width: "100%",
        }}>
            {
                props.children
            }

        </Box>
    );
}
