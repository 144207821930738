import React, { useState, useEffect, useCallback } from "react";
import {
  Container,
  Grid,
  Button,
  Typography,
  Box,
  FormLabel,
  Checkbox,
  FormControlLabel,
  Slider,
  Card,
  TextField,
} from "@mui/material";
import { Name, Calendar, RadioInput, Dropdown } from "../../reusableComponents"; // Adjust the path
import ConfirmationDialog from "../../reusableComponents/ConfirmationDialog";
import { getCookie } from "../../../utils/utils";
import Admin from "../../../assets/images/users/Admin.svg";
import dayjs from "dayjs";

export const AssessmentCardEdit = ({
  assessmentQuestions,
  assessmentName,
  assessmentId,
  user,
  toggleEditMode,
  name,
  selectedCard,
  isNewAssessmentToggle,
  resetNewAssessmentVisibility,
}) => {
  const currentUserId = `${user.id}`;

  const signName = `${user.first_name + " " + user.last_name}`;

  const [initialForm, setInitialForm] = useState([{}]);

  // const [sign1Submitted, setSign1Submitted] = useState(false); //To check if 2nd sign should be added

  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const fetchAssessmetDetails = async () => {
    try {
      const token = getCookie();
      const URL = `${process.env.REACT_APP_BASE_URL}/${selectedCard.api_assessment_name}?client_id=${selectedCard.client_id}`;
      const response = await fetch(URL, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch assessment details");
      }

      const data = await response.json();
      const activeData = data.data.filter((item) => item.status === "Active");

      setInitialForm(activeData.length > 0 ? activeData : [{}]); // Reset or set form
      let Sign2 = initialForm[0].sign_1 === signName ? "" : signName;

      setInitialForm((prevState) => [
        {
          ...prevState[0],
          sign_2: Sign2,
          date_2: dayjs(),
        },
      ]);
      console.log("Fetched Form Data:", activeData);
    } catch (error) {
      console.error("Error fetching assessment details:", error);
      setInitialForm([{}]); // Fallback to an empty form if fetch fails
    }
  };
  // useEffect(() => {}, []);

  useEffect(() => {
    if (isNewAssessmentToggle || selectedCard.status === "") {
      if (assessmentQuestions?.length > 0) {
        // Initialize form for new assessment
        const defaultForm = assessmentQuestions
          .filter((assessment) => assessment.assessment_name === assessmentName) // Filter by selected assessment name
          .reduce(
            (acc, assessment) => {
              assessment.questionslist.forEach((question, index) => {
                acc[`status_${question.questionid}`] = ""; // Initialize status
                acc[`comment_${question.questionid}`] = ""; // Initialize comment
                acc[`q${index + 1}`] = question.questionid; // Include questionid explicitly
              });
              return acc;
            },
            {
              client_id: selectedCard.client_id,
              sign_1: signName,
              date_1: dayjs(),
              sign_2: "",
              date_2: "",
              consent: 0,
            }
          );

        setInitialForm([defaultForm]); // Set the initialized form
        console.log("Initialized Form with Question IDs:", defaultForm); // Debugging
      } else {
        // Initialize a simpler default form
        const defaultForm = {
          client_id: selectedCard.client_id,
          sign_1: signName,
          date_1: dayjs(),
          sign_2: "",
          date_2: "",
          consent: 0,
        };

        setInitialForm([defaultForm]);
        console.log(
          "Initialized Form without Assessment Questions:",
          defaultForm
        );
      }
    } else if (isNewAssessmentToggle || selectedCard.status === "Active") {
      const defaultForm = {
        client_id: selectedCard.client_id,
        sign_1: signName,
        date_1: dayjs(),
        sign_2: "",
        date_2: "",
        consent: 0,
      };

      setInitialForm([defaultForm]); // Set the initialized form
      console.log("Initialized Form with Question IDs:", defaultForm); // Debugging
    } else {
      // Fetch data for editing
      fetchAssessmetDetails();
    }
  }, [
    isNewAssessmentToggle,
    assessmentQuestions,
    assessmentName,
    selectedCard,
    signName,
  ]);

  const putAssessmentAPI = async () => {
    try {
      const token = getCookie();

      // Exclude keys starting with 'q' and specific keys like 'assessment_name_id' and 'client_id'
      const excludedKeys = [
        "assessment_name_id",
        "client_id",
        "id",
        "updated_at",
        "created_at",
      ];
      const filteredBody = Object.keys(initialForm[0])
        .filter((key) => !key.startsWith("q") && !excludedKeys.includes(key)) // Exclude specific keys
        .reduce((acc, key) => {
          acc[key] = initialForm[0][key];
          return acc;
        }, {});

      const URL = `${process.env.REACT_APP_BASE_URL}/${selectedCard.api_assessment_name}/${initialForm[0].client_id}/${initialForm[0].id}`;
      const options = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(filteredBody),
      };

      const response = await fetch(URL, options);
      const data = await response.json();

      if (response.ok && data.statusCode === 200) {
        console.log("Data posted");
      } else {
        console.log("Failed to edit Assessment.");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const postAsessmentDetails = async () => {
    try {
      // const updatedInitialForm = initialForm.filter()

      const token = getCookie();

      console.log("POST initialForm", initialForm);

      const BASE_URL = "https://3.8.158.142:8443/";
      const URL = `${BASE_URL}api/${selectedCard.api_assessment_name}/${selectedCard.assessment_id}`;
      // const URL = `${BASE_URL}api/client/aboutMe/31`;
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(initialForm[0]),
      };

      const response = await fetch(URL, options);
      const data = await response.json();
      console.log("Form Data Body option.body.......: ", data);

      if (response.ok && data.statusCode === 200) {
        // toggleEditMode();
        console.log("Data uploaded successfully");
      } else {
        console.log("Failed to create user.");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  useEffect(() => {
    console.log("Updated Initial Form:", initialForm);
  }, [initialForm]);

  const [dialogOpen, setDialogOpen] = useState(false); // Track dialog visibility

  // Open the ConfirmationDialog if there are unsaved changes
  const handleSave = () => {
    if (hasUnsavedChanges) {
      setDialogOpen(true); // Show dialog
    } else {
      handleSubmit(); // No changes, proceed with save
    }
  };

  // Handle ConfirmationDialog actions
  const handleDialogConfirm = () => {
    setDialogOpen(false); // Close dialog
    if (resetNewAssessmentVisibility) {
      resetNewAssessmentVisibility(); // Call the parent function to reset visibility
    }
  };

  const handleDialogCancel = () => {
    setDialogOpen(false); // Close dialog without saving
  };

  const handleRadioChange = (value, name) => {
    setInitialForm((prevState) => {
      const updatedForm = prevState.length ? [...prevState] : [""]; // Initialize if empty
      updatedForm[0] = {
        ...updatedForm[0],
        [name]: value,
      };
      handleChange(updatedForm);
      return updatedForm;
    });
  };

  const [isCheckboxTrue, setIsCheckboxTrue] = useState(false);
  const [checkboxError, setCheckboxError] = useState(false);

  // const handleCheckboxChange = () => {
  //   isCheckboxTrue ? setIsCheckboxTrue(false) : setIsCheckboxTrue(true);
  //   // setIsCheckboxTrue(event.target.checked); // Update state based on user interaction
  // };
  const handleCheckboxChange = () => {
    setIsCheckboxTrue((prevState) => {
      const newState = !prevState; // Toggle the checkbox state
      if (newState) {
        setCheckboxError(false); // Hide the error message if the checkbox is checked
      }

      // Update the `consent` field in `initialForm`
      setInitialForm((prevForm) => {
        const updatedForm = [...prevForm];
        updatedForm[0] = {
          ...updatedForm[0],
          consent: newState ? 1 : 0, // Set consent to 1 if checked, 0 if unchecked
        };
        return updatedForm;
      });

      return newState;
    });
  };

  const handleValueChange = (value, name) => {
    setInitialForm((prevState) => {
      const updatedForm = prevState.length ? [...prevState] : " "; // Initialize if empty
      updatedForm[0] = {
        ...updatedForm[0],
        [name]: value,
      };
      handleChange(updatedForm);
      return updatedForm;
    });
  };

  const handleDateChange = (date, name) => {
    setInitialForm((prevState) => {
      const updatedForm = [...prevState]; // Create a shallow copy of the array
      if (updatedForm[0]) {
        updatedForm[0] = {
          ...updatedForm[0], // Create a shallow copy of the object
          [name]: date, // Update the specific field
        };
      }
      handleChange(updatedForm);
      return updatedForm;
    });
  };

  const areObjectsEqual = (obj1, obj2) => {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  };

  const handleChange = (updatedForm) => {
    areObjectsEqual(updatedForm, initialForm)
      ? setHasUnsavedChanges(false)
      : setHasUnsavedChanges(true); // Detect changes
  };

  const handleSubmit = () => {
    // Validate checkbox
    if (initialForm[0]?.consent === 0) {
      setCheckboxError(true);
      return; // Stop submission if checkbox is not checked
    }

    setCheckboxError(false); // Clear any existing checkbox errors

    console.log("Form Data before submission:", initialForm[0]);

    // Handle submission based on mode
    if (isNewAssessmentToggle || selectedCard.status === "") {
      postAsessmentDetails(initialForm[0])
        .then(() => {
          console.log("New Assessment submitted successfully.");
          toggleEditMode();
          if (resetNewAssessmentVisibility) {
            resetNewAssessmentVisibility();
          }
        })
        .catch((error) => {
          console.error("Error submitting new assessment:", error);
        });
    } else {
      putAssessmentAPI(initialForm[0])
        .then(() => {
          console.log("Assessment updated successfully.");
          toggleEditMode();
          if (resetNewAssessmentVisibility) {
            resetNewAssessmentVisibility();
          }
        })
        .catch((error) => {
          console.error("Error updating assessment:", error);
        });
    }
  };

  // marks common for all Sliders
  const marks = [
    {
      value: 1,
      label: "1",
    },
    {
      value: 2,
      label: "2",
    },
    {
      value: 3,
      label: "3",
    },
    {
      value: 4,
      label: "4",
    },
    {
      value: 5,
      label: "5",
    },
  ];

  // Activity Risk Assessment section begins
  const [activities, setActivities] = useState([
    { activity: "", task: "", risk: "", range: 1, equipment: "", action: "" },
  ]);
  const handleActivityValueChange = (value, name, index) => {
    setInitialForm((prevState) => {
      const updatedForm = prevState.length ? [...prevState] : [{}];
      const currentActivities = updatedForm[0]?.[`activity_${index}`] || {};
      updatedForm[0] = {
        ...updatedForm[0],
        [`activity_${index}`]: {
          ...currentActivities,
          [name]: value,
        },
      };
      handleChange(updatedForm);
      return updatedForm;
    });
  };

  const addActivity = () => {
    setActivities([
      ...activities,
      {
        activity: "",
        task: "",
        risk: "",
        range: 0,
        equipment: "",
        action: "",
      },
    ]);
  };

  const activityRiskAssessment = () => {
    return (
      <Box>
        <Typography variant="h4">Activity Risk Assessment</Typography>
        <Typography variant="h6"></Typography>
        <Container sx={{ marginLeft: "-10px" }}>
          <RadioInput
            label={`Does ${name} require support with activities?`}
            // name={`status_${index}`}
            name="q_support"
            options={[
              { value: "yes", label: "Yes" },
              { value: "no", label: "No" },
            ]}
            onValueChange={(e) => {
              handleRadioChange(e, "q_support");
            }}
            preFilledvalue={
              initialForm[0]?.q_support?.toLowerCase() || "" // Dynamically access the status field
            }
          />
        </Container>
        {initialForm[0]?.q_support?.toLowerCase() === "yes" && (
          <>
            {activities.map((activity, index) => (
              <Box
                key={index}
                sx={{
                  marginBlock: "20px",
                  border: "1px solid #2799894d",
                  padding: "5px 15px",
                  borderRadius: "8px",
                  position: "relative",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    display: "inline-block",
                    position: "absolute",
                    margin: "-19px 0 0 10px",
                    backgroundColor: "#F0FCFA",
                    fontWeight: "600",
                    color: "#279989",
                  }}
                >
                  Activity {index + 1}
                </Typography>
                <Grid container spacing={2} direction="row" paddingTop={4}>
                  <Grid item md={5.75} xs={12}>
                    <Name
                      label="Activity"
                      name="activity_name"
                      onValueChange={(value) =>
                        handleActivityValueChange(value, "activity_name", index)
                      }
                      preFilledvalue={
                        initialForm[0]?.[`activity_${index}`]?.activity_name ||
                        ""
                      }
                    />
                  </Grid>
                  <Grid item md={0.5} xs={false} />
                  <Grid item md={5.75} xs={12}>
                    <Name
                      label="Task/Support Required"
                      name="task_name"
                      onValueChange={(value) => {
                        handleActivityValueChange(value, "task_name", index);
                      }}
                      preFilledvalue={
                        initialForm[0]?.[`activity_${index}`]?.task_name || ""
                      }
                    />
                  </Grid>
                  <Grid item md={5.75} xs={12}>
                    <Dropdown
                      label="Risk"
                      name={`risk_level`}
                      list={[
                        { text: "Low" },
                        { text: "Medium" },
                        { text: "High" },
                      ]}
                      onDropdownSelect={(v) =>
                        handleActivityValueChange(
                          v?.text || "",
                          "risk_level",
                          index
                        )
                      }
                      preFilledvalue={
                        initialForm[0]?.[`activity_${index}`]?.risk_level || ""
                      }
                    />
                  </Grid>
                  <Grid item md={5.75} xs={12} alignContent="flex-end">
                    <Box sx={{ paddingInline: "50px" }}>
                      <Slider
                        aria-label="Risk Level"
                        name="range"
                        defaultValue={
                          initialForm[0]?.[`activity_${index}`]?.range || ""
                        }
                        valueLabelDisplay="auto"
                        step={1}
                        min={1}
                        max={5}
                        sx={{
                          color: "#279989",
                          "& .MuiSlider-thumb": {
                            backgroundColor: "#279989",
                          },
                          "& .MuiSlider-track": {
                            backgroundColor: "#279989",
                          },
                          "& .MuiSlider-rail": {
                            backgroundColor: "#ccc",
                          },
                          "&.Mui-disabled .MuiSlider-track": {
                            backgroundColor: "#ccc",
                          },
                          "&.Mui-disabled .MuiSlider-thumb": {
                            backgroundColor: "#ccc",
                          },
                          "& .MuiSlider-valueLabel": {
                            backgroundColor: "#279989",
                          },
                        }}
                        disabled={
                          initialForm[0]?.[`activity_${index}`]?.[
                            `risk_level`
                          ] === undefined ||
                          initialForm[0]?.[`activity_${index}`]?.[
                            `risk_level`
                          ] === ""
                        }
                        marks={marks}
                        onChange={(e) =>
                          handleActivityValueChange(
                            e.target.value,
                            "range",
                            index
                          )
                        }
                      />
                    </Box>
                  </Grid>
                  <Grid item md={0.5} xs={false} />
                  <Grid item md={5.75} xs={12}>
                    <Name
                      label="Equipment"
                      name="equipment_name"
                      onValueChange={(value) => {
                        handleActivityValueChange(
                          value,
                          "equipment_name",
                          index
                        );
                      }}
                      preFilledvalue={
                        initialForm[0]?.[`activity_${index}`]?.equipment_name ||
                        ""
                      }
                    />
                  </Grid>
                  <Grid item lg={12}>
                    <Name
                      className="multiline-textfield"
                      name="action_taken"
                      label="Action to be taken"
                      placeholder="Action to be taken"
                      multiline
                      rows="5"
                      onValueChange={(value) => {
                        handleActivityValueChange(value, "action_taken", index);
                      }}
                      preFilledvalue={
                        initialForm[0]?.[`activity_${index}`]?.action_taken ??
                        ""
                      }
                    />
                  </Grid>
                </Grid>
              </Box>
            ))}

            <Button
              variant="contained"
              color="primary"
              onClick={addActivity}
              sx={{ marginTop: "16px" }}
            >
              Add Activity
            </Button>
          </>
        )}
      </Box>
    );
  };
  // Activity Risk Assessment section Ends

  //Self-Administration of Medication Risk Assessment Begins
  const [medication, setMedication] = useState([
    { name: "", dose: "", route: "", time: "", self: "" },
  ]);

  const addMedication = () => {
    setMedication([
      ...medication,
      { name: "", dose: "", route: "", time: "", self: "" },
    ]);
  };

  const handleSelfMedicicationValueChange = (value, name, index) => {
    setInitialForm((prevState) => {
      const updatedForm = prevState.length ? [...prevState] : [{}]; // Ensure initial form is initialized
      const currentMedication = updatedForm[0]?.[`medication_${index}`] || {}; // Extract existing activities or initialize
      updatedForm[0] = {
        ...updatedForm[0],
        [`medication_${index}`]: {
          ...currentMedication,
          [name]: value, // Dynamically add or update the activity field
        },
      };
      handleChange(updatedForm); // Ensure changes trigger relevant updates
      return updatedForm;
    });
  };
  const selfAdministrationofMedicationRiskAssessment = () => {
    const radioLabels = [
      `Does ${name} know what medicines they are taking and what they are for?`,
      `Does ${name} know what time to take the medicines?`,
      `Does ${name} know what dosage to take?`,
      `Does ${name} know how to take the medicines?`,
      `Does ${name} know about any special instructions?`,
      `Does ${name} know about common, possible side effects?`,
      `Does ${name} know what to do if they miss a dose?`,
      `Does ${name} have any difficulty in reading the label on the medicines?`,
      `Does ${name} open their medication (blister packs, bottles)?`,
      `Does ${name} understand the principles of safe storage for medicines, including their responsibility for safe keeping?`,
      `Does ${name} agree to notify staff of any changes to the prescribed medication and/or over-the-counter/homely medications?`,
      `Would ${name} be responsible for the re-ordering of prescribed medication and its collection?`,
    ];
    return (
      <Box>
        <Typography variant="h4">
          Self-Administration of Medication Risk Assessment
        </Typography>
        {/* <Name label="Name of GP" placeholder="Name of GP" disabled="true" /> */}
        <Typography variant="h5">Name of GP</Typography>
        <Container sx={{ marginLeft: "-10px" }}>
          <RadioInput
            name="medication_question"
            label={`Does ${name} want to self-administer fully or partially?`}
            options={[
              { value: "fully", label: "Fully" },
              { value: "partially", label: "Partially" },
            ]}
            onValueChange={(val) => {
              handleValueChange(val, "medication_question");
            }}
            preFilledvalue={
              initialForm[0]?.medication_question?.toLowerCase() || ""
            }
          />
          {radioLabels.map((eachLabel, index) => (
            <RadioInput
              name={`medication_question_${index + 1}`}
              label={eachLabel}
              options={[
                { value: "yes", label: "Yes" },
                { value: "no", label: "No" },
              ]}
              onValueChange={(val) => {
                handleValueChange(val, `medication_question_${index + 1}`);
              }}
              preFilledvalue={
                initialForm[0]?.[
                  `medication_question_${index + 1}`
                ]?.toLowerCase() || ""
              }
            />
          ))}
        </Container>
        <Typography variant="h6">
          Please note all the medications used by {name} and Medication Details:
        </Typography>
        {medication.map((activity, index) => (
          <Box
            key={index}
            sx={{
              marginBlock: "30px",
              border: "1px solid #2799894d",
              padding: "5px 15px",
              borderRadius: "8px",
              position: "relative",
            }}
          >
            <Typography
              variant="h6"
              sx={{
                display: "inline-block",
                position: "absolute",
                margin: "-19px 0 0 10px",
                backgroundColor: "#F0FCFA",
                fontWeight: "600",
                color: "#279989",
              }}
            >
              Medication {index + 1}
            </Typography>
            <Grid container spacing={3} paddingTop={3}>
              <Grid item md={5.75} xs={12}>
                <Name
                  name="medicine_name"
                  label="Name of Medicine"
                  onValueChange={(val) => {
                    handleSelfMedicicationValueChange(
                      val,
                      "medicine_name",
                      index + 1
                    );
                  }}
                  preFilledvalue={
                    initialForm[0]?.[`medication_${index + 1}`]
                      ?.medicine_name || ""
                  }
                />
              </Grid>
              <Grid item md={0.5} xs={false} />
              <Grid item md={5.75} xs={12}>
                <Name
                  name="dose"
                  label="Dose"
                  onValueChange={(val) => {
                    handleSelfMedicicationValueChange(val, "dose", index + 1);
                  }}
                  preFilledvalue={
                    initialForm[0]?.[`medication_${index + 1}`]?.dose || ""
                  }
                />
              </Grid>
              <Grid item md={5.75} xs={12}>
                <Name
                  name="route"
                  label="Route"
                  onValueChange={(val) => {
                    handleSelfMedicicationValueChange(val, "route", index + 1);
                  }}
                  preFilledvalue={
                    initialForm[0]?.[`medication_${index + 1}`]?.route || ""
                  }
                />
              </Grid>
              <Grid item md={0.5} xs={false} />
              <Grid item md={5.75} xs={12}>
                <Name
                  name="frequency"
                  label="Time/Frequency"
                  onValueChange={(val) => {
                    handleSelfMedicicationValueChange(
                      val,
                      "frequency",
                      index + 1
                    );
                  }}
                  preFilledvalue={
                    initialForm[0]?.[`medication_${index + 1}`]?.frequency || ""
                  }
                />
              </Grid>
              <Grid item md={5.75} xs={12}>
                <Container sx={{ marginLeft: "-10px" }}>
                  <RadioInput
                    name="self_administration"
                    label="Self-Administration"
                    options={[
                      { value: "yes", label: "Yes" },
                      { value: "no", label: "No" },
                    ]}
                    onValueChange={(val) => {
                      handleSelfMedicicationValueChange(
                        val,
                        "self_administration",
                        index + 1
                      );
                    }}
                    preFilledvalue={
                      initialForm[0]?.[`medication_${index + 1}`]
                        ?.self_administration || ""
                    }
                  />
                </Container>
              </Grid>
            </Grid>
          </Box>
        ))}

        <Button
          variant="contained"
          color="primary"
          onClick={addMedication}
          sx={{ marginTop: "16px" }}
        >
          Add Medication
        </Button>
      </Box>
    );
  };
  //Self-Administration of Medication Risk Assessment Ends

  //Financial Risk Assessment Begins

  const financialRiskAssessment = () => {
    return (
      <Box>
        <Typography variant="h4">Financial Risk Assessment</Typography>
        <Container sx={{ marginLeft: "-10px" }}>
          <RadioInput
            name="require_assistance"
            label={`Does ${name} require assistance with managing your finances?`}
            options={[
              { value: "yes", label: "Yes" },
              { value: "no", label: "No" },
            ]}
            onValueChange={(v) => {
              handleValueChange(v, "require_assistance");
            }}
            preFilledvalue={
              initialForm[0]?.require_assistance?.toLowerCase() || ""
            }
          />
        </Container>
        {initialForm[0]?.require_assistance?.toLowerCase() === "yes" && (
          <Grid container spacing={3} direction="row">
            <Grid item md={5.75} xs={12}>
              <Name
                label="Family Member"
                name="family_member"
                onValueChange={(v) => {
                  handleValueChange(v, "family_member");
                }}
                preFilledvalue={initialForm[0]?.family_member || ""}
              />
            </Grid>
            <Grid item md={0.5} xs={false} />
            <Grid item md={5.75} xs={12}>
              <Name
                label="Local Authority"
                name="local_authority"
                onValueChange={(v) => {
                  handleValueChange(v, "local_authority");
                }}
                preFilledvalue={initialForm[0]?.local_authority || ""}
              />
            </Grid>
            <Grid item md={12}>
              <Typography variant="h5">Agency Name</Typography>
            </Grid>
            <Grid item md={5.75} xs={12}>
              <Name
                label="Other "
                name="other"
                onValueChange={(v) => {
                  handleValueChange(v, "other");
                }}
                preFilledvalue={initialForm[0]?.other || ""}
              />
            </Grid>
          </Grid>
        )}
        <Typography variant="h6" sx={{ marginBlock: "15px" }}>
          Where /AGENCY NAME/ has responsibility please complete
        </Typography>
        <Container sx={{ marginLeft: "-10px" }}>
          <RadioInput
            label="Is there a spending budget/ limit in place?"
            options={[
              { value: "yes", label: "Yes" },
              { value: "no", label: "No" },
            ]}
            name="spend_budget"
            onValueChange={(v) => {
              handleValueChange(v, "spend_budget");
            }}
            preFilledvalue={initialForm[0]?.spend_budget?.toLowerCase() || ""}
          />
        </Container>
        <Grid container spacing={3} alignItems="flex-end">
          <Grid item md={5.75} xs={12}>
            <Name
              label="Details"
              name="details"
              onValueChange={(v) => {
                handleValueChange(v, "details");
              }}
              preFilledvalue={initialForm[0]?.details || ""}
            />
          </Grid>
          <Grid item md={0.5} xs={false} />
          <Grid item md={5.75} xs={12}>
            <Name
              label="Who has responsibility for deciding how the money is spent?"
              name="responsibility"
              onValueChange={(v) => {
                handleValueChange(v, "responsibility");
              }}
              preFilledvalue={initialForm[0]?.responsibility || ""}
            />
          </Grid>
        </Grid>
        <Container sx={{ marginLeft: "-10px" }}>
          <RadioInput
            label="Are there any risks associated with the Service User or client who requires additional help with day to day general routine activities,’s finances?"
            options={[
              { value: "yes", label: "Yes" },
              { value: "no", label: "No" },
            ]}
            name="risk_associated"
            onValueChange={(v) => {
              handleValueChange(v, "risk_associated");
            }}
            preFilledvalue={initialForm[0]?.risk_associated || ""}
          />
        </Container>
        {initialForm[0]?.risk_associated === "yes" && (
          <Grid container>
            <Grid item md={5.75} xs={12}>
              <Name
                label="If yes, provide details"
                name="yes_risk_associated"
                onValueChange={(v) => {
                  handleValueChange(v, "yes_risk_associated");
                }}
                preFilledvalue={initialForm[0]?.yes_risk_associated || ""}
              />
            </Grid>
          </Grid>
        )}
        <Container sx={{ marginLeft: "-10px" }}>
          <RadioInput
            label="Is there a safe location to store cash and receipts? "
            options={[
              { value: "yes", label: "Yes" },
              { value: "no", label: "No" },
            ]}
            name="safe_location"
            onValueChange={(v) => {
              handleValueChange(v, "safe_location");
            }}
            preFilledvalue={initialForm[0]?.safe_location?.toLowerCase() || ""}
          />
        </Container>
        {initialForm[0]?.safe_location?.toLowerCase() === "yes" && (
          <Grid container>
            <Grid item md={5.75} xs={12}>
              <Name
                label="If yes, provide details"
                name="yes_safe_location"
                onValueChange={(v) => {
                  handleValueChange(v, "yes_safe_location");
                }}
                preFilledvalue={initialForm[0]?.yes_safe_location || ""}
              />
            </Grid>
          </Grid>
        )}
      </Box>
    );
  };
  //Financial Risk Assessment Ends

  //Equipment Register Begins
  const [equipment, setEquipment] = useState([
    {
      equipment: "",
      equipment_description: "",
      by: "",
      purpose: "",
      date_tested: "",
      test_date_completed: "",
    },
  ]);
  const addEquipment = () => {
    setEquipment([
      ...equipment,
      {
        equipment: "",
        equipment_description: "",
        by: "",
        purpose: "",
        date_tested: "",
        test_date_completed: "",
      },
    ]);
  };

  const handleEquipmentValueChange = (value, name, index) => {
    setInitialForm((prevState) => {
      const updatedForm = prevState.length ? [...prevState] : [{}]; // Ensure initial form is initialized
      const currentEquipment = updatedForm[0]?.[`equipment_${index}`] || {}; // Extract existing activities or initialize
      updatedForm[0] = {
        ...updatedForm[0],
        [`equipment_${index}`]: {
          ...currentEquipment,
          [name]: value, // Dynamically add or update the activity field
        },
      };
      handleChange(updatedForm); // Ensure changes trigger relevant updates
      return updatedForm;
    });
  };

  const equipmentRegister = () => [
    <Box>
      <Typography variant="h4">Equipment Register</Typography>
      <p>
        As part of delivering care, our Care Workers will often have to use care
        equipment, such as hoists or stand aids. These will usually be supplied
        and serviced by the Local Authority or other third party. It is an
        expectation that the Care Workers will routinely check that the
        equipment 'looks' suitable for use before carrying out any activity
        using that equipment. However, under legislation we are required to
        ensure any equipment being used by our employees is fit for use.
        <br />
        <br />
        This form must be used to record all equipment that will be used during
        the provision of care, and where appropriate, the equipment service/test
        dates..(Note)
      </p>
      {equipment.map((activity, index) => (
        <Box
          key={index}
          sx={{
            marginBlock: "25px",
            border: "1px solid #2799894d",
            padding: "5px 15px",
            borderRadius: "8px",
            position: "relative",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              display: "inline-block",
              position: "absolute",
              margin: "-19px 0 0 10px",
              backgroundColor: "#F0FCFA",
              fontWeight: "600",
              color: "#279989",
            }}
          >
            Equipment {index + 1}
          </Typography>
          <Box sx={{ paddingTop: "10px" }}>
            <Name
              name="equipment"
              label="Equipment"
              placeholder="Equipment Name"
              onValueChange={(val) => {
                handleEquipmentValueChange(val, "equipment", index);
              }}
              preFilledvalue={
                initialForm[0]?.[`equipment_${index}`]?.equipment || ""
              }
            />
          </Box>
          <Name
            name="equipment_description"
            className="multiline-textfield"
            label="Equipment Description"
            placeholder="Equipment Description"
            multiline
            rows="5"
            onValueChange={(val) => {
              handleEquipmentValueChange(val, "equipment_description", index);
            }}
            preFilledvalue={
              initialForm[0]?.[`equipment_${index}`]?.equipment_description ||
              ""
            }
          />
          <Name
            name="by"
            label="Provided by"
            placeholder="Provided by"
            onValueChange={(val) => {
              handleEquipmentValueChange(val, "by", index);
            }}
            preFilledvalue={initialForm[0]?.[`equipment_${index}`]?.by || ""}
          />
          <Name
            name="purspose"
            className="multiline-textfield"
            label="Purpose"
            placeholder="Purpose"
            multiline
            rows="5"
            onValueChange={(val) => {
              handleEquipmentValueChange(val, "purpose", index);
            }}
            preFilledvalue={
              initialForm[0]?.[`equipment_${index}`]?.purpose || ""
            }
          />
          <Grid container spacing={3}>
            <Grid item md={5.75} xs={12}>
              <Calendar
                name="date_tested"
                label="Date of next test"
                onDateChange={(val) => {
                  handleEquipmentValueChange(val, "date_tested", index);
                }}
                preFilledvalue={
                  initialForm[0]?.[`equipment_${index}`]?.date_tested || ""
                }
              />
            </Grid>
            <Grid item md={0.5} xs={false} />
            <Grid item md={5.75} xs={12}>
              <Calendar
                name="test_date_completed"
                label="Test completed on"
                onDateChange={(val) => {
                  handleEquipmentValueChange(val, "test_date_completed", index);
                }}
                preFilledvalue={
                  initialForm[0]?.[`equipment_${index}`]?.test_date_completed ||
                  ""
                }
              />
            </Grid>
          </Grid>
        </Box>
      ))}

      <Button
        variant="contained"
        color="primary"
        onClick={addEquipment}
        sx={{ marginTop: "16px" }}
      >
        Add Equipment
      </Button>
    </Box>,
  ];
  //Equipment Register Ends

  //Medication Risk Assessment Begins

  const handleMedicationValueChange = (value, name) => {
    setInitialForm((prevState) => {
      const updatedForm = prevState.length ? [...prevState] : [{}]; // Ensure initial form is initialized
      const currentMedicines = updatedForm[0] || {};
      updatedForm[0] = {
        ...currentMedicines,
        [name]: value, // Dynamically add or update
      };
      handleChange(updatedForm);
      return updatedForm;
    });
  };

  const medicationRiskAssessment = () => {
    return (
      <Box>
        <Typography variant="h4">Medication Risk Assessment</Typography>
        <Container sx={{ marginLeft: "-10px" }}>
          <Grid container spacing={3} alignItems="flex-end">
            <Grid item md={5.75} xs={12}>
              <RadioInput
                name="ordering_radio"
                label="Ordering"
                options={[
                  { value: "yes", label: "Yes" },
                  { value: "no", label: "No" },
                ]}
                onValueChange={(v) => {
                  handleMedicationValueChange(v, "ordering_radio");
                }}
                preFilledvalue={
                  initialForm[0]?.ordering_radio?.toLowerCase() || ""
                }
              />
              <Name
                name="ordering_comment"
                label="Comments"
                onValueChange={(v) => {
                  handleMedicationValueChange(v, "ordering_comment");
                }}
                preFilledvalue={initialForm[0]?.ordering_comment || ""}
              />
            </Grid>
            <Grid item md={0.5} xs={false} />
            <Grid item md={5.75} xs={12}>
              <RadioInput
                name="collecting_radio"
                label="Collecting"
                options={[
                  { value: "yes", label: "Yes" },
                  { value: "no", label: "No" },
                ]}
                onValueChange={(v) => {
                  handleMedicationValueChange(v, "collecting_radio");
                }}
                preFilledvalue={
                  initialForm[0]?.collecting_radio?.toLowerCase() || ""
                }
              />
              <Name
                name="collecting_comment"
                label="Comments"
                onValueChange={(v) => {
                  handleMedicationValueChange(v, "collecting_comment");
                }}
                preFilledvalue={initialForm[0]?.collecting_comment || ""}
              />
            </Grid>
            <Grid item md={5.75} xs={12}>
              <RadioInput
                name="verbal_prompt_radio"
                label="Verbal Prompt"
                options={[
                  { value: "yes", label: "Yes" },
                  { value: "no", label: "No" },
                ]}
                onValueChange={(v) => {
                  handleMedicationValueChange(v, "verbal_prompt_radio");
                }}
                preFilledvalue={
                  initialForm[0]?.verbal_prompt_radio?.toLowerCase() || ""
                }
              />
              <Name
                name="verbal_prompt_comment"
                label="Comments"
                onValueChange={(v) => {
                  handleMedicationValueChange(v, "verbal_prompt_comment");
                }}
                preFilledvalue={initialForm[0]?.verbal_prompt_comment || ""}
              />
            </Grid>
            <Grid item md={0.5} xs={false} />
            <Grid item md={5.75} xs={12}>
              <RadioInput
                name="assisting_radio"
                label="Assisting (physically opening boxes/bottles)"
                options={[
                  { value: "yes", label: "Yes" },
                  { value: "no", label: "No" },
                ]}
                onValueChange={(v) => {
                  handleMedicationValueChange(v, "assisting_radio");
                }}
                preFilledvalue={
                  initialForm[0]?.assisting_radio?.toLowerCase() || ""
                }
              />
              <Name
                name="assisting_comment"
                label="Comments"
                onValueChange={(v) => {
                  handleMedicationValueChange(v, "assisting_comment");
                }}
                preFilledvalue={initialForm[0]?.assisting_comment || ""}
              />
            </Grid>
            <Grid item md={5.75} xs={12}>
              <RadioInput
                name="administering_radio"
                label="Administering* (including creams, patches or lotions)"
                options={[
                  { value: "yes", label: "Yes" },
                  { value: "no", label: "No" },
                ]}
                onValueChange={(v) => {
                  handleMedicationValueChange(v, "administering_radio");
                }}
                preFilledvalue={
                  initialForm[0]?.administering_radio?.toLowerCase() || ""
                }
              />
              <Name
                name="administering_comment"
                label="Comments"
                onValueChange={(v) => {
                  handleMedicationValueChange(v, "administering_comment");
                }}
                preFilledvalue={initialForm[0]?.administering_comment || ""}
              />
            </Grid>
            <Grid item md={0.5} xs={false} />
            <Grid item md={5.75} xs={12}>
              <RadioInput
                name="specialized_support_radio"
                label="Specialized Support*"
                options={[
                  { value: "yes", label: "Yes" },
                  { value: "no", label: "No" },
                ]}
                onValueChange={(v) => {
                  handleMedicationValueChange(v, "specialized_support_radio");
                }}
                preFilledvalue={
                  initialForm[0]?.specialized_support_radio?.toLowerCase() || ""
                }
              />
              <Name
                name="specialized_support_comment"
                label="Comments"
                onValueChange={(v) => {
                  handleMedicationValueChange(v, "specialized_support_comment");
                }}
                preFilledvalue={
                  initialForm[0]?.specialized_support_comment || ""
                }
              />
            </Grid>
            <Grid item md={5.75} xs={12}>
              <RadioInput
                name="time_specific_radio"
                label="Time Specific"
                options={[
                  { value: "yes", label: "Yes" },
                  { value: "no", label: "No" },
                ]}
                onValueChange={(v) => {
                  handleMedicationValueChange(v, "time_specific_radio");
                }}
                preFilledvalue={
                  initialForm[0]?.time_specific_radio?.toLowerCase() || ""
                }
              />
              <Name
                name="time_specific_comment"
                label="Comments"
                onValueChange={(v) => {
                  handleMedicationValueChange(v, "time_specific_comment");
                }}
                preFilledvalue={initialForm[0]?.time_specific_comment || ""}
              />
            </Grid>
          </Grid>
        </Container>
        <p>
          *Where /AGENCY NAME/ is providing verbal, physical or specialized
          support or administering, a <b>MAR chart must be completed.</b> Where
          prescribed external medication (e.g. creams, patches or lotions)
          support is provided by /AGENCY NAME/, a{" "}
          <b>TMAR must also be completed.*(Notes)</b>
        </p>
        <Typography variant="h5">Controlled Drugs:</Typography>
        <Container sx={{ marginLeft: "-10px" }}>
          <Grid container spacing={3}>
            <Grid item md={5.75} xs={12}>
              <RadioInput
                name="controlled_drug_radio"
                label="Are any of the medicines a Controlled Drug?"
                options={[
                  { value: "yes", label: "Yes" },
                  { value: "no", label: "No" },
                ]}
                onValueChange={(v) => {
                  handleMedicationValueChange(v, "controlled_drug_radio");
                }}
                preFilledvalue={
                  initialForm[0]?.controlled_drug_radio?.toLowerCase() || ""
                }
              />
              {(initialForm[0]?.controlled_drug_radio || "").toLowerCase() ===
                "yes" && (
                <Name
                  name="controlled_drug_comment"
                  label="If yes, give details:"
                  onValueChange={(v) => {
                    handleMedicationValueChange(v, "controlled_drug_comment");
                  }}
                  preFilledvalue={initialForm[0]?.controlled_drug_comment || ""}
                />
              )}
            </Grid>
          </Grid>
        </Container>

        <Typography variant="h5">Ordering and Collecting:</Typography>
        <Grid container spacing={3} alignItems="flex-end">
          <Grid item md={5.75} xs={12}>
            <Name
              name="ordering_collecting_1"
              label="Where <Agency name> is responsible for the collecting and/or ordering how will #Agency be notified of this? "
              onValueChange={(v) => {
                handleMedicationValueChange(v, "ordering_collecting_1");
              }}
              preFilledvalue={initialForm[0]?.ordering_collecting_1 || ""}
            />
          </Grid>
          <Grid item md={0.5} xs={false} />
          <Grid item md={5.75} xs={12}>
            <Name
              name="ordering_collecting_2"
              label="Where not responsible, when should <Agency name> expect your medication to be collected/ordered?"
              onValueChange={(v) => {
                handleMedicationValueChange(v, "ordering_collecting_2");
              }}
              preFilledvalue={initialForm[0]?.ordering_collecting_2 || ""}
            />
          </Grid>
        </Grid>

        <Typography variant="h5">PRN Medication:</Typography>
        <Grid container spacing={3}>
          <Grid item md={5.75} xs={12}>
            <Name
              name="pnr_radio"
              label="How will you notify the Care Worker when a dose of PRN medication is required?"
              onValueChange={(v) => {
                handleMedicationValueChange(v, "pnr_radio");
              }}
              preFilledvalue={initialForm[0]?.pnr_radio || ""}
            />
          </Grid>
        </Grid>

        <Typography variant="h5">Storage:</Typography>
        <Container sx={{ marginLeft: "-10px" }}>
          <Grid container spacing={3}>
            <Grid item md={5.75} xs={12}>
              <RadioInput
                name="storage_radio"
                label="Is there a safe place to store medication? "
                options={[
                  { value: "yes", label: "Yes" },
                  { value: "no", label: "No" },
                ]}
                onValueChange={(v) => {
                  handleMedicationValueChange(v, "storage_radio");
                }}
                preFilledvalue={
                  initialForm[0]?.storage_radio?.toLowerCase() || ""
                }
              />
              {(initialForm[0]?.storage_radio || "").toLowerCase() ===
                "yes" && (
                <Name
                  name="storage_comment"
                  label="If yes, please specify where:"
                  onValueChange={(v) => {
                    handleMedicationValueChange(v, "storage_comment");
                  }}
                  preFilledvalue={initialForm[0]?.storage_comment || ""}
                />
              )}
            </Grid>
          </Grid>
        </Container>
      </Box>
    );
  };
  //Medication Risk Assessment Ends

  //Behaviours That May Challenge Risk Assessment Begins

  const behavioursThatMayChallengeRiskAssessment = () => {
    const frequencyArray = [
      "Occassionally",
      "Weekly",
      "Daily",
      "Constantly",
      "In Specific environments",
      "Other",
    ];
    const behaviourArray = ["Staff", "Relatives", "Individual", "Other"];
    const triggerArray = [
      "Personality clash",
      "Frustration",
      "Drugs/Alcohol",
      "Lack of food/water",
      "Attention seeking",
      "Domestic provocation",
      "Lack of medication",
      "Incontinence",
      "Sexual Awareness Allergic Reaction",
      "Other",
    ];
    const hazardArray = [
      "Running Away",
      "Threatening",
      "Anxiety",
      "Spitting",
      "Biting",
      "Kicking",
      "Throwing Objectives",
      "Self-harm",
      "Verbal Abuse",
      "Unpredictability",
      "Scratching",
      "Punching",
      "Head Butting",
      "Weapons",
      "Bullying",
      "Sexualised Behaviour",
      "Involuntary Movement",
      "Shouting/Screaming",
      "Other",
    ];

    const handleBehaviourValueChange = (value, name) => {
      setInitialForm((prevState) => {
        const updatedForm = prevState.length ? [...prevState] : " "; // Initialize if empty
        if (name === "other_affected_by_behaviour") {
          updatedForm[0] = {
            ...updatedForm[0],
            affected_by_behaviour: "Other",
            other_affected_by_behaviour: value,
          };
        } else {
          updatedForm[0] = {
            ...updatedForm[0],
            affected_by_behaviour: name,
            other_affected_by_behaviour: "",
          };
        }

        handleChange(updatedForm);
        return updatedForm;
      });
    };

    const handlePotentialTrigger = (value, name, index) => {
      setInitialForm((prevState) => {
        const updatedForm = prevState.length ? [...prevState] : " "; // Initialize if empty
        const potentialTriggers = updatedForm[0]?.potential_triggers || {};

        if (name === "other_potential_triggers") {
          updatedForm[0] = {
            ...updatedForm[0],
            other_potential_triggers: value,
          };
        } else {
          if (value) {
            // Add or update the trigger for the given index
            potentialTriggers[index] = name;
          } else {
            // Remove the trigger if unchecked
            delete potentialTriggers[index];
          }

          updatedForm[0] = {
            ...updatedForm[0],
            potential_triggers: potentialTriggers,
            other_potential_triggers: "",
          };
        }

        handleChange(updatedForm);
        return updatedForm;
      });
    };

    const handlePotentialHazard = (value, name, index) => {
      setInitialForm((prevState) => {
        const updatedForm = prevState.length ? [...prevState] : " "; // Initialize if empty
        const potentialHazards = updatedForm[0]?.potential_hazards || {};

        if (name === "other_potential_hazards") {
          updatedForm[0] = {
            ...updatedForm[0],
            other_potential_hazards: value,
          };
        } else {
          if (value) {
            // Add or update the trigger for the given index
            potentialHazards[index] = name;
          } else {
            // Remove the trigger if unchecked
            delete potentialHazards[index];
          }

          updatedForm[0] = {
            ...updatedForm[0],
            potential_hazards: potentialHazards,
            other_potential_hazards: "",
          };
        }

        handleChange(updatedForm);
        return updatedForm;
      });
    };

    const handlePotentialHazardSub = (value, name, index) => {
      setInitialForm((prevState) => {
        const updatedForm = prevState.length ? [...prevState] : " "; // Initialize if empty
        const levelOfRisk = updatedForm[0]?.level_of_risk || {};
        const riskRange = updatedForm[0]?.risk_range || {};
        const supportMethods = updatedForm[0]?.support_methods || {};
        const regulatoryMeasures = updatedForm[0]?.regulatory_measures || {};
        const identifiedHazard = updatedForm[0]?.identified_hazard || {};
        const controlsAdequate = updatedForm[0]?.controls_adequate || {};

        if (name === "level_of_risk") {
          levelOfRisk[index] = value;
          updatedForm[0] = {
            ...updatedForm[0],
            level_of_risk: levelOfRisk,
          };
        } else if (name === "risk_range") {
          riskRange[index] = value;
          updatedForm[0] = {
            ...updatedForm[0],
            risk_range: riskRange,
          };
        } else if (name === "controls_adequate") {
          controlsAdequate[index] = value;
          updatedForm[0] = {
            ...updatedForm[0],
            controls_adequate: controlsAdequate,
          };
        } else if (name === "support_methods") {
          supportMethods[index] = value;
          updatedForm[0] = {
            ...updatedForm[0],
            support_methods: supportMethods,
          };
        } else if (name === "regulatory_measures") {
          regulatoryMeasures[index] = value;
          updatedForm[0] = {
            ...updatedForm[0],
            regulatory_measures: regulatoryMeasures,
          };
        } else if (name === "identified_hazard") {
          identifiedHazard[index] = value;
          updatedForm[0] = {
            ...updatedForm[0],
            identified_hazard: identifiedHazard,
          };
        }

        handleChange(updatedForm);
        return updatedForm;
      });
    };

    const handleFrequencyValueChange = (value, name) => {
      setInitialForm((prevState) => {
        const updatedForm = prevState.length ? [...prevState] : " "; // Initialize if empty
        if (name === "other_frequency_potential") {
          updatedForm[0] = {
            ...updatedForm[0],
            other_frequency_potential: value,
            frequency_potential: "Other",
          };
        } else {
          updatedForm[0] = {
            ...updatedForm[0],
            frequency_potential: name,
            other_frequency_potential: "",
          };
        }

        handleChange(updatedForm);
        return updatedForm;
      });
    };

    return (
      <Box>
        <Typography variant="h4">
          Behaviours That May Challenge Risk Assessment
        </Typography>
        <p>
          Describe the Service User or client who requires additional help with
          day to day general routine activities’ behaviours as per the
          Behaviours That May Challenge assessment.(Note)
        </p>
        <Typography variant="h6">Frequency Potential</Typography>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          {frequencyArray.map((each) => {
            return (
              <FormControlLabel
                key={each}
                name={each}
                label={each}
                style={{ marginRight: "40px" }}
                control={
                  <Checkbox
                    checked={
                      initialForm[0]?.frequency_potential === each || false
                    }
                    onChange={(event) => {
                      const isChecked = event.target.checked; // Get whether the checkbox is checked
                      handleFrequencyValueChange(isChecked, each); // Pass the value and name correctly
                    }}
                  />
                }
              />
            );
          })}
        </Box>
        {initialForm[0]?.frequency_potential === "Other" && (
          <Grid>
            <Grid item md={6} xs={12}>
              <Name
                label="If Other"
                name="other_frequency_potential"
                onValueChange={(v) => {
                  handleFrequencyValueChange(v, "other_frequency_potential");
                }}
                preFilledvalue={
                  initialForm[0]?.frequency_potential
                    ?.other_frequency_potential || ""
                }
              />
            </Grid>
          </Grid>
        )}

        <Typography variant="h6">
          Who may be affected by the behaviour
        </Typography>
        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
          {behaviourArray.map((each) => {
            return (
              <FormControlLabel
                key={each}
                label={each}
                style={{ marginRight: "40px" }}
                control={
                  <Checkbox
                    checked={
                      initialForm[0]?.affected_by_behaviour === each || false
                    }
                    onChange={(event) => {
                      const isChecked = event.target.checked; // Get whether the checkbox is checked
                      handleBehaviourValueChange(isChecked, each);
                    }}
                    name={each}
                  />
                }
              />
            );
          })}
        </Box>
        {initialForm[0]?.affected_by_behaviour === "Other" && (
          <Grid>
            <Grid item md={6} xs={12}>
              <Name
                label="If Other"
                name="other_affected_by_behaviour"
                onValueChange={(v) => {
                  handleBehaviourValueChange(v, "other_affected_by_behaviour");
                }}
                preFilledvalue={
                  initialForm[0]?.affected_by_behaviour
                    ?.other_affected_by_behaviour || ""
                }
              />
            </Grid>
          </Grid>
        )}

        <Typography variant="h6">Potential Triggers</Typography>
        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
          {triggerArray.map((each, index) => {
            return (
              <FormControlLabel
                key={each}
                label={each}
                style={{ marginRight: "40px" }}
                control={
                  <Checkbox
                    checked={Object.values(
                      initialForm[0]?.potential_triggers || {}
                    ).includes(each)}
                    onChange={(event) => {
                      const isChecked = event.target.checked; // Get whether the checkbox is checked
                      handlePotentialTrigger(isChecked, each, index);
                    }}
                    name={each}
                  />
                }
              />
            );
          })}
        </Box>
        {Object.values(initialForm[0]?.potential_triggers || {}).includes(
          "Other"
        ) && (
          <Grid>
            <Grid item md={6} xs={12}>
              <Name
                label="If Other"
                name="other_potential_triggers"
                onValueChange={(v) => {
                  handlePotentialTrigger(v, "other_potential_triggers", null);
                }}
                preFilledvalue={
                  initialForm[0]?.potential_triggers
                    ?.other_potential_triggers || ""
                }
              />
            </Grid>
          </Grid>
        )}

        <Typography variant="h6">Potential Hazards</Typography>
        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
          {hazardArray.map((each, index) => {
            return (
              <FormControlLabel
                key={each}
                label={each}
                style={{ marginRight: "40px" }}
                control={
                  <Checkbox
                    checked={Object.values(
                      initialForm[0]?.potential_hazards || {}
                    ).includes(each)}
                    onChange={(event) => {
                      const isChecked = event.target.checked; // Get whether the checkbox is checked
                      handlePotentialHazard(isChecked, each, index);
                    }}
                    name={each}
                  />
                }
              />
            );
          })}
        </Box>
        {Object.values(initialForm[0]?.potential_hazards || {}).includes(
          "Other"
        ) && (
          <Grid>
            <Grid item md={6} xs={12}>
              <Name
                label="If Other"
                name="other_potential_hazards"
                onValueChange={(v) => {
                  handlePotentialHazard(v, "other_potential_hazards", null);
                }}
                preFilledvalue={
                  initialForm[0]?.potential_trigger?.if_other || ""
                }
              />
            </Grid>
          </Grid>
        )}

        {Object.keys(initialForm[0]?.potential_hazards || {}).map(
          (hazardArrayIndex) => (
            <Box
              key={hazardArrayIndex}
              sx={{
                marginBlock: "30px",
                border: "1px solid #2799894d",
                padding: "5px 15px",
                borderRadius: "8px",
                position: "relative",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  display: "inline-block",
                  position: "absolute",
                  margin: "-19px 0 0 10px",
                  backgroundColor: "#F0FCFA",
                  fontWeight: "600",
                  color: "#279989",
                }}
              >
                {initialForm[0]?.potential_hazards?.[hazardArrayIndex]}
              </Typography>
              <Grid container spacing={2} direction="row">
                <Grid item md={5.75} xs={12}>
                  <Dropdown
                    label="Level of Risk"
                    name={`level_of_risk`}
                    list={[
                      { text: "Low" },
                      { text: "Medium" },
                      { text: "High" },
                    ]}
                    onDropdownSelect={(v) =>
                      handlePotentialHazardSub(
                        v?.text,
                        "level_of_risk",
                        hazardArrayIndex
                      )
                    }
                    preFilledvalue={
                      initialForm[0]?.level_of_risk?.[hazardArrayIndex] || ""
                    }
                  />
                </Grid>

                <Grid item md={0.5} xs={false} />
                <Grid item md={5.75} xs={12} alignContent="flex-end">
                  <Box sx={{ paddingInline: "50px" }}>
                    <Slider
                      // aria-label="Risk Level"
                      defaultValue={
                        initialForm[0]?.risk_range?.[hazardArrayIndex] || 1
                      }
                      valueLabelDisplay="auto"
                      step={1}
                      marks={marks}
                      min={1}
                      max={5}
                      name="risk_range"
                      sx={{
                        color: "#279989",
                        "& .MuiSlider-thumb": {
                          backgroundColor: "#279989",
                        },
                        "& .MuiSlider-track": {
                          backgroundColor: "#279989",
                        },
                        "& .MuiSlider-rail": {
                          backgroundColor: "#ccc",
                        },

                        "&.Mui-disabled .MuiSlider-track": {
                          backgroundColor: "#ccc",
                        },
                        "&.Mui-disabled .MuiSlider-thumb": {
                          backgroundColor: "#ccc",
                        },
                        "& .MuiSlider-valueLabel": {
                          backgroundColor: "#279989",
                        },
                      }}
                      disabled={
                        initialForm[0]?.level_of_risk?.[hazardArrayIndex] ===
                          "" ||
                        initialForm[0]?.level_of_risk?.[hazardArrayIndex] ===
                          undefined
                      }
                      onChange={(event) =>
                        handlePotentialHazardSub(
                          event.target.value,
                          "risk_range",
                          hazardArrayIndex
                        )
                      }
                    />
                  </Box>
                </Grid>
                <Grid item md={5.75} xs={12}>
                  <Name
                    label="Methods of support/management"
                    name="support_methods"
                    onValueChange={(value) => {
                      handlePotentialHazardSub(
                        value,
                        "support_methods",
                        hazardArrayIndex
                      );
                    }}
                    preFilledvalue={
                      initialForm[0]?.support_methods?.[hazardArrayIndex] || ""
                    }
                  />
                </Grid>
              </Grid>
              <Grid spacing={3}>
                <Grid item md={5.75} xs={12}>
                  <Container sx={{ marginLeft: "-10px" }}>
                    <RadioInput
                      name="controls_adequate"
                      label="Are the controls adequate?"
                      options={[
                        { value: "yes", label: "Yes" },
                        { value: "no", label: "No" },
                      ]}
                      onValueChange={(value) => {
                        handlePotentialHazardSub(
                          value,
                          "controls_adequate",
                          hazardArrayIndex
                        );
                      }}
                      preFilledvalue={
                        initialForm[0]?.controls_adequate?.[hazardArrayIndex] ||
                        ""
                      }
                    />
                  </Container>
                  {initialForm[0]?.controls_adequate?.[hazardArrayIndex] ===
                    "yes" && (
                    <Name
                      name="regulatory_measures"
                      label="Regulatory Measures"
                      onValueChange={(value) => {
                        handlePotentialHazardSub(
                          value,
                          "regulatory_measures",
                          hazardArrayIndex
                        );
                      }}
                      preFilledvalue={
                        initialForm[0]?.regulatory_measures?.[
                          hazardArrayIndex
                        ] || ""
                      }
                    />
                  )}
                </Grid>
              </Grid>
            </Box>
          )
        )}
      </Box>
    );
  };
  //Behaviours That May Challenge Risk Assessment Ends

  //COSHH Risk Assessment Begins

  const [substance, setSubstance] = useState([
    {
      name: "",
      harmType: "",
      description: "",
      color: "",
      harmDetails: "",
      contact: "",
      frequency: "",
      circumstances: "",
      safeAlternative: null,
      controls: "",
      emergencyProcedures: "",
      staffAwareness: null,
      riskReduction: null,
      actionRequired: "",
    },
  ]);

  const addSubstance = () => {
    setSubstance([
      ...substance,
      {
        name: "",
        harmType: "",
        description: "",
        color: "",
        harmDetails: "",
        contact: "",
        frequency: "",
        circumstances: "",
        safeAlternative: null,
        controls: "",
        emergencyProcedures: "",
        staffAwareness: null,
        riskReduction: null,
        actionRequired: "",
      },
    ]);
  };

  const handleCoshhValueChange = (value, name, index) => {
    setInitialForm((prevState) => {
      const updatedForm = prevState.length ? [...prevState] : [{}]; // Ensure initial form is initialized
      const currentCoshh = updatedForm[0]?.[`substance_${index}`] || {}; // Extract existing activities or initialize
      updatedForm[0] = {
        ...updatedForm[0],
        [`substance_${index}`]: {
          ...currentCoshh,
          [name]: value, // Dynamically add or update the activity field
        },
      };
      handleChange(updatedForm); // Ensure changes trigger relevant updates
      return updatedForm;
    });
  };

  const coshhRiskAssessment = () => {
    const substanceArray = ["Liquid", "Solid", "Vapor", "Gas"];
    return (
      <Box>
        <Typography variant="h4">COSHH Risk Assessment</Typography>
        <p>
          This assessment will only address the risk to health from the
          substances listed. Additional risk assessments may be required to
          control the risk from other hazards associated with the work being
          conducted.(Note)
        </p>
        {substance.map((sub, index) => (
          <Box
            key={index}
            sx={{
              marginBlock: "30px",
              border: "1px solid #2799894d",
              padding: "5px 15px",
              borderRadius: "8px",
              position: "relative",
            }}
          >
            <Typography
              variant="h6"
              sx={{
                display: "inline-block",
                position: "absolute",
                margin: "-19px 0 0 10px",
                backgroundColor: "#F0FCFA",
                fontWeight: "600",
                color: "#279989",
              }}
            >
              Substance {index + 1}
            </Typography>
            <Grid container spacing={3} alignItems="flex-end">
              <Grid item md={5.75} xs={12}>
                <Name
                  label="Name of product/substance"
                  name="substance_name"
                  onValueChange={(val) => {
                    handleCoshhValueChange(val, "substance_name", index);
                  }}
                  preFilledvalue={
                    initialForm[0]?.[`substance_${index}`]?.substance_name || ""
                  }
                />
              </Grid>
              <Grid item md={0.5} xs={false} />
              <Grid item md={5.75} xs={12}>
                <Name
                  label="Type of harm the substance could cause"
                  name="substance_harm_type"
                  onValueChange={(val) => {
                    handleCoshhValueChange(val, "substance_harm_type", index);
                  }}
                  preFilledvalue={
                    initialForm[0]?.[`substance_${index}`]
                      ?.substance_harm_type || ""
                  }
                />
              </Grid>
              <Grid item md={5.75} xs={12}>
                <Typography variant="h6">
                  Description of the substance
                </Typography>
                <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                  {substanceArray.map((each) => (
                    <FormControlLabel
                      key={each}
                      label={each}
                      name="substance_description"
                      style={{ marginRight: "40px" }}
                      control={
                        <Checkbox
                          checked={
                            initialForm[0]?.[`substance_${index}`]
                              ?.substance_description === each || false
                          }
                          onChange={(e) => {
                            const checkedValue = e.target.checked ? each : "";
                            handleCoshhValueChange(
                              checkedValue,
                              "substance_description",
                              index
                            );
                          }}
                        />
                      }
                    />
                  ))}
                </Box>
                <Name
                  label="Color"
                  name="substance_color"
                  onValueChange={(val) => {
                    handleCoshhValueChange(val, "substance_color", index);
                  }}
                  preFilledvalue={
                    initialForm[0]?.[`substance_${index}`]?.substance_color ||
                    ""
                  }
                />
              </Grid>
              <Grid item md={0.5} xs={false} />
              <Grid item md={5.75} xs={12}>
                <Name
                  label="Detail how the substance causes harm (i.e. being breathed in, being swallowed etc)"
                  name="substance_cause_harm"
                  onValueChange={(val) => {
                    handleCoshhValueChange(val, "substance_cause_harm", index);
                  }}
                  preFilledvalue={
                    initialForm[0]?.[`substance_${index}`]
                      ?.substance_cause_harm || ""
                  }
                />
              </Grid>
              <Grid item md={5.75} xs={12}>
                <Name
                  label="Who is likely to come into contact with the substance?"
                  name="substance_contact"
                  onValueChange={(val) => {
                    handleCoshhValueChange(val, "substance_contact", index);
                  }}
                  preFilledvalue={
                    initialForm[0]?.[`substance_${index}`]?.substance_contact ||
                    ""
                  }
                />
              </Grid>
              <Grid item md={0.5} xs={false} />
              <Grid item md={5.75} xs={12}>
                <Name
                  label="How often is it used, or may it occur?"
                  name="how_often"
                  onValueChange={(val) => {
                    handleCoshhValueChange(val, "how_often", index);
                  }}
                  preFilledvalue={
                    initialForm[0]?.[`substance_${index}`]?.how_often || ""
                  }
                />
              </Grid>
              <Grid item md={5.75} xs={12}>
                <Name
                  label="What will the substance be used for or what circumstances/activity may produce the substance?"
                  name="substance_circumstance"
                  onValueChange={(val) => {
                    handleCoshhValueChange(
                      val,
                      "substance_circumstance",
                      index
                    );
                  }}
                  preFilledvalue={
                    initialForm[0]?.[`substance_${index}`]
                      ?.substance_circumstance || ""
                  }
                />
              </Grid>
            </Grid>

            <Container sx={{ marginLeft: "-10px" }}>
              <RadioInput
                name="safe_alternative"
                label="Can the hazardous substance, circumstances or activity be eliminated, or a safe alternative be used?"
                options={[
                  { value: "yes", label: "Yes" },
                  { value: "no", label: "No" },
                ]}
                onValueChange={(val) => {
                  handleCoshhValueChange(val, "safe_alternative", index);
                }}
                preFilledvalue={
                  initialForm[0]?.[`substance_${index}`]?.safe_alternative || ""
                }
              />
            </Container>

            {initialForm[0]?.[`substance_${index}`]?.safe_alternative ===
              "yes" && (
              <Box>
                <Grid container spacing={3} alignItems="flex-end">
                  <Grid item md={5.75} xs={12}>
                    <Name
                      label="Controls (list control measures for storage, use and handling, as appropriate)"
                      name="controls"
                      onValueChange={(val) => {
                        handleCoshhValueChange(val, "controls", index);
                      }}
                      preFilledvalue={
                        initialForm[0]?.[`substance_${index}`]?.controls || ""
                      }
                    />
                  </Grid>
                  <Grid item md={0.5} xs={false} />
                  <Grid item md={5.75} xs={12}>
                    <Name
                      label="Detail emergency procedures in case of accidental spillage or contact"
                      name="emergency_procedure"
                      onValueChange={(val) => {
                        handleCoshhValueChange(
                          val,
                          "emergency_procedure",
                          index
                        );
                      }}
                      preFilledvalue={
                        initialForm[0]?.[`substance_${index}`]
                          ?.emergency_procedure || ""
                      }
                    />
                  </Grid>
                </Grid>

                <Grid container direction="column">
                  <Grid item md={6} xs={12}>
                    <Container sx={{ marginLeft: "-10px" }}>
                      <RadioInput
                        name="staff_aware"
                        label="Are all staff aware of this assessment, in particular the controls and emergency procedures?"
                        options={[
                          { value: "yes", label: "Yes" },
                          { value: "no", label: "No" },
                        ]}
                        onValueChange={(val) => {
                          handleCoshhValueChange(val, "staff_aware", index);
                        }}
                        preFilledvalue={
                          initialForm[0]?.[`substance_${index}`]?.staff_aware ||
                          ""
                        }
                      />
                    </Container>
                    {initialForm[0]?.[`substance_${index}`]?.staff_aware ===
                      "no" && (
                      <Typography sx={{ marginTop: "10px", fontWeight: 600 }}>
                        ***NOTE: Train all staff***
                      </Typography>
                    )}
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Container sx={{ marginLeft: "-10px" }}>
                      <RadioInput
                        name="risk_reduced"
                        label="Have controls reduced the risk of harm to an acceptable level?"
                        options={[
                          { value: "yes", label: "Yes" },
                          { value: "no", label: "No" },
                        ]}
                        onValueChange={(val) => {
                          handleCoshhValueChange(val, "risk_reduced", index);
                        }}
                        preFilledvalue={
                          initialForm[0]?.[`substance_${index}`]
                            ?.risk_reduced || ""
                        }
                      />
                    </Container>
                    {initialForm[0]?.[`substance_${index}`]?.risk_reduced ===
                      "no" && (
                      <Box sx={{ marginBottom: "-15px" }}>
                        <Name
                          name="action_to_take"
                          className="multiline-textfield"
                          label="Action to be taken where required"
                          placeholder="Action to be taken"
                          multiline
                          rows="5"
                          onValueChange={(val) => {
                            handleCoshhValueChange(
                              val,
                              "action_to_take",
                              index
                            );
                          }}
                          preFilledvalue={
                            initialForm[0]?.action_to_take?.risk_reduced || ""
                          }
                        />
                      </Box>
                    )}
                  </Grid>
                </Grid>
              </Box>
            )}
          </Box>
        ))}
        <Button
          variant="contained"
          color="primary"
          onClick={addSubstance}
          sx={{ marginTop: "16px" }}
        >
          Add Substance
        </Button>
      </Box>
    );
  };

  //COSHH Risk Assessment Ends

  return (
    <Box
      sx={{
        textAlign: "Left",
        height: "100%",
        overflow: "auto",
        paddingRight: 2,
        width: "100%",
        boxShadow: "0px 0px 19px 7px #",
      }}
    >
      {assessmentId < 21 ? (
        <Box>
          {assessmentQuestions.length > 0 ? (
            assessmentQuestions
              .filter(
                (assessment) => assessment.assessment_name === assessmentName
              )
              .map((assessment, index) => (
                <Box key={index}>
                  <Typography variant="h4">
                    {assessment.assessment_name}
                  </Typography>
                  {assessment.questionslist.map((question, index, arr) => (
                    <>
                      <Typography
                        variant="h6"
                        sx={{
                          marginTop: "4px",
                          marginBottom: "-20px",
                        }}
                      >
                        {question.questionname}
                      </Typography>

                      {index === arr.length - 1 ? null : (
                        <Container sx={{ marginLeft: "-10px" }}>
                          <RadioInput
                            name={`status_${question.questionid}`}
                            options={[
                              { value: "yes", label: "Yes" },
                              { value: "no", label: "No" },
                            ]}
                            onValueChange={handleRadioChange}
                            preFilledvalue={
                              initialForm[0]?.[
                                `status_${question.questionid}`
                              ]?.toLowerCase() || "" // Dynamically access the status field
                            }
                          />

                          {initialForm[0]?.[
                            `status_${question.questionid}`
                          ]?.toLowerCase() === "yes" ? (
                            <Name
                              name={`comment_${question.questionid}`}
                              className="multiline-textfield"
                              label="Please Mention"
                              placeholder="Please Mention"
                              multiline
                              rows="7"
                              onValueChange={handleValueChange}
                              preFilledvalue={
                                initialForm[0]?.[
                                  `comment_${question.questionid}`
                                ] ?? "" // Dynamically access the status field
                              }
                            ></Name>
                          ) : null}
                        </Container>
                      )}
                    </>
                  ))}
                  <Name
                    name="finalNote"
                    className="multiline-textfield"
                    placeholder="Care properties and safety concerns"
                    multiline
                    rows="7"
                    onValueChange={handleValueChange}
                    // preFilledvalue={form.finalNote}
                  />
                </Box>
              ))
          ) : (
            <p>No assessment questions available</p>
          )}
        </Box>
      ) : assessmentId === 21 ? (
        <Box>{activityRiskAssessment()}</Box>
      ) : assessmentId === 22 ? (
        <Box>{behavioursThatMayChallengeRiskAssessment()}</Box>
      ) : assessmentId === 23 ? (
        <Box>{coshhRiskAssessment()}</Box>
      ) : assessmentId === 24 ? (
        <Box>{equipmentRegister()}</Box>
      ) : assessmentId === 25 ? (
        <Box>{financialRiskAssessment()}</Box>
      ) : assessmentId === 26 ? (
        <Box>{medicationRiskAssessment()}</Box>
      ) : assessmentId === 27 ? (
        <Box>{selfAdministrationofMedicationRiskAssessment()}</Box>
      ) : null}
      <Box>
        <FormLabel sx={{ fontSize: 18, paddingTop: "20px" }}>
          Signatures of All Involved Admins in the Assessment
        </FormLabel>
        <Grid // First signature
          container
          direction="row"
          alignItems="top"
          sx={{
            gap: 8,
            p: 2,
            borderRadius: "8px",
            backgroundColor: "#F0FCFA",
            mb: -2,
          }}
        >
          <Grid item xs={10} md={5}>
            <Name
              name="sign_1"
              label="Name"
              preFilledvalue={initialForm[0]?.sign_1 || signName} // Prefill with sign_1 or signName
              placeholder="Signature1"
              disabled={true} // Always disabled
            />
          </Grid>
          <Grid item xs={10} md={5}>
            <Calendar
              name="date_1"
              label="Date"
              onDateChange={handleDateChange}
              preFilledvalue={
                initialForm[0]?.date_1 ? initialForm[0]?.date_1 : dayjs() // Prefill with date_1 or current date
              }
              disabled={
                initialForm[0]?.sign_1 && initialForm[0]?.sign_1 !== signName
                  ? true
                  : false
              } // Disable if date_1 exists
            />
          </Grid>
        </Grid>

        {/* Second signature section */}
        {initialForm[0]?.sign_1 && initialForm[0]?.sign_1 !== signName ? (
          <Grid
            container
            direction="row"
            alignItems="top"
            sx={{
              gap: 8,
              p: 2,
              borderRadius: "8px",
              backgroundColor: "#F0FCFA",
            }}
          >
            <Grid item xs={10} md={5}>
              <Name
                name="sign_2"
                label="Name"
                placeholder="Name"
                preFilledvalue={initialForm[0].sign_2} // Prefill with sign_2 or signName
                disabled
              />
            </Grid>
            <Grid item xs={10} md={5}>
              <Calendar
                name="date_2"
                label="Date"
                onDateChange={handleDateChange} // Ensure date changes are tracked
                preFilledvalue={
                  initialForm[0]?.date_2 ? initialForm[0]?.date_2 : dayjs() // Prefill with date_2 or current date
                }
              />
            </Grid>
          </Grid>
        ) : null}

        <Grid>
          <Grid item>
            <FormControlLabel
              label={
                "I hereby confirm the accuracy of the information provided above"
              }
              control={
                <Checkbox
                  checked={isCheckboxTrue}
                  onChange={handleCheckboxChange}
                />
              }
            />
            {checkboxError && (
              <Typography variant="errmsg">Please check this box</Typography>
            )}
          </Grid>

          <Grid item sx={{ mt: 3, mb: 3 }}>
            <Box sx={{ width: "fit-content" }}>
              <Button
                variant="customcancel"
                onClick={() => {
                  if (hasUnsavedChanges) {
                    handleSave();
                  } else {
                    // toggleEditMode();
                    if (resetNewAssessmentVisibility) {
                      resetNewAssessmentVisibility();
                    }
                  }
                }}
              >
                Cancel
              </Button>

              <Button
                type="submit"
                variant="customsave"
                onClick={() => {
                  // handleSignSubmit();
                  handleSubmit();
                }}
              >
                Save
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
      {dialogOpen ? (
        <ConfirmationDialog
          openDialog={hasUnsavedChanges}
          TitleText="Do you want to leave without saving changes?"
          paraText="Any unsaved changes will be lost."
          IconUrl={Admin}
          cancelText="Cancel"
          confirmText="Confirm"
          onCancelFun={handleDialogCancel}
          onConfirmFun={handleDialogConfirm}
          // handleCloseDialog={handleClose}
        />
      ) : null}
    </Box>
  );
};
