import React, {
  useState,
  forwardRef,
  useEffect,
  useImperativeHandle,
} from "react";
import {
  Box,
  FormLabel,
  Typography,
  TextField,
  Autocomplete,
} from "@mui/material";

const RosterDropdown = forwardRef(
  (
    {
      onDropdownSelect,
      label,
      list,
      placeholder,
      preFilledvalue,
      required = false,
      nolabel = false,
      readonlytextfield = false,
    },
    ref
  ) => {
    const [dropdown, setDropdown] = useState(null);
    const [inputDropdown, setInputDropdown] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
      const matchingDropdown = list.find(
        (option) => option.text === preFilledvalue
      );

      if (matchingDropdown) {
        setDropdown(matchingDropdown);
        setInputDropdown(matchingDropdown.text);
      } else {
        setDropdown(null);
        setInputDropdown("");
      }

      setErrorMessage("");
    }, [preFilledvalue, list]);

    const handleChange = (event, newDropdown) => {
      setDropdown(newDropdown);
      setInputDropdown(newDropdown ? newDropdown.text : ""); // Update input text
      setErrorMessage("");
      onDropdownSelect(newDropdown); // Notify parent component
    };

    const handleValidation = () => {
      if (required && !dropdown) {
        setErrorMessage(`${label} is required.`);
        return false;
      }
      return true;
    };

    useImperativeHandle(ref, () => ({
      handleValidation,
    }));

    const filteredOptions = list.filter((option) => option.id !== dropdown?.id);

    return (
      <Box sx={{ textAlign: "left" }}>
        {!nolabel && (
          <FormLabel>
            <Typography variant="h6">
              {label}
              {required && <span className="star">*</span>}
            </Typography>
          </FormLabel>
        )}
        <Autocomplete
          value={dropdown}
          onChange={handleChange}
          inputValue={inputDropdown}
          onInputChange={(event, newInputDropdown) =>
            setInputDropdown(newInputDropdown)
          }
          onKeyDown={(event) => {
            if (readonlytextfield) {
              event.preventDefault(); // Prevent typing if read-only
            }
          }}
          options={filteredOptions}
          getOptionLabel={(option) => option?.text || ""}
          isOptionEqualToValue={(option, value) => option.text === value?.text}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={placeholder}
              sx={{
                "& .MuiInputBase-root": {
                  height: "28px",
                  whiteSpace: "nowrap", // Prevent text from wrapping
                  overflow: "hidden", // Hide overflowing text
                  textOverflow: "ellipsis", // Show ellipsis for overflow
                  maxWidth: "100%", // Adjust based on container width
                },
                "& .MuiInputBase-input": {
                  maxWidth: "100px", // Adjust based on container width
                  cursor: readonlytextfield && "pointer",
                  caretColor: readonlytextfield && "transparent", // Hides the cursor (caret)
                },
              }}
            />
          )}
        />
        {errorMessage && (
          <Typography variant="errmsg" sx={{ color: "red" }}>
            {errorMessage}
          </Typography>
        )}
      </Box>
    );
  }
);

export default RosterDropdown;
