import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import LogCards from "./LogCards";
import Calendar from "../reusableComponents/Calendar";
import Dropdown from "../reusableComponents/Dropdown";
import MultiselectDropdown from "../reusableComponents/MultiselectDropdown";
import LogRightDashboard from "./LogRightDashboard";
import dayjs from "dayjs";
import { getCookie } from "../../utils/utils";
import CustomCalendar from "./CustomCalendar";

const visitsData = [
  {
    clientName: "John Doe",
    carers: ["Alice Johnson", "Bob Smith"],
    runName: "Morning Run",
    completedTodos: 5,
    completedMedications: 2,
    visitDate: "2024-11-01",
    visitStatus: "Completed",
  },
  {
    clientName: "Jane Smith",
    carers: ["Catherine Blake", "Daniel Green"],
    runName: "Afternoon Run",
    completedTodos: 3,
    completedMedications: 1,
    visitDate: "2024-11-01",
    visitStatus: "In Progress",
  },
  {
    clientName: "Michael Brown",
    carers: ["Emily Davis", "Chris Wilson"],
    runName: "Evening Run",
    completedTodos: 7,
    completedMedications: 4,
    visitDate: "2024-11-01",
    visitStatus: "Completed",
  },
  {
    clientName: "Laura White",
    carers: ["Susan Lee", "Tom Harris"],
    runName: "Morning Run",
    completedTodos: 2,
    completedMedications: 0,
    visitDate: "2024-11-02",
    visitStatus: "Action Required",
  },
  {
    clientName: "Paul Black",
    carers: ["Nancy Young", "Gary Scott"],
    runName: "Afternoon Run",
    completedTodos: 6,
    completedMedications: 3,
    visitDate: "2024-11-02",
    visitStatus: "Completed",
  },
  {
    clientName: "Emma Green",
    carers: ["Diana Hall", "Tim Roberts"],
    runName: "Evening Run",
    completedTodos: 4,
    completedMedications: 2,
    visitDate: "2024-11-02",
    visitStatus: "In Progress",
  },
  {
    clientName: "Sophia Gray",
    carers: ["Megan Clark", "Ethan King"],
    runName: "Morning Run",
    completedTodos: 8,
    completedMedications: 5,
    visitDate: "2024-11-03",
    visitStatus: "Completed",
  },
  {
    clientName: "Jack Brown",
    carers: ["Olivia Lewis", "George Moore"],
    runName: "Afternoon Run",
    completedTodos: 1,
    completedMedications: 0,
    visitDate: "2024-11-03",
    visitStatus: "Follow Up",
  },
  {
    clientName: "Lily Evans",
    carers: ["Rachel Adams", "David Clark"],
    runName: "Evening Run",
    completedTodos: 5,
    completedMedications: 3,
    visitDate: "2024-11-03",
    visitStatus: "In Progress",
  },
  {
    clientName: "Henry Wilson",
    carers: ["Jasmine Cooper", "Ryan Edwards"],
    runName: "Morning Run",
    completedTodos: 3,
    completedMedications: 1,
    visitDate: "2024-11-04",
    visitStatus: "Completed",
  },
];

const status = [
  { id: "1", text: "Completed", value: "Completed" },
  { id: "2", text: "In Progress", value: "In Progress" },
  { id: "3", text: "Action Required", value: "Action Required" },
  { id: "4", text: "Follow Up", value: "Follow Up" },
];

export default function LogContent() {
  const [selectedDate, setSelectedDate] = useState(dayjs()); // Initialize with today's date
  const [startDate, setStartDate] = useState(dayjs());
  const [endDate, setEndDate] = useState(dayjs().add(1, "month"));
  const [visitData, setVisitData] = useState([]);
  const [error, setError] = useState(null); // State for error handling
  const [selectedRunsDropdown, setSelectedRunsDropdown] = useState([]);
  const [selectedStatusDropdown, setSelectedStatusDropdown] = useState([]);
  const [selectedClientsDropdown, setSelectedClientsDropdown] = useState([]);

  const clientId = 104;

  const clientList = visitsData.map((visit, index) => ({
    id: index + 1, // Generating a unique ID based on the index
    text: visit.clientName,
  }));
  const runsList = visitsData.map((visit, index) => ({
    id: index + 1, // Generating a unique ID based on the index
    text: visit.runName,
  }));

  const handleCardClick = (visit) => {
    setSelectedVisit(visit); // Update selected visit on click
  };

  const handleDateChange = (newDate) => {
    setSelectedDate(newDate);
    const newsDate = dayjs(newDate);
  };

  const visitDate = dayjs(selectedDate).format("YYYY/MM/DD");

  const fetchVisits = async (date) => {
    try {
      const token = getCookie();
      const customDate = date ? new Date(date) : new Date();
      const URL = `${
        process.env.REACT_APP_BASE_URL
      }/log-get-log-list?&fromDate=${dayjs(startDate).format(
        "YYYY-MM-DD"
      )}&toDate=${dayjs(endDate).format("YYYY-MM-DD")}`;
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await fetch(URL, options);
      const data = await response.json();

      if (response.ok) {
        const info = data.data;
        setVisitData(info); // Store the fetched visit data in the state
      } else {
        setError("Failed to fetch visit data.");
        setVisitData([]); // Clear the visit data if there's an error
      }
    } catch (error) {
      console.error("Error fetching visit data:", error);
      setError("An error occurred while fetching data.");
      setVisitData([]); // Clear the visit data if there's an error
    }
  };

  useEffect(() => {
    fetchVisits();
  }, [startDate, endDate]);

  useEffect(() => {
    if (visitData.length > 0) {
      setSelectedVisit(visitData[0]);
    } else {
      setSelectedVisit();
    }
  }, [visitData]);

  const [selectedVisit, setSelectedVisit] = useState();

  const handleRunsDropdown = (event, newValue) => {
    setSelectedRunsDropdown(newValue);
  };

  const handleStatusDropdown = (event, newValue) => {
    setSelectedStatusDropdown(newValue);
  };

  const handleClientsDropdown = (event, newValue) => {
    setSelectedClientsDropdown(newValue);
  };

  // useEffect(() => {
  //   console.log(selectedRunsDropdown);
  // }, [selectedRunsDropdown]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
      }}
    >
      {/* Top Box */}
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: "10%",
          borderBottom: "3px solid #E6F5F6",
          flexDirection: "row",
        }}
      >
        {/* Date Box */}
        <Box
          sx={{
            width: "40%",
            height: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 5,
            marginRight: "15px",
            paddingLeft: "15px",
          }}
        >
          <CustomCalendar
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
          />
        </Box>

        {/* Dropdowns Box */}
        <Box
          sx={{
            width: "60%",
            height: "100%",
            display: "flex",
            flexDirection: "row",
            gap: 5,
            marginTop: "2px",
          }}
        >
          {/* Status Dropdown */}
          <Box
            sx={{
              width: "33%",
              height: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Typography variant="h6">Status:</Typography>
            <Box sx={{ width: "80%", paddingLeft: "10px" }}>
              <MultiselectDropdown
                list={status}
                onDropdownSelect={handleStatusDropdown}
                selectedOptions={selectedStatusDropdown}
              />
            </Box>
          </Box>

          {/* Clients Dropdown */}
          <Box
            sx={{
              width: "33%",
              height: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Typography variant="h6">Client:</Typography>
            <Box sx={{ width: "80%", paddingLeft: "10px" }}>
              <MultiselectDropdown
                list={clientList}
                onDropdownSelect={handleClientsDropdown}
                selectedOptions={selectedClientsDropdown}
              />
            </Box>
          </Box>

          {/* Runs Dropdown */}
          <Box
            sx={{
              width: "33%",
              height: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Typography variant="h6">Runs:</Typography>
            <Box sx={{ width: "80%", paddingLeft: "10px" }}>
              <MultiselectDropdown
                list={runsList}
                onDropdownSelect={handleRunsDropdown}
                selectedOptions={selectedRunsDropdown}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      {/* Bottom Box */}
      <Box sx={{ display: "flex", width: "100%", height: "90%" }}>
        {/* Left Box */}
        <Box
          sx={{
            width: "40%",
            height: "100%",
            borderRight: "3px solid #E6F5F6",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "15px",
            overflow: "auto",
          }}
        >
          {visitData.map((visit, index) => (
            <LogCards
              key={index}
              visit={visit}
              onClick={() => handleCardClick(visit)}
              isSelected={selectedVisit === visit}
            />
          ))}
        </Box>
        {/* Right Box */}
        <Box sx={{ width: "60%", height: "100%" }}>
          <LogRightDashboard selectedVisit={selectedVisit} />
        </Box>
      </Box>
    </Box>
  );
}
