import React, { useState, useEffect } from "react";
import { ClientSideBarHeader } from "../clientReusableComponents/ClientSideBarHeader";

import {
  Box,
  Grid,
  Button,
} from "@mui/material";

const Tab = ({
  each,
  setActiveTabID,
  scrollToSection,
  isActiveTab = false,
}) => {
  const [isHover, setHover] = useState(false);

  return (
    <Button
      onClick={() => {
        setActiveTabID(each.id);
        scrollToSection(each.id);
      }}
      key={each.id}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      variant="outlinedSecondary"
      style={{
        border: isActiveTab
          ? "2px solid #279989"
          : isHover
          ? "2px solid #279989"
          : "2px solid rgba(39, 153, 137, 0.30)",
        color: isActiveTab ? "#279989" : isHover ? "#279989" : "#707171",
        background: "white",
        width: "100%",
        margin: "5px 0px",
        display: "flex",
        justifyContent: "start",
        "&:hover": {
          background: "white",
          color: "#279989",
        },
      }}
    >
      <svg
        width="7"
        height="14"
        viewBox="0 0 7 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 1.5L6 7L1 12.5"
          stroke={isHover ? "#279989" : "#969999"}
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <span style={{ margin: "auto" }}> {each.text}</span>
    </Button>
  );
};

export const ClientSideBar = ({
  clientMainData,
  isEditMode,
  scrollToSection,
  tabsListConstants,
  activeTabID,
  setActiveTabID,
}) => {
  const [userName, setUserName] = useState("");

  useEffect(() => {
    if (clientMainData) {
      setUserName(
        `${clientMainData?.clientInfo?.first_name || ""} 
        ${clientMainData?.clientInfo?.middle_name || ""}
        ${clientMainData?.clientInfo?.last_name || ""}
        `
      );
    }
  }, [clientMainData]);

  const slicedText = () => {
    if (clientMainData) {
      let text =
        (clientMainData?.clientInfo?.first_name[0] || "") +
        (clientMainData?.clientInfo?.last_name[0] || "");

      return text.toUpperCase();
    }

    return "";
  };

  return (
    <Box
      sx={{
        background: "#F0FCFA",
        borderRadius: "8px",
        padding: "10px",
        height: "100%",
        overflowY: "auto",
        // margin:
        // margin:"20px 0px"
      }}
    >
      {/* side bar  */}
      <ClientSideBarHeader
        clientMainData={clientMainData}
        isEditMode={isEditMode}
      />

      <Grid fullWidth>
        {tabsListConstants?.map((each) => (
          <Tab
            each={each}
            isActiveTab={each.id === activeTabID}
            setActiveTabID={setActiveTabID}
            scrollToSection={scrollToSection}
          />
        ))}
      </Grid>
    </Box>
  );
};
