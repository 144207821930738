import React, { useState, useRef } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  IconButton,
  Grid,
} from "@mui/material";
import {
  Calendar,
  Days,
  Time,
  NumberInput,
  Sessions,
} from "../../reusableComponents";
import CloseIcon from "../../../assets/icons/close.svg";
import CloseHoverIcon from "../../../assets/icons/closehover.svg";
import CancelVisit from "../../../assets/images/clients/Visits/cancelvisit.svg";
import { getCookie } from "../../../utils/utils";
import dayjs from "dayjs";

export default function CancelMultipleVisitsDialog({
  openDialog,
  clientId,
  setRefreshVisits,
  handleCloseDialog = () => {},
}) {
  const currentDate = dayjs().format("YYYY-MM-DD");
  const cancelVisits = {
    startdate: currentDate,
    enddate: "",
    days: [],
    sessions: [],
  };
  const [form, setForm] = useState(cancelVisits);
  const [startingForm, setStartingForm] = useState(form);

  const startdateRef = useRef();
  const daysRef = useRef();
  const enddateRef = useRef();
  const sessionsRef = useRef();

  const handleClose = (event, reason) => {
    // Prevent closing when clicking outside or pressing Escape
    if (reason === "backdropClick" || reason === "escapeKeyDown") {
      return;
    }
    handleCloseDialog();
  };

  const checkIsValidsGiven = () => {
    if (startdateRef.current) {
      startdateRef.current.handleValidation();
    }

    if (enddateRef.current) {
      enddateRef.current.handleValidation();
    }

    if (daysRef.current) {
      daysRef.current.handleValidation();
    }

    if (sessionsRef.current) {
      sessionsRef.current.handleValidation();
    }

    return (
      startdateRef.current.handleValidation() &&
      enddateRef.current.handleValidation() &&
      daysRef.current.handleValidation() &&
      sessionsRef.current.handleValidation()
    );
  };

  const postCancelMultipleVisitsApi = async () => {
    try {
      const token = getCookie();

      const body = {
        start_date: form.startdate,
        end_date: form.enddate,
        select_days: form.days,
        choose_sessions: form.sessions,
      };

      console.log(body, "post api body................");
      const BASE_URL = "https://3.8.158.142:8443/";
      const URL = `${BASE_URL}api/visits-cancel-multiple/${clientId}`;
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      };

      const response = await fetch(URL, options);
      const data = await response.json();

      if (response.ok && data.statusCode === 200) {
        console.log("Data posted");
        setRefreshVisits("1");
        handleCloseDialog();
      } else {
        console.log("Failed to create Visit.");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  const postCancelMultipleVisits = () => {
    if (checkIsValidsGiven() === true) {
      postCancelMultipleVisitsApi();
    }
  };

  const onConfirmFun = () => {
    postCancelMultipleVisits();
    //handleCloseDialog();
  };
  const onCancelFun = () => {
    handleCloseDialog();
  };

  const handleDateChange = (date, name) => {
    setForm({ ...form, [name]: date });
    //setForm({ ...form, startdate: value });
  };

  const handleSessionSelect = (value) => {
    const sessionIds = value.map((item) => item.id);
    setForm({ ...form, sessions: sessionIds });
  };

  return (
    <Dialog
      open={openDialog}
      onClose={handleClose} // Custom close handler
      PaperProps={{
        sx: {
          width: "50%", // Set your desired width here
          maxWidth: "900px", // Prevent it from shrinking to smaller widths
        },
      }}
    >
      <Box textAlign="center" justifyContent="Center">
        {/* Close button */}
        <IconButton
          aria-label="close"
          onClick={handleCloseDialog}
          sx={{
            position: "absolute",
            right: 1,
            top: 10,
            width: "20px !important",
            height: "20px !important",
            backgroundImage: `url(${CloseIcon})`, // Default close.svg
            backgroundSize: "cover",
            "&:hover": {
              backgroundImage: `url(${CloseHoverIcon})`, // On hover, change to closehover.svg
            },
          }}
        >
          {/* Empty content since the SVG icons are used in background */}
        </IconButton>
        <DialogContent sx={{ margin: "0px 30px" }}>
          <Box sx={{ marginTop: "10px" }}>
            <img
              style={{
                width: "10%",
                height: "auto",
              }}
              src={CancelVisit}
              alt="Cancel Visit"
            />
            <Typography variant="h3">Cancel Multiple Visits</Typography>
          </Box>

          <Box>
            <Grid container direction="row" alignItems="Top">
              <Grid item xs={12} md={5.75}>
                <Calendar
                  ref={startdateRef}
                  label="Start Date"
                  onDateChange={(value) =>
                    setForm({ ...form, startdate: value })
                  }
                  name="startdate"
                  minDate={currentDate}
                  required
                />
              </Grid>
              <Grid item md={0.5} xs={false} />
              <Grid item xs={12} md={5.75}>
                <Calendar
                  ref={enddateRef}
                  label="End Date"
                  onDateChange={handleDateChange}
                  name="enddate"
                  //preFilledvalue={}
                  minDate={form.startdate}
                  //required
                />
              </Grid>
              <Grid item xs={12} md={12} lg={5.75} sx={{ marginTop: "5px" }}>
                <Days
                  ref={daysRef}
                  //label="Select your preferred days"
                  //initialSelectedDays={["Mon", "Wed"]}
                  onDaysChange={(value) => setForm({ ...form, days: value })}
                  //   selectAllLabel="Select all days"
                  //ref={daysRef} // Attach ref
                  required
                />
              </Grid>
              <Grid item md={0.5} xs={false} />
              <Grid item xs={12} md={12} lg={5.75}>
                <Sessions
                  ref={sessionsRef}
                  onSessionSelect={handleSessionSelect}
                  required
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="customcancel" onClick={onCancelFun} color="primary">
            Cancel
          </Button>
          <Button variant="customsave" onClick={onConfirmFun} color="primary">
            Save
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
