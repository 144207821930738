import React, { useState } from "react";

import Header from "../components/common/header/Header";
import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";

const DashboardLayout = () => {
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  //   const handleSaveChanges = () => {
  //     setHasUnsavedChanges(false);
  //   };
  return (
    <div>
      <Header
        hasUnsavedChanges={hasUnsavedChanges}
        setHasUnsavedChanges={setHasUnsavedChanges}
      />

      <Box
        sx={{
          width: "92%",
          boxShadow: "none",
          margin: "0px auto",
          background: "transparent",
        }}
      >
        {/* Render child routes here */}
        <Outlet context={[hasUnsavedChanges, setHasUnsavedChanges]} />
      </Box>
    </div>
  );
};

export default DashboardLayout;
