import React, { useState, useEffect, useCallback } from "react";
import { Container, Grid, Card, Button, Box, Typography } from "@mui/material";
import { ClientHeader } from "../../clients/clientReusableComponents/components";
import Admin from "../../../assets/images/users/Admin.svg";
import { ViewTheme } from "../../../themes/Theme";
import { ThemeProvider } from "@mui/material/styles";
import { EditTheme } from "../../../themes/Theme";
import EYE from "../../../assets/images/users/view.svg";
import ASSESMENT_FILE from "../../../assets/images/users/assesments.svg";
import { getCookie } from "../../../utils/utils";
import EditIcon from "@mui/icons-material/Edit";
import { AssessmentCardView } from "./AssessmentCardView";
import { AssessmentCardEdit } from "./AssessmentCardEdit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import HistoricalAssessmentCard from "./HistoricalAssessmentCards";
import CarePlanInformationDialog from "./carePlanInformationDialog";

const AssessmentFormView = ({
  clientId,
  name,
  assessmentName,
  assessmentId,
  selectedCard,
}) => {
  console.log("selectedcardclient:", selectedCard);
  const [assessmentQuestions, setAssessmentQuestions] = useState([]);

  const [isEditToggle, setIsEditToggle] = useState(false);

  const [inActiveAssessment, setInActiveAssessment] = useState([]);

  const [isNewAssessmentToggle, setIsNewAssessmentToggle] = useState(false);

  const [isNewAssessmentVisible, setIsNewAssessmentVisible] = useState(true);

  const resetNewAssessmentVisibility = () => {
    setIsEditToggle(false); // Exit edit mode
    setIsNewAssessmentToggle(false); // Ensure new assessment mode is canceled
    setIsNewAssessmentVisible(true); // Show "New Assessment" button again
  };

  const toggleEditMode = () => {
    if (!isEditToggle) {
      setIsEditToggle(true);
      setIsNewAssessmentToggle(false); // Ensure "Edit" mode doesn't trigger "New Assessment" logic
    } else {
      setIsEditToggle(false);
    }
  };

  // const toggleNewAssessmentMode = () => {
  //   setIsNewAssessmentToggle((prevState) => {
  //     if (!prevState) {
  //       setIsEditToggle(true); // Enable edit mode for "New Assessment"
  //       setIsNewAssessmentVisible(false); // Hide "New Assessment" button
  //     }
  //     return !prevState;
  //   });
  // };

  const toggleNewVisible = () => {
    setIsNewAssessmentToggle((prevState) => {
      if (!prevState) {
        setIsEditToggle(true); // Enable edit mode for "New Assessment"
        setIsNewAssessmentVisible(false); // Hide "New Assessment" button
      }
      return !prevState;
    });
  };

  const userInfo = localStorage.getItem("user");
  const user = JSON.parse(userInfo);

  const fetchAssessmentQuestions = async () => {
    //if (!clientId) return;
    try {
      const token = getCookie();
      const URL = `${process.env.REACT_APP_BASE_URL}/careplan-assessment-questions-list`;
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await fetch(URL, options);
      const data = await response.json();

      if (response.ok) {
        // Map the response to transform it as required
        const modifiedAssessmentList = data.finalData.map((assessment) => ({
          assessment_name: assessment.assessment_name,
          questionslist: assessment.questions.map((question) => ({
            questionid: question.id,
            questionname: question.question_name.replace("<name>", name),
          })),
        }));

        // Update the state with the modified data
        setAssessmentQuestions(modifiedAssessmentList);
        // console.log(
        //   "modifiedAssessmentList",
        //   modifiedAssessmentList.assessment_name
        // );
      } else {
        setAssessmentQuestions([]);
      }
    } catch (e) {
      console.log("Error fetching assessment questions:", e.message);
      setAssessmentQuestions([]);
    }
  };
  useEffect(() => {
    fetchAssessmentQuestions();
  }, []);

  const fetchAssessmetDetails = async () => {
    try {
      const token = getCookie();
      const URL = `${process.env.REACT_APP_BASE_URL}/${selectedCard.api_assessment_name}?client_id=${selectedCard.client_id}`;
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await fetch(URL, options);

      const data = await response.json();
      if (response.ok) {
        const inactiveData = data.data.filter(
          (item) => item.status === "Inactive"
        );
        console.log("££££££££££££££££££££££££", inactiveData);
        if (inactiveData.length > 0) {
          setInActiveAssessment(inactiveData);
        } else {
          setInActiveAssessment([]); // Reset if no active items found
        }
      } else {
        setInActiveAssessment([]);
      }
    } catch (e) {
      console.error("Error fetching assessment info:", e.message);
    }
  };

  useEffect(() => {
    fetchAssessmetDetails();
    console.log("INACTIVEEEEEEEEEEEEEEEE", inActiveAssessment);
  }, [selectedCard]);

  useEffect(() => {
    // console.log(
    //   "assessmentQuestions~~~~~~~~~~~~~~~~~~~~~~~",
    //   inActiveAssessment
    // );

    assessmentQuestions.forEach((assessment, index) => {
      assessment.questionslist.forEach((question) => {
        // console.log("Question Name:", question.questionname);
      });
    });
  }, [assessmentQuestions]);

  return (
    <ThemeProvider theme={EditTheme}>
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          padding: "0px 10px !important",
          paddingTop: "15px !important",
        }}
        maxWidth={false}
      >
        {/* <Card> */}
        <ClientHeader head={`${name}'s Care Plan`} isHideButton={true} />
        {!isEditToggle ? (
          <Button
            variant="outlined"
            color="secondary"
            startIcon={<EditIcon />}
            onClick={toggleEditMode}
            sx={{
              margin: 0,
              height: "35px",
              position: "absolute",
              top: "16px",
              zIndex: 11,
              right: "220px",
            }}
          >
            Edit
          </Button>
        ) : null}
        {isNewAssessmentVisible && (
          <Button
            variant="outlined"
            color="secondary"
            onClick={toggleNewVisible}
            sx={{
              margin: 0,
              height: "35px",
              position: "absolute",
              top: "16px",
              right: "25px",
              zIndex: 11,
            }}
          >
            New Assessment
          </Button>
        )}

        <Box sx={{ marginTop: "20px" }}>
          <Grid container direction="row" alignItems="top">
            <Grid item lg={9} xs={8}>
              <Card
                variant="elevated"
                elevation={0}
                sx={{
                  height: "calc(100vh - 325px)",
                  paddingRight: 2,
                  margin: 0,
                  marginRight: "20px",
                  backgroundColor: isEditToggle ? "#F0FCFA" : "white",
                }}
              >
                {isEditToggle ? (
                  <AssessmentCardEdit
                    assessmentQuestions={assessmentQuestions}
                    assessmentName={assessmentName}
                    assessmentId={assessmentId}
                    user={user}
                    toggleEditMode={toggleEditMode}
                    name={name}
                    selectedCard={selectedCard}
                    isNewAssessmentToggle={isNewAssessmentToggle}
                    resetNewAssessmentVisibility={resetNewAssessmentVisibility} // Pass the callback
                  ></AssessmentCardEdit>
                ) : (
                  <AssessmentCardView
                    assessmentQuestions={assessmentQuestions}
                    assessmentName={assessmentName}
                    assessmentId={assessmentId}
                    user={user}
                    name={name}
                    selectedCard={selectedCard}
                    setInActiveAssessment={setInActiveAssessment}
                  ></AssessmentCardView>
                )}
              </Card>
            </Grid>
            <Grid item lg={3} xs={4}>
              <Card
                variant="elevated"
                elevation={0}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  height: "calc(100vh - 325px)",
                  margin: 0,
                  marginLeft: "20px",
                }}
              >
                <Box
                  sx={{
                    padding: ViewTheme.spacing(2, 1),
                    width: "100%",
                    textAlign: "center",
                    overflow: "auto",
                  }}
                >
                  <Box
                    sx={{
                      border: "1px solid #279989",
                      backgroundColor: "#279989",
                      color: "#fff",
                      fontFamily: "Arvo",
                      fontSize: "16px",
                      borderRadius: "8px",
                      padding: "8px 5px",
                    }}
                  >
                    Historical Assessments
                  </Box>
                  <Box sx={{ paddingBlock: "15px" }}>
                    {inActiveAssessment.length > 0
                      ? inActiveAssessment.map((inactiveassessment, index) => (
                          <HistoricalAssessmentCard
                            // key={index} // Ensure a unique key
                            inactiveassessment={inactiveassessment} // Pass assessment data
                            assessmentQuestions={assessmentQuestions}
                            assessmentName={assessmentName}
                            user={user}
                          />
                        ))
                      : null}
                  </Box>
                </Box>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default AssessmentFormView;
