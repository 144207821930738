import {
  Card,
  Box,
  Typography,
  Button,
  TextField,
  InputLabel,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  OutlinedInput,
  Autocomplete,
  Grid,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { ThemeProvider } from "@mui/material/styles";
import { EditTheme, ViewTheme } from "../../components/users/Theme";
//import { Container, Grid, Card, Button, Typography, Box, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';


import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export const getRequiredOption = (required) => {
  switch (required) {
    case "required":
      return <span style={{ color: "#279989" }}>*</span>;
    case "optional":
      return <span style={{ color: "#969999" }}> (optional) </span>;

    default:
      return "";
  }
};

export const InputEl = ({
  textLabel = "",
  placeholder = "",
  required = "",
  errorMessage = "",
  requiredMarginBottom = "20px",
  value = "",
  onChange = () => {},
  ...params
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        flexDirection: "column",
        marginBottom: requiredMarginBottom,
      }}
    >
      
        {textLabel && (
          <FormLabel sx={{ fontSize: "15px" }}>
            {textLabel}
            {getRequiredOption(required)}
          </FormLabel>
        )}

        <TextField
          fullWidth
          variant="outlined"
          placeholder={placeholder}
          size="small"
          style={{ marginLeft: "0px", marginRight: "0px" }}
          value={value}
          onChange={onChange}
        ></TextField>
      

      {errorMessage && (
        <Typography
          variant="body0"
          sx={{
            textAlign: "left",
            fontSize: "10px",
            lineHeight: "18px",
            color: "red",
            padding: "0px",
            marginTop: "-3px",
          }}
        >
          {errorMessage}
        </Typography>
      )}
    </div>
  );
};

export const SelectEl = ({
  listData = [],
  textLabel = "",
  placeholder = "",
  required = "",
  errorMessage = "",
  value = "",
  onChange = () => {},
  placeholderLable = "",
  ...params
}) => {
  const menuItemStyle = {
    position: "relative",
    marginTop: "1px",
    "&:hover": {
      background: "#CACACA",
    },
    fontSize: "12px", // Adjust font size
  };

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        flexDirection: "column",
        marginBottom: "20px",
      }}
    >
      <Grid container direction="column">
        {textLabel && (
          <FormLabel>
            {textLabel}
            {getRequiredOption(required)}
          </FormLabel>
        )}
      </Grid>

      <Autocomplete
        value={value}
        onChange={onChange}
        options={listData}  // Pass your data array here
        getOptionLabel={(option) => option?.text || ""}
        IconComponent={ChevronRightIcon}
        renderInput={(params) => <TextField {...params} placeholder={placeholder} style={{ marginLeft: "0px", marginRight: "0px" }}/>}
      />
      {errorMessage && (
        <Typography
          variant="body0"
          sx={{
            textAlign: "left",
            fontSize: "10px",
            lineHeight: "18px",
            color: "red",
            padding: "0px",
            marginTop: "-3px",
          }}
        >
          {errorMessage}
        </Typography>
      )}
    </div>
  );
};

export const StyledRadio = ({
  value,
  labelText,
  checked = false,
  onChange = () => {},

  ...params
}) => {
  return (
    <FormControlLabel
      value={value}
      control={
        <Radio
          {...params}
          checked={checked}
          onChange={onChange}
          sx={{
            // color: "black", // Default radio color
            "& .MuiSvgIcon-root": {
              width: "16px", // Smaller width
              height: "16px", // Smaller height
            },
            "&.Mui-checked": {
              color: "#279989",
            },
          }}
        />
      }
      label={labelText}
      sx={{
        fontSize: "22px",
        color: "red",
        "& .MuiFormControlLabel-label": {
          fontSize: "14px",
          color: "black",
        },
      }}
    />
  );
};
