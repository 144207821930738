import React from "react";
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";

import { ThemeProvider } from "@mui/material/styles";
import {EditTheme} from "../../components/users/Theme"

export default function ConfirmationDialog({
  openDialog,
  TitleText = "",
  paraText = "",
  IconUrl = "",
  cancelText = "",
  confirmText = "",
  onCancelFun = () => {},
  onConfirmFun = () => {},
  handleCloseDialog = () => {},
}) {
  return (
    <ThemeProvider theme={EditTheme}>
      {/* Confirmation Dialog */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <Box textAlign="center" justifyContent="Center">
          <DialogContent>
            <Box>
              <img
                style={{
                  width: "25%",
                  height: "auto",
                }}
                src={IconUrl}
                alt="Admin"
              />
            </Box>
            <DialogContentText>
              <Typography variant="h2">{TitleText}</Typography>
              <Typography variant="body2">{paraText}</Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="customcancel"
              onClick={onCancelFun}
              color="primary"
            >
              {cancelText}
            </Button>
            <Button variant="customsave" onClick={onConfirmFun} color="primary">
              {confirmText}
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </ThemeProvider>
  );
}
