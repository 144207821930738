import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { TabButton, UserListBox } from "../users/userPersonal/Components";
import { Box } from "@mui/material";
import LogVisitDetails from "./LogTabsContent/LogVisitDetails";
import LogToDos from "./LogTabsContent/LogToDo";
import LogMedication from "./LogTabsContent/LogMedication";
import LogCarerNotes from "./LogTabsContent/LogCarerNotes";
import LogTimeline from "./LogTabsContent/LogTimeline";

const tabsList = [
  { id: 1, text: "Visit Details" },
  { id: 2, text: "To-Do's" },
  { id: 3, text: "Medication" },
  { id: 4, text: "Carer Notes" },
  { id: 5, text: "Timeline" },
];

export default function LogRightDashboard({ selectedVisit }) {
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState(1);

  // Handle tab switching with unsaved changes confirmation
  const handleTabSwitch = (tabId) => {
    setActiveTab(tabId);
  };

  const renderActiveTabContent = () => {
    switch (activeTab) {
      case 1:
        return <LogVisitDetails selectedVisit={selectedVisit} />;

      case 2:
        return <LogToDos selectedVisit={selectedVisit} />;

      case 3:
        return <LogMedication selectedVisit={selectedVisit} />;

      case 4:
        return <LogCarerNotes />;
      case 5:
        return <LogTimeline />;
      default:
        return null; // or a default component
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Box
        component="div"
        sx={{
          background: "white",
          width: "95%",
          position: "relative",
          borderRadius: "15px",
          marginTop: "45px",
        }}
      >
        <Box
          component="div"
          sx={{
            display: "flex",
            alignItems: "center",
            position: "absolute",
            top: 10,
            left: "50%",
            transform: "translate(-50%, -100%)",
            zIndex: 1,
          }}
        >
          {tabsList.map((each) => (
            <TabButton
              key={each.id}
              isActive={activeTab === each.id}
              onClick={() => handleTabSwitch(each.id)}
              data={each}
            />
          ))}
        </Box>

        <Box
          sx={{
            marginTop: "15px",
            borderRadius: "10px",
            height: "calc(100vh - 330px)",
            //boxShadow: "0px 0px 10px 0px #27998933",
            overflow: "hidden",
          }}
        >
          {" "}
          {renderActiveTabContent()}{" "}
          {/* Render the content based on the active tab */}
        </Box>
      </Box>
    </Box>
  );
}
