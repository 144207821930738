import React from "react";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import userLogo from "../../../assets/images/common/userLogo.png";
import LogoutModal from "./LogoutModal";
import ConfirmationDialog from "../../reusableComponents/ConfirmationDialog";
import Admin from "../../../assets/images/users/Admin.svg";

export default function UserLogin({ hasUnsavedChanges, setHasUnsavedChanges }) {
  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [pendingNavigation, setPendingNavigation] = useState(false);
  const userInfo = localStorage.getItem("user");
  const user = JSON.parse(userInfo);

  const handlepopup = () => {
    if (hasUnsavedChanges) {
      setPendingNavigation(true);
      handleOpenDialog();
    } else {
      setOpen(true);
    }
  };
  const handleOpenDialog = () => setOpenDialog(true);
  const handleCloseDialog = () => {
    setOpenDialog(false);
    setPendingNavigation(false);
  };
  const onClickYesDialogBtn = () => {
    if (pendingNavigation) {
      setOpen(true);
    }
    setHasUnsavedChanges(false); // Reset unsaved changes after confirming dialog
    handleCloseDialog();
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        boxShadow: "none",
      }}
    >
      {open && <LogoutModal closeModal={() => setOpen(false)} />}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "50%",
          fontSize: "12px",
          position: "relative",
          flexGrow: 0,
          height: "40px",
          width: "40px",
        }}
      >
        <img
          style={{
            position: "absolute",
            height: "100%",
            width: "100%",
            flexShrink: 0,
            borderRadius: "inherit",
            border: 0,
          }}
          src={user?.profile_photo || userLogo}
          alt="user logo"
        />
      </Box>
      <Typography
        variant="body0"
        sx={{
          fontSize: "12px",
          margin: "0px",
          padding: "0px 10px",
          textAlign: "left",
        }}
      >
        Hello! <br />
        {user?.first_name + " " + user?.last_name || "Harprit Sigh"}
      </Typography>
      <Box
        sx={{
          marginTop: "auto",
          marginBottom: "2px",
        }}
      >
        <KeyboardArrowDownIcon
          sx={{
            cursor: "pointer",
            width: "20px",
            color: "black",
            transform: open ? "rotate(180deg)" : "rotate(0deg)",
          }}
          onClick={handlepopup}
        />
      </Box>
      <ConfirmationDialog
        openDialog={openDialog}
        TitleText="Do you want to leave without saving changes?"
        paraText="Any unsaved changes will be lost."
        IconUrl={Admin}
        cancelText="Cancel"
        confirmText="Confirm"
        onCancelFun={handleCloseDialog}
        onConfirmFun={onClickYesDialogBtn}
        handleCloseDialog={handleCloseDialog}
      />
    </Box>
  );
}
