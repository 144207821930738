import React, { useState, useEffect, useRef } from "react";
import {
  Grid,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Autocomplete,
} from "@mui/material";
import vector from "../../../assets/images/clients/Vector.svg";
import { getCookie } from "../../../utils/utils";
import { Name, Email, RadioInput } from "../../reusableComponents";
import ProfilePhotoClose from "../../../assets/images/ProfilePhotoPopupIcons/ProfilePhotoClose.svg";
import ProfilePhotoCloseHover from "../../../assets/images/ProfilePhotoPopupIcons/ProfilePhotoCloseHover.svg";
import PopupCloseButton from "../clientReusableComponents/PopupCloseButton";

const ShareAccessPopup = ({
  accessAvailed,
  clientId,
  onClose = () => {},
  openDialogue = false,
  handleRefresh,
}) => {
  const [radio, setRadio] = useState("");
  const [networkInfo, setNetworkInfo] = useState([]);
  const [mcn, setMCN] = useState("");
  const [inputmcn, setInputMCN] = useState("");
  const [mcnData, setMcnData] = useState("");
  const initialSaMcn = {
    occupation_type: "",
    email: "",
    reason: "",
  };
  const [shareAccessMcn, setShareAccessMcn] = useState(initialSaMcn);
  const orgNameRef = useRef();
  const emailRef = useRef();

  const handleClose = () => onClose();

  useEffect(() => {
    const fetchNetwork = async () => {
      try {
        const token = getCookie();
        const URL = `${process.env.REACT_APP_BASE_URL}/care-network-get-all/${clientId}`;
        const options = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        };
        const response = await fetch(URL, options);
        const data = await response.json();

        if (!response.ok) setNetworkInfo([]);
        const info = data.data;
        const idleMCN = accessAvailed.filter(
          (a) => a.role === "Third Party" && a.status === "Active"
        );

        if (!idleMCN.length) setNetworkInfo([]);
        const mcns = idleMCN.map((m) => m.mcn_id);
        const regMcn = info.filter(
          (m) => !mcns.includes(m.id) && m.email !== ""
        );

        setNetworkInfo(regMcn);
      } catch (e) {
        console.error("error", e.message);
      }
    };
    fetchNetwork();
  }, [clientId]);

  const handleRadioChange = (rad) => {
    setRadio(rad);
    if (rad === "0") {
      setMCN("");
      setMcnData([]);
      setShareAccessMcn(initialSaMcn);
    } else {
      setMcnData(networkInfo);
      setShareAccessMcn(networkInfo);
    }
  };
  const handleMCNChange = (e, newmcn) => {
    setMCN(newmcn);
    const finalMcn = networkInfo.find((m) => m.id === newmcn.id);
    setMcnData(finalMcn);
    setShareAccessMcn({
      occupation_type: finalMcn.occupation_type,
      email: finalMcn.email,
    });
  };

  const handleChange = (newValue, name) => {
    setShareAccessMcn((prevFormData) => {
      const updatedForm = {
        ...prevFormData,
        [name]: newValue,
      };
      // setHasUnsavedChanges(!areObjectsEqual(updatedForm, networkInfoOriginal));
      return updatedForm;
    });
  };

  const shareAccess = async () => {
    let isValid = true;
    if (orgNameRef.current && !orgNameRef.current.handleValidation()) {
      isValid = false;
    }
    if (emailRef.current && !emailRef.current.handleValidation()) {
      isValid = false;
    }
    if (isValid) {
      provideClientAccess();
    } else {
      console.error(
        "Form is invalid. Please correct the errors and try again."
      );
    }
  };

  const provideClientAccess = async () => {
    if (!clientId) return;
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/access?client_id=${clientId}`;
      const token = getCookie();
      const accessData = {
        client_id: clientId,
        name: mcnData?.occupation_type || shareAccessMcn.occupation_type,
        email: mcnData?.email || shareAccessMcn.email,
        reason: mcnData?.reason || shareAccessMcn.reason,
        mcnId: mcnData?.id || 0,
        role: "Third Party",
        status: "Active",
      };
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(accessData),
      };
      const response = await fetch(url, options);
      const data = await response.json();
      if (response.ok && data.statusCode === 200) {
        handleClose();
        handleRefresh();
      } else {
        console.log("issue fetching");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  return (
    <Dialog
      open={openDialogue}
      onClose={handleClose}
      PaperProps={{
        sx: {
          borderRadius: "20px",
          maxWidth: "415px",
          width: "100%",
        },
      }}
    >
      <DialogTitle sx={{ display: "flex" }}>
        <PopupCloseButton
          buttonText=""
          onClickButton={handleClose}
          imgUrlBeforeHover={ProfilePhotoClose}
          imgUrlAfterHover={ProfilePhotoCloseHover}
          styles={{
            position: "absolute",
            right: "0",
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        />
      </DialogTitle>
      <DialogContent>
        <Grid container justifyContent="center" alignItems="center">
          <img src={vector} alt="Admin" />
          <Grid
            container
            direction="row"
            alignItems="Top"
            sx={{ padding: "10px" }}
          >
            <Grid item md={12} xs={12}>
              <RadioInput
                // ref={lpaRef}
                label="Share Access with Existing Care Network ?"
                name="shareAccess"
                // required
                options={[
                  { value: "1", label: "Yes" },
                  { value: "0", label: "No" },
                ]}
                onValueChange={(val) => handleRadioChange(val)}
                // preFilledvalue={networkInfo.org_lpa_behalf.toString()}
              />
            </Grid>
            {radio === "1" ? (
              <Grid item md={12} xs={12}>
                <Box
                  sx={{
                    textAlign: "left",
                  }}
                >
                  <Autocomplete
                    value={mcn || ""}
                    onChange={handleMCNChange}
                    inputValue={inputmcn}
                    onInputChange={(e, newInputmcn) => setInputMCN(newInputmcn)}
                    options={networkInfo}
                    getOptionLabel={(option) => option?.occupation_type || ""}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="My Care Network" />
                    )}
                  />
                </Box>
              </Grid>
            ) : null}
            <Grid item md={12} xs={12}>
              <Name
                ref={orgNameRef}
                label="Name/ Organization"
                placeholder="First Name"
                onValueChange={handleChange}
                name="occupation_type"
                disabled={mcnData?.occupation_type}
                preFilledvalue={mcnData?.occupation_type}
                required
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <Email
                ref={emailRef}
                disabled={mcnData?.email}
                preFilledvalue={mcnData?.email}
                onEmailValueChange={handleChange}
                name="email"
                required
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <Name
                className="multiline-textfield"
                multiline
                label="Reason"
                placeholder="Reason"
                onValueChange={handleChange}
                name="reason"
                rows={2}
              />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="customcancel" onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button variant="customsave" onClick={shareAccess} color="primary">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ShareAccessPopup;
