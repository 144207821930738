import React, { useState } from "react";
import Cropper from "react-easy-crop";
import Slider from "@mui/material/Slider";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import ImgDialog from "./ImgDialog";
import getCroppedImg from "./cropImage";
import ProfilePhotoCloseHover from "../../../assets/images/ProfilePhotoPopupIcons/ProfilePhotoCloseHover.svg";
import ProfilePhotoClose from "../../../assets/images/ProfilePhotoPopupIcons/ProfilePhotoClose.svg";
import { ProfilePhotoButton } from "../../users/userPersonal/Components";

// Styled components
const CropContainer = styled("div")({
  position: "relative",
  width: "100%",
  height: 400,
  width: 400,
  background: "#333",
  margin: "auto",
});

const Controls = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginBottom: "50px",

  background: "red",
});

const SliderContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  width: "80%",
  marginTop: 10,
});

const Demo = ({ open, setOpen, selectedImage, setImage, setSelectedImage }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const showCroppedImage = async () => {
    try {
      const croppedImage = await getCroppedImg(
        selectedImage,
        croppedAreaPixels,
        rotation
      );

      setCroppedImage(croppedImage);
      // setImage(croppedImage);
      setSelectedImage(croppedImage);
      setOpen(false);
    } catch (e) {
      console.error(e);
    }
  };

  const onClose = () => {
    setCroppedImage(null);
  };

  return (
    <div
      style={{
        position: "fixed",
        height: "100%",
        width: "100%",
        top: 0,
        left: 0,
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        zIndex: 11,
      }}
    >
      <div
        style={{
          margin: "auto",
          background: "white",
          padding: "20px",
          borderRadius: "10px",
          width: "fit-content",
          // height:"95vh",
          // overflowY:"auto"
          maxHeight: "650px",
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        <Typography
          onClick={() => setOpen(false)}
          style={{
            marginLeft: "auto",
            textAlign: "right",
            padding: 0,
            margin: 0,
            marginBottom: "10px",
            marginTop: "-20px",
            marginRight: "-25px",
            cursor: "pointer",
            color: "#269988",
            fontWeight: "900",
            display: "flex",
            justifyContent: "flex-end",
            // background:"red",
          }}
        >
          {/* X */}
          <ProfilePhotoButton
            onClickButton={() => setOpen(false)}
            imgUrlBeforeHover={ProfilePhotoClose} // url before hove the img
            imgUrlAfterHover={ProfilePhotoCloseHover} // url after hover imge
          />
        </Typography>
        <CropContainer>
          <Cropper
            image={selectedImage}
            crop={crop}
            rotation={rotation}
            zoom={zoom}
            aspect={3 / 3}
            onCropChange={setCrop}
            onRotationChange={setRotation}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
          />
        </CropContainer>

        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <SliderContainer>
            <Typography
              variant="overline"
              sx={{
                marginRight: "55px",
                fontFamily: "Arvo",
                fontWeight: 400,
                fontSize: "12px",
                color: "#1E3037",
                fontStyle: "normal",
                marginBottom: "-5px",
                lineHeight: "10px",
              }}
            >
              ZOOM
            </Typography>
            <Slider
              sx={{ color: "#269988" }}
              value={zoom}
              min={1}
              max={3}
              step={0.1}
              onChange={(e, zoom) => setZoom(zoom)}
            />
          </SliderContainer>

          <SliderContainer>
            <Typography
              variant="overline"
              sx={{
                marginRight: "15px",
                fontFamily: "Arvo",
                fontWeight: 400,
                fontSize: "12px",
                color: "#1E3037",
                fontStyle: "normal",
                marginBottom: "0px",
              }}
            >
              ROTATION
            </Typography>
            <Slider
              sx={{ color: "#269988" }}
              value={rotation}
              min={0}
              max={360}
              step={1}
              onChange={(e, rotation) => setRotation(rotation)}
            />
          </SliderContainer>

          <Button
            onClick={showCroppedImage}
            variant="contained"
            color="primary"
            sx={{
              // marginRight: "15px",
              marginTop: "10px",
              fontFamily: "Arvo",
              fontWeight: 400,
              fontSize: "16px",
              color: "#FFFFFF",
              fontStyle: "normal",
            }}
          >
            Save
          </Button>
        </div>

        <ImgDialog img={croppedImage} onClose={onClose} />
      </div>
    </div>
  );
};

export default Demo;
