import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from "react";
import {
  Box,
  FormLabel,
  Typography,
  TextField,
  Autocomplete,
} from "@mui/material";
import { getCookie } from "../../utils/utils";

const Religion = forwardRef(
  ({ onReligionSelect, preFilledvalue, required = false }, ref) => {
    const [Religion, setReligion] = useState("");
    const [inputReligion, setInputReligion] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [originalReligions, setOriginalReligions] = useState([]);

    useEffect(() => {
      const fetchReligions = async () => {
        try {
          const token = getCookie();
          const BASE_URL = "https://3.8.158.142:8443/";
          const URL = `${BASE_URL}api/getAllReligions`;
          const options = {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          };

          const response = await fetch(URL, options);
          const data = await response.json();

          if (response.ok) {
            const modifiedReligionList = data.data.map((each) => ({
              ...each,
              id: each.id,
              text: each.religion,
              value: each.religion,
            }));
            setOriginalReligions(modifiedReligionList);

            const selectedReligion =
              modifiedReligionList.find(
                (option) => option.text === preFilledvalue
              ) ||
              // modifiedReligionList.find(
              //   (option) => option.text === "American Samoa"
              // ) ||
              null;

            setReligion(selectedReligion || "");
            setInputReligion(selectedReligion?.text || "");
          } else {
            setOriginalReligions([]);
            setReligion("");
            setInputReligion("");
          }
        } catch (e) {
          console.log("error", e.message);
          setOriginalReligions([]);
          setReligion("");
          setInputReligion("");
        }
      };

      fetchReligions();
      setErrorMessage("");
    }, [preFilledvalue]);

    const handleChange = (e, newReligion) => {
      setReligion(newReligion);
      setErrorMessage(""); // Clear error when a Religion is selected
      onReligionSelect(newReligion); // Pass the selected Religion to the parent
    };

    const handleValidation = () => {
      if (required && !Religion) {
        setErrorMessage("Religion is required.");
        return false;
      }
      return true;
    };

    // Expose the validation function to the parent component
    useImperativeHandle(ref, () => ({
      handleValidation,
    }));

    return (
      <Box sx={{ textAlign: "left" }}>
        <FormLabel>
          <Typography variant="h6">
            Religion
            {required && <span className="star">*</span>}
          </Typography>
        </FormLabel>
        <Autocomplete
          value={Religion}
          onChange={handleChange}
          inputValue={inputReligion}
          onInputChange={(e, newInputReligion) =>
            setInputReligion(newInputReligion)
          }
          options={originalReligions}
          getOptionLabel={(option) => option?.text || ""}
          renderInput={(params) => (
            <TextField {...params} placeholder="Select Religion" />
          )}
        />
        {errorMessage && (
          <Typography variant="errmsg">{errorMessage}</Typography>
        )}
      </Box>
    );
  }
);

export default Religion;
