import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import {
  Box,
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
  FormLabel,
} from "@mui/material";
import { getCookie } from "../../utils/utils";

// Reusable Days Component
const Days = forwardRef(
  (
    {
      label = "Choose day in a week", // Label for the component
      initialSelectedDays = [], // Array of initially selected days (using ids)
      onDaysChange, // Callback when selected days change
      selectAllLabel = "Select All Days In The Week", // Label for the "select all" option
      required = false, // Required field
      disabled = false, // Disable the component
    },
    ref
  ) => {
    const [selectedDays, setSelectedDays] = useState(initialSelectedDays); // Selected day ids
    const [daysList, setDaysList] = useState([]); // Days fetched from API (with ids and names)
    const [errorMessage, setErrorMessage] = useState(""); // Error message state

    // Fetch the days from the API
    useEffect(() => {
      const fetchDays = async () => {
        try {
          const token = getCookie(); // Fetch the token from cookies
          const BASE_URL = "https://3.8.158.142:8443/"; // Base URL for your API
          const URL = `${BASE_URL}api/getAllDays`; // Endpoint for fetching days
          const options = {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          };

          const response = await fetch(URL, options);
          const data = await response.json();

          if (response.ok) {
            // Set the daysList with both id and day_name from the API response
            setDaysList(data.data);
          } else {
            setDaysList([]); // Handle error case
          }
        } catch (e) {
          console.log("Error fetching days:", e.message);
          setDaysList([]); // In case of an error, clear the list
        }
      };

      fetchDays(); // Fetch days when the component mounts
    }, []);

    // Handle individual day selection (using id)
    const toggleDaySelection = (id) => {
      const updatedDays = selectedDays.includes(id)
        ? selectedDays.filter((d) => d !== id) // Remove day if already selected
        : [...selectedDays, id]; // Add day if not selected
      setSelectedDays(updatedDays);
      setErrorMessage(""); // Clear any error message when a day is selected
      onDaysChange && onDaysChange(updatedDays); // Call parent callback if provided
    };

    // Handle "select all" toggle
    const toggleSelectAll = (checked) => {
      const updatedDays = checked ? daysList.map((day) => day.id) : []; // Use ids for all or none
      setSelectedDays(updatedDays);
      setErrorMessage(""); // Clear error message when days are selected
      onDaysChange && onDaysChange(updatedDays);
    };

    const areAllDaysSelected = selectedDays.length === daysList.length;

    // Validation function
    const handleValidation = () => {
      if (required && selectedDays.length === 0) {
        setErrorMessage(`${label} is required.`);
        return false;
      }
      setErrorMessage(""); // Clear error if validation passes
      return true;
    };

    // Expose the validation function to the parent component
    useImperativeHandle(ref, () => ({
      handleValidation,
    }));

    return (
      <Box sx={{ textAlign: "left" }}>
        {/* Label */}
        <FormLabel>
          <Typography variant="h6">
            {label}
            {required && <span className="star">*</span>}
          </Typography>
        </FormLabel>

        {/* Grid of days */}
        <Grid
          container
          justifyContent="space-between"
          sx={{ marginTop: "10px" }}
        >
          {daysList.map((day, index) => {
            const isDaySelected = selectedDays.includes(day.id); // Check if the id is selected
            return (
              <Grid item key={index}>
                <Box
                  onClick={() => !disabled && toggleDaySelection(day.id)} // Use id for toggling
                  disabled
                  sx={{
                    border: "1.5px solid #2799894d",
                    textTransform: "capitalize",
                    height: 33,
                    width: 36,
                    borderRadius: "5px",
                    display: "flex",
                    alignItems: "center",
                    background: isDaySelected
                      ? "#279989"
                      : disabled
                      ? "#dddddd"
                      : "none",
                    color: isDaySelected ? "#FFF" : "#1E3037",
                    fontWeight: isDaySelected ? 700 : 400,
                    fontFamily: "Arvo",
                    fontSize: "15px",
                    justifyContent: "center",
                    cursor: disabled ? "default" : "pointer",
                    "&:hover": !isDaySelected &&
                      !disabled && {
                        background: "#279989",
                        color: "#FFF",
                      },
                    "&:disabled": {
                      background: "#dddddd",
                      color: "#FFF",
                      cursor: "default", // Optional: Change cursor to indicate it's disabled
                    },
                  }}
                >
                  {day.day_name} {/* Display the day name */}
                </Box>
              </Grid>
            );
          })}
        </Grid>

        {/* Select All Option */}
        <Box sx={{ width: "100%", textAlign: "left", marginTop: "auto" }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={areAllDaysSelected}
                onChange={(e) => toggleSelectAll(e.target.checked)}
                disabled={disabled}
              />
            }
            label={selectAllLabel}
            sx={{
              "& .MuiFormControlLabel-label": {
                fontFamily: "Arvo", // Custom font family
                fontSize: "12px", // Custom font size
                color: "#48464D",
              },
            }}
          />
        </Box>

        {/* Error Message */}
        {errorMessage && (
          <Typography variant="errmsg">{errorMessage}</Typography>
        )}
      </Box>
    );
  }
);

export default Days;
