import React from 'react'
import Header from '../components/common/header/Header'

const ReportPage = () => {
  return (
    <div>
      <Header/>
        <h1>ReportPage</h1>
    </div>
  )
}

export default ReportPage