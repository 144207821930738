import React, { useEffect, useState, useRef } from "react";
import {
  Card,
  Box,
  Typography,
  Button,
  TextField,
  InputLabel,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Container,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ThemeProvider } from "@mui/material/styles";
import { EditTheme, ViewTheme } from "../Theme";

import { getCookie } from "../../../utils/utils";

import Admin from "../../../assets/images/users/Admin.svg";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import CountrySelect from "../CountrySelect";

import { useNavigate } from "react-router-dom";

import { InputEl, SelectEl, StyledRadio } from "../InputsEl";
import ConfirmationDialog from "../../reusableComponents/ConfirmationDialog";
import ContactNumber from "../../reusableComponents/Contact";
import Prefix from "../../reusableComponents/Prefix";
import Name from "../../reusableComponents/Name";
import Email from "../../reusableComponents/Email";

const initialForm = {
  prefix: "",
  inputValue: "",
  firstName: "",
  middleName: "",
  lastName: "",
  phoneNumber: "",
  email: "",
  admin: false,
  role: "",
  telephoneCodes: "",
  prefixError: "",
  firstNameError: "",
  middleNameError: "",
  lastNameError: "",
  phoneNumberError: "",
  emailError: "",
  roleError: "",
};

export default function AddUserForm() {
  const [form, setForm] = useState(initialForm);
  const [startingForm, setStartingForm] = useState(form);

  const contactRef = useRef();
  const prefixRef = useRef();
  const firstNameRef = useRef();
  const middleNameRef = useRef();
  const lastNameRef = useRef();
  const emailRef = useRef();
  const roleNameRef = useRef();

  const [selectedCountry, setSelectedCountry] = useState(217);

  const [openDialog, setOpenDialog] = useState(false);
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [value, setValue] = useState("");
  const [inputValue, setInputValue] = useState("");

  const [emailExistsError, setEmailExistError] = useState("");
  const [contactExistsError, setContactExistsError] = useState("");

  const showToastMessage = () => {
    toast.success("User added successfully", {
      position: "top-right",
      autoClose: 3000, // Duration in milliseconds
    });
  };

  // Handle country selection in the Contact component
  const handleCountrySelect = (country) => {
    if (country !== null) {
      setSelectedCountry(country.id);
    }
    setContactExistsError("");
  };

  const onChangeContactNumber = (contactVal) => {
    setForm({ ...form, phoneNumber: contactVal });
  };

  // Handle Prefix selection
  const onChangePrefixVal = (prefixVal) => {
    if (prefixVal !== null) {
      setForm({ ...form, prefix: prefixVal.text });
    } else {
      setForm({ ...form, prefix: "" });
    }
  };

  //Handle First name
  const onChangeFirstName = (firstNameVal) => {
    setForm({ ...form, firstName: firstNameVal });
  };

  //Handle Middle name
  const onChangeMiddleName = (middleNameVal) => {
    setForm({ ...form, middleName: middleNameVal });
  };

  //Handle last name
  const onChangeLastName = (lastNameVal) => {
    setForm({ ...form, lastName: lastNameVal });
  };

  //Handle Email
  const onChangeEmail = (emailValue) => {
    setForm({ ...form, email: emailValue });
    setEmailExistError("");
  };

  //Handle Role Name
  const onChangeRole = (roleNameVal) => {
    setForm({ ...form, role: roleNameVal });
  };

  const showToastFailureMessage = () => {
    toast.error("Failed to add user", {
      position: "top-right",
    });
  };

  const handleOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const onClickYesDialogBtn = () => {
    navigate("/users");
  };

  const checkIsValidsGiven = () => {
    let errorData = {
      prefixError: "",
      firstNameError: "",
      lastNameError: "",
      phoneNumberError: "",
      emailError: "",
      roleError: "",
    };

    // prefix validation
    if (prefixRef.current) {
      // Call the handleValidation method from the child component
      prefixRef.current.handleValidation();
    }

    // first name validation
    if (firstNameRef.current) {
      // Call the handleValidation method from the child component
      firstNameRef.current.handleValidation();
    }

    // last name validation
    if (lastNameRef.current) {
      // Call the handleValidation method from the child component
      lastNameRef.current.handleValidation();
    }

    // phone number validation
    if (contactRef.current) {
      // Call the handleValidation method from the child component
      contactRef.current.handleValidation();
    }

    // email validation
    if (emailRef.current) {
      // Call the handleValidation method from the child component
      emailRef.current.handleValidation();
    }

    // role name  validation
    if (roleNameRef.current) {
      // Call the handleValidation method from the child component
      roleNameRef.current.handleValidation();
    }

    if (form.admin) {
      return (
        prefixRef.current.handleValidation() &&
        firstNameRef.current.handleValidation() &&
        lastNameRef.current.handleValidation() &&
        contactRef.current.handleValidation() &&
        emailRef.current.handleValidation() &&
        roleNameRef.current.handleValidation()
      );
    }

    return (
      prefixRef.current.handleValidation() &&
      firstNameRef.current.handleValidation() &&
      lastNameRef.current.handleValidation() &&
      contactRef.current.handleValidation() &&
      emailRef.current.handleValidation()
    );
  };

  const postUserAPI = async () => {
    try {
      const token = getCookie();
      setEmailExistError("");
      setContactExistsError("");

      const body = {
        prefix: form.prefix,
        first_name: form.firstName,
        middle_name: form.middleName,
        last_name: form.lastName,
        telephone_codes: selectedCountry,
        contact_number: form.phoneNumber,
        email: form.email,
        admin: form.admin ? 1 : 0,
        role: form.role,
      };

      const BASE_URL = "https://3.8.158.142:8443/";
      // const BASE_URL = "http://3.8.158.142:3000/";
      const URL = `${BASE_URL}api/user`;
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      };

      const response = await fetch(URL, options);
      const data = await response.json();

      if (response.ok && data.statusCode === 200) {
        showToastMessage();

        setTimeout(() => {
          navigate("/users");
        }, 4000); // Delay in milliseconds
      } else {
        // email already exists

        if (data.message === "Email already exists") {
          setEmailExistError(data.message);
        }

        // contact already exists
        if (data.message === "Contact number already exists") {
          setContactExistsError(data.message);
        }

        showToastFailureMessage();
        console.log("Failed to create user.");
      }
    } catch (error) {
      console.error("An error occurred:", error);
      showToastFailureMessage();
    }
  };

  const postUser = () => {
    if (checkIsValidsGiven() === true) {
      postUserAPI();
    } else {
      handleClose();
    }
  };
  const onSubmitForm = (e) => {
    e.preventDefault();
    postUser();
  };

  const elementWidth = {
    // width: "22%",
    width: { xs: "100%", md: "45%", lg: "22%" },
    gap: 1,
  };

  return (
    <ThemeProvider theme={EditTheme}>
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          //width: { md: "75%", xs: "90%" },
        }}
        maxWidth={false}
      >
        <Box
          sx={{ elementWidth }}
          onSubmit={onSubmitForm}
          component="form"
          noValidate
          autoComplete="off"
        >
          <Grid container direction="row" alignItems="Top">
            <Grid item xs={12} md={1.5}>
              <Prefix ref={prefixRef} onPrefixSelect={onChangePrefixVal} />
            </Grid>
            <Grid item md={0.5} xs={false} />
            <Grid item xs={12} md={3}>
              <Name
                label={"First Name"}
                placeholder={"First Name"}
                onValueChange={onChangeFirstName}
                required={true}
                ref={firstNameRef}
                maxLength={35}
              />
            </Grid>
            <Grid item md={0.5} xs={false} />
            <Grid item xs={12} md={3}>
              <Name
                label={"Middle Name"}
                placeholder={"Middle Name"}
                onValueChange={onChangeMiddleName}
                required={false}
                ref={middleNameRef}
                maxLength={35}
              />
            </Grid>
            <Grid item md={0.5} xs={false} />
            <Grid item xs={12} md={3}>
              <Name
                label={"Last Name"}
                placeholder={"Last Name"}
                onValueChange={onChangeLastName}
                required={true}
                ref={lastNameRef}
                maxLength={35}
              />
            </Grid>

            <Grid item md={5} xs={12}>
              <ContactNumber
                onValueChange={onChangeContactNumber}
                onCountrySelect={handleCountrySelect}
                preFilledcode="+44"
                //defaultCountry={selectedCountry} // Pass the default country value
                ref={contactRef}
                contactExistsError={contactExistsError}
              />
            </Grid>
            <Grid item md={0.5} xs={false} />
            <Grid item md={6.5} xs={12}>
              <Email
                onEmailValueChange={onChangeEmail}
                ref={emailRef}
                required
                emailExistsError={emailExistsError}
              />
            </Grid>

            <Grid item xs={12}>
              <InputLabel
                sx={{
                  textAlign: "left",
                  fontSize: "14px",
                  fontWeight: 400,
                  color: "black",
                  marginBottom: "5px",
                  fontFamily: "Arvo",
                }}
              >
                Admin
              </InputLabel>

              <RadioGroup
                aria-label="gender"
                name="gender"
                sx={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <StyledRadio
                  value="yes"
                  labelText="Yes"
                  checked={form.admin}
                  onChange={() => {
                    setForm({ ...form, admin: !form.admin });
                  }}
                />
                <StyledRadio
                  value="no"
                  labelText="No"
                  checked={!form.admin}
                  onChange={() => {
                    setForm({ ...form, admin: !form.admin });
                  }}
                />
              </RadioGroup>

              <Grid sx={{ paddingBottom: "20px" }}>
                {form.admin === true && (
                  <Name
                    label={"Role Name"}
                    placeholder={"Role Name"}
                    onValueChange={onChangeRole}
                    required={true}
                    ref={roleNameRef}
                    maxLength={35}
                  />
                )}
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Box sx={{ width: "fit-content" }}>
                <Button
                  variant="customcancel"
                  onClick={() => {
                    if (JSON.stringify(startingForm) === JSON.stringify(form)) {
                      navigate("/users");
                    } else {
                      handleOpen();
                    }
                  }}
                >
                  Cancel
                </Button>
                <Button type="submit" variant="customsave">
                  Save
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>

        {/* Confirmation Dialog */}
        {openDialog && (
          <ConfirmationDialog
            openDialog={openDialog}
            TitleText="Are you sure you want to exit without saving the changes?"
            paraText="This will disable web application access to the user."
            IconUrl={Admin}
            cancelText="Cancel"
            confirmText="Yes"
            onCancelFun={() => {
              // navigate("/users");
              handleClose();
            }}
            onConfirmFun={onClickYesDialogBtn}
            handleCloseDialog={handleClose}
          />
        )}

        <ToastContainer />
      </Container>
    </ThemeProvider>
  );
}
