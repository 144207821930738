import React, { useState } from "react";
import { Box, Grid, Card, Typography } from "@mui/material";
import { Name } from "../../../reusableComponents";
import ActionIcon from "../../../../assets/images/userTableIcons/UserTableEdit.svg";
import HoverActionIcon from "../../../../assets/images/userTableIcons/UserTableEditHover.svg";
import Todo_Right_Tic_Icon from "../../../../assets/images/clients/todoList_Icon/Todo_Right_Tic_Icon.svg";
import Todo_Close_Icon from "../../../../assets/images/clients/todoList_Icon/Todo_Close_Icon.svg";

const VisitNotes = () => {
  // Array of five notes with unique placeholders or labels
  const notesArray = [
    { id: 1, label: "Note 1", placeholder: "Enter Note 1" },
    { id: 2, label: "Note 2", placeholder: "Enter Note 2" },
    { id: 3, label: "Note 3", placeholder: "Enter Note 3" },
    { id: 4, label: "Note 4", placeholder: "Enter Note 4" },
    { id: 5, label: "Note 5", placeholder: "Enter Note 5" },
  ];

  // State to keep track of the editable note ID
  const [editableNoteId, setEditableNoteId] = useState(null);
  const [noteValues, setNoteValues] = useState(
    notesArray.map((note) => note.placeholder)
  );

  // Handle icon click to toggle edit mode
  const handleIconClick = (id) => {
    setEditableNoteId((prevId) => (prevId === id ? null : id));
  };

  // Handle note value change
  const handleNoteChange = (index, value) => {
    const updatedValues = [...noteValues];
    updatedValues[index] = value; // Update the specific note value
    setNoteValues(updatedValues); // Update state with new values
  };

  // Handle saving changes
  const handleSave = (id) => {
    setEditableNoteId(null); // Exit edit mode
  };

  // Handle canceling changes
  const handleCancel = () => {
    setEditableNoteId(null); // Exit edit mode without saving
  };

  return (
    <Grid item xs={12} sx={{ margin: "10px" }}>
      {/* Render notes if array is not empty */}
      {notesArray.length > 0 ? (
        notesArray.map((note, index) => (
          <Box key={note.id} sx={{ marginBottom: "16px" }}>
            {/* Header with icon and label */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: "-20px",
              }}
            >
              <Typography variant="h6">{note.label}</Typography>
              {editableNoteId === note.id ? (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box
                    sx={{
                      width: "20px",
                      height: "20px",
                      cursor: "pointer", // Ensure cursor changes to pointer
                      position: "relative", // Make the box position relative
                      marginRight: "10px",
                      paddingTop: "1px",
                    }}
                    onClick={() => handleSave(note.id)} // Call the click handler
                  >
                    <img
                      src={Todo_Right_Tic_Icon}
                      alt="Todo_Right_Tic_Icon"
                      style={{ width: "100%", height: "100%" }} // Fill the container
                    />
                  </Box>
                  <Box
                    sx={{
                      width: "16px",
                      height: "16px",
                      cursor: "pointer", // Ensure cursor changes to pointer
                      position: "relative", // Make the box position relative
                      marginRight: "5px",
                    }}
                    onClick={() => handleCancel(note.id)} // Call the click handler
                  >
                    <img
                      src={Todo_Close_Icon}
                      alt="Todo_Close_Icon"
                      style={{ width: "100%", height: "100%" }} // Fill the container
                    />
                  </Box>
                </Box>
              ) : (
                <Box
                  sx={{
                    width: "24px",
                    height: "24px",
                    cursor: "pointer", // Ensure cursor changes to pointer
                    position: "relative", // Make the box position relative
                    "&:hover img": {
                      content: `url(${HoverActionIcon})`, // Change the image on hover
                    },
                  }}
                  onClick={() => handleIconClick(note.id)} // Call the click handler
                >
                  <img
                    src={ActionIcon}
                    alt="Action Icon"
                    style={{ width: "100%", height: "100%" }} // Fill the container
                  />
                </Box>
              )}
            </Box>
            {/* Note text area */}
            <Name
              className="multiline-textfield"
              preFilledvalue={noteValues[index]} // Show the current note value
              onValueChange={(e) => handleNoteChange(index, e.target.value)} // Handle change
              multiline
              rows="4"
              sx={{ marginTop: "4px" }} // Adjust margin if needed
              disabled={editableNoteId !== note.id} // Keep it disabled when not editing
            />
          </Box>
        ))
      ) : (
        // Display "No Notes Available" message if array is empty
        <Card
          sx={{
            backgroundColor: "#FFFFFF",
            padding: "10px",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "100px",
          }}
        >
          <Typography>No Notes Available</Typography>
        </Card>
      )}
    </Grid>
  );
};

export default VisitNotes;
