import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import {
  Box,
  FormLabel,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

const ClientInfoCheckBoxList = forwardRef(
  (
    { label, labelList, onCheckBoxChange, checkedList, required = false },
    ref
  ) => {
    const [errorMessage, setErrorMessage] = useState("");
    // const [checkedList, setCheckedList] = useState([]);

    // Function to handle changes in the checkbox list
    const handleCheckboxChange = (value) => {
      let updatedList;

      if (!checkedList.includes(value)) {
        updatedList = [...checkedList, value]; // Add the item if checked
      } else {
        updatedList = checkedList.filter((item) => item !== value); // Remove the item if unchecked
      }

      console.log("updatedList..........", updatedList);
      onCheckBoxChange(updatedList); // Notify the parent component of changes
      setErrorMessage("")
    };

    // Function to validate the input
    const handleValidation = () => {  
      if (required && checkedList.length === 0) {
        setErrorMessage(`${label} is required.`);
        return false;
      }
      setErrorMessage("");
      return true;
    };

    // Expose the validation function to the parent component
    useImperativeHandle(ref, () => ({
      handleValidation,
    }));

    

    return (
      <Box sx={{ textAlign: "left" }}>
        <FormLabel>
          <Typography variant="h6">
            {label}
            {required && <span style={{ color: "#279989" }}>*</span>}
          </Typography>
        </FormLabel>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          {labelList.map((each) => {
            return (
              <FormControlLabel
                key={each}
                label={each}
                style={{ marginRight: "40px" }}
                control={
                  <Checkbox
                    checked={checkedList.includes(each)}
                    onChange={() => handleCheckboxChange(each)}
                    name={each}
                  />
                }
              />
            );
          })}
        </Box>

        {errorMessage && (
          <Typography variant="caption" color="error">
            {errorMessage}
          </Typography>
        )}
      </Box>
    );
  }
);

export default ClientInfoCheckBoxList;
