import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { Box, FormLabel, Typography, TextField } from "@mui/material";

const Name = forwardRef(
  (
    {
      nolabel = false,
      label,
      preFilledvalue,
      name,
      placeholder,
      onValueChange,
      required = false,
      AllCapital = false,
      maxLength,
      disabled = false,
      multiline = false,
      rows = 0,
      className,
    },
    ref
  ) => {
    const [value, setValue] = useState(preFilledvalue || "");
    const [errorMessage, setErrorMessage] = useState("");
    useEffect(() => {
      if (preFilledvalue) {
        setValue(preFilledvalue);
        setErrorMessage("");
      } else {
        setValue("");
        setErrorMessage("");
      }
    }, [preFilledvalue]);
    // Function to capitalize the first letter of each word
    const capitalizeFirstLetter = (str) => {
      if (AllCapital) {
        return str.toUpperCase();
      } else {
        return str.replace(/\b\w/g, (char) => char.toUpperCase());
      }
    };

    // Function to handle changes in the text field
    const handleChange = (event) => {
      if (disabled) return;
      const inputValue = event.target.value;
      const sanitizedValue = sanitizeInput(inputValue);

      const capitalizedValue = capitalizeFirstLetter(sanitizedValue);

      setValue(capitalizedValue);
      setErrorMessage(""); // Clear error message as soon as value changes
      onValueChange(capitalizedValue, event.target.name); // Notify parent component about value change
    };

    // Function to sanitize input by allowing only alphabets and numbers
    const sanitizeInput = (input) => {
      return String(input).replace(/[^a-zA-Z0-9 ]/g, ""); // Convert input to string and remove special characters except spaces
    };

    // Function to validate the input
    const handleValidation = () => {
      const strValue = String(value).trim(); // Ensure value is treated as a string
      if (required && !strValue) {
        setErrorMessage(`${label} is required.`);
        return false;
      }
      if (!/^[a-zA-Z0-9 ]*$/.test(strValue)) {
        setErrorMessage(`Please enter valid ${label}.`);
        return false;
      }
      if (maxLength && strValue.replace(/-/g, "").length > maxLength) {
        setErrorMessage(`${label} cannot exceed ${maxLength} digits.`);
        return false;
      }
      setErrorMessage("");
      return true;
    };

    // Expose the validation function to the parent component
    useImperativeHandle(ref, () => ({
      handleValidation,
    }));

    return (
      <Box sx={{ textAlign: "left" }}>
        {!nolabel && (
          <FormLabel>
            <Typography variant="h6">
              {label}
              {required && <span className="star">*</span>}
            </Typography>
          </FormLabel>
        )}
        <TextField
          fullWidth
          variant="outlined"
          placeholder={placeholder}
          value={value}
          name={name}
          inputProps={{ maxLength }} // Restrict max length in the input field itself
          onChange={handleChange}
          disabled={disabled}
          multiline={multiline}
          rows={rows}
          className={className}
        />
        {errorMessage && (
          <Typography variant="errmsg">{errorMessage}</Typography>
        )}
      </Box>
    );
  }
);

export default Name;
